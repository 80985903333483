import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpClub'

// LIST CONTACT FRANCHISE
export const contacts = (params = '') => Http.get(`/contact-franchise${serialize(params)}`)

// DELETE FRANCHISE MESSAGE
export const deleteContact = (params = '') => Http.delete(`/contact-franchise/${params.id}`)

// CONTACT FRANCHISE READ
export const updateContacts = (params = '') => Http.put(`/contact-franchise/${params.id}`, params)
