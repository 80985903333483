import {
	deletePerspective,
	getPerspectives,
	savePerspective,
	updatePerspective,
} from '@/services/community/wikigamer/games/perspectives'
export default {
	namespaced: true,
	state: {
		perspectiveList: {
			meta: {
				total: 0,
			},
		},
		perspectiveStatus: 'pending',
	},
	getters: {
		getPerspectiveList(state) {
			return state.perspectiveList
		},
		getPerspectiveStatus(state) {
			return state.perspectiveStatus
		},
	},
	mutations: {
		SET_PERSPECTIVE_LIST(state, value) {
			state.perspectiveList = value
		},
		SET_PERSPECTIVE_STATUS(state, value) {
			state.perspectiveStatus = value
		},
	},
	actions: {
		async fetchPerspectives({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_PERSPECTIVE_STATUS', 'pending')
			commit('SET_PERSPECTIVE_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getPerspectives(params)
				commit('SET_PERSPECTIVE_STATUS', 'init')
				commit('SET_PERSPECTIVE_LIST', data)
				return data
			} catch (error) {
				commit('SET_PERSPECTIVE_STATUS', 'init')
				throw error
			}
		},

		async deletePerspectiveRecord({ commit }, params) {
			commit('SET_PERSPECTIVE_STATUS', 'pending')
			try {
				const { data } = await deletePerspective(params)
				commit('SET_PERSPECTIVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_PERSPECTIVE_STATUS', 'init')
				throw error
			}
		},
		async savePerspective({ commit }, params) {
			commit('SET_PERSPECTIVE_STATUS', 'pending')
			try {
				const { data } = await savePerspective(params)
				commit('SET_PERSPECTIVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_PERSPECTIVE_STATUS', 'init')
				throw error
			}
		},
		async updatePerspective({ commit }, params) {
			commit('SET_PERSPECTIVE_STATUS', 'pending')
			try {
				const { data } = await updatePerspective(params)
				commit('SET_PERSPECTIVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_PERSPECTIVE_STATUS', 'init')
				throw error
			}
		},
	},
}
