import { goalByID, goalCreate, goalDestroy, goalList, goalUpdate } from '@/services/agency/goals.service'

export default {
	namespaced: true,
	state: {
		// SERVICE GOAL
		goalList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		goal: {},

		// STATUS SERVICE GOAL
		goalStatus: 'pending',
		goalSaveStatus: 'init',
		goalDeleteStatus: 'init',
	},
	getters: {
		// SERVICE GOAL
		getGoalList(state) {
			return state.goalList
		},
		getGoal(state) {
			return state.goal
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE GOAL
		getGoalDeleteStatus(state) {
			return state.goalDeleteStatus
		},
		getGoalSaveStatus(state) {
			return state.goalSaveStatus
		},
		getGoalStatus(state) {
			return state.goalStatus
		},
	},
	mutations: {
		// SERVICE GOAL
		GOAL_LIST(state, value) {
			state.goalList = value
		},
		SET_GOAL(state, value) {
			state.goal = value
		},

		// STATUS SERVICE GOAL
		GOAL_STATUS(state, value) {
			state.goalStatus = value
		},
		GOAL_SAVE_STATUS(state, value) {
			state.goalSaveStatus = value
		},
		GOAL_DELETE_STATUS(state, value) {
			state.goalDeleteStatus = value
		},
	},
	actions: {
		// Service Goal
		async fetchGoalList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('GOAL_STATUS', 'pending')
			commit('GOAL_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await goalList(params)
				commit('GOAL_STATUS', 'init')
				commit('GOAL_LIST', data)
				return data
			} catch (error) {
				commit('GOAL_STATUS', 'init')
				throw error
			}
		},
		async fetchGoalByID({ commit }, params) {
			commit('GOAL_STATUS', 'pending')
			try {
				const { data } = await goalByID(params)
				commit('GOAL_STATUS', 'init')
				commit('SET_GOAL', data.data)
				return data
			} catch (error) {
				commit('GOAL_STATUS', 'init')

				return error
			}
		},
		async saveNewGoal({ commit }, params) {
			commit('GOAL_SAVE_STATUS', 'pending')
			try {
				const { data } = await goalCreate(params)
				commit('GOAL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('GOAL_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateGoal({ commit }, params) {
			commit('GOAL_SAVE_STATUS', 'pending')
			try {
				const { data } = await goalUpdate(params)
				commit('GOAL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('GOAL_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteGoalByID({ commit }, params) {
			commit('GOAL_DELETE_STATUS', 'pending')
			try {
				const { data } = await goalDestroy(params)
				commit('GOAL_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('GOAL_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
