import {
	professionalsituationByID,
	professionalsituationCreate,
	professionalsituationDestroy,
	professionalsituationsList,
	professionalsituationUpdate,
} from '@/services/jobs/professionalSituations.service'

export default {
	namespaced: true,
	state: {
		// PROFESSIONALSITUATIONS
		professionalsituationsList: {},
		professionalsituation: {},
		// STATUS PROFESSIONALSITUATION
		professionalsituationsStatus: 'pending',
		professionalsituationsSaveStatus: 'init',
		professionalsituationsDeleteStatus: 'init',
	},
	getters: {
		// PROFESSIONALSITUATION
		getProfessionalSituationsList(state) {
			return state.professionalsituationsList
		},
		getProfessionalSituation(state) {
			return state.professionalsituation
		},
		// PROFESSIONALSITUATION
		getProfessionalSituationsDeleteStatus(state) {
			return state.professionalsituationsDeleteStatus
		},
		getProfessionalSituationsSaveStatus(state) {
			return state.professionalsituationsSaveStatus
		},
		getProfessionalSituationsStatus(state) {
			return state.professionalsituationsStatus
		},
	},
	mutations: {
		// PROFESSIONALSITUATIONS
		PROFESSIONALSITUATIONS_LIST(state, value) {
			state.professionalsituationsList = value
		},
		SET_PROFESSIONALSITUATION(state, value) {
			state.professionalsituation = value
		},
		// STATUS PROFESSIONALSITUATIONS
		PROFESSIONALSITUATIONS_STATUS(state, value) {
			state.professionalsituationsStatus = value
		},
		PROFESSIONALSITUATION_SAVE_STATUS(state, value) {
			state.professionalsituationsSaveStatus = value
		},
		PROFESSIONALSITUATION_DELETE_STATUS(state, value) {
			state.professionalsituationsDeleteStatus = value
		},
	},
	actions: {
		// ProfessionalSituations
		async fetchProfessionalSituationList({ commit }, params) {
			console.log('params', params)
			commit('PROFESSIONALSITUATIONS_STATUS', 'pending')
			commit('PROFESSIONALSITUATIONS_LIST', {})
			try {
				const { data } = await professionalsituationsList(params)
				console.log(data)
				commit('PROFESSIONALSITUATIONS_STATUS', 'init')
				commit('PROFESSIONALSITUATIONS_LIST', data)
				return data
			} catch (error) {
				commit('PROFESSIONALSITUATIONS_STATUS', 'init')
				throw error
			}
		},
		async fetchProfessionalSituationByID({ commit }, params) {
			commit('PROFESSIONALSITUATIONS_STATUS', 'pending')
			try {
				const { data } = await professionalsituationByID(params)
				commit('PROFESSIONALSITUATIONS_STATUS', 'init')
				commit('SET_PROFESSIONALSITUATION', data.data)
				return data
			} catch (error) {
				commit('PROFESSIONALSITUATIONS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewProfessionalSituation({ commit }, params) {
			commit('PROFESSIONALSITUATION_SAVE_STATUS', 'pending')
			try {
				const { data } = await professionalsituationCreate(params)
				commit('PROFESSIONALSITUATION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PROFESSIONALSITUATION_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateProfessionalSituation({ commit }, params) {
			commit('PROFESSIONALSITUATION_SAVE_STATUS', 'pending')
			try {
				const { data } = await professionalsituationUpdate(params)
				commit('PROFESSIONALSITUATION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PROFESSIONALSITUATION_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteProfessionalSituationByID({ commit }, params) {
			commit('PROFESSIONALSITUATION_DELETE_STATUS', 'pending')
			try {
				const { data } = await professionalsituationDestroy(params)
				commit('PROFESSIONALSITUATION_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PROFESSIONALSITUATION_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
