const franchises = [
	{
		path: '/club/franchise-models/list',
		name: 'club-franchise-models-list',
		component: () => import('@/views/club/franchise-models/list.vue'),
		meta: {
			layout: 'content',
		},
	},

	{
		path: '/club/contact-franchise/list',
		name: 'club-contact-franchise-list',
		component: () => import('@/views/club/contact-franchise/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default franchises
