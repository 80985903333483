import HttpMain from '@/services/http'
import HttpCommunity from '@/services/httpCommunity'

export default {
	state: {
		pulseStatusesState: null,
		pulseStatuses: {},
		pulseCategoriesState: null,
		pulseCategories: {},
		timezonesState: null,
		timezones: {},
		statusesState: null,
		statuses: {},
		eventCategoriesState: null,
		eventCategories: {},
		videoCategoriesState: null,
		videoCategories: {},
		genresState: null,
		genres: {},
		documenttypesState: null,
		documenttypes: {},
		perspectivesState: null,
		perspectives: {},
		modesState: null,
		modes: {},
		gendersState: null,
		genders: {},
		enginesState: null,
		engines: {},
		themesState: null,
		themes: {},
		esrbsState: null,
		esrbs: {},
		pegisState: null,
		pegis: {},
		levelsState: null,
		levels: {},
		timesState: null,
		times: {},
		typesState: null,
		types: {},
		awardCategoriesState: null,
		awardCategories: {},
		awardId: null,
		awardCategoryId: null
	},
	mutations: {
		SET_AWARD_ID(state, val) {
			state.awardId = val
		},
		SET_AWARD_CATEGORY_ID(state, val) {
			state.awardCategoryId = val
		},
	},
	actions: {
		async setAwardId({ commit }, awardId) {
			commit('SET_AWARD_ID', awardId)
		},
		async setAwardCategoryId({ commit }, awardCategoryId) {
			commit('SET_AWARD_CATEGORY_ID', awardCategoryId)
		}
	},
	getters: {
		getPulseStatuses(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.pulseStatusesState === 'PENDING') {
				return () => Promise.resolve(state.pulseStatuses[locale] || [])
			}
			return () => {
				state.pulseStatusesState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.pulseStatuses[locale]) {
						resolve(state.pulseStatuses[locale])
						return
					}
					HttpCommunity.get(`pulse-status/fetch`)
						.then(response => {
							state.pulseStatuses[locale] = response.data.data
							resolve(response.data.data)
							state.pulseStatusesState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},
		getTimezones(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.getTimezones === 'PENDING') {
				return () => Promise.resolve(state.timezones[locale] || [])
			}
			return () => {
				state.getTimezones = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.timezones[locale]) {
						resolve(state.timezones[locale])
						return
					}
					HttpMain.get(`timezone`)
						.then(response => {
							state.timezones[locale] = response.data.data
							resolve(response.data.data)
							state.timezonesState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},

		getPulseCategories(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.pulseCategories === 'PENDING') {
				return () => Promise.resolve(state.pulseCategories[locale] || [])
			}
			return () => {
				state.pulseCategoriesStatus = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.pulseCategories[locale]) {
						resolve(state.pulseCategories[locale])
						return
					}
					HttpCommunity.get(`pulse-item-categories/fetch?per_page=100`)
						.then(response => {
							state.pulseCategories[locale] = response.data.data
							resolve(response.data.data)
							state.pulseCategoriesStatus = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},

		getStatuses(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.statusesState === 'PENDING') {
				return () => Promise.resolve(state.statuses[locale] || [])
			}
			return () => {
				state.statusesState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.statuses[locale]) {
						resolve(state.statuses[locale])
						return
					}
					HttpCommunity.get(`statuses/fetch`)
						.then(response => {
							state.statuses[locale] = response.data.data
							resolve(response.data.data)
							state.statusesState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},

		getEventCategories(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.statusesState === 'PENDING') {
				return () => Promise.resolve(state.eventCategories[locale] || [])
			}
			return () => {
				state.eventCategoriesState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.eventCategoriesState[locale]) {
						resolve(state.eventCategories[locale])
						return
					}
					HttpCommunity.get(`events-category/fetch`)
						.then(response => {
							state.eventCategories[locale] = response.data.data
							resolve(response.data.data)
							state.eventCategoriesState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},

		getVideoCategories(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.statusesState === 'PENDING') {
				return () => Promise.resolve(state.videoCategories[locale] || [])
			}
			return () => {
				state.videoCategoriesState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.videoCategoriesState[locale]) {
						resolve(state.videoCategories[locale])
						return
					}
					HttpCommunity.get(`videos-category/fetch`)
						.then(response => {
							state.videoCategories[locale] = response.data.data
							resolve(response.data.data)
							state.videoCategoriesState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},

		//Documents Types
		getDocumentTypes(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.documenttypesState === 'PENDING') {
				return () => Promise.resolve(state.documenttypes[locale] || [])
			}
			return () => {
				state.documenttypesState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.documenttypes[locale]) {
						resolve(state.documenttypes[locale])
						return
					}
					HttpMain.get(`document-types`)
						.then(response => {
							state.documenttypes[locale] = response.data.data
							resolve(response.data.data)
							state.documenttypesState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},

		//Games
		getGenres(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.genresState === 'PENDING') {
				return () => Promise.resolve(state.genres[locale] || [])
			}
			return () => {
				state.genresState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.genres[locale]) {
						resolve(state.genres[locale])
						return
					}
					HttpCommunity.get(`genres/fetch`)
						.then(response => {
							state.genres[locale] = response.data.data
							resolve(response.data.data)
							state.genresState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},

		getPerspectives(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.perspectivesState === 'PENDING') {
				return () => Promise.resolve(state.perspectives[locale] || [])
			}
			return () => {
				state.perspectivesState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.perspectives[locale]) {
						resolve(state.perspectives[locale])
						return
					}
					HttpCommunity.get(`player-perspectives/fetch`)
						.then(response => {
							state.perspectives[locale] = response.data.data
							resolve(response.data.data)
							state.perspectivesState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},

		getModes(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.modesState === 'PENDING') {
				return () => Promise.resolve(state.modes[locale] || [])
			}
			return () => {
				state.modesState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.modes[locale]) {
						resolve(state.modes[locale])
						return
					}
					HttpCommunity.get(`game-modes/fetch`)
						.then(response => {
							state.modes[locale] = response.data.data
							resolve(response.data.data)
							state.modesState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},

		getEngines(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.enginesState === 'PENDING') {
				return () => Promise.resolve(state.engines[locale] || [])
			}
			return () => {
				state.enginesState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.engines[locale]) {
						resolve(state.engines[locale])
						return
					}
					HttpCommunity.get(`game-engines/fetch`)
						.then(response => {
							state.engines[locale] = response.data.data
							resolve(response.data.data)
							state.enginesState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},

		getGenders(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.gendersState === 'PENDING') {
				return () => Promise.resolve(state.genders[locale] || [])
			}
			return () => {
				state.gendersState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.genders[locale]) {
						resolve(state.genders[locale])
						return
					}
					HttpMain.get(`genders`)
						.then(response => {
							state.genders[locale] = response.data.data
							resolve(response.data.data)
							state.gendersState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},

		getThemes(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.themesState === 'PENDING') {
				return () => Promise.resolve(state.themes[locale] || [])
			}
			return () => {
				state.themesState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.themes[locale]) {
						resolve(state.themes[locale])
						return
					}
					HttpCommunity.get(`themes/fetch`)
						.then(response => {
							state.themes[locale] = response.data.data
							resolve(response.data.data)
							state.themesState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},

		getEsrbs(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.esrbsState === 'PENDING') {
				return () => Promise.resolve(state.esrbs[locale] || [])
			}
			return () => {
				state.esrbsState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.esrbs[locale]) {
						resolve(state.esrbs[locale])
						return
					}
					HttpCommunity.get(`esrb-ratings/fetch`)
						.then(response => {
							state.esrbs[locale] = response.data.data
							resolve(response.data.data)
							state.esrbsState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},

		getPegis(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.pegisState === 'PENDING') {
				return () => Promise.resolve(state.pegis[locale] || [])
			}
			return () => {
				state.pegisState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.pegis[locale]) {
						resolve(state.pegis[locale])
						return
					}
					HttpCommunity.get(`pegi-ratings/fetch`)
						.then(response => {
							state.pegis[locale] = response.data.data
							resolve(response.data.data)
							state.pegisState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},

		getLevels(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.levelsState === 'PENDING') {
				return () => Promise.resolve(state.levels[locale] || [])
			}
			return () => {
				state.levelsState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.levels[locale]) {
						resolve(state.levels[locale])
						return
					}
					HttpCommunity.get('quizzes/levels/fetch')
						.then(response => {
							state.levels[locale] = response.data
							resolve(response.data)
							state.levelsState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},

		getTimes(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.timesState === 'PENDING') {
				return () => Promise.resolve(state.times[locale] || [])
			}
			return () => {
				state.timesState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.times[locale]) {
						resolve(state.times[locale])
						return
					}
					HttpCommunity.get('quizzes/times/fetch')
						.then(response => {
							state.times[locale] = response.data
							resolve(response.data)
							state.timesState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},

		getTypes(state, getters, rootState, rootGetters) {
			const locale = rootState.languages.currentLocale
			if (state.typesState === 'PENDING') {
				return () => Promise.resolve(state.types[locale] || [])
			}
			return () => {
				state.typesState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.types[locale]) {
						resolve(state.types[locale])
						return
					}
					HttpCommunity.get('quizzes/types/fetch')
						.then(response => {
							state.types[locale] = response.data
							resolve(response.data)
							state.typesState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})

				})
			}
		},

		getAwardCategories(state, getters, rootState, rootGetters) {			
			const locale = rootState.languages.currentLocale
			const awardId = state.awardId
			const awardCategoryId = state.awardCategoryId
			
			if (state.statusesState === 'PENDING') {
				return () => Promise.resolve(state.awardCategories[locale] || [])
			}
			return () => {
				state.awardCategoriesState = 'PENDING'

				return new Promise((resolve, reject) => {
					if (state.awardCategoriesState[locale]) {
						resolve(state.awardCategories[locale])
						return
					}

					HttpCommunity.get(`award/categories/fetch?filter[awardId]=${awardId}&filter[awardCategoryId]=${awardCategoryId}`)
						.then(response => {
							state.awardCategories[locale] = response.data.data
							resolve(response.data.data)
							state.awardCategoriesState = 'LOADED'
						})
						.catch(error => {
							reject(error)
						})
				})
			}
		},
	},
}
