import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpTV'

export const getHighlights = (params = '') => Http.get(`/highlights-banners${serialize(params)}`)

export const getStats = (
	params = {
		languageId: '',
		status: 1,
	},
) => Http.get(`highlights-banners/list/stats?filter[status]=${params.status}&filter[language_id]=${params.languageId}`)

export const getStatsLanguageEnglish = (
	params = {
		languageId: 1,
		status: 1,
	},
) => Http.get(`highlights-banners/list/stats?filter[status]=${params.status}&filter[language_id]=${params.languageId}`)

export const getStatsLanguageDeutsch = (
	params = {
		languageId: 2,
		status: 1,
	},
) => Http.get(`highlights-banners/list/stats?filter[status]=${params.status}&filter[language_id]=${params.languageId}`)

export const getStatsLanguagePortuguese = (
	params = {
		languageId: 3,
		status: 1,
	},
) => Http.get(`highlights-banners/list/stats?filter[status]=${params.status}&filter[language_id]=${params.languageId}`)

export const getStatsLanguageSpanish = (
	params = {
		languageId: 4,
		status: 1,
	},
) => Http.get(`highlights-banners/list/stats?filter[status]=${params.status}&filter[language_id]=${params.languageId}`)

export const getStatsLanguageRomanian = (
	params = {
		languageId: 5,
		status: 1,
	},
) => Http.get(`highlights-banners/list/stats?filter[status]=${params.status}&filter[language_id]=${params.languageId}`)
