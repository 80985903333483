import { serialize } from '@/@core/libs/serialize/util'
import Http from '../http'

// // EXPERIENCES
export const experienceCreate = (params = '') => Http.post('/experiences', params)
// // // LIST ALL EXPERIENCES
export const experiencesList = (params = '') => Http.get(`/experiences${serialize(params)}`)
// // // GET EXPERIENCE BY ID
export const experienceByID = (params = '') => Http.get(`/experiences/${params}`)
// // // UPDATE EXPERIENCE
export const experienceUpdate = (params = '') => Http.put(`/experiences/${params.id}`, params)
// // // DELETE EXPERIENCE
export const experienceDestroy = (params = '') => Http.delete(`/experiences/${params.id}`)
