import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getPlatformTypes = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/platform-types/fetch${serialize(params)}`)

// DESTROY PLATFORM TYPE
export const destroyPlatformType = (
	params = {
		platformTypeId: null,
	},
) => Http.delete(`/platform-types/${params.platformTypeId}/destroy`)

// SAVE PLATFORM TYPE
export const savePlatformType = (
	params = {
		name: null,
	},
) => Http.post(`/platform-types/store`, params)

// UPDATE PLATFORM TYPE
export const updatePlatformType = (
	params = {
		platformTypeId: null,
		name: null,
	},
) => Http.put(`/platform-types/${params.platformTypeId}/update`, params)
