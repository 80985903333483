import {
	mdiHeadphonesSettings,
	mdiHome,
	mdiMessage,
	mdiNewspaperVariantMultipleOutline,
	mdiViewCompact,
	mdiViewListOutline,
} from '@mdi/js'

// Array of sections

export default [
	{
		icon: mdiHome,
		title: 'Dashboard',
		to: 'tv-dashboard',
	},
	{
		title: 'Banners',
		icon: mdiViewCompact,
		children: [
			{
				title: 'menu.tv.banners.rotatingBanners',
				to: 'tv-banners-rotating-banners-list',
			},
		],
	},
	{
		title: 'categories',
		icon: mdiViewListOutline,
		children: [
			{
				title: 'categories',
				to: 'tv-categories-list',
			},
			{
				title: 'subCategories',
				to: 'tv-subcategories-list',
			},
		],
	},
	{
		title: 'Streamers',
		icon: mdiHeadphonesSettings,
		children: [
			{
				title: 'Streamers',
				to: 'tv-streamers-list',
			},
			{
				title: 'menu.tv.streamersApplications',
				to: 'tv-streamers-applications-list',
			},
		],
	},
	{
		icon: mdiMessage,
		title: 'menu.messages',
		to: 'tv-messages-list',
	},
	{
		icon: mdiNewspaperVariantMultipleOutline,
		title: 'Newsletter',
		to: 'tv-newsletter-list',
	},
]
