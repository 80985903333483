const services = [
	{
		path: '/ai/services/list',
		name: 'ai-services-list',
		component: () => import('@/views/ai/services/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default services
