import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpAgency'

//LIST OF ALL SERVICES CATEGORIES
export const categoriesList = (params = '') => Http.get(`/services-categories${serialize(params)}`)

export const categoryCreate = (params = '') => Http.post('/services-categories', params)
export const categoryByID = (params = '') => Http.get(`/services-categories/${params}`)
export const categoryUpdate = (params = '') => Http.put(`/services-categories/${params.id}`, params)
export const categoryDestroy = (params = '') => Http.delete(`/services-categories/${params.id}`)

// LIST ALL Service
export const serviceList = (params = '') => Http.get(`/services${serialize(params)}`)
export const serviceCreate = (params = '') => Http.post('/services', params)
export const serviceByID = (params = '') => Http.get(`/services/${params}`)
export const serviceUpdate = (params = '') => Http.put(`/services/${params.id}`, params)
export const serviceDestroy = (params = '') => Http.delete(`/services/${params.id}`)
