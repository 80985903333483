import { serialize } from '@/@core/libs/serialize/util'
import http from '@/services/http'

//MAIN
// // LANGUAGES
// // // GET LANGUAGES WITH PAGINATION
export const getLanguages = () => http.get(`/languages?filter[status]=1&per_page=10&page=1&sort=id&order=asc`)

// // // GET LANGUAGES WITHOUT PAGINATION
export const getAllLanguage = (params = { page: 1, filter: '' }) => http.get(`/languages${serialize(params)}`)

// // // POST NEW LANGUAGE
export const newLanguage = params => http.post('/languages', params)

// // // UPDATE A LANGUAGE
export const updateLanguage = params => http.put(`/languages/${params.id}`, params)

// // // DELETE A LANGUAGE
export const deleteLanguage = params => http.delete(`/languages/${params.id}`)
