import {
	deleteFranchiseModel,
	getFranchiseModels,
	saveFranchiseModel,
	updateFranchiseModel,
} from '@/services/club/franchise.service'
export default {
	namespaced: true,
	state: {
		franchisemodelList: {
			meta: {
				total: 0,
			},
		},
		franchisemodelsStatus: 'pending',
	},
	getters: {
		getFranchiseModelList(state) {
			return state.franchisemodelList
		},
		getFranchiseModelsStatus(state) {
			return state.franchisemodelsStatus
		},
	},
	mutations: {
		SET_FRANCHISE_MODEL_LIST(state, value) {
			state.franchisemodelList = value
		},
		SET_FRANCHISE_MODEL_STATUS(state, value) {
			state.franchisemodelsStatus = value
		},
	},
	actions: {
		async fetchFranchiseModels({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_FRANCHISE_MODEL_STATUS', 'pending')
			commit('SET_FRANCHISE_MODEL_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getFranchiseModels(params)
				commit('SET_FRANCHISE_MODEL_STATUS', 'init')
				commit('SET_FRANCHISE_MODEL_LIST', data)
				return data
			} catch (error) {
				commit('SET_FRANCHISE_MODEL_STATUS', 'init')
				throw error
			}
		},

		async deleteFranchiseModelRecord({ commit }, params) {
			commit('SET_FRANCHISE_MODEL_STATUS', 'pending')
			try {
				const { data } = await deleteFranchiseModel(params)
				commit('SET_FRANCHISE_MODEL_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_FRANCHISE_MODEL_STATUS', 'init')
				throw error
			}
		},
		async saveFranchiseModel({ commit }, params) {
			commit('SET_FRANCHISE_MODEL_STATUS', 'pending')
			try {
				const { data } = await saveFranchiseModel(params)
				commit('SET_FRANCHISE_MODEL_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_FRANCHISE_MODEL_STATUS', 'init')
				throw error
			}
		},
		async updateFranchiseModel({ commit }, params) {
			commit('SET_FRANCHISE_MODEL_STATUS', 'pending')
			try {
				const { data } = await updateFranchiseModel(params)
				commit('SET_FRANCHISE_MODEL_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_FRANCHISE_MODEL_STATUS', 'init')
				throw error
			}
		},
	},
}
