import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getReviews = params => Http.get(`/reviews/fetch${serialize(params)}`)

export const getStats = (
	params = {
		language_id: '',
		status_id: 1,
	},
) => Http.get(`reviews/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.language_id}`)

export const newReview = (params = '') => Http.post('/creator-mode/new-review', params, { baseURL: 'locale' })

export const updateReview = (params = '') =>
	Http.put(`/creator-mode/review/${params.slug}`, params, { baseURL: 'locale' })

export const getReviewBySlug = slug => {
	return Http.get(`creator-mode/review/${slug}`, { baseURL: 'locale' })
}
