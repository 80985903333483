import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpJobs'

// // EDUCATION LEVELS
export const applicationstatusCreate = (params = '') => Http.post('/application-status', params)
// // // LIST ALL EDUCATION LEVELS
export const applicationstatusList = (params = '') => Http.get(`/application-status${serialize(params)}`)
// // // GET ApplicationStatus BY ID
export const applicationstatusByID = (params = '') => Http.get(`/application-status/${params}`)
// // // UPDATE EDUCATION LEVEL
export const applicationstatusUpdate = (params = '') => Http.put(`/application-status/${params.id}`, params)
// // // DELETE EDUCATION LEVEL
export const applicationstatusDestroy = (params = '') => Http.delete(`/application-status/${params.id}`)
