const subcategories = [
	{
		path: '/tv/subcategories/list',
		name: 'tv-subcategories-list',
		component: () => import('@/views/tv/subcategories/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default subcategories
