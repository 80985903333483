import { createAxiosClient } from './create.axios.client'

const baseDomain = process.env.VUE_APP_API_AGENCY_URL

const clientAgency = createAxiosClient({
	baseURL: baseDomain,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'Content-Language': `pt-BR`,
	},
})

clientAgency.interceptors.request.use(
	config => {
		// Do something before request is sent
		const token = typeof window !== 'undefined' ? sessionStorage.getItem('token') : null
		if (token) {
			config.headers.common.Authorization = token
		}
		return config
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error)
	},
)

clientAgency.interceptors.response.use(
	res => {
		// Do something before request is sent
		if (res.status === 401) {
			//todo log out the user
		}
		const hasAuthProperty = Object.prototype.hasOwnProperty.call(res.data, 'token')
		if (hasAuthProperty) {
			if (typeof window !== 'undefined') {
				sessionStorage.setItem('token', 'bearer ' + res.data.token)
			}
		}
		return res
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error)
	},
)

export default clientAgency
