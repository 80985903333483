const workMethodologies = [
	{
		path: '/work-methodologies/list',
		name: 'jobs-work-methodologies-list',
		component: () =>
			import(/* webpackChunkName: "WorkmethodologiesList" */ '@/views/jobs/work-methodologies/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default workMethodologies
