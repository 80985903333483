import { getTranslation, saveTranslation, updateTranslation, resetTranslation, runTranslation, refreshTranslation, deleteTranslation } from '@/services/community/contents/translation-queue'
export default {
	namespaced: true,
	state: {
		translationList: {
			meta: {
				total: 0,
			},
		},

		translationStatus: 'pending',
	},
	getters: {
		getTranslationList(state) {
			return state.translationList
		},
		getTranslationStatus(state) {
			return state.translationStatus
		},
	},
	mutations: {
		SET_TRANSLATION_LIST(state, value) {
			state.translationList = value
		},
		SET_TRANSLATION_STATUS(state, value) {
			state.translationStatus = value
		},
	},
	actions: {
		async fetchTranslations({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_TRANSLATION_STATUS', 'pending')
			commit('SET_TRANSLATION_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getTranslation(params)
				commit('SET_TRANSLATION_STATUS', 'init')
				commit('SET_TRANSLATION_LIST', data)
				return data
			} catch (error) {
				commit('SET_TRANSLATION_STATUS', 'init')
				throw error
			}
		},
		async deleteTranslationRecord({ commit }, params) {
			commit('SET_TRANSLATION_STATUS', 'pending')
			try {
				const { data } = await deleteTranslation(params)
				commit('SET_TRANSLATION_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_TRANSLATION_STATUS', 'init')
				throw error
			}
		},
		async saveTranslation({ commit }, params) {
			commit('SET_TRANSLATION_STATUS', 'pending')
			try {
				const { data } = await saveTranslation(params)
				commit('SET_TRANSLATION_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_TRANSLATION_STATUS', 'init')
				throw error
			}
		},
		async updateTranslation({ commit }, params) {
			commit('SET_TRANSLATION_STATUS', 'pending')
			try {
				const { data } = await updateTranslation(params)
				commit('SET_TRANSLATION_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_TRANSLATION_STATUS', 'init')
				throw error
			}
		},
		async resetTranslation({ commit }, params) {
			commit('SET_TRANSLATION_STATUS', 'pending')
			try {
				const { data } = await resetTranslation(params)
				commit('SET_TRANSLATION_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_TRANSLATION_STATUS', 'init')
				throw error
			}
		},
		async runTranslation({ commit }, params) {
			commit('SET_TRANSLATION_STATUS', 'pending')
			try {
				const { data } = await runTranslation(params)
				commit('SET_TRANSLATION_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_TRANSLATION_STATUS', 'init')
				throw error
			}
		},
		async refreshTranslation({ commit }, params) {
			commit('SET_TRANSLATION_STATUS', 'pending')
			try {
				const { data } = await refreshTranslation(params)
				commit('SET_TRANSLATION_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_TRANSLATION_STATUS', 'init')
				throw error
			}
		},
	},
}
