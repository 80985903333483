import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpTV'

export const streamerCreate = (
	params = {
		twitch_login: null,
	},
) => Http.post(`/admin/streamers/${serialize(params)}`)

export const streamersList = (params = '') => Http.get(`/admin/streamers${serialize(params)}`)
export const streamerByID = (params = '') => Http.get(`/admin/streamers/${params}`)
export const streamerUpdate = (params = '') => Http.post(`/admin/streamers/${params.id}`, params)
export const streamerDestroy = (params = '') => Http.delete(`/admin/streamers`, {data: params})
export const streamerStatusList = () => Http.get(`/admin/streamers/stats`)
export const getStats = () => Http.get(`/admin/streamers/stats`)
