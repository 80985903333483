import {
	createKey, createProject, editKey, editProject,
	getKey,
	listKeys, listNetworkSystems, listProjects, removeKey
} from '@/services/main/translation-manager.service'

export default {
	namespaced: true,
	state: {
		keysList: {
			meta: {
				pagination: {
					total: 0
				}
			}
		},
		keysStatus: 'pending',
		saveKeysStatus: 'init',
		deleteKeysStatus: 'init',
		keysProjects: {
			meta: {
				pagination: {
					total: 0
				}
			}
		},
		keysProjectsStatus: 'init',
		mundoGamerPlatforms: {
			meta: {
				pagination: {
					total: 0
				}
			}
		},
		mundoGamerPlatformsStatus: 'init',
	},
	getters: {
		getKeysList(state) {
			return state.keysList
		},
		getKey(state) {
			return state.key
		},
		getKeysStatus(state) {
			return state.keysStatus
		},
		getSaveKeysStatus(state) {
			return state.saveKeysStatus
		},
		getDeleteKeysStatus(state) {
			return state.deleteKeysStatus
		},
		getKeysProjects(state) {
			return state.keysProjects
		},
		getKeysProjectsStatus(state) {
			return state.keysProjectsStatus
		},
		getMundoGamerPlatforms(state) {
			return state.mundoGamerPlatforms
		},
		getMundoGamerPlatformsStatus(state) {
			return state.mundoGamerPlatformsStatus
		},
	},
	mutations: {
		//this is used in pulses
		SET_KEYS_LIST(state, value) {
			state.keysList = value
		},
		SET_KEY(state, value) {
			state.key = value
		},
		SET_KEYS_STATUS(state, value) {
			state.keysStatus = value
		},
		SAVE_KEYS_STATUS(state, value) {
			state.saveKeysStatus = value
		},
		DELETE_KEYS_STATUS(state, value) {
			state.deleteKeysStatus = value
		},
		SET_KEYS_PROJECTS(state, value) {
			state.keysProjects = value
		},
		SET_KEYS_PROJECTS_STATUS(state, value) {
			state.keysProjectsStatus = value
		},
		SET_MUNDO_GAMER_PLATFORMS(state, value) {
			state.mundoGamerPlatforms = value
		},
		SET_MUNDO_GAMER_PLATFORMS_STATUS(state, value) {
			state.mundoGamerPlatformsStatus = value
		},
	},
	actions: {
		async fetchKeys({ commit }, params) {
			commit('SET_KEYS_LIST', {
				meta: {
					pagination: {
						total: 0
					}
				}
			})
			commit('SET_KEYS_STATUS', 'pending')
			try {
				const { data } = await listKeys(params)
				commit('SET_KEYS_STATUS', 'init')
				commit('SET_KEYS_LIST', data)
				return data
			} catch (error) {
				commit('SET_KEYS_STATUS', 'init')
				throw error
			}
		},
		async fetchKeyByID({ commit }, id) {
			commit('SET_KEYS_STATUS', 'pending')
			try {
				const { data } = await getKey(id)
				commit('SET_KEYS_STATUS', 'init')
				commit('SET_KEY', data)
				return data
			} catch (error) {
				commit('SET_KEYS_STATUS', 'init')
				throw error
			}
		},
		async saveKeys({ commit }, params) {
			commit('SAVE_KEYS_STATUS', 'pending')
			try {
				const { data } = await createKey(params)
				commit('SAVE_KEYS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SAVE_KEYS_STATUS', 'error')
				throw error
			}
		},
		async updateKey({ commit }, params) {
			commit('SAVE_KEYS_STATUS', 'pending')
			try {
				const { data } = await editKey(params.id, params)
				commit('SAVE_KEYS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SAVE_KEYS_STATUS', 'error')
				throw error
			}
		},
		async deleteKey({ commit }, params) {
			commit('DELETE_KEYS_STATUS', 'pending')
			try {
				const { data } = await removeKey(params)
				commit('DELETE_KEYS_STATUS', 'init')
				return data
			} catch(error) {
				commit('DELETE_KEYS_STATUS', 'error')
				throw error
			}
		},
		async fetchKeysProjects({ commit }, params) {
			commit('SET_KEYS_PROJECTS_STATUS', 'pending')
			commit('SET_KEYS_PROJECTS', {
				meta: {
					pagination: {
						total: 0
					}
				}
			})
			try {
				const { data } = await listProjects(params)
				commit('SET_KEYS_PROJECTS_STATUS', 'init')
				commit('SET_KEYS_PROJECTS', data)
				return data
			} catch (error) {
				commit('SET_KEYS_PROJECTS_STATUS', 'init')
				throw error
			}
		},
		async saveKeysProjects({ commit }, params) {
			commit('SET_KEYS_PROJECTS_STATUS', 'pending')
			try {
				const { data } = await createProject(params)
				commit('SET_KEYS_PROJECTS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_KEYS_PROJECTS_STATUS', 'error')
				throw error
			}
		},
		async updateKeysProjects({ commit }, params) {
			commit('SET_KEYS_PROJECTS_STATUS', 'pending')
			try {
				const { data } = await editProject(params.id, params)
				commit('SET_KEYS_PROJECTS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_KEYS_PROJECTS_STATUS', 'error')
				throw error
			}
		},
		async fetchMundoGamerPlatforms({ commit }, params) {
			commit('SET_MUNDO_GAMER_PLATFORMS_STATUS', 'pending')
			commit('SET_MUNDO_GAMER_PLATFORMS', {
				meta: {
					pagination: {
						total: 0
					}
				}
			})
			try {
				const { data } = await listNetworkSystems(params)
				commit('SET_MUNDO_GAMER_PLATFORMS_STATUS', 'init')
				commit('SET_MUNDO_GAMER_PLATFORMS', data)
				return data
			} catch (error) {
				commit('SET_MUNDO_GAMER_PLATFORMS_STATUS', 'init')
				throw error
			}
		}
	},
}
