const schedulers = [
	{
		path: '/community/contents/schedulers/list',
		name: 'community-contents-schedulers-list',
		component: () => import('@/views/community/schedulers/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default schedulers
