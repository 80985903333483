import Http from '@/services/http'

export const getGenders = (
	params = {
		perPage: 10,
		page: 1,
		search: '',
	},
) => Http.get(`/genders?perPage=${params.perPage}&page=${params.page}&filter[term]=${params.search}`)

export const destroyGender = (
	params = {
		genderId: null,
	},
) => Http.delete(`/genders/${params.genderId}`)

export const saveGender = (
	params = {
		name: null,
	},
) => Http.post(`/genders/`, params)

export const updateGender = (
	params = {
		genderId: null,
		name: null,
	},
) => Http.put(`/genders/${params.genderId}`, params)
