import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

// LIST OF ALL ENGINES
export const getFamilies = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/platform-families/fetch${serialize(params)}`)

// SAVE FAMILY
export const saveFamily = (
	params = {
		name: '',
		name_abrev: '',
	},
) => Http.post(`/platform-families/store`, params)

// UPDATE FAMILY
export const updateFamily = (
	params = {
		familyId: null,
		name: '',
		name_abrev: '',
	},
) => Http.put(`/platform-families/${params.familyId}/update`, params)

// DELETE FAMILY
export const deleteFamily = (
	params = {
		familyId: null,
	},
) => Http.delete(`/platform-families/${params.familyId}/destroy`)
