import {
	providerByID,
	providerCreate,
	providerDestroy,
	providerList,
	providerUpdate,
} from '@/services/agency/providers.service'

export default {
	namespaced: true,
	state: {
		// SERVICE PROVIDER
		providerList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		provider: {},

		// STATUS SERVICE PROVIDER
		providerStatus: 'pending',
		providerSaveStatus: 'init',
		providerDeleteStatus: 'init',
	},
	getters: {
		// SERVICE PROVIDER
		getProviderList(state) {
			return state.providerList
		},
		getProvider(state) {
			return state.provider
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE PROVIDER
		getProviderDeleteStatus(state) {
			return state.providerDeleteStatus
		},
		getProviderSaveStatus(state) {
			return state.providerSaveStatus
		},
		getProviderStatus(state) {
			return state.providerStatus
		},
	},
	mutations: {
		// SERVICE PROVIDER
		PROVIDER_LIST(state, value) {
			state.providerList = value
		},
		SET_PROVIDER(state, value) {
			state.provider = value
		},

		// STATUS SERVICE PROVIDER
		PROVIDER_STATUS(state, value) {
			state.providerStatus = value
		},
		PROVIDER_SAVE_STATUS(state, value) {
			state.providerSaveStatus = value
		},
		PROVIDER_DELETE_STATUS(state, value) {
			state.providerDeleteStatus = value
		},
	},
	actions: {
		// Service Provider
		async fetchProviderList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('PROVIDER_STATUS', 'pending')
			commit('PROVIDER_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await providerList(params)
				commit('PROVIDER_STATUS', 'init')
				commit('PROVIDER_LIST', data)
				return data
			} catch (error) {
				commit('PROVIDER_STATUS', 'init')
				throw error
			}
		},
		async fetchProviderByID({ commit }, params) {
			commit('PROVIDER_STATUS', 'pending')
			try {
				const { data } = await providerByID(params)
				commit('PROVIDER_STATUS', 'init')
				commit('SET_PROVIDER', data.data)
				return data
			} catch (error) {
				commit('PROVIDER_STATUS', 'init')

				return error
			}
		},
		async saveNewProvider({ commit }, params) {
			commit('PROVIDER_SAVE_STATUS', 'pending')
			try {
				const { data } = await providerCreate(params)
				commit('PROVIDER_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PROVIDER_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateProvider({ commit }, params) {
			commit('PROVIDER_SAVE_STATUS', 'pending')
			try {
				const { data } = await providerUpdate(params)
				commit('PROVIDER_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PROVIDER_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteProviderByID({ commit }, params) {
			commit('PROVIDER_DELETE_STATUS', 'pending')
			try {
				const { data } = await providerDestroy(params)
				commit('PROVIDER_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PROVIDER_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
