const mgCompanies = [
	{
		path: '/mg-companies/list',
		name: 'mg-companies-list',
		component: () => import(/* webpackChunkName: "MGCompaniesList" */ '@/views/network/mgCompanies/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/mg-companies/create',
		name: 'mg-companies-create',
		component: () => import(/* webpackChunkName: "MGCompaniesCreate" */ '@/views/network/mgCompanies/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/mg-companies/edit/:id',
		name: 'mg-companies-edit',
		component: () => import(/* webpackChunkName: "MGCompaniesEdit" */ '@/views/network/mgCompanies/new.vue'),
		meta: {
			layout: 'content',
		},
	},

	{
		path: '/network/institutional/companies-type/list',
		name: 'institutional-companies-type-list',
		component: () => import('@/views/network/institutional/companies-type/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default mgCompanies
