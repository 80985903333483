import {
	applicationsByID,
	applicationsList,
	careersByID,
	careersList,
	deleteApplicationByID,
	deleteCareerByID,
	deleteSkillByID,
	newApplication,
	newCareer,
	newSkill,
	skillsByID,
	skillsList,
	updateApplications,
	updateCareer,
	updateSkill,
	updateFavoriteToggle,
	updateReadToggle,
} from '@/services/careers.service'

export default {
	namespaced: true,
	state: {
		// CAREERS
		careersList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		career: {},
		// APPLICATIONS
		applicationsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		application: {},
		// SKILLS
		skillsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		skill: {},
		// STATUS
		careersStatus: 'init',
		careersSaveStatus: 'init',
		careersDeleteStatus: 'init',
	},
	getters: {
		// CAREERS
		getCareersList(state) {
			return state.careersList
		},
		getCareer(state) {
			return state.career
		},
		// APPLICATIONS
		getApplicationsList(state) {
			return state.applicationsList
		},
		getApplication(state) {
			return state.application
		},
		// SKILLS
		getSkillsList(state) {
			return state.skillsList
		},
		getSkill(state) {
			return state.skill
		},
		// STATUS
		getCareersDeleteStatus(state) {
			return state.careersDeleteStatus
		},
		getCareersSaveStatus(state) {
			return state.careersSaveStatus
		},
		getCareersStatus(state) {
			return state.careersStatus
		},
	},
	mutations: {
		// CAREERS
		CAREERS_LIST(state, value) {
			state.careersList = value
		},
		SET_CAREER(state, value) {
			state.career = value
		},
		// APPLICATIONS
		APPLICATIONS_LIST(state, value) {
			state.applicationsList = value
		},
		SET_APPLICATION(state, value) {
			state.application = value
		},
		// SKILLS
		SKILLS_LIST(state, value) {
			state.skillsList = value
		},
		SET_SKILL(state, value) {
			state.skill = value
		},
		// STATUS
		CAREERS_STATUS(state, value) {
			state.careersStatus = value
		},
		CAREERS_SAVE_STATUS(state, value) {
			state.careersSaveStatus = value
		},
		CAREERS_DELETE_STATUS(state, value) {
			state.careersDeleteStatus = value
		},
	},
	actions: {
		// Careers
		async fetchCareersList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('CAREERS_STATUS', 'pending')
			commit('CAREERS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await careersList(params)
				commit('CAREERS_STATUS', 'init')
				commit('CAREERS_LIST', data)
				return data
			} catch (error) {
				commit('CAREERS_STATUS', 'init')
				throw error
			}
		},
		async fetchCareerByID({ state, commit }, params) {
			commit('CAREERS_STATUS', 'pending')
			try {
				const { data } = await careersByID(params)
				commit('CAREERS_STATUS', 'init')
				commit('SET_CAREER', data.data)
				return data
			} catch (error) {
				commit('CAREERS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewCareer({ commit }, params) {
			commit('CAREERS_SAVE_STATUS', 'pending')
			try {
				const { data } = await newCareer(params)
				commit('CAREERS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAREERS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCareer({ commit }, params) {
			commit('CAREERS_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateCareer(params)
				commit('CAREERS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAREERS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCareerByID({ state, commit }, params) {
			commit('CAREERS_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteCareerByID(params)
				commit('CAREERS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAREERS_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		// Applications
		async fetchApplicationsList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('CAREERS_STATUS', 'pending')
			commit('APPLICATIONS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await applicationsList(params)
				commit('CAREERS_STATUS', 'init')
				commit('APPLICATIONS_LIST', data)
				return data
			} catch (error) {
				commit('CAREERS_STATUS', 'init')
				throw error
			}
		},
		async fetchApplicationByID({ state, commit }, params) {
			commit('CAREERS_STATUS', 'pending')
			try {
				const { data } = await applicationsByID(params)
				commit('CAREERS_STATUS', 'init')
				commit('SET_APPLICATION', data.data)
				return data
			} catch (error) {
				commit('CAREERS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async updateFavoriteToggle({ commit }, params) {
			commit('CAREERS_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateFavoriteToggle(params)
				commit('CAREERS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAREERS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateReadToggle({ commit }, params) {
			commit('CAREERS_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateReadToggle(params)
				commit('CAREERS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAREERS_SAVE_STATUS', 'init')
				throw error
			}
		},

		async saveNewApplication({ commit }, params) {
			commit('CAREERS_SAVE_STATUS', 'pending')
			try {
				const { data } = await newApplication(params)
				commit('CAREERS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAREERS_SAVE_STATUS', 'init')
				throw error
			}
		},

		async updateApplications({ commit }, params) {
			commit('CAREERS_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateApplications(params)
				commit('CAREERS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAREERS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteApplicationByID({ state, commit }, params) {
			commit('CAREERS_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteApplicationByID(params)
				commit('CAREERS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAREERS_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		// Skills
		async fetchSkillsList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('CAREERS_STATUS', 'pending')
			commit('SKILLS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await skillsList(params)
				commit('CAREERS_STATUS', 'init')
				commit('SKILLS_LIST', data)
				return data
			} catch (error) {
				commit('CAREERS_STATUS', 'init')
				throw error
			}
		},
		async fetchSkillByID({ state, commit }, params) {
			commit('CAREERS_STATUS', 'pending')
			try {
				const { data } = await skillsByID(params)
				commit('CAREERS_STATUS', 'init')
				commit('SET_SKILL', data.data)
				return data
			} catch (error) {
				commit('CAREERS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewSkill({ commit }, params) {
			commit('CAREERS_SAVE_STATUS', 'pending')
			try {
				const { data } = await newSkill(params)
				commit('CAREERS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAREERS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateSkill({ commit }, params) {
			commit('CAREERS_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateSkill(params)
				commit('CAREERS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAREERS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteSkillByID({ state, commit }, params) {
			commit('CAREERS_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteSkillByID(params)
				commit('CAREERS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAREERS_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
