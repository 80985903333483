import { serialize } from '@/@core/libs/serialize/util'
import Http from '../http'

// // EDUCATION LEVELS
export const applicationmethodCreate = (params = '') => Http.post('/application-method', params)
// // // LIST ALL EDUCATION LEVELS
export const applicationmethodsList = (params = '') => Http.get(`/application-method${serialize(params)}`)

// // // GET ApplicationMethod BY ID
export const applicationmethodByID = (params = '') => Http.get(`/application-method/${params}`)
// // // UPDATE EDUCATION LEVEL
export const applicationmethodUpdate = (params = '') => Http.put(`/application-method/${params.id}`, params)
// // // DELETE EDUCATION LEVEL
export const applicationmethodDestroy = (params = '') => Http.delete(`/application-method/${params.id}`)
