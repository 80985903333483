import {
	languagelevelByID,
	languagelevelCreate,
	languagelevelDestroy,
	languagelevelsList,
	languagelevelUpdate,
} from '@/services/jobs/languageLevels.service'

export default {
	namespaced: true,
	state: {
		// LANGUAGELEVELS
		languagelevelsList: {},
		languagelevel: {},
		// STATUS LANGUAGELEVEL
		languagelevelsStatus: 'pending',
		languagelevelsSaveStatus: 'init',
		languagelevelsDeleteStatus: 'init',
	},
	getters: {
		// LANGUAGELEVEL
		getLanguageLevelsList(state) {
			return state.languagelevelsList
		},
		getLanguageLevel(state) {
			return state.languagelevel
		},
		// LANGUAGELEVEL
		getLanguageLevelsDeleteStatus(state) {
			return state.languagelevelsDeleteStatus
		},
		getLanguageLevelsSaveStatus(state) {
			return state.languagelevelsSaveStatus
		},
		getLanguageLevelsStatus(state) {
			return state.languagelevelsStatus
		},
	},
	mutations: {
		// LANGUAGELEVELS
		LANGUAGELEVELS_LIST(state, value) {
			state.languagelevelsList = value
		},
		SET_LANGUAGELEVEL(state, value) {
			state.languagelevel = value
		},
		// STATUS LANGUAGELEVELS
		LANGUAGELEVELS_STATUS(state, value) {
			state.languagelevelsStatus = value
		},
		LANGUAGELEVEL_SAVE_STATUS(state, value) {
			state.languagelevelsSaveStatus = value
		},
		LANGUAGELEVEL_DELETE_STATUS(state, value) {
			state.languagelevelsDeleteStatus = value
		},
	},
	actions: {
		// LanguageLevels
		async fetchLanguageLevelList({ commit }, params) {
			console.log('params', params)
			commit('LANGUAGELEVELS_STATUS', 'pending')
			commit('LANGUAGELEVELS_LIST', {})
			try {
				const { data } = await languagelevelsList(params)
				console.log(data)
				commit('LANGUAGELEVELS_STATUS', 'init')
				commit('LANGUAGELEVELS_LIST', data)
				return data
			} catch (error) {
				commit('LANGUAGELEVELS_STATUS', 'init')
				throw error
			}
		},
		async fetchLanguageLevelByID({ commit }, params) {
			commit('LANGUAGELEVELS_STATUS', 'pending')
			try {
				const { data } = await languagelevelByID(params)
				commit('LANGUAGELEVELS_STATUS', 'init')
				commit('SET_LANGUAGELEVEL', data.data)
				return data
			} catch (error) {
				commit('LANGUAGELEVELS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewLanguageLevel({ commit }, params) {
			commit('LANGUAGELEVEL_SAVE_STATUS', 'pending')
			try {
				const { data } = await languagelevelCreate(params)
				commit('LANGUAGELEVEL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('LANGUAGELEVEL_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateLanguageLevel({ commit }, params) {
			commit('LANGUAGELEVEL_SAVE_STATUS', 'pending')
			try {
				const { data } = await languagelevelUpdate(params)
				commit('LANGUAGELEVEL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('LANGUAGELEVEL_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteLanguageLevelByID({ commit }, params) {
			commit('LANGUAGELEVEL_DELETE_STATUS', 'pending')
			try {
				const { data } = await languagelevelDestroy(params)
				commit('LANGUAGELEVEL_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('LANGUAGELEVEL_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
