const employments = [
	{
		path: '/employments/employments-types/list',
		name: 'jobs-employments-types-list',
		component: () =>
			import(/* webpackChunkName: "EmploymentsList" */ '@/views/jobs/employments/employments-types/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default employments
