import {
	deleteAchievement,
	getAchievements,
	saveAchievement,
	updateAchievement,
} from '@/services/community/wikigamer/games/achievements'
export default {
	namespaced: true,
	state: {
		achievementList: {
			meta: {
				total: 0,
			},
		},
		achievementStatus: 'pending',
	},
	getters: {
		getAchievementList(state) {
			return state.achievementList
		},
		getAchievementStatus(state) {
			return state.achievementStatus
		},
	},
	mutations: {
		SET_ACHIEVEMENT_LIST(state, val) {
			state.achievementList = val
		},
		SET_ACHIEVEMENT_STATUS(state, val) {
			state.achievementStatus = val
		},
	},
	actions: {
		async fetchAchievements({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_ACHIEVEMENT_STATUS', 'pending')
			commit('SET_ACHIEVEMENT_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getAchievements(params)
				commit('SET_ACHIEVEMENT_STATUS', 'init')
				commit('SET_ACHIEVEMENT_LIST', data)
				return data
			} catch (error) {
				commit('SET_ACHIEVEMENT_STATUS', 'init')
				throw error
			}
		},

		async deleteAchievementRecord({ commit }, params) {
			commit('SET_ACHIEVEMENT_STATUS', 'pending')
			try {
				const { data } = await deleteAchievement(params)
				commit('SET_ACHIEVEMENT_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_ACHIEVEMENT_STATUS', 'init')
				throw error
			}
		},
		async saveAchievement({ commit }, params) {
			commit('SET_ACHIEVEMENT_STATUS', 'pending')
			try {
				const { data } = await saveAchievement(params)
				commit('SET_ACHIEVEMENT_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_ACHIEVEMENT_STATUS', 'init')
				throw error
			}
		},
		async updateAchievement({ commit }, params) {
			commit('SET_ACHIEVEMENT_STATUS', 'pending')
			try {
				const data = await updateAchievement(params)
				commit('SET_ACHIEVEMENT_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_ACHIEVEMENT_STATUS', 'init')
				throw error
			}
		},
	},
}
