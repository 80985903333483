const languagesLocations = [
	{
		path: '/network/languages-locations/languages/list',
		name: 'network-languages-list',
		component: () => import('@/views/network/languages-locations/languages/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/languages-locations/countries/list',
		name: 'network-countries-list',
		component: () => import('@/views/network/languages-locations/countries/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/languages-locations/countries/create',
		name: 'network-countries-create',
		component: () => import('@/views/network/languages-locations/countries/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/languages-locations/countries/edit/:id',
		name: 'network-countries-edit',
		component: () => import('@/views/network/languages-locations/countries/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/languages-locations/cities/list',
		name: 'network-cities-list',
		component: () => import('@/views/network/languages-locations/cities/list.vue'),
		meta: {
			layout: 'content',
		},
	},

	{
		path: '/network/languages-locations/regions/list',
		name: 'network-regions-list',
		component: () => import('@/views/network/languages-locations/regions/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/languages-locations/nationalities/list',
		name: 'network-nationalities-list',
		component: () => import('@/views/network/languages-locations/nationalities/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/languages-locations/states/list',
		name: 'network-states-list',
		component: () => import('@/views/network/languages-locations/states/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/languages-locations/timezones/list',
		name: 'network-timezones-list',
		component: () => import('@/views/network/languages-locations/timezones/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default languagesLocations
