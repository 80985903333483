const translationQueue = [
	{
		path: '/community/contents/translation-queue/list',
		name: 'community-contents-translation-queue-list',
		component: () => import('@/views/community/translation-queue/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	// {
	// 	path: '/community/contents/translation-queue/new',
	// 	name: 'community-contents-translation-queue-create',
	// 	component: () => import('@/views/community/translation-queue/new.vue'),
	// 	meta: {
	// 		layout: 'content',
	// 	},
	// },
	// {
	// 	path: '/community/contents/translation-queue/edit/:id',
	// 	name: 'comunity-contents-translation-queue-edit',
	// 	component: () => import('@/views/community/translation-queue/new.vue'),
	// 	meta: {
	// 		layout: 'content',
	// 	},
	// },
	
]

export default translationQueue
