import {
	vacanciesstatusList,
	vacancystatusByID,
	vacancystatusCreate,
	vacancystatusDestroy,
	vacancystatusUpdate,
} from '@/services/jobs/vacanciesStatus.service'

export default {
	namespaced: true,
	state: {
		// VACANCIESSTATUS
		vacanciesstatusList: {},
		vacancystatus: {},
		// STATUS VACANCYSTATUS
		vacanciesstatusStatus: 'pending',
		vacanciesstatusSaveStatus: 'init',
		vacanciesstatusDeleteStatus: 'init',
	},
	getters: {
		// VACANCYSTATUS
		getVacanciesStatusList(state) {
			return state.vacanciesstatusList
		},
		getVacancyStatus(state) {
			return state.vacancystatus
		},
		// VACANCYSTATUS
		getVacanciesStatusDeleteStatus(state) {
			return state.vacanciesstatusDeleteStatus
		},
		getVacanciesStatusSaveStatus(state) {
			return state.vacanciesstatusSaveStatus
		},
		getVacanciesStatusStatus(state) {
			return state.vacanciesstatusStatus
		},
	},
	mutations: {
		// VACANCIESSTATUS
		VACANCIESSTATUS_LIST(state, value) {
			state.vacanciesstatusList = value
		},
		SET_VACANCYSTATUS(state, value) {
			state.vacancystatus = value
		},
		// STATUS VACANCIESSTATUS
		VACANCIESSTATUS_STATUS(state, value) {
			state.vacanciesstatusStatus = value
		},
		VACANCYSTATUS_SAVE_STATUS(state, value) {
			state.vacanciesstatusSaveStatus = value
		},
		VACANCYSTATUS_DELETE_STATUS(state, value) {
			state.vacanciesstatusDeleteStatus = value
		},
	},
	actions: {
		// VacanciesStatus
		async fetchVacancyStatusList({ commit }, params) {
			console.log('params', params)
			commit('VACANCIESSTATUS_STATUS', 'pending')
			commit('VACANCIESSTATUS_LIST', {})
			try {
				const { data } = await vacanciesstatusList(params)
				console.log(data)
				commit('VACANCIESSTATUS_STATUS', 'init')
				commit('VACANCIESSTATUS_LIST', data)
				return data
			} catch (error) {
				commit('VACANCIESSTATUS_STATUS', 'init')
				throw error
			}
		},
		async fetchVacancyStatusByID({ commit }, params) {
			commit('VACANCIESSTATUS_STATUS', 'pending')
			try {
				const { data } = await vacancystatusByID(params)
				commit('VACANCIESSTATUS_STATUS', 'init')
				commit('SET_VACANCYSTATUS', data.data)
				return data
			} catch (error) {
				commit('VACANCIESSTATUS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewVacancyStatus({ commit }, params) {
			commit('VACANCYSTATUS_SAVE_STATUS', 'pending')
			try {
				const { data } = await vacancystatusCreate(params)
				commit('VACANCYSTATUS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('VACANCYSTATUS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateVacancyStatus({ commit }, params) {
			commit('VACANCYSTATUS_SAVE_STATUS', 'pending')
			try {
				const { data } = await vacancystatusUpdate(params)
				commit('VACANCYSTATUS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('VACANCYSTATUS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteVacancyStatusByID({ commit }, params) {
			commit('VACANCYSTATUS_DELETE_STATUS', 'pending')
			try {
				const { data } = await vacancystatusDestroy(params)
				commit('VACANCYSTATUS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('VACANCYSTATUS_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
