import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getQuestions = (
	params = {
		perPage: 10,
		page: 1,
		search: '',
		quiz_id: '',
	},
) => Http.get(`/quizzes/questions/fetch${serialize(params)}`)

export const destroyQuestion = (
	params = {
		id: null,
	},
) => Http.delete(`/quizzes/questions/${params.id}/destroy`)

export const saveQuestion = (
	params = {
		name: null,
		url: null,
		status_id: null,
	},
) => Http.post(`/quizzes/questions/store`, params)

export const updateQuestion = (
	params = {
		id: null,
		name: null,
		url: null,
		status_id: null,
	},
) => Http.put(`/quizzes/questions/${params.id}/update`, params)

export const getQuestion = (
	params = {
		questionId: '',
	},
) => Http.get(`/quizzes/questions/${params.questionId}/show`)
