import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getPulses = params => Http.get(`/pulses/fetch${serialize(params)}`)

export const getStats = params => Http.get(`pulses/stats${serialize(params)}`)

export const getStatsLanguageEnglish = (
	params = {
		language_id: 1,
		status_id: 1,
	},
) => Http.get(`pulses/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.language_id}`)

export const getStatsLanguageDeutsch = (
	params = {
		language_id: 2,
		status_id: 1,
	},
) => Http.get(`pulses/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.language_id}`)

export const getStatsLanguagePortuguese = (
	params = {
		language_id: 3,
		status_id: 1,
	},
) => Http.get(`pulses/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.language_id}`)

export const getStatsLanguageSpanish = (
	params = {
		language_id: 4,
		status_id: 1,
	},
) => Http.get(`pulses/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.language_id}`)

export const getStatsLanguageRomanian = (
	params = {
		language_id: 5,
		status_id: 1,
	},
) => Http.get(`pulses/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.language_id}`)

export const removePulse = params => {
	return Http.delete(`/creator-mode/article/${params.link}`, { baseURL: 'locale' })
}

export const getPulseByLink = link => {
	return Http.get(`/creator-mode/article/${link}`, { baseURL: 'locale' })
}
