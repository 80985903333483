import {
	formatByID,
	formatCreate,
	formatDestroy,
	formatList,
	formatUpdate,
} from '@/services/academy/courses/formats.service'

export default {
	namespaced: true,
	state: {
		// SERVICE FORMAT
		formatList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		format: {},

		// STATUS SERVICE FORMAT
		formatStatus: 'pending',
		formatSaveStatus: 'init',
		formatDeleteStatus: 'init',
	},
	getters: {
		// SERVICE FORMAT
		getFormatList(state) {
			return state.formatList
		},
		getFormat(state) {
			return state.format
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE FORMAT
		getFormatDeleteStatus(state) {
			return state.formatDeleteStatus
		},
		getFormatSaveStatus(state) {
			return state.formatSaveStatus
		},
		getFormatStatus(state) {
			return state.formatStatus
		},
	},
	mutations: {
		// SERVICE FORMAT
		FORMAT_LIST(state, value) {
			state.formatList = value
		},
		SET_FORMAT(state, value) {
			state.format = value
		},

		// STATUS SERVICE FORMAT
		FORMAT_STATUS(state, value) {
			state.formatStatus = value
		},
		FORMAT_SAVE_STATUS(state, value) {
			state.formatSaveStatus = value
		},
		FORMAT_DELETE_STATUS(state, value) {
			state.formatDeleteStatus = value
		},
	},
	actions: {
		// Service Format
		async fetchFormatList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('FORMAT_STATUS', 'pending')
			commit('FORMAT_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await formatList(params)
				commit('FORMAT_STATUS', 'init')
				commit('FORMAT_LIST', data)
				return data
			} catch (error) {
				commit('FORMAT_STATUS', 'init')
				throw error
			}
		},
		async fetchFormatByID({ commit }, params) {
			commit('FORMAT_STATUS', 'pending')
			try {
				const { data } = await formatByID(params)
				commit('FORMAT_STATUS', 'init')
				commit('SET_FORMAT', data.data)
				return data
			} catch (error) {
				commit('FORMAT_STATUS', 'init')

				return error
			}
		},
		async saveNewFormat({ commit }, params) {
			commit('FORMAT_SAVE_STATUS', 'pending')
			try {
				const { data } = await formatCreate(params)
				commit('FORMAT_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('FORMAT_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateFormat({ commit }, params) {
			commit('FORMAT_SAVE_STATUS', 'pending')
			try {
				const { data } = await formatUpdate(params)
				commit('FORMAT_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('FORMAT_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteFormatByID({ commit }, params) {
			commit('FORMAT_DELETE_STATUS', 'pending')
			try {
				const { data } = await formatDestroy(params)
				commit('FORMAT_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('FORMAT_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
