import { deleteRoadmapByID, newRoadmap, roadmapsByID, roadmapsList, updateRoadmap } from '@/services/roadmaps.service'

export default {
	namespaced: true,
	state: {
		// ROADMAPS
		roadmapsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		roadmap: {},
		// STATUS
		roadmapsStatus: 'init',
		roadmapsSaveStatus: 'init',
		roadmapsDeleteStatus: 'init',
	},
	getters: {
		// ROADMAPS
		getRoadmapsList(state) {
			return state.roadmapsList
		},
		getRoadmap(state) {
			return state.roadmap
		},
		// STATUS
		getRoadmapsDeleteStatus(state) {
			return state.roadmapsDeleteStatus
		},
		getRoadmapsSaveStatus(state) {
			return state.roadmapsSaveStatus
		},
		getRoadmapsStatus(state) {
			return state.roadmapsStatus
		},
	},
	mutations: {
		// ROADMAPS
		ROADMAPS_LIST(state, value) {
			state.roadmapsList = value
		},
		SET_ROADMAP(state, value) {
			state.roadmap = value
		},
		// STATUS
		ROADMAPS_STATUS(state, value) {
			console.log(value)
			state.roadmapsStatus = value
		},
		ROADMAPS_SAVE_STATUS(state, value) {
			state.roadmapsSaveStatus = value
		},
		ROADMAPS_DELETE_STATUS(state, value) {
			state.roadmapsDeleteStatus = value
		},
	},
	actions: {
		// Roadmaps
		async fetchRoadmapsList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('ROADMAPS_STATUS', 'pending')
			commit('ROADMAPS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await roadmapsList(params)
				commit('ROADMAPS_STATUS', 'init')
				commit('ROADMAPS_LIST', data)
				return data
			} catch (error) {
				commit('ROADMAPS_STATUS', 'init')
				throw error
			}
		},
		async fetchRoadmapByID({ state, commit }, params) {
			commit('ROADMAPS_STATUS', 'pending')
			try {
				const { data } = await roadmapsByID(params)
				commit('ROADMAPS_STATUS', 'init')
				commit('SET_ROADMAP', data.data)
				return data
			} catch (error) {
				commit('ROADMAPS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewRoadmap({ commit }, params) {
			commit('ROADMAPS_SAVE_STATUS', 'pending')
			try {
				const { data } = await newRoadmap(params)
				commit('ROADMAPS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('ROADMAPS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateRoadmap({ commit }, params) {
			commit('ROADMAPS_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateRoadmap(params)
				commit('ROADMAPS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('ROADMAPS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteRoadmapByID({ state, commit }, params) {
			commit('ROADMAPS_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteRoadmapByID(params)
				commit('ROADMAPS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('ROADMAPS_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
