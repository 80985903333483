import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

// LIST ALL EVENTS
export const getEvents = (
	params = {
		'perPage': null,
		'page': null,
		'filter[term]': '',
	},
) => Http.get(`/events/fetch${serialize(params)}`)

export const newEvent = (params = '') => Http.post('/events/store', params)

export const eventByID = (id = '') => Http.get(`/events/${id}/show`)

export const updateEvent = (params = '') => {
	return Http.put(`events/${params.id}/update`, params)
}
