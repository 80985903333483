const contact = [
	{
		path: '/agency/contact-requests/list',
		name: 'contact-requests-list',
		component: () => import('@/views/agency/contact-requests/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default contact
