import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpJobs'

// // // LIST ALL VACANCIES FROM JOBS
export const vacancyList = params => Http.get(`/vacancy${serialize(params)}`)

// // // LIST VACANCIES FROM ID
export const vacancyByID = (params = '') => Http.get(`/vacancy/${params}`)

// // // POST NEW VACANCY
export const newVacancy = (params = '') => Http.post('/vacancy', params)

// // // UPDATE VACANCY
export const updateVacancy = (params = '') => Http.put(`/vacancy/${params.id}`, params)

// // // DELETE VACANCY
export const vacancyDestroy = (params = '') => Http.delete(`/vacancy/${params.id}`)

export const vacancyStatusList = () => Http.get(`/vacancy-status?status=true`)

export const getStats = (params = '') => Http.get(`/vacancies/stats/status`, params)
