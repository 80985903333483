import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/http'

export const listMarketings = params => {
	return Http.get(`/marketings${serialize(params)}`)
}

export const listMarketingProviders = params => {
	return Http.get(`/marketing-providers${serialize(params)}`)
}

export const marketingByID = params => {
	return Http.get(`/marketings/${params}`)
}
