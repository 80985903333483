// eslint-disable-next-line object-curly-newline
import moment from 'moment'
import { getCurrentInstance, reactive, toRefs, watch } from 'vue'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
	const today = new Date()

	return (
		/* eslint-disable operator-linebreak */
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
		/* eslint-enable */
	)
}

export const dateInPast = (firstDate, secondDate) => {
	if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
		return true
	}

	return false
}

export const getVuetify = () => {
	const ins = getCurrentInstance().proxy

	return ins && ins.$vuetify ? ins.$vuetify : null
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
	const vm = getCurrentInstance().proxy

	const state = reactive({
		route: vm.$route,
	})

	watch(
		() => vm.$route,
		r => {
			state.route = r
		},
	)

	return { ...toRefs(state), router: vm.$router }
}

export const isEmpty = value => {
	if (value === null || value === undefined || value === '') {
		return true
	}

	if (Array.isArray(value) && value.length === 0) {
		return true
	}

	return false
}

// ——— Get Initial Text from name ——————— //

export const getInitialName = fullName =>
	// eslint-disable-next-line implicit-arrow-linebreak
	fullName
		.split(' ')
		.map(n => n[0])
		.join('')

// ——— Add Alpha To color ——————— //

export const addAlpha = (color, opacity) => {
	const opacityLocal = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)

	return color + opacityLocal.toString(16).toUpperCase()
}

// ——— Perfect Scrollbar Scroll to bottom ——————— //

export const psScrollToBottom = psRef => () => {
	const scrollEl = psRef.value.$el || psRef.value
	scrollEl.scrollTop = scrollEl.scrollHeight
}

// ——— Perfect Scrollbar Scroll to bottom ——————— //

export const psScrollToTop = psRef => () => {
	const scrollEl = psRef.value.$el || psRef.value
	scrollEl.scrollTop = 0
}

// ————————————————————————————————————
//* ——— Color Manipulations
// ————————————————————————————————————

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const rgbToHex = (r, g, b) => {
	const componentToHex = c => {
		const hex = c.toString(16)

		return hex.length === 1 ? `0${hex}` : hex
	}

	return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const hexToRgb = hex => {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
	// eslint-disable-next-line no-param-reassign
	hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

	/* eslint-disable indent */
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null
	/* eslint-enable */
}

export const formatCreatedAt = createdAt => {
	const dateTime = moment.utc(createdAt).format('DD/MM/YYYY HH:mm:ss z Z')
	return dateTime
}

export const formatUpdatedAt = updatedAt => {
	const dateTime = moment.utc(updatedAt).format('DD/MM/YYYY HH:mm:ss z Z')
	return dateTime
}

export const urlJoin = parts => {
	// trim leading and trailing slashes
	const trimmed = parts.map(part => part.replace(/^\/|\/$/g, ''))
	// join with slashes
	return trimmed.join('/')
}

// BASE64
export const getBase64FromUrl = async (url) => {
	try {
		const response = await fetch(url)
		const contentType = response.headers.get('content-type')
		
		if (contentType === 'image/svg+xml') {
			const svgData = await response.text()
			const base64data = btoa(svgData)
			return `data:image/svg+xml;base64,${base64data}`
		} else {
			const blob = await response.blob()
			return new Promise(resolve => {
				const reader = new FileReader()
				reader.readAsDataURL(blob)
				reader.onloadend = () => {
					const base64data = reader.result
					resolve(base64data)
				}
			})
		}
	} catch (error) {
		console.log(error)
		return ''
	}
}

export const getBase64FromFile = async file => {
	return new Promise((resolve, reject) => {
	  	const reader = new FileReader()
		reader.readAsDataURL(file)
		
		reader.onloadend = () => {
			const base64data = reader.result
			resolve(base64data)
		}
	
		reader.onerror = error => {
			console.log(error)
			reject(error)
		}
	})
}

// IMAGE
export const validateImageFromBase64 = async (data) => {
    try {
        const response = await fetch(data);
        const contentType = response.headers.get('content-type');

        if (contentType.startsWith('image/')) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

export const imageDimensionsToPercentage = async (base64data, width, height) => {
    try {
        const image = new Image()
        image.src = base64data

        await image.decode()

        const imageWidth = image.width
        const imageHeight = image.height

		// if (imageWidth < width || imageHeight < height) {
		// 	return [1, 1]
		// }
        const widthPercentage = Math.max(1, Math.min(100, (width / imageWidth) * 100))
        const heightPercentage = Math.max(1, Math.min(100, (height / imageHeight) * 100))
        return [widthPercentage, heightPercentage]
    } catch (error) {
        console.error(error)
        return [1, 1]
    }
};

export const validateImageDimensionsFromBase64 = async (base64data, mode, width, height) => {
    try {
        const image = new Image()
        image.src = base64data

        await image.decode()

        const imageWidth = image.width
        const imageHeight = image.height

        if (mode === 'max')
            return imageWidth <= width && imageHeight <= height
        else if (mode === 'min')
            return imageWidth >= width && imageHeight >= height
        else
            return false
    } catch (error) {
        console.error(error)
        return false
    }
}

export const validateImageSizeFromBase64 = async (base64data, maxSizeKB) => {
    try {
        const byteCharacters = atob(base64data.split(',')[1])
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            const slice = byteCharacters.slice(offset, offset + 1024)

            const byteNumbers = new Array(slice.length)
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            const byteArray = new Uint8Array(byteNumbers)
            byteArrays.push(byteArray)
        }

        const totalBytes = byteArrays.reduce((acc, byteArray) => acc + byteArray.length, 0)
        const maxSizeBytes = maxSizeKB * 1024

        return totalBytes > maxSizeBytes
    } catch (error) {
        console.error(error)
        return false
    }
};

// URL
export const validateImageURL = async url => {
    try {
		const response = await fetch(url, { method: 'HEAD' })
		if (response.status >= 400 && response.status <= 599)
			return false
		return true
    } catch (error) {
      	return false
    }
}