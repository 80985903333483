import {
	workhourByID,
	workhourCreate,
	workhourDestroy,
	workhoursList,
	workhourUpdate,
} from '@/services/jobs/workHours.service'

export default {
	namespaced: true,
	state: {
		// WORKHOURS
		workhoursList: {},
		workhour: {},
		// STATUS WORKHOUR
		workhoursStatus: 'pending',
		workhoursSaveStatus: 'init',
		workhoursDeleteStatus: 'init',
	},
	getters: {
		// WORKHOUR
		getWorkhoursList(state) {
			return state.workhoursList
		},
		getWorkhour(state) {
			return state.workhour
		},
		// WORKHOUR
		getWorkhoursDeleteStatus(state) {
			return state.workhoursDeleteStatus
		},
		getWorkhoursSaveStatus(state) {
			return state.workhoursSaveStatus
		},
		getWorkhoursStatus(state) {
			return state.workhoursStatus
		},
	},
	mutations: {
		// WORKHOURS
		WORKHOURS_LIST(state, value) {
			state.workhoursList = value
		},
		SET_WORKHOUR(state, value) {
			state.workhour = value
		},
		// STATUS WORKHOURS
		WORKHOURS_STATUS(state, value) {
			state.workhoursStatus = value
		},
		WORKHOUR_SAVE_STATUS(state, value) {
			state.workhoursSaveStatus = value
		},
		WORKHOUR_DELETE_STATUS(state, value) {
			state.workhoursDeleteStatus = value
		},
	},
	actions: {
		// Workhours
		async fetchWorkhourList({ commit }, params) {
			console.log('params', params)
			commit('WORKHOURS_STATUS', 'pending')
			commit('WORKHOURS_LIST', {})
			try {
				const { data } = await workhoursList(params)
				console.log(data)
				commit('WORKHOURS_STATUS', 'init')
				commit('WORKHOURS_LIST', data)
				return data
			} catch (error) {
				commit('WORKHOURS_STATUS', 'init')
				throw error
			}
		},
		async fetchWorkhourByID({ commit }, params) {
			commit('WORKHOURS_STATUS', 'pending')
			try {
				const { data } = await workhourByID(params)
				commit('WORKHOURS_STATUS', 'init')
				commit('SET_WORKHOUR', data.data)
				return data
			} catch (error) {
				commit('WORKHOURS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewWorkhour({ commit }, params) {
			commit('WORKHOUR_SAVE_STATUS', 'pending')
			try {
				const { data } = await workhourCreate(params)
				commit('WORKHOUR_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WORKHOUR_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateWorkhour({ commit }, params) {
			commit('WORKHOUR_SAVE_STATUS', 'pending')
			try {
				const { data } = await workhourUpdate(params)
				commit('WORKHOUR_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WORKHOUR_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteWorkhourByID({ commit }, params) {
			commit('WORKHOUR_DELETE_STATUS', 'pending')
			try {
				const { data } = await workhourDestroy(params)
				commit('WORKHOUR_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WORKHOUR_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
