import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const communityList = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/communities/fetch${serialize(params)}`)

export const communityUpdate = (params = {}) =>
	Http.put(`/creator-mode/community/${params.url}`, params, { baseURL: 'locale' })

export const GetCommunityBySlug = url => {
	return Http.get(`/creator-mode/community/${params.url}`, params, { baseURL: 'locale' })
}

export const communityDestroy = params => {
	return Http.delete(`/creator-mode/community/${params.url}`, params, { baseURL: 'locale' })
}
