import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n'
import '@/styles/styles.scss'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import _ from 'lodash'
import { VueMaskDirective } from 'v-mask'
import Vue from 'vue'
import Notify from 'vue-notifyjs'
import 'vue-notifyjs/themes/default.css'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import './plugins/acl'

import VueRx from 'vue-rx'

import filters from './filters'
import vuetify from './plugins/vuetify'
import router from './router'
import { AuthService } from './services/auth.service'
import store from './store'

import moment from 'moment'

import 'vuejs-clipper/dist/vuejs-clipper.css'
import VuejsClipper from 'vuejs-clipper/dist/vuejs-clipper.umd.min'

const $authService = new AuthService()

$authService.isAuthenticated().then(isAuthenticated => {
	if (!isAuthenticated) {
		if (process.env.NODE_ENV === 'development' && !process.env.VUE_APP_ACCOUNTS_URL) {
			return document.write('<h1>Please check the Authenticate section in your README.md before continuing</h1>')
		}
		window.location =
			process.env.VUE_APP_ACCOUNTS_URL.replace(/\/$/, '') + '/logout?redirect_to=' + window.location.href
		return
	}

	Vue.config.productionTip = false
	Vue.use(Notify)
	Vue.use(Vuelidate)

	Vue.use(VueRx)
	Vue.use(VuejsClipper)

	Vue.directive('mask', VueMaskDirective)
	Vue.set(Vue.prototype, '$_', _)

	Vue.prototype.$authService = $authService
	Vue.use(CKEditor)

	Vue.config.productionTip = false
	Vue.use(Notify)
	Vue.use(Vuelidate)
	Vue.directive('mask', VueMaskDirective)
	Vue.set(Vue.prototype, '$_', _)

	const moment = require('vue-moment')
	Vue.use(moment)
	Vue.prototype.moment = moment

	new Vue({
		router,
		store,
		i18n,
		vuetify,
		filters,
		render: h => h(App),
	}).$mount('#app')
})
