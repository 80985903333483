import {
	blogCategoriesList,
	blogCategoryByID,
	blogPostsByID,
	blogPostsList,
	blogPostsStatusList,
	blogTagByID,
	blogTagsList,
	blogTagsListAll,
	deleteCategoryByID,
	deleteTagByID,
	newBlogTag,
	newCategory,
	newPost,
	postDestroy,
	requiredTags,
	updateCategory,
	updatePost,
	updateTag,
} from '@/services/blog.service'

export default {
	namespaced: true,
	state: {
		// POSTS
		postList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		postStatusList: {},
		post: {},
		// CATEGORIES
		categoriesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		category: {},
		// CATEGORIES
		tagsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		tag: {},
		// STATUS
		blogStatus: 'init',
		blogSaveStatus: 'init',
		blogDeleteStatus: 'init',

		// BLOG CATEGORIES
		blogCategories: [],
		blogCategoriesStatus: 'init',

		// REQUIRED TAGS
		requiredTags: [],
		requiredTagsStatus: 'init',
	},
	getters: {
		// POSTS
		getPostList(state) {
			return state.postList
		},
		getPost(state) {
			return state.post
		},
		getPostStatusList(state) {
			return state.postStatusList
		},
		// CATEGORIES
		getCategoriesList(state) {
			return state.categoriesList
		},
		getCategory(state) {
			return state.category
		},
		// TAGS
		getTagsList(state) {
			return state.tagsList
		},
		// STATUS
		getBlogDeleteStatus(state) {
			return state.blogDeleteStatus
		},
		getBlogSaveStatus(state) {
			return state.blogSaveStatus
		},
		getBlogStatus(state) {
			return state.blogStatus
		},

		// REQUIRED TAGS
		requiredTags(state) {
			return state.requiredTags
		},
		requiredTagsStatus(state) {
			return state.requiredTagsStatus
		},
	},
	mutations: {
		// POSTS
		POSTS_LIST(state, value) {
			state.postList = value
		},
		POSTS_STATUS_LIST(state, value) {
			state.postStatusList = value
		},
		SET_POST(state, value) {
			state.post = value
		},
		// CATEGORIES
		CATEGORIES_LIST(state, value) {
			state.categoriesList = value
		},
		// CATEGORIES
		SET_BLOG_CATEGORY(state, value) {
			state.category = value
		},
		// TAGS
		TAGS_LIST(state, value) {
			state.tagsList = value
		},
		// STATUS
		BLOG_STATUS(state, value) {
			state.blogStatus = value
		},
		BLOG_SAVE_STATUS(state, value) {
			state.blogSaveStatus = value
		},
		BLOG_DELETE_STATUS(state, value) {
			state.blogDeleteStatus = value
		},

		// REQUIRED TAGS
		SET_REQUIRED_TAGS(state, val) {
			state.requiredTags = val
		},
		SET_REQUIRED_TAGS_STATUS(state, val) {
			state.requiredTagsStatus = val
		},
	},
	actions: {
		// POSTS
		async getPostsList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('POSTS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			commit('BLOG_STATUS', 'pending')
			try {
				const { data } = await blogPostsList(params)
				commit('BLOG_STATUS', 'init')
				commit('POSTS_LIST', data)
				return data
			} catch (error) {
				commit('BLOG_STATUS', 'init')
				throw error
			}
		},
		async delePostByID({ commit }, params) {
			commit('BLOG_DELETE_STATUS', 'pending')
			try {
				const { data } = await postDestroy(params)
				commit('BLOG_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BLOG_DELETE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		async fetchPostsByID({ state, commit }, params) {
			commit('BLOG_STATUS', 'pending')
			try {
				const { data } = await blogPostsByID(params)
				commit('BLOG_STATUS', 'init')
				commit('SET_POST', data.data)
				return data
			} catch (error) {
				commit('BLOG_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async updatePost({ commit }, params) {
			commit('BLOG_SAVE_STATUS', 'pending')
			try {
				const { data } = await updatePost(params)
				commit('BLOG_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BLOG_SAVE_STATUS', 'init')
				throw error
			}
		},
		async getPostsStatusList({ commit }, params) {
			commit('BLOG_STATUS', 'pending')
			try {
				const { data } = await blogPostsStatusList(params)
				commit('BLOG_STATUS', 'init')
				commit('POSTS_STATUS_LIST', data.data)
				return data
			} catch (error) {
				commit('BLOG_STATUS', 'init')
				throw error
			}
		},
		async saveNewPost({ commit }, params) {
			commit('BLOG_SAVE_STATUS', 'pending')
			try {
				const { data } = await newPost(params)
				commit('BLOG_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BLOG_SAVE_STATUS', 'init')
				throw error
			}
		},
		// CATEGORIES
		async fetchCategoriesList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('CATEGORIES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			commit('BLOG_STATUS', 'pending')
			try {
				const { data } = await blogCategoriesList(params)
				commit('BLOG_STATUS', 'init')
				commit('CATEGORIES_LIST', data)
				return data
			} catch (error) {
				commit('BLOG_STATUS', 'init')
				throw error
			}
		},

		async fetchCategoryByID({ state, commit }, params) {
			try {
				const { data } = await blogCategoryByID(params)
				commit('SET_BLOG_CATEGORY', data.data)
				return data
			} catch (error) {
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewCategory({ commit }, params) {
			commit('BLOG_SAVE_STATUS', 'pending')
			try {
				const { data } = await newCategory(params)
				commit('BLOG_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BLOG_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCategory({ commit }, params) {
			params = {
				id: params.id,
				name: params.name,
				status: params.status,
				language_id: params.language_id,
			}
			commit('BLOG_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateCategory(params)
				commit('BLOG_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BLOG_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCategoryByID({ state, commit }, params) {
			commit('BLOG_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteCategoryByID(params)
				commit('BLOG_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BLOG_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		// TAGS
		async fetchTagsList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('TAGS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			commit('BLOG_STATUS', 'pending')
			try {
				const { data } = await blogTagsList(params)
				commit('BLOG_STATUS', 'init')
				commit('TAGS_LIST', data)
				return data
			} catch (error) {
				commit('BLOG_STATUS', 'init')
				throw error
			}
		},
		async fetchAllTagsList({ commit }, params) {
			commit('BLOG_STATUS', 'pending')
			try {
				const { data } = await blogTagsListAll(params)
				commit('BLOG_STATUS', 'init')
				commit('TAGS_LIST', data.data)
				return data
			} catch (error) {
				commit('BLOG_STATUS', 'init')
				throw error
			}
		},
		async fetchTagByID({ state, commit }, params) {
			try {
				const { data } = await blogTagByID(params)
				commit('SET_BLOG_CATEGORY', data.data)
				return data
			} catch (error) {
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewTag({ commit }, params) {
			commit('BLOG_SAVE_STATUS', 'pending')
			try {
				const { data } = await newBlogTag(params)
				commit('BLOG_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BLOG_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateTag({ commit }, params) {
			params = {
				id: params.id,
				name: params.name,
				description: params.description,
				status: params.status,
				language_id: params.language_id,
				seo_title: params.seo_title,
				seo_description: params.seo_description,
			}

			commit('BLOG_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateTag(params)
				commit('BLOG_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BLOG_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteTagByID({ state, commit }, params) {
			commit('BLOG_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteTagByID(params)
				commit('BLOG_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BLOG_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		// REQUIRED TAGS
		async fetchRequiredTags({ commit }, params) {
			commit('SET_REQUIRED_TAGS_STATUS', 'pending')
			try {
				const { data } = await requiredTags(params)
				commit('SET_REQUIRED_TAGS', data.data)
				commit('SET_REQUIRED_TAGS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_REQUIRED_TAGS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
	},
}
