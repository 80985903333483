const community = [
	{
		path: '/community/communities',
		name: 'community-communities-list',
		component: () => import('@/views/community/communities/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/creators',
		name: 'community-creators-list',
		component: () => import('@/views/community/push-notifications/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/features',
		name: 'community-features-list',
		component: () => import('@/views/network/blog/posts/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/ratings',
		name: 'community-ratings-list',
		component: () => import('@/views/community/ratings/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/comments',
		name: 'community-comments-list',
		component: () => import('@/views/community/comments/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default community
