import {
	subcategoryByID,
	subcategoryCreate,
	subcategoryDestroy,
	subcategoryList,
	subcategoryUpdate,
} from '@/services/academy/courses/subcategories.service'

export default {
	namespaced: true,
	state: {
		// SERVICE SUBCATEGORY
		subcategoryList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		subcategory: {},

		// STATUS SERVICE SUBCATEGORY
		subcategoryStatus: 'pending',
		subcategorySaveStatus: 'init',
		subcategoryDeleteStatus: 'init',
	},
	getters: {
		// SERVICE SUBCATEGORY
		getSubcategoryList(state) {
			return state.subcategoryList
		},
		getSubcategory(state) {
			return state.subcategory
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE SUBCATEGORY
		getSubcategoryDeleteStatus(state) {
			return state.subcategoryDeleteStatus
		},
		getSubcategorySaveStatus(state) {
			return state.subcategorySaveStatus
		},
		getSubcategoryStatus(state) {
			return state.subcategoryStatus
		},
	},
	mutations: {
		// SERVICE SUBCATEGORY
		SUBCATEGORY_LIST(state, value) {
			state.subcategoryList = value
		},
		SET_SUBCATEGORY(state, value) {
			state.subcategory = value
		},

		// STATUS SERVICE SUBCATEGORY
		SUBCATEGORY_STATUS(state, value) {
			state.subcategoryStatus = value
		},
		SUBCATEGORY_SAVE_STATUS(state, value) {
			state.subcategorySaveStatus = value
		},
		SUBCATEGORY_DELETE_STATUS(state, value) {
			state.subcategoryDeleteStatus = value
		},
	},
	actions: {
		// Service Subcategory
		async fetchSubcategoryList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SUBCATEGORY_STATUS', 'pending')
			commit('SUBCATEGORY_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await subcategoryList(params)
				commit('SUBCATEGORY_STATUS', 'init')
				commit('SUBCATEGORY_LIST', data)
				return data
			} catch (error) {
				commit('SUBCATEGORY_STATUS', 'init')
				throw error
			}
		},
		async fetchSubcategoryByID({ commit }, params) {
			commit('SUBCATEGORY_STATUS', 'pending')
			try {
				const { data } = await subcategoryByID(params)
				commit('SUBCATEGORY_STATUS', 'init')
				commit('SET_SUBCATEGORY', data.data)
				return data
			} catch (error) {
				commit('SUBCATEGORY_STATUS', 'init')

				return error
			}
		},
		async saveNewSubcategory({ commit }, params) {
			commit('SUBCATEGORY_SAVE_STATUS', 'pending')
			try {
				const { data } = await subcategoryCreate(params)
				commit('SUBCATEGORY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SUBCATEGORY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateSubcategory({ commit }, params) {
			commit('SUBCATEGORY_SAVE_STATUS', 'pending')
			try {
				const { data } = await subcategoryUpdate(params)
				commit('SUBCATEGORY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SUBCATEGORY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteSubcategoryByID({ commit }, params) {
			commit('SUBCATEGORY_DELETE_STATUS', 'pending')
			try {
				const { data } = await subcategoryDestroy(params)
				commit('SUBCATEGORY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SUBCATEGORY_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
