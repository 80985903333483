import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getRegions = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/regions/fetch${serialize(params)}`)

// SAVE REGION
export const saveRegion = (
	params = {
		name: null,
	},
) => Http.post(`/regions/store`, params)

// UPDATE REGION
export const updateRegion = (
	params = {
		name: null,
		regionId: null,
	},
) => Http.put(`/regions/${params.regionId}/update`, params)

// DELETE REGION
export const deleteRegion = (
	params = {
		regionId: null,
	},
) => Http.delete(`/regions/${params.regionId}/destroy`)
