import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

export const getSocialiteProviders =  (params = '') => Http.get(`/socialite-provider/list${serialize(params)}`)

//SAVE A NEW SOCIALITEPROVIDER
export const saveSocialiteProvider = (params = '')=> Http.post('/socialite-provider', params)

//UPDATE SOCIALITEPROVIDER
export const updateSocialiteProvider = (
	params = {
		slug: null,
	},
) => Http.put(`/socialite-provider/${params.slug}`, params)

//DELETE SOCIALITEPROVIDER
export const deleteSocialiteProvider = (
	params = {
		slug: null,
	},
) => Http.delete(`/socialite-provider/${params.slug}`)
