const pulses = [
	{
		path: '/community/contents/pulses/list',
		name: 'community-contents-pulses-list',
		component: () => import('@/views/community/pulses/pulses/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/contents/pulses/new',
		name: 'community-contents-pulses-create',
		component: () => import('@/views/community/pulses/pulses/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/contents/pulses/edit/:link',
		name: 'community-contents-pulses-edit',
		component: () => import('@/views/community/pulses/pulses/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/contents/pulses/category',
		name: 'community-contents-pulses-category-list',
		component: () => import('@/views/community/pulses/category/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/pulses/pending',
		name: 'community-contents-pulses-pending-list',
		component: () => import('@/views/community/pulses/pending/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/contents/pulses/source',
		name: 'community-contents-pulses-source-list',
		component: () => import('@/views/community/pulses/source/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/contents/pulses/status',
		name: 'community-contents-pulses-status-list',
		component: () => import('@/views/community/pulses/status/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default pulses
