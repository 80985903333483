const magazine = [
	{
		path: '/agency/magazine/list',
		name: 'agency-magazine-list',
		component: () => import('@/views/agency/magazine/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/magazine-sections/list',
		name: 'agency-magazine-sections-list',
		component: () => import('@/views/agency/magazine-sections/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default magazine
