const pressMedia = [
	{
		path: '/network/press-media/press-posts/list',
		name: 'network-press-posts-list',
		component: () =>
			import(/* webpackChunkName: "PressMediaList" */ '@/views/network/press-media/press-posts/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/press-media/press-posts/create',
		name: 'network-press-posts-create',
		component: () =>
			import(/* webpackChunkName: "PressMediaCreate" */ '@/views/network/press-media/press-posts/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/press-media/press-posts/edit/:id',
		name: 'network-press-posts-edit',
		component: () =>
			import(/* webpackChunkName: "PressMediaEdit" */ '@/views/network/press-media/press-posts/new.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default pressMedia
