import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

//Content Types
export const typeCreate = (params = '') => Http.post('/editorial-content-types', params)
export const typesList = (params = '') => Http.get(`/editorial-content-types${serialize(params)}`)
export const typeByID = (params = '') => Http.get(`/editorial-content-types/${params}`)
export const typeUpdate = (params = '') => Http.put(`/editorial-content-types/${params.id}`, params)
export const typeDestroy = (params = '') => Http.delete(`/editorial-content-types/${params.id}`)

//Calculation Rules
export const ruleCreate = (params = '') => Http.post('/editorial-calculation-rules', params)
export const rulesList = (params = '') => Http.get(`/editorial-calculation-rules${serialize(params)}`)
export const ruleByID = (params = '') => Http.get(`/editorial-calculation-rules/${params}`)
export const ruleUpdate = (params = '') => Http.put(`/editorial-calculation-rules/${params.id}`, params)
export const ruleDestroy = (params = '') => Http.delete(`/editorial-calculation-rules/${params.id}`)

//Journalist Categories
export const categoryCreate = (params = '') => Http.post('/editorial-journalist-categories', params)
export const categoriesList = (params = '') => Http.get(`/editorial-journalist-categories${serialize(params)}`)
export const categoryByID = (params = '') => Http.get(`/editorial-journalist-categories/${params}`)
export const categoryUpdate = (params = '') => Http.put(`/editorial-journalist-categories/${params.id}`, params)
export const categoryDestroy = (params = '') => Http.delete(`/editorial-journalist-categories/${params.id}`)
