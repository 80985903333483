import { serialize } from '@/@core/libs/serialize/util'
import http from './http'

// // OPERATIONAL AREA
export const listAllMembers = params => http.get(`/teams-members${serialize(params)}`)
// // // LIST OPERATIONAL AREAS
export const listOperationalArea = (params = { page: 1, filter: '' }) =>
	http.get(`/operational-areas${serialize(params)}`)
// // // POST NEW OPERATIONAL AREA
export const newOperationalArea = params => http.post(`/operational-areas`, params)
// // // PUT OPERATIONAL AREA
export const updateOperationalArea = params => http.put(`/operational-areas/${params.id}`, params)
// // // DELETE OPERATIONAL AREA
export const deleteOperationalArea = params => http.delete(`/operational-areas/${params.id}`)
