import { render, staticRenderFns } from "./LayoutContentVerticalNav.vue?vue&type=template&id=42b57ac9&scoped=true&"
import script from "./LayoutContentVerticalNav.vue?vue&type=script&lang=js&"
export * from "./LayoutContentVerticalNav.vue?vue&type=script&lang=js&"
import style0 from "./LayoutContentVerticalNav.vue?vue&type=style&index=0&id=42b57ac9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42b57ac9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VApp,VAppBar,VFooter,VMain,VNavigationDrawer,VOverlay})
