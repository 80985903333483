import { communityDestroy, communityList, communityUpdate } from '@/services/community/communities'

export default {
	namespaced: true,
	state: {
		// SERVICE COMMUNITY
		communityList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		community: {},

		// STATUS SERVICE COMMUNITY
		communityStatus: 'pending',
		communitySaveStatus: 'init',
		communityDeleteStatus: 'init',
	},
	getters: {
		// SERVICE COMMUNITY
		getCommunityList(state) {
			return state.communityList
		},
		getCommunity(state) {
			return state.community
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE COMMUNITY
		getCommunityDeleteStatus(state) {
			return state.communityDeleteStatus
		},
		getCommunitySaveStatus(state) {
			return state.communitySaveStatus
		},
		getCommunityStatus(state) {
			return state.communityStatus
		},
	},
	mutations: {
		// SERVICE COMMUNITY
		COMMUNITY_LIST(state, value) {
			state.communityList = value
		},
		SET_COMMUNITY(state, value) {
			state.community = value
		},

		// STATUS SERVICE COMMUNITY
		COMMUNITY_STATUS(state, value) {
			state.communityStatus = value
		},
		COMMUNITY_SAVE_STATUS(state, value) {
			state.communitySaveStatus = value
		},
		COMMUNITY_DELETE_STATUS(state, value) {
			state.communityDeleteStatus = value
		},
	},
	actions: {
		// Service Community
		async fetchCommunityList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('COMMUNITY_STATUS', 'pending')
			commit('COMMUNITY_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await communityList(params)
				commit('COMMUNITY_STATUS', 'init')
				commit('COMMUNITY_LIST', data)
				return data
			} catch (error) {
				commit('COMMUNITY_STATUS', 'init')
				throw error
			}
		},
		async updateCommunity({ commit }, params) {
			commit('COMMUNITY_SAVE_STATUS', 'pending')
			try {
				const { data } = await communityUpdate(params)
				commit('COMMUNITY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('COMMUNITY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCommunityByID({ commit }, params) {
			commit('COMMUNITY_DELETE_STATUS', 'pending')
			try {
				const { data } = await communityDestroy(params)
				commit('COMMUNITY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('COMMUNITY_DELETE_STATUS', 'error')

				return error
			}
		},
		async fetchCommunityBySlug({ commit }, params) {
			commit('SET_COMMUNITY_STATUS', 'pending')
			try {
				const { data } = await getCommunityBySlug(params)
				commit('SET_COMMUNITY_STATUS', 'init')
				commit('SET_COMMUNITY', data)
				return data
			} catch (error) {
				commit('SET_COMMUNITY_STATUS', 'init')
				throw error
			}
		},
	},
}
