import {
	categoriesList,
	categoryByID,
	categoryCreate,
	categoryDestroy,
	categoryUpdate,
	// EDITORIAL CALCULATION RULES
	ruleByID,
	ruleCreate,
	ruleDestroy,
	ruleUpdate,
	rulesList,
	// EDITORIAL TYPES
	typeByID,
	typeCreate,
	typeDestroy,
	typeUpdate,
	typesList,
} from '@/services/editorial.service'

export default {
	namespaced: true,
	state: {
		// EDITORIAL TYPES
		typesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		type: {},
		// STATUS EDITORIAL TYPES
		typesStatus: 'pending',
		typesSaveStatus: 'init',
		typesDeleteStatus: 'init',

		// EDITORIAL CALCULATION RULES
		rulesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		rule: {},
		// STATUS EDITORIAL CALCULATION RULES
		rulesStatus: 'pending',
		rulesSaveStatus: 'init',
		rulesDeleteStatus: 'init',

		// EDITORIAL JOURNALIST CATEGORIES
		categoriesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		category: {},
		// STATUS JOURNALIST CATEGORIES
		categoriesStatus: 'pending',
		categoriesSaveStatus: 'init',
		categoriesDeleteStatus: 'init',
	},
	getters: {
		// EDITORIAL TYPES
		getTypesList(state) {
			return state.typesList
		},
		getType(state) {
			return state.type
		},
		// EDITORIAL TYPES
		getTypesDeleteStatus(state) {
			return state.typesDeleteStatus
		},
		getTypesSaveStatus(state) {
			return state.typesSaveStatus
		},
		getTypesStatus(state) {
			return state.typesStatus
		},

		// EDITORIAL CALCULATION RULES
		getRulesList(state) {
			return state.rulesList
		},
		getRule(state) {
			return state.rule
		},
		// EDITORIAL CALCULATION RULES
		getRulesDeleteStatus(state) {
			return state.rulesDeleteStatus
		},
		getRulesSaveStatus(state) {
			return state.rulesSaveStatus
		},
		getRulesStatus(state) {
			return state.rulesStatus
		},

		// EDITORIAL JOURNALIST CATEGORIES
		getCategoriesList(state) {
			return state.categoriesList
		},
		getCategory(state) {
			return state.category
		},
		// EDITORIAL JOURNALIST CATEGORIES
		getCategoriesDeleteStatus(state) {
			return state.categoriesDeleteStatus
		},
		getCategoriesSaveStatus(state) {
			return state.categoriesSaveStatus
		},
		getCategoriesStatus(state) {
			return state.categoriesStatus
		},
	},
	mutations: {
		// EDITORIAL TYPES
		TYPES_LIST(state, value) {
			state.typesList = value
		},
		SET_TYPE(state, value) {
			state.type = value
		},
		// STATUS EDITORIAL TYPES
		TYPES_STATUS(state, value) {
			state.typesStatus = value
		},
		TYPE_SAVE_STATUS(state, value) {
			state.typesSaveStatus = value
		},
		TYPE_DELETE_STATUS(state, value) {
			state.typesDeleteStatus = value
		},

		// EDITORIAL CALCULATION RULES
		RULES_LIST(state, value) {
			state.rulesList = value
		},
		SET_RULE(state, value) {
			state.rule = value
		},
		// STATUS EDITORIAL CALCULATION RULES
		RULES_STATUS(state, value) {
			state.rulesStatus = value
		},
		RULE_SAVE_STATUS(state, value) {
			state.rulesSaveStatus = value
		},
		RULE_DELETE_STATUS(state, value) {
			state.rulesDeleteStatus = value
		},

		// EDITORIAL JOURNALIST CATEGORIES
		CATEGORIES_LIST(state, value) {
			state.categoriesList = value
		},
		SET_CATEGORY(state, value) {
			state.category = value
		},
		// STATUS EDITORIAL JOURNALIST CATEGORIES
		CATEGORIES_STATUS(state, value) {
			state.categoriesStatus = value
		},
		CATEGORY_SAVE_STATUS(state, value) {
			state.categoriesSaveStatus = value
		},
		CATEGORY_DELETE_STATUS(state, value) {
			state.categoriesDeleteStatus = value
		},
	},
	actions: {
		// Editorial Types
		async fetchTypeList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('TYPES_STATUS', 'pending')
			commit('TYPES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await typesList(params)
				commit('TYPES_STATUS', 'init')
				commit('TYPES_LIST', data)
				return data
			} catch (error) {
				commit('TYPES_STATUS', 'init')
				throw error
			}
		},
		async fetchTypeByID({ commit }, params) {
			commit('TYPES_STATUS', 'pending')
			try {
				const { data } = await typeByID(params)
				commit('TYPES_STATUS', 'init')
				commit('SET_TYPE', data.data)
				return data
			} catch (error) {
				commit('TYPES_STATUS', 'init')

				return error
			}
		},
		async saveNewType({ commit }, params) {
			commit('TYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await typeCreate(params)
				commit('TYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateType({ commit }, params) {
			commit('TYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await typeUpdate(params)
				commit('TYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteTypeByID({ commit }, params) {
			commit('TYPE_DELETE_STATUS', 'pending')
			try {
				const { data } = await typeDestroy(params)
				commit('TYPE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_DELETE_STATUS', 'error')

				return error
			}
		},

		// EDITORIAL CALCULATION RULES
		async fetchRuleList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('RULES_STATUS', 'pending')
			commit('RULES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await rulesList(params)
				commit('RULES_STATUS', 'init')
				commit('RULES_LIST', data)
				return data
			} catch (error) {
				commit('RULES_STATUS', 'init')
				throw error
			}
		},
		async fetchRuleByID({ commit }, params) {
			commit('RULES_STATUS', 'pending')
			try {
				const { data } = await ruleByID(params)
				commit('RULES_STATUS', 'init')
				commit('SET_RULE', data.data)
				return data
			} catch (error) {
				commit('RULES_STATUS', 'init')

				return error
			}
		},
		async saveNewRule({ commit }, params) {
			commit('RULE_SAVE_STATUS', 'pending')
			try {
				const { data } = await ruleCreate(params)
				commit('RULE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('RULE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateRule({ commit }, params) {
			commit('RULE_SAVE_STATUS', 'pending')
			try {
				const { data } = await ruleUpdate(params)
				commit('RULE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('RULE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteRuleByID({ commit }, params) {
			commit('RULE_DELETE_STATUS', 'pending')
			try {
				const { data } = await ruleDestroy(params)
				commit('RULE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('RULE_DELETE_STATUS', 'error')

				return error
			}
		},

		// EDITORIAL JOURNALIST CATEGORIES
		async fetchCategoryList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('CATEGORIES_STATUS', 'pending')
			commit('CATEGORIES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await categoriesList(params)
				commit('CATEGORIES_STATUS', 'init')
				commit('CATEGORIES_LIST', data)
				return data
			} catch (error) {
				commit('CATEGORIES_STATUS', 'init')
				throw error
			}
		},
		async fetchCategoryByID({ commit }, params) {
			commit('CATEGORIES_STATUS', 'pending')
			try {
				const { data } = await categoryByID(params)
				commit('CATEGORIES_STATUS', 'init')
				commit('SET_CATEGORY', data.data)
				return data
			} catch (error) {
				commit('CATEGORIES_STATUS', 'init')

				return error
			}
		},
		async saveNewCategory({ commit }, params) {
			commit('CATEGORY_SAVE_STATUS', 'pending')
			try {
				const { data } = await categoryCreate(params)
				commit('CATEGORY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CATEGORY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCategory({ commit }, params) {
			commit('CATEGORY_SAVE_STATUS', 'pending')
			try {
				const { data } = await categoryUpdate(params)
				commit('CATEGORY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CATEGORY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCategoryByID({ commit }, params) {
			commit('CATEGORY_DELETE_STATUS', 'pending')
			try {
				const { data } = await categoryDestroy(params)
				commit('CATEGORY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CATEGORY_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
