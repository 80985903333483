import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getVideos = params => Http.get(`/videos/fetch${serialize(params)}`)

export const getStats = () => Http.get(`videos/stats`)

export const getYoutubeInfo = params => Http.get(`videos/get-youtube-infos${serialize(params)}`)

export const newVideo = (params = '') => Http.post('/creator-mode/new-video', params, { baseURL: 'locale' })
export const updateVideo = (slug, params) => Http.put(`/creator-mode/video/${slug}`, params, { baseURL: 'locale' })

export const translateVideo = (params = '') => Http.put(`/creator-mode/translate-video/${params.link}`, params, { baseURL: 'locale' })

export const getVideoByLink = link => {
	return Http.get(`/creator-mode/video/${link}`, { baseURL: 'locale' })
}

export const removeVideo = params => {
	return Http.delete(`/creator-mode/video/${params.link}`, { baseURL: 'locale' })
}
