import { deleteReport, getReports, updateReport, updateReadToggle } from '@/services/community/reports'
export default {
	namespaced: true,
	state: {
		reportList: {
			meta: {
				total: 0,
			},
		},
		reportStatus: 'pending',
	},
	getters: {
		getReportList(state) {
			return state.reportList
		},
		getReportStatus(state) {
			return state.reportStatus
		},
	},
	mutations: {
		SET_REPORT_LIST(state, value) {
			state.reportList = value
		},
		SET_REPORT_STATUS(state, value) {
			state.reportStatus = value
		},
	},
	actions: {
		async fetchReports({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_REPORT_STATUS', 'pending')
			commit('SET_REPORT_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getReports(params)
				commit('SET_REPORT_STATUS', 'init')
				commit('SET_REPORT_LIST', data)
				return data
			} catch (error) {
				commit('SET_REPORT_STATUS', 'init')
				throw error
			}
		},

		async deleteReportRecord({ commit }, params) {
			commit('SET_REPORT_STATUS', 'pending')
			try {
				const { data } = await deleteReport(params)
				commit('SET_REPORT_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_REPORT_STATUS', 'init')
				throw error
			}
		},

		async updateReport({ commit }, params) {
			commit('SET_REPORT_STATUS', 'pending')
			try {
				const { data } = await updateReport(params)
				commit('SET_REPORT_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_REPORT_STATUS', 'init')
				throw error
			}
		},

		async updateReadToggle({ commit }, params) {
			commit('SET_REPORT_STATUS', 'pending')
			try {
				const { data } = await updateReadToggle(params)
				commit('SET_REPORT_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_REPORT_STATUS', 'init')
				throw error
			}
		},
	},
}
