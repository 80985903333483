import {
	eventByID,
	getEvents,
	newEvent,
	updateEvent
} from '@/services/community/wikigamer/events/events.js'

export default {
	namespaced: true,
	state: {
		eventsList: {
			past: [],
			ongoing: [],
			upcoming: []
		},
		eventsStatus: 'pending',
		event: {},
		eventStatus: 'init',
	},
	getters: {
		getEventsList(state) {
			return state.eventsList
		},
		getEventsStatus(state) {
			return state.eventsStatus
		},
		getEvent(state) {
			return state.event
		},
		getEventStatus(state) {
			return state.eventStatus
		},
	},
	mutations: {
		SET_EVENTS_LIST(state, value) {
			state.eventsList = value
		},
		SET_EVENTS_STATUS(state, value) {
			state.eventsStatus = value
		},
		SET_EVENT(state, value) {
			state.event = value
		},
		EVENT_STATUS(state, value) {
			state.eventStatus = value
		},
	},
	actions: {
		async fetchEvents({ commit }, params) {
			commit('SET_EVENTS_LIST', [])
			commit('SET_EVENTS_STATUS', 'pending')
			try {
				const paramsOld = {...params, 'filter[period]' : 'past'}
				const dataOld = await getEvents(paramsOld)

				const paramsOngoing = {...params, 'filter[period]' : 'ongoing'}
				const dataOngoing = await getEvents(paramsOngoing)

				const paramsUpcoming = {...params, 'filter[period]' : 'upcoming'}
				const dataUpcoming = await getEvents(paramsUpcoming)

				const data = {
					past: dataOld,
					ongoing: dataOngoing,
					upcoming: dataUpcoming
				}

				commit('SET_EVENTS_STATUS', 'init')
				commit('SET_EVENTS_LIST', data)
				return data
			} catch (error) {
				commit('SET_EVENTS_STATUS', 'init')
				throw error
			}
		},
		async saveNewEvent({ commit }, params) {
			commit('SET_EVENTS_STATUS', 'pending')
			try {
				const { data } = await newEvent(params)
				commit('SET_EVENTS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_EVENTS_STATUS', 'init')
				throw error
			}
		},
		async updateNewEvent({ commit }, params) {
			commit('SET_EVENTS_STATUS', 'pending')
			try {
				const { data } = await updateEvent(params)
				commit('SET_EVENTS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_EVENTS_STATUS', 'pending')
				throw error
			}
		},
		async fetchEventByID({ state, commit }, id) {
			commit('EVENT_STATUS', 'pending');

			try {
				const { data } = await eventByID(id);
				commit('EVENT_STATUS', 'init');
				commit('SET_EVENT', data.data);
				return data;
			} catch {
				commit('EVENT_STATUS', 'init');
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error;
			}
		}
	},
}
