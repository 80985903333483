import { destroyQuiz, getQuizzes, saveQuiz, showQuiz, updateQuiz } from '@/services/community/contents/quizzes'

export default {
	namespaced: true,
	state: {
		quizList: {
			meta: {
				total: 0,
			},
		},
		quizStatus: 'init',
		quizData: null,
	},
	getters: {
		getQuizList(state) {
			return state.quizList
		},
		getQuizStatus(state) {
			return state.quizStatus
		},
		getQuizData(state) {
			return state.quizData
		},
	},
	mutations: {
		SET_QUIZ_LIST(state, value) {
			state.quizList = value
		},
		SET_QUIZ_STATUS(state, value) {
			state.quizStatus = value
		},
		SET_QUIZ_DATA(state, value) {
			state.quizData = value
		},
	},
	actions: {
		async fetchQuizzes({ commit }, params) {
			commit('SET_QUIZ_STATUS', 'pending')
			commit('SET_QUIZ_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getQuizzes(params)
				commit('SET_QUIZ_STATUS', 'init')
				commit('SET_QUIZ_LIST', data)
				return data
			} catch (error) {
				commit('SET_QUIZ_STATUS', 'init')
				throw error
			}
		},
		async destroyQuizRecord({ commit }, params) {
			commit('SET_QUIZ_STATUS', 'pending')
			try {
				const { data } = await destroyQuiz(params)
				commit('SET_QUIZ_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_QUIZ_STATUS', 'init')
				throw error
			}
		},
		async saveQuiz({ commit }, params) {
			commit('SET_QUIZ_STATUS', 'pending')
			try {
				const { data } = await saveQuiz(params)
				if (params.game_id.length > 0) {
					await batchUpsertGamesQuiz({ quizzeslug: data.data.slug, games_id: params.games_id })
				}
				commit('SET_QUIZ_STATUS', 'init')
				commit('SET_QUIZ_DATA', null)
				return data
			} catch (error) {
				commit('SET_QUIZ_STATUS', 'init')
				throw error
			}
		},
		async updateQuiz({ commit }, params) {
			commit('SET_QUIZ_STATUS', 'pending')
			try {
				const { data } = await updateQuiz(params)
				if (params.game_id.length > 0) {
					await batchUpsertGamesQuiz({ quizzeslug: data.data.slug, games_id: params.games_id })
				}
				commit('SET_QUIZ_STATUS', 'init')
				commit('SET_QUIZ_DATA', null)
				return data
			} catch (error) {
				commit('SET_QUIZ_STATUS', 'init')
				throw error
			}
		},

		async destroyGameByQuiz({ commit }, params) {
			commit('SET_QUIZ_STATUS', 'pending')
			try {
				await destroyGameQuiz(params)
				commit('SET_QUIZ_STATUS', 'init')
			} catch (error) {
				commit('SET_QUIZ_STATUS', 'init')
				throw error
			}
		},
		async getQuiz({ commit }, params) {
			commit('SET_QUIZ_STATUS', 'pending')
			try {
				const { data } = await showQuiz(params)
				commit('SET_QUIZ_STATUS', 'init')
				commit('SET_QUIZ_DATA', data)
				return data
			} catch (error) {
				commit('SET_QUIZ_STATUS', 'init')
				throw error
			}
		},
	},
}
