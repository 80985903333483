import {
	citiesList,
	cityByID,
	cityCreate,
	cityDestroy,
	cityUpdate,
	countriesByID,
	countriesList,
	deleteCountryByID,
	nationalitiesList,
	nationalityByID,
	nationalityCreate,
	nationalityDestroy,
	nationalityUpdate,
	newCountry,
	// REGIONS
	regionByID,
	regionCreate,
	regionDestroy,
	regionsList,
	regionUpdate,
	searchCity,
	searchState,
	// STATES
	stateByID,
	stateCreate,
	stateDestroy,
	statesList,
	stateUpdate,
	// TIMEZONES
	timezoneByID,
	timezoneCreate,
	timezoneDestroy,
	timezonesList,
	timezoneUpdate,
	updateCountry,
} from '@/services/countries.service'

export default {
	namespaced: true,
	state: {
		// COUNTRIES
		countriesList: {},
		country: {},
		// STATUS
		countriesStatus: 'pending',
		countriesSaveStatus: 'init',
		countriesDeleteStatus: 'init',

		// CITIES
		citiesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		city: {},
		// STATUS CITIES
		citiesStatus: 'init',
		citiesSaveStatus: 'init',
		citiesDeleteStatus: 'init',

		// NATIONALITIES
		nationalitiesList: {},
		nationality: {},
		// STATUS NATIONALITY
		nationalitiesStatus: 'pending',
		nationalitiesSaveStatus: 'init',
		nationalitiesDeleteStatus: 'init',

		// REGIONS
		regionsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		region: {},
		// STATUS REGIONS
		regionsStatus: 'pending',
		regionsSaveStatus: 'init',
		regionsDeleteStatus: 'init',

		// STATES
		statesList: {},
		state: {},
		// STATUS STATES
		statesStatus: 'pending',
		statesSaveStatus: 'init',
		statesDeleteStatus: 'init',

		// TIMEZONES
		timezonesList: {},
		timezone: {},
		// STATUS TIMEZONE
		timezonesStatus: 'pending',
		timezonesSaveStatus: 'init',
		timezonesDeleteStatus: 'init',
	},
	getters: {
		// COUNTRIES
		getCountriesList(state) {
			return state.countriesList
		},
		getCountry(state) {
			return state.country
		},
		// COUNTRIES STATUS
		getCountriesDeleteStatus(state) {
			return state.countriesDeleteStatus
		},
		getCountriesSaveStatus(state) {
			return state.countriesSaveStatus
		},
		getCountriesStatus(state) {
			return state.countriesStatus
		},

		// CITIES
		getCitiesList(state) {
			return state.citiesList
		},
		getCity(state) {
			return state.city
		},
		// CITIES STATUS
		getCitiesDeleteStatus(state) {
			return state.citiesDeleteStatus
		},
		getCitiesSaveStatus(state) {
			return state.citiesSaveStatus
		},
		getCitiesStatus(state) {
			return state.citiesStatus
		},

		// NATIONALITY
		getNationalitiesList(state) {
			return state.nationalitiesList
		},
		getNationality(state) {
			return state.nationality
		},
		// NATIONALITY
		getNationalitiesDeleteStatus(state) {
			return state.nationalitiesDeleteStatus
		},
		getNationalitiesSaveStatus(state) {
			return state.nationalitiesSaveStatus
		},
		getNationalitiesStatus(state) {
			return state.nationalitiesStatus
		},

		// REGIONS
		getRegionsList(state) {
			return state.regionsList
		},
		getRegion(state) {
			return state.region
		},
		// REGIONS STATUS
		getRegionsDeleteStatus(state) {
			return state.regionsDeleteStatus
		},
		getRegionsSaveStatus(state) {
			return state.regionsSaveStatus
		},
		getRegionsStatus(state) {
			return state.regionsStatus
		},

		// STATES
		getStatesList(state) {
			return state.statesList
		},
		getState(state) {
			return state.state
		},
		// STATES STATUS
		getStatesDeleteStatus(state) {
			return state.statesDeleteStatus
		},
		getStatesSaveStatus(state) {
			return state.statesSaveStatus
		},
		getStatesStatus(state) {
			return state.statesStatus
		},

		// TIMEZONE
		getTimezonesList(state) {
			return state.timezonesList
		},
		getTimezone(state) {
			return state.city
		},
		// TIMEZONE STATUS
		getTimezonesDeleteStatus(state) {
			return state.timezonesDeleteStatus
		},
		getTimezonesSaveStatus(state) {
			return state.timezonesSaveStatus
		},
		getTimezonesStatus(state) {
			return state.timezonesStatus
		},
	},
	mutations: {
		// COUNTRIES
		COUNTRIES_LIST(state, value) {
			state.countriesList = value
		},
		SET_COUNTRY(state, value) {
			state.country = value
		},
		// COUNTRIES STATUS
		COUNTRIES_STATUS(state, value) {
			state.countriesStatus = value
		},
		COUNTRIES_SAVE_STATUS(state, value) {
			state.countriesSaveStatus = value
		},
		COUNTRIES_DELETE_STATUS(state, value) {
			state.countriesDeleteStatus = value
		},

		// CITIES
		CITIES_LIST(state, value) {
			state.citiesList = value
		},
		SET_CITY(state, value) {
			state.city = value
		},
		// CITIES STATUS
		CITIES_STATUS(state, value) {
			state.citiesStatus = value
		},
		CITY_SAVE_STATUS(state, value) {
			state.citiesSaveStatus = value
		},
		CITY_DELETE_STATUS(state, value) {
			state.citiesDeleteStatus = value
		},

		// NATIONALITIES
		NATIONALITIES_LIST(state, value) {
			state.nationalitiesList = value
		},
		SET_NATIONALITY(state, value) {
			state.nationality = value
		},
		// STATUS NATIONALITIES
		NATIONALITIES_STATUS(state, value) {
			state.nationalitiesStatus = value
		},
		NATIONALITY_SAVE_STATUS(state, value) {
			state.nationalitiesSaveStatus = value
		},
		NATIONALITY_DELETE_STATUS(state, value) {
			state.nationalitiesDeleteStatus = value
		},

		// REGIONS
		REGIONS_LIST(state, value) {
			state.regionsList = value
		},
		SET_REGION(state, value) {
			state.region = value
		},
		// REGIONS STATUS
		REGIONS_STATUS(state, value) {
			state.regionsStatus = value
		},
		REGION_SAVE_STATUS(state, value) {
			state.regionsSaveStatus = value
		},
		REGION_DELETE_STATUS(state, value) {
			state.regionsDeleteStatus = value
		},

		// STATES
		STATES_LIST(state, value) {
			state.statesList = value
		},
		SET_STATE(state, value) {
			state.state = value
		},
		// STATES STATUS
		STATES_STATUS(state, value) {
			state.statesStatus = value
		},
		STATE_SAVE_STATUS(state, value) {
			state.statesSaveStatus = value
		},
		STATE_DELETE_STATUS(state, value) {
			state.statesDeleteStatus = value
		},

		// TIMEZONES
		TIMEZONES_LIST(state, value) {
			state.timezonesList = value
		},
		SET_TIMEZONE(state, value) {
			state.timezone = value
		},
		// STATUS TIMEZONES
		TIMEZONES_STATUS(state, value) {
			state.timezonesStatus = value
		},
		TIMEZONE_SAVE_STATUS(state, value) {
			state.timezonesSaveStatus = value
		},
		TIMEZONE_DELETE_STATUS(state, value) {
			state.timezonesDeleteStatus = value
		},
	},
	actions: {
		// Countries
		async fetchCountriesList({ commit }, params) {
			commit('COUNTRIES_STATUS', 'pending')
			commit('COUNTRIES_LIST', {})
			try {
				const { data } = await countriesList(params)
				commit('COUNTRIES_STATUS', 'init')
				commit('COUNTRIES_LIST', data)
				return data
			} catch (error) {
				commit('COUNTRIES_STATUS', 'init')
				throw error
			}
		},
		async fetchCountryByID({ state, commit }, params) {
			commit('COUNTRIES_STATUS', 'pending')
			try {
				const { data } = await countriesByID(params)
				commit('COUNTRIES_STATUS', 'init')
				commit('SET_COUNTRY', data.data)
				return data
			} catch (error) {
				commit('COUNTRIES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewCountry({ commit }, params) {
			commit('COUNTRIES_SAVE_STATUS', 'pending')
			try {
				const { data } = await newCountry(params)
				commit('COUNTRIES_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('COUNTRIES_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCountry({ commit }, params) {
			commit('COUNTRIES_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateCountry(params)
				commit('COUNTRIES_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('COUNTRIES_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCountryByID({ state, commit }, params) {
			commit('COUNTRIES_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteCountryByID(params)
				commit('COUNTRIES_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('COUNTRIES_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		// Cities
		async fetchCityList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('CITIES_STATUS', 'pending')
			commit('CITIES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await citiesList(params)
				commit('CITIES_STATUS', 'init')
				commit('CITIES_LIST', data)
				return data
			} catch (error) {
				commit('CITIES_STATUS', 'init')
				throw error
			}
		},
		async fetchCityByID({ commit }, params) {
			commit('CITIES_STATUS', 'pending')
			try {
				const { data } = await cityByID(params)
				commit('CITIES_STATUS', 'init')
				commit('SET_CITY', data.data)
				return data
			} catch (error) {
				commit('CITIES_STATUS', 'init')

				return error
			}
		},
		async saveNewCity({ commit }, params) {
			commit('CITY_SAVE_STATUS', 'pending')
			try {
				const { data } = await cityCreate(params)
				commit('CITY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CITY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCity({ commit }, params) {
			commit('CITY_SAVE_STATUS', 'pending')
			try {
				const { data } = await cityUpdate(params)
				commit('CITY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CITY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCityByID({ commit }, params) {
			commit('CITY_DELETE_STATUS', 'pending')
			try {
				const { data } = await cityDestroy(params)
				commit('CITY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CITY_DELETE_STATUS', 'error')

				return error
			}
		},
		async fetchCityBySearch({ commit }, params) {
			commit('CITIES_STATUS', 'pending')
			try {
				const { data } = await searchCity(params)
				commit('CITIES_LIST', data)
				commit('CITIES_STATUS', 'init')
				return data
			} catch (error) {
				commit('CITIES_STATUS', 'error')
				return error
			}
		},

		// Nationalities
		async fetchNationalityList({ commit }, params) {
			commit('NATIONALITIES_STATUS', 'pending')
			commit('NATIONALITIES_LIST', {})
			try {
				const { data } = await nationalitiesList(params)

				commit('NATIONALITIES_STATUS', 'init')
				commit('NATIONALITIES_LIST', data)
				return data
			} catch (error) {
				commit('NATIONALITIES_STATUS', 'init')
				throw error
			}
		},

		async fetchNationalityByID({ commit }, params) {
			commit('NATIONALITIES_STATUS', 'pending')
			try {
				const { data } = await nationalityByID(params)
				commit('NATIONALITIES_STATUS', 'init')
				commit('SET_NATIONALITY', data.data)
				return data
			} catch (error) {
				commit('NATIONALITIES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewNationality({ commit }, params) {
			commit('NATIONALITY_SAVE_STATUS', 'pending')
			try {
				const { data } = await nationalityCreate(params)
				commit('NATIONALITY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('NATIONALITY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateNationality({ commit }, params) {
			commit('NATIONALITY_SAVE_STATUS', 'pending')
			try {
				const { data } = await nationalityUpdate(params)
				commit('NATIONALITY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('NATIONALITY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteNationalityByID({ commit }, params) {
			commit('NATIONALITY_DELETE_STATUS', 'pending')
			try {
				const { data } = await nationalityDestroy(params)
				commit('NATIONALITY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('NATIONALITY_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		// Regions
		async fetchRegionList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('REGIONS_STATUS', 'pending')
			commit('REGIONS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await regionsList(params)
				commit('REGIONS_STATUS', 'init')
				commit('REGIONS_LIST', data)
				return data
			} catch (error) {
				commit('REGIONS_STATUS', 'init')
				throw error
			}
		},
		async fetchRegionByID({ commit }, params) {
			commit('REGIONS_STATUS', 'pending')
			try {
				const { data } = await regionByID(params)
				commit('REGIONS_STATUS', 'init')
				commit('SET_REGION', data.data)
				return data
			} catch (error) {
				commit('REGIONS_STATUS', 'init')

				return error
			}
		},
		async saveNewRegion({ commit }, params) {
			commit('REGION_SAVE_STATUS', 'pending')
			try {
				const { data } = await regionCreate(params)
				commit('REGION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('REGION_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateRegion({ commit }, params) {
			commit('REGION_SAVE_STATUS', 'pending')
			try {
				const { data } = await regionUpdate(params)
				commit('REGION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('REGION_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteRegionByID({ commit }, params) {
			commit('REGION_DELETE_STATUS', 'pending')
			try {
				const { data } = await regionDestroy(params)
				commit('REGION_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('REGION_DELETE_STATUS', 'error')

				return error
			}
		},

		// States
		//fetch states by GeoDB
		async fetchStates({ commit }, params) {
			commit('STATES_STATUS', 'pending')
			try {
				const { data } = await searchState(params)
				commit('STATES_STATUS', 'init')
				commit('STATES_LIST', data)
				return data
			} catch (error) {
				commit('STATES_STATUS', 'init')
				throw error
			}
		},

		async fetchStateList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('STATES_STATUS', 'pending')
			commit('STATES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await statesList(params)
				commit('STATES_STATUS', 'init')
				commit('STATES_LIST', data)
				return data
			} catch (error) {
				commit('STATES_STATUS', 'init')
				throw error
			}
		},
		async fetchStateByID({ commit }, params) {
			commit('STATES_STATUS', 'pending')
			try {
				const { data } = await stateByID(params)
				commit('STATES_STATUS', 'init')
				commit('SET_STATE', data.data)
				return data
			} catch (error) {
				commit('STATES_STATUS', 'init')

				return error
			}
		},
		async saveNewState({ commit }, params) {
			commit('STATE_SAVE_STATUS', 'pending')
			try {
				const { data } = await stateCreate(params)
				commit('STATE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STATE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateState({ commit }, params) {
			commit('STATE_SAVE_STATUS', 'pending')
			try {
				const { data } = await stateUpdate(params)
				commit('STATE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STATE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteStateByID({ commit }, params) {
			commit('STATE_DELETE_STATUS', 'pending')
			try {
				const { data } = await stateDestroy(params)
				commit('STATE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STATE_DELETE_STATUS', 'error')

				return error
			}
		},

		// Timezones
		async fetchTimezoneList({ commit }, params) {
			console.log('params', params)
			commit('TIMEZONES_STATUS', 'pending')
			commit('TIMEZONES_LIST', {})
			try {
				const { data } = await timezonesList(params)
				console.log(data)
				commit('TIMEZONES_STATUS', 'init')
				commit('TIMEZONES_LIST', data)
				return data
			} catch (error) {
				commit('TIMEZONES_STATUS', 'init')
				throw error
			}
		},

		async fetchTimezoneByID({ commit }, params) {
			commit('TIMEZONES_STATUS', 'pending')
			try {
				const { data } = await timezoneByID(params)
				commit('TIMEZONES_STATUS', 'init')
				commit('SET_TIMEZONE', data.data)
				return data
			} catch (error) {
				commit('TIMEZONES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewTimezone({ commit }, params) {
			commit('TIMEZONE_SAVE_STATUS', 'pending')
			try {
				const { data } = await timezoneCreate(params)
				commit('TIMEZONE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TIMEZONE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateTimezone({ commit }, params) {
			commit('TIMEZONE_SAVE_STATUS', 'pending')
			try {
				const { data } = await timezoneUpdate(params)
				commit('TIMEZONE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TIMEZONE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteTimezoneByID({ commit }, params) {
			commit('TIMEZONE_DELETE_STATUS', 'pending')
			try {
				const { data } = await timezoneDestroy(params)
				commit('TIMEZONE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TIMEZONE_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
