import { destroySpecie, getSpecies, saveSpecie, updateSpecie } from '@/services/community/wikigamer/characters/species'

export default {
	namespaced: true,
	state: {
		specieList: {
			meta: {
				total: 0,
			},
		},
		specieStatus: 'pending',
	},
	getters: {
		getSpecieList(state) {
			return state.specieList
		},
		getSpecieStatus(state) {
			return state.specieStatus
		},
	},
	mutations: {
		SET_SPECIE_LIST(state, value) {
			state.specieList = value
		},
		SET_SPECIE_STATUS(state, value) {
			state.specieStatus = value
		},
	},
	actions: {
		async fetchSpecies({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_SPECIE_STATUS', 'pending')
			commit('SET_SPECIE_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getSpecies(params)
				commit('SET_SPECIE_STATUS', 'init')
				commit('SET_SPECIE_LIST', data)
				return data
			} catch (error) {
				commit('SET_SPECIE_STATUS', 'init')
				throw error
			}
		},
		async destroySpecieRecord({ commit }, params) {
			commit('SET_SPECIE_STATUS', 'pending')
			try {
				const { data } = await destroySpecie(params)
				commit('SET_SPECIE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_SPECIE_STATUS', 'init')
				throw error
			}
		},
		async saveSpecie({ commit }, params) {
			commit('SET_SPECIE_STATUS', 'pending')
			try {
				const { data } = await saveSpecie(params)
				commit('SET_SPECIE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_SPECIE_STATUS', 'init')
				throw error
			}
		},
		async updateSpecie({ commit }, params) {
			commit('SET_SPECIE_STATUS', 'pending')
			try {
				const { data } = await updateSpecie(params)
				commit('SET_SPECIE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_SPECIE_STATUS', 'init')
				throw error
			}
		},
	},
}
