const professionalSituations = [
	{
		path: '/professional-situations/list',
		name: 'jobs-professional-situations-list',
		component: () =>
			import(
				/* webpackChunkName: "ProfessionalSituationsList" */ '@/views/jobs/professional-situations/list.vue'
			),
		meta: {
			layout: 'content',
		},
	},
]

export default professionalSituations
