import { deletePartnerByID, newPartner, partnersByID, partnersList, updatePartner } from '@/services/partners.service'

export default {
	namespaced: true,
	state: {
		// PARTNERS
		partnersList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		partner: {},
		// STATUS
		partnersStatus: 'init',
		partnersSaveStatus: 'init',
		partnersDeleteStatus: 'init',
	},
	getters: {
		// PARTNERS
		getPartnersList(state) {
			return state.partnersList
		},
		getPartner(state) {
			return state.partner
		},
		// STATUS
		getPartnersDeleteStatus(state) {
			return state.partnersDeleteStatus
		},
		getPartnersSaveStatus(state) {
			return state.partnersSaveStatus
		},
		getPartnersStatus(state) {
			return state.partnersStatus
		},
	},
	mutations: {
		// PARTNERS
		PARTNERS_LIST(state, value) {
			state.partnersList = value
		},
		SET_PARTNER(state, value) {
			state.partner = value
		},
		// STATUS
		PARTNERS_STATUS(state, value) {
			console.log(value)
			state.partnersStatus = value
		},
		PARTNERS_SAVE_STATUS(state, value) {
			state.partnersSaveStatus = value
		},
		PARTNERS_DELETE_STATUS(state, value) {
			state.partnersDeleteStatus = value
		},
	},
	actions: {
		// Partners
		async fetchPartnersList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('PARTNERS_STATUS', 'pending')
			commit('PARTNERS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await partnersList(params)
				commit('PARTNERS_STATUS', 'init')
				commit('PARTNERS_LIST', data)
				return data
			} catch (error) {
				commit('PARTNERS_STATUS', 'init')
				throw error
			}
		},
		async fetchPartnerByID({ state, commit }, params) {
			commit('PARTNERS_STATUS', 'pending')
			try {
				const { data } = await partnersByID(params)
				commit('PARTNERS_STATUS', 'init')
				commit('SET_PARTNER', data.data)
				return data
			} catch (error) {
				commit('PARTNERS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewPartner({ commit }, params) {
			commit('PARTNERS_SAVE_STATUS', 'pending')
			try {
				const { data } = await newPartner(params)
				commit('PARTNERS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PARTNERS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updatePartner({ commit }, params) {
			commit('PARTNERS_SAVE_STATUS', 'pending')
			try {
				const { data } = await updatePartner(params)
				commit('PARTNERS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PARTNERS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deletePartnerByID({ state, commit }, params) {
			commit('PARTNERS_DELETE_STATUS', 'pending')
			try {
				const { data } = await deletePartnerByID(params)
				commit('PARTNERS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PARTNERS_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
