const workHours = [
	{
		path: '/work-hours/list',
		name: 'jobs-work-hours-list',
		component: () => import(/* webpackChunkName: "WorkhoursList" */ '@/views/jobs/work-hours/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default workHours
