const appThemes = [
	{
		path: '/themes/app-themes/list',
		name: 'app-themes-list',
		component: () => import('@/views/themes/app-themes/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/themes/projects-variables/list',
		name: 'projects-variables-list',
		component: () => import('@/views/themes/projects-variables/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default appThemes
