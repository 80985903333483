import {
	projectByID,
	projectCreate,
	projectDestroy,
	projectList,
	projectUpdate,
} from '@/services/agency/projects.service'

export default {
	namespaced: true,
	state: {
		// SERVICE PROJECT
		projectList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		project: {},

		// STATUS SERVICE PROJECT
		projectStatus: 'pending',
		projectSaveStatus: 'init',
		projectDeleteStatus: 'init',
	},
	getters: {
		// SERVICE PROJECT
		getProjectList(state) {
			return state.projectList
		},
		getProject(state) {
			return state.project
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE PROJECT
		getProjectDeleteStatus(state) {
			return state.projectDeleteStatus
		},
		getProjectSaveStatus(state) {
			return state.projectSaveStatus
		},
		getProjectStatus(state) {
			return state.projectStatus
		},
	},
	mutations: {
		// SERVICE PROJECT
		PROJECT_LIST(state, value) {
			state.projectList = value
		},
		SET_PROJECT(state, value) {
			state.project = value
		},

		// STATUS SERVICE PROJECT
		PROJECT_STATUS(state, value) {
			state.projectStatus = value
		},
		PROJECT_SAVE_STATUS(state, value) {
			state.projectSaveStatus = value
		},
		PROJECT_DELETE_STATUS(state, value) {
			state.projectDeleteStatus = value
		},
	},
	actions: {
		// Service Project
		async fetchProjectList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('PROJECT_STATUS', 'pending')
			commit('PROJECT_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await projectList(params)
				commit('PROJECT_STATUS', 'init')
				commit('PROJECT_LIST', data)
				return data
			} catch (error) {
				commit('PROJECT_STATUS', 'init')
				throw error
			}
		},
		async fetchProjectByID({ commit }, params) {
			commit('PROJECT_STATUS', 'pending')
			try {
				const { data } = await projectByID(params)
				commit('PROJECT_STATUS', 'init')
				commit('SET_PROJECT', data.data)
				return data
			} catch (error) {
				commit('PROJECT_STATUS', 'init')

				return error
			}
		},
		async saveNewProject({ commit }, params) {
			commit('PROJECT_SAVE_STATUS', 'pending')
			try {
				const { data } = await projectCreate(params)
				commit('PROJECT_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PROJECT_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateProject({ commit }, params) {
			commit('PROJECT_SAVE_STATUS', 'pending')
			try {
				const { data } = await projectUpdate(params)
				commit('PROJECT_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PROJECT_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteProjectByID({ commit }, params) {
			commit('PROJECT_DELETE_STATUS', 'pending')
			try {
				const { data } = await projectDestroy(params)
				commit('PROJECT_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PROJECT_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
