import {
	deleteGeneration,
	getGenerations,
	saveGeneration,
	updateGeneration,
} from '@/services/community/wikigamer/platforms/generations'

export default {
	namespaced: true,
	state: {
		generationList: {
			meta: {
				total: 0,
			},
		},
		generation: {},

		// GENERATIONS STATUS
		generationStatus: 'pending',
		generationSaveStatus: 'init',
		generationDeleteStatus: 'init',
	},
	getters: {
		getGenerationList(state) {
			return state.generationList
		},
		getGenerationStatus(state) {
			return state.generationStatus
		},
		getGenerationSaveStatus(state) {
			return state.generationSaveStatus
		},
		getGenerationDeleteStatus(state) {
			return state.generationDeleteStatus
		},
	},
	mutations: {
		SET_GENERATION_LIST(state, value) {
			state.generationList = value
		},
		SET_GENERATION_STATUS(state, value) {
			state.generationStatus = value
		},
		SET_GENERATION_SAVE_STATUS(state, value) {
			state.generationSaveStatus = value
		},
		SET_GENERATION_DELETE_STATUS(state, value) {
			state.generationDeleteStatus = value
		},
	},
	actions: {
		async fetchGenerations({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_GENERATION_STATUS', 'pending')
			commit('SET_GENERATION_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getGenerations(params)
				commit('SET_GENERATION_STATUS', 'init')
				commit('SET_GENERATION_LIST', data)
				return data
			} catch (error) {
				commit('SET_GENERATION_STATUS', 'init')
				throw error
			}
		},

		async saveGeneration({ commit }, params) {
			commit('SET_GENERATION_SAVE_STATUS', 'pending')
			try {
				const { data } = await saveGeneration(params)
				commit('SET_GENERATION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_GENERATION_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateGeneration({ commit }, params) {
			commit('SET_GENERATION_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateGeneration(params)
				commit('SET_GENERATION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_GENERATION_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteGenerationRecord({ commit }, params) {
			commit('SET_GENERATION_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteGeneration(params)
				commit('SET_GENERATION_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_GENERATION_DELETE_STATUS', 'init')
				throw error
			}
		},
	},
}
