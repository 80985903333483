import {
	destroyPlatformType,
	getPlatformTypes,
	savePlatformType,
	updatePlatformType,
} from '@/services/community/wikigamer/platforms/platformTypes.js'

export default {
	namespaced: true,
	state: {
		platformTypesList: {
			meta: {
				total: 0,
			},
		},
		platformTypeStatus: 'pending',
	},
	getters: {
		getPlatformTypesList(state) {
			return state.platformTypesList
		},
		getPlatformTypesStatus(state) {
			return state.platformTypeStatus
		},
	},
	mutations: {
		SET_PLATFORM_TYPES_LIST(state, value) {
			state.platformTypesList = value
		},
		SET_PLATFORM_TYPES_STATUS(state, value) {
			state.platformTypeStatus = value
		},
	},
	actions: {
		async fetchPlatformTypes({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_PLATFORM_TYPES_STATUS', 'pending')
			commit('SET_PLATFORM_TYPES_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getPlatformTypes(params)
				commit('SET_PLATFORM_TYPES_STATUS', 'init')
				commit('SET_PLATFORM_TYPES_LIST', data)
				return data
			} catch (error) {
				commit('SET_PLATFORM_TYPES_STATUS', 'init')
				throw error
			}
		},

		async destroyPlatformTypeRecord({ commit }, params) {
			commit('SET_PLATFORM_TYPES_STATUS', 'pending')
			try {
				const { data } = await destroyPlatformType(params)
				commit('SET_PLATFORM_TYPES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_PLATFORM_TYPES_STATUS', 'init')
				throw error
			}
		},
		async savePlatformType({ commit }, params) {
			commit('SET_PLATFORM_TYPES_STATUS', 'pending')
			try {
				const { data } = await savePlatformType(params)
				commit('SET_PLATFORM_TYPES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_PLATFORM_TYPES_STATUS', 'init')
				throw error
			}
		},
		async updatePlatformType({ commit }, params) {
			commit('SET_PLATFORM_TYPES_STATUS', 'pending')
			try {
				const { data } = await updatePlatformType(params)
				commit('SET_PLATFORM_TYPES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_PLATFORM_TYPES_STATUS', 'init')
				throw error
			}
		},
	},
}
