import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

// APIs
export const apiList = params => Http.get(`/apis${serialize(params)}`)
// // // GET BLOG API BY ID
export const apisByID = (params = '') => Http.get(`/apis/${params}`)
// // // POST NEW API
export const newApis = (params = '') => Http.post('/apis', params)
// // // UPDATE API
export const updateApis = (params = '') => Http.put(`/apis/${params.id}`, params)
// // // DELETE API
export const deleteApisByID = (params = '') => Http.delete(`/apis/${params.id}`)

// MG COMPANIES
export const mgCompaniesList = params => Http.get(`/network-companies${serialize(params)}`)
// // // GET MG COMPANIES BY ID
export const mgCompaniesByID = (params = '') => Http.get(`/network-companies/${params}`)
// // // POST NEW MG COMPANY
export const newMGCompany = (params = '') => Http.post('/network-companies', params)
// // // UPDATE MG COMPANY
export const updateMGCompany = (params = '') => Http.put(`/network-companies/${params.id}`, params)
// // // DELETE MG COMPANY
export const deleteMgCompanyByID = (params = '') => Http.delete(`/network-companies/${params.id}`)
export const companiesByTerm = (params = '') => Http.get(`/company${serialize(params)}`)

// ACCOUNTS
export const accountsList = (params = '') => Http.get(`/network-company/accounts/${serialize(params)}`)
export const accountCreate = (params = '') => Http.post('/network-company/create/account', params)
export const accountUpdate = (params = '') => Http.put(`/network-company/update/account/${params.id}`, params)
export const accountDestroy = (params = '') => Http.delete(`/network-company/delete/account/${params.id}`)

// // // LIST ALL Companies Types
export const typeCreate = (params = '') => Http.post('/network-companies-categories', params)
export const typesList = (params = '') => Http.get(`/network-companies-categories${serialize(params)}`)
export const typeByID = (params = '') => Http.get(`/network-companies-categories/${params}`)
export const typeUpdate = (params = '') => Http.put(`/network-companies-categories/${params.id}`, params)
export const typeDestroy = (params = '') => Http.delete(`/network-companies-categories/${params.id}`)
