const tokens = [
	{
		path: '/ai/token-configs/list',
		name: 'ai-token-configs-list',
		component: () => import('@/views/ai/token-configs/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default tokens
