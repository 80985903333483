// eslint-disable-next-line no-unused-vars
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import Vue from 'vue'
import Notify from 'vue-notifyjs'
import 'vue-notifyjs/themes/default.css'
Vue.use(Notify)

const baseDomain = process.env.VUE_APP_API_URL
let defaultConfig = {
	baseURL: baseDomain,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'Content-Language': `pt-BR`,
		'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
	},
}

if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_BEARER_TOKEN) {
	defaultConfig.headers.Authorization = `Bearer ${process.env.VUE_APP_BEARER_TOKEN}`
}
/**
 *
 * @param {AxiosRequestConfig} config
 * @returns {AxiosInstance}
 */
export function createAxiosClient(config = {}) {
	const client = axios.create(Object.assign({}, defaultConfig, config))
	client.interceptors.request.use(
		config => {
			if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_BEARER_TOKEN) {
				config.headers.Authorization = `Bearer ${process.env.VUE_APP_BEARER_TOKEN}`
			}
			config.url =
				config.url.indexOf('?') > -1
					? config.url + `&lang=${localStorage.getItem('currentLocale')}`
					: config.url + `?lang=${localStorage.getItem('currentLocale')}`
			return config
		},
		function (error) {
			// Do something with request error
			return Promise.reject(error)
		},
	)
	client.interceptors.response.use(
		res => {
			if (res.status === 401) {
				//todo log out the user
			}
			if (res.status === 422 || res.status === 400) {
				Vue.prototype.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
			}
			return res
		},
		function (error) {
			if (error.response) {
				if (error.response.headers['x-must-be-verified']) {
					alert('You must verify your email address')
				}
				if (error.response.status === 401) {
					//todo log out the user
				}
				if (error.response.status === 422 || error.response.status === 400) {
					Vue.prototype.$notify({
						message: error.response.data.message,
						type: 'danger',
					})
				}
			}
			// Do something with request error
			return Promise.reject(error)
		},
	)
	return client
}
