import {
	categoriesListNoPagination,
	deleteCategoryByID,
	newCategory,
	newPost,
	postDestroy,
	pressCategoriesList,
	pressCategoryByID,
	pressPostsByID,
	pressPostsList,
	pressPostsStatusList,
	updateCategory,
	updatePost,
} from '@/services/pressMedia.service'

export default {
	namespaced: true,
	state: {
		// POSTS
		postList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		postStatusList: {},
		post: {},

		// CATEGORIES
		categoriesList: {},
		category: {},

		// STATUS
		pressStatus: 'init',
		pressSaveStatus: 'init',
		pressDeleteStatus: 'init',
	},
	getters: {
		// POSTS
		getPostList(state) {
			return state.postList
		},
		getPost(state) {
			return state.post
		},
		getPostStatusList(state) {
			return state.postStatusList
		},

		// CATEGORIES
		getCategoriesList(state) {
			return state.categoriesList
		},
		getCategory(state) {
			return state.category
		},

		// STATUS
		getPressDeleteStatus(state) {
			return state.pressDeleteStatus
		},
		getPressSaveStatus(state) {
			return state.pressSaveStatus
		},
		getPressStatus(state) {
			return state.pressStatus
		},
	},
	mutations: {
		// POSTS
		POSTS_LIST(state, value) {
			state.postList = value
		},
		POSTS_STATUS_LIST(state, value) {
			state.postStatusList = value
		},
		SET_POST(state, value) {
			state.post = value
		},

		// CATEGORIES
		CATEGORIES_LIST(state, value) {
			state.categoriesList = value
		},
		// CATEGORIES
		SET_PRESS_CATEGORY(state, value) {
			state.category = value
		},

		// STATUS
		PRESS_STATUS(state, value) {
			state.pressStatus = value
		},
		PRESS_SAVE_STATUS(state, value) {
			state.pressSaveStatus = value
		},
		PRESS_DELETE_STATUS(state, value) {
			state.pressDeleteStatus = value
		},
	},
	actions: {
		// POSTS
		async getPostsList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('POSTS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			commit('PRESS_STATUS', 'pending')
			try {
				const { data } = await pressPostsList(params)
				commit('PRESS_STATUS', 'init')
				commit('POSTS_LIST', data)
				return data
			} catch (error) {
				commit('PRESS_STATUS', 'init')
				throw error
			}
		},
		async delePostByID({ commit }, params) {
			commit('PRESS_DELETE_STATUS', 'pending')
			try {
				const { data } = await postDestroy(params)
				commit('PRESS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRESS_DELETE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		async fetchPostsByID({ state, commit }, params) {
			commit('PRESS_STATUS', 'pending')
			try {
				const { data } = await pressPostsByID(params)
				commit('PRESS_STATUS', 'init')
				commit('SET_POST', data.data)
				return data
			} catch (error) {
				commit('PRESS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async updatePost({ commit }, params) {
			commit('PRESS_SAVE_STATUS', 'pending')
			try {
				const { data } = await updatePost(params)
				commit('PRESS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRESS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async getPostsStatusList({ commit }, params) {
			commit('PRESS_STATUS', 'pending')
			try {
				const { data } = await pressPostsStatusList(params)
				commit('PRESS_STATUS', 'init')
				commit('POSTS_STATUS_LIST', data.data)
				return data
			} catch (error) {
				commit('PRESS_STATUS', 'init')
				throw error
			}
		},
		async saveNewPost({ commit }, params) {
			commit('PRESS_SAVE_STATUS', 'pending')
			try {
				const { data } = await newPost(params)
				commit('PRESS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRESS_SAVE_STATUS', 'init')
				throw error
			}
		},
		// CATEGORIES
		async fetchCategoriesList({ commit }, params) {
			commit('CATEGORIES_LIST', {})
			commit('PRESS_STATUS', 'pending')
			try {
				const { data } = await pressCategoriesList(params)
				commit('PRESS_STATUS', 'init')
				commit('CATEGORIES_LIST', data)
				return data
			} catch (error) {
				commit('PRESS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async fetchCategoriesNoPagination({ commit }, params) {
			commit('CATEGORIES_LIST', {})
			commit('PRESS_STATUS', 'pending')
			try {
				const { data } = await categoriesListNoPagination(params)
				commit('PRESS_STATUS', 'init')
				commit('CATEGORIES_LIST', { items: data })
				return data
			} catch (error) {
				commit('PRESS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async fetchCategoryByID({ state, commit }, params) {
			try {
				const { data } = await pressCategoryByID(params)
				commit('SET_PRESS_CATEGORY', data.data)
				return data
			} catch (error) {
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewCategory({ commit }, params) {
			commit('PRESS_SAVE_STATUS', 'pending')
			try {
				const { data } = await newCategory(params)
				commit('PRESS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRESS_SAVE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async updateCategory({ commit }, params) {
			params = {
				id: params.id,
				name: params.name,
				status: params.status,
				language_id: params.language_id,
			}
			commit('PRESS_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateCategory(params)
				commit('PRESS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRESS_SAVE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async deleteCategoryByID({ state, commit }, params) {
			commit('PRESS_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteCategoryByID(params)
				commit('PRESS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PRESS_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
