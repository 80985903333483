import { deleteFamily, getFamilies, saveFamily, updateFamily } from '@/services/community/wikigamer/platforms/families'

export default {
	namespaced: true,
	state: {
		familyList: {
			meta: {
				total: 0,
			},
		},
		familyStatus: 'pending',
	},
	getters: {
		getFamilyList(state) {
			return state.familyList
		},
		getFamilyStatus(state) {
			return state.familyStatus
		},
	},
	mutations: {
		SET_FAMILY_LIST(state, value) {
			state.familyList = value
		},
		SET_FAMILY_STATUS(state, value) {
			state.familyStatus = value
		},
	},
	actions: {
		async fetchFamilies({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_FAMILY_STATUS', 'pending')
			commit('SET_FAMILY_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getFamilies(params)
				commit('SET_FAMILY_STATUS', 'init')
				commit('SET_FAMILY_LIST', data)
				return data
			} catch (error) {
				commit('SET_FAMILY_STATUS', 'init')
				throw error
			}
		},

		async saveFamily({ commit }, params) {
			commit('SET_FAMILY_STATUS', 'pending')
			try {
				const { data } = await saveFamily(params)
				commit('SET_FAMILY_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_FAMILY_STATUS', 'init')
				throw error
			}
		},
		async updateFamily({ commit }, params) {
			commit('SET_FAMILY_STATUS', 'pending')
			try {
				const { data } = await updateFamily(params)
				commit('SET_FAMILY_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_FAMILY_STATUS', 'init')
				throw error
			}
		},
		async deleteFamily({ commit }, params) {
			commit('SET_FAMILY_STATUS', 'pending')
			try {
				const { data } = await deleteFamily(params)
				commit('SET_FAMILY_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_FAMILY_STATUS', 'init')
				throw error
			}
		},
	},
}
