import { statusList, statusByID, statusCreate, statusUpdate, statusDestroy } from '@/services/community/wikigamer/characters/status'

export default {
	namespaced: true,
	state: {
		statusList: {
			meta: {
				total: 0,
				pagination: {
					total: 0,
				},
			},
		},
		status: {},

		statusStatus: 'pending',
		statusSaveStatus: 'init',
		statusDeleteStatus: 'init',
	},
	getters: {
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		getStatusDeleteStatus(state) {
			return state.statusDeleteStatus
		},
		getStatusSaveStatus(state) {
			return state.statusSaveStatus
		},
		getStatusStatus(state) {
			return state.statusStatus
		},
	},
	mutations: {
		STATUS_LIST(state, value) {
			state.statusList = value
		},
		SET_STATUS(state, value) {
			state.status = value
		},

		STATUS_STATUS(state, value) {
			state.statusStatus = value
		},
		STATUS_SAVE_STATUS(state, value) {
			state.statusSaveStatus = value
		},
		STATUS_DELETE_STATUS(state, value) {
			state.statusDeleteStatus = value
		},
	},
	actions: {
		async fetchStatusList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('STATUS_STATUS', 'pending')
			commit('STATUS_LIST', {
				meta: {
					total: 0,
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await statusList(params)
				commit('STATUS_STATUS', 'init')
				commit('STATUS_LIST', data)
				return data
			} catch (error) {
				commit('STATUS_STATUS', 'init')
				throw error
			}
		},
		async fetchStatusByID({ commit }, params) {
			commit('STATUS_STATUS', 'pending')
			try {
				const { data } = await statusByID(params)
				commit('STATUS_STATUS', 'init')
				commit('SET_STATUS', data.data)
				return data
			} catch (error) {
				commit('STATUS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		async saveNewStatus({ commit }, params) {
			commit('STATUS_SAVE_STATUS', 'pending')
			try {
				const { data } = await statusCreate(params)
				commit('STATUS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STATUS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateStatus({ commit }, params) {
			commit('STATUS_SAVE_STATUS', 'pending')
			try {
				const { data } = await statusUpdate(params)
				commit('STATUS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STATUS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteStatusByID({ commit }, params) {
			commit('STATUS_DELETE_STATUS', 'pending')
			try {
				const { data } = await statusDestroy(params)
				commit('STATUS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STATUS_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
