import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getCategories = (
	params = {
		per_page: 'all',
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/videos-category/fetch${serialize(params)}`)

//SAVE A NEW CATEGORY
export const saveCategory = (
	params = {
		name: null,
	},
) => Http.post(`/videos-category/store`, params)

//UPDATE CATEGORY
export const updateCategory = (
	params = {
		name: null,
		categoryId: null,
	},
) => Http.put(`/videos-category/${params.categoryId}/update`, params)

//DELETE CATEGORY
export const deleteCategory = (
	params = {
		categoryId: null,
	},
) => Http.delete(`/videos-category/${params.categoryId}/destroy`)
