import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpAcademy'

// LIST ALL FORMATS
export const formatList = (params = '') => Http.get(`/course-formats${serialize(params)}`)

export const formatCreate = (params = '') => Http.post('/course-formats', params)
export const formatByID = (params = '') => Http.get(`/course-formats/${params}`)
export const formatUpdate = (params = '') => Http.put(`/course-formats/${params.id}`, params)
export const formatDestroy = (params = '') => Http.delete(`/course-formats/${params.id}`)
