import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getCollections = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/collections/fetch${serialize(params)}`)

export const destroyCollection = (
	params = {
		collectionSlug: null,
	},
) => Http.delete(`/collections/${params.collectionSlug}/destroy`)

export const saveCollection = (
	params = {
		name: null,
		url: null,
		status_id: null,
	},
) => Http.post(`/collections/store`, params)

export const updateCollection = (
	params = {
		collectionSlug: null,
		name: null,
		url: null,
		status_id: null,
	},
) => Http.put(`/collections/${params.collectionSlug}/update`, params)

export const showCollection = (
	params = {
		collectionSlug: null,
	},
) => Http.get(`/collections/${params.collectionSlug}/show`)

export const batchUpsertGamesCollection = (
	params = {
		collectionSlug: null,
		games_id: null,
	},
) => Http.post(`/collections/batch/games/${params.collectionSlug}/upsert`, params)
export const destroyGameCollection = (
	params = {
		gameId: null,
	},
) => Http.delete(`/collections/games/${params.collectionSlug}/destroy`, { data: { game_id: params.gameId } })
