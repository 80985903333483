import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

// LIST STATUS
export const getStatus = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/game-status/fetch${serialize(params)}`)

// SAVE STATUS
export const saveStatus = (
	params = {
		status: null,
	},
) => Http.post('/game-status/store', params)

// UPDATE STATUS
export const updateStatus = (
	params = {
		status: null,
		statusId: null,
	},
) => Http.put(`/game-status/${params.statusId}/update`, params)

// DELETE STATUS
export const deleteStatus = (
	params = {
		statusId: null,
	},
) => Http.delete(`/game-status/${params.statusId}/destroy`)
