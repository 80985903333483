const apis = [
	{
		path: '/apis/list',
		name: 'apis-list',
		component: () => import(/* webpackChunkName: "ApisList" */ '@/views/network/institutional/apis/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/apis/credentials/:id',
		name: 'apis-list-credentials',
		component: () =>
			import(
				/* webpackChunkName: "ApisListCredentials" */ '@/views/network/institutional/credentials/credentials.vue'
			),
		meta: {
			layout: 'content',
		},
	},
]

export default apis
