import {
	destroyPlatform,
	getPlatforms,
	savePlatform,
	updatePlatform,
} from '@/services/community/wikigamer/platforms/platforms'

export default {
	namespaced: true,
	state: {
		platformList: {
			meta: {
				total: 0,
			},
		},

		platformStatus: 'pending',
		platformData: null,
	},
	getters: {
		getPlatformList(state) {
			return state.platformList
		},
		getPlatformStatus(state) {
			return state.platformStatus
		},
		getPlatformData(state) {
			return state.platformData
		},
	},
	mutations: {
		SET_PLATFORM_LIST(state, value) {
			state.platformList = value
		},
		SET_PLATFORM_STATUS(state, value) {
			state.platformStatus = value
		},
		SET_PLATFORM_DATA(state, value) {
			state.platformData = value
		},
	},
	actions: {
		async fetchPlatforms({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_PLATFORM_STATUS', 'pending')
			commit('SET_PLATFORM_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getPlatforms(params)
				commit('SET_PLATFORM_STATUS', 'init')
				commit('SET_PLATFORM_LIST', data)
				return data
			} catch (error) {
				commit('SET_PLATFORM_STATUS', 'init')
				throw error
			}
		},

		async destroyPlatformRecord({ commit }, params) {
			commit('SET_PLATFORM_STATUS', 'pending')
			try {
				const { data } = await destroyPlatform(params)
				commit('SET_PLATFORM_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_PLATFORM_STATUS', 'init')
				throw error
			}
		},
		async savePlatform({ commit }, params) {
			commit('SET_PLATFORM_STATUS', 'pending')
			try {
				const { data } = await savePlatform(params)
				if (params.games_id.length > 0) {
					await batchUpsertGamesPlatform({ platformSlug: data.data.slug, games_id: params.games_id })
				}
				commit('SET_PLATFORM_STATUS', 'init')
				commit('SET_PLATFORM_DATA', null)
				return data
			} catch (error) {
				commit('SET_PLATFORM_STATUS', 'init')
				throw error
			}
		},
		async updatePlatform({ commit }, params) {
			commit('SET_PLATFORM_STATUS', 'pending')
			try {
				const { data } = await updatePlatform(params)
				if (params.games_id.length > 0) {
					await batchUpsertGamesPlatform({ platformSlug: data.data.slug, games_id: params.games_id })
				}
				commit('SET_PLATFORM_STATUS', 'init')
				commit('SET_PLATFORM_DATA', null)
				return data
			} catch (error) {
				commit('SET_PLATFORM_STATUS', 'init')
				throw error
			}
		},
		clear({ commit }, params) {
			commit('SET_PLATFORM_STATUS', 'init')
			commit('SET_PLATFORM_DATA', null)
		},
		async destroyGameByPlatform({ commit }, params) {
			commit('SET_PLATFORM_STATUS', 'pending')
			try {
				await destroyGamePlatform(params)
				commit('SET_PLATFORM_STATUS', 'init')
			} catch (error) {
				commit('SET_PLATFORM_STATUS', 'init')
				throw error
			}
		},
	},
}
