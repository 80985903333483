import { deleteCategory, getCategories, saveCategory, updateCategory } from '@/services/community/reportsCategories'

export default {
	namespaced: true,
	state: {
		categoryList: {
			meta: {
				total: 0,
			},
		},
		categoriestatus: 'pending',
	},
	getters: {
		getCategoryList(state) {
			return state.categoryList
		},
		getCategoriestatus(state) {
			return state.categoriestatus
		},
	},
	mutations: {
		SET_CATEGORY_LIST(state, value) {
			state.categoryList = value
		},
		SET_CATEGORY_STATUS(state, value) {
			state.categoriestatus = value
		},
	},
	actions: {
		async fetchCategories({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_CATEGORY_STATUS', 'pending')
			commit('SET_CATEGORY_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getCategories(params)
				commit('SET_CATEGORY_STATUS', 'init')
				commit('SET_CATEGORY_LIST', data)
				return data
			} catch (error) {
				commit('SET_CATEGORY_STATUS', 'init')
				throw error
			}
		},
		async deleteCategoryRecord({ commit }, params) {
			commit('SET_CATEGORY_STATUS', 'pending')
			try {
				const { data } = await deleteCategory(params)
				commit('SET_CATEGORY_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_CATEGORY_STATUS', 'init')
				throw error
			}
		},
		async saveCategory({ commit }, params) {
			commit('SET_CATEGORY_STATUS', 'pending')
			try {
				const { data } = await saveCategory(params)
				commit('SET_CATEGORY_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_CATEGORY_STATUS', 'init')
				throw error
			}
		},
		async updateCategory({ commit }, params) {
			commit('SET_CATEGORY_STATUS', 'pending')
			try {
				const { data } = await updateCategory(params)
				commit('SET_CATEGORY_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_CATEGORY_STATUS', 'init')
				throw error
			}
		},
	},
}
