const listProjects = [
	{
		path: '/network/list-projects/list',
		name: 'network-list-projects',
		component: () => import('@/views/network/list-projects/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default listProjects
