import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/http'

//LIST OF ALL NEWSLETTER CATEGORIES
export const categoriesList = (params = '') => Http.get(`/newsletter-categories${serialize(params)}`)
export const categoryCreate = (params = '') => Http.post('/newsletter-categories', params)
export const categoryByID = (params = '') => Http.get(`/newsletter-categories/${params}`)
export const categoryUpdate = (params = '') => Http.put(`/newsletter-categories/${params.id}`, params)
export const categoryDestroy = (params = '') => Http.delete(`/newsletter-categories/${params.id}`)

//LIST OF ALL NEWSLETTER PERIODICITIES
export const periodicitiesList = (params = '') => Http.get(`/newsletter-periodicities${serialize(params)}`)
export const periodicityCreate = (params = '') => Http.post('/newsletter-periodicities', params)
export const periodicityByID = (params = '') => Http.get(`/newsletter-periodicities/${params}`)
export const periodicityUpdate = (params = '') => Http.put(`/newsletter-periodicities/${params.id}`, params)
export const periodicityDestroy = (params = '') => Http.delete(`/newsletter-periodicities/${params.id}`)

//LIST OF ALL NEWSLETTER CAMPAIGNS
export const campaignsList = (params = '') => Http.get(`/newsletter-campaigns${serialize(params)}`)
export const campaignCreate = (params = '') => Http.post('/newsletter-campaigns', params)
export const campaignByID = (params = '') => Http.get(`/newsletter-campaigns/${params}`)
export const campaignUpdate = (params = '') => Http.put(`/newsletter-campaigns/${params.id}`, params)
export const campaignDestroy = (params = '') => Http.delete(`/newsletter-campaigns/${params.id}`)

//LIST OF ALL NEWSLETTER RESULT
export const resultsList = (params = '') => Http.get(`/newsletter-campaign-emails${serialize(params)}`)
export const resultByID = (params = '') => Http.get(`/newsletter-campaign-emails/${params}`)
