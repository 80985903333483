import { deleteTheme, getThemes, saveTheme, updateTheme } from '@/services/community/wikigamer/games/themes'
export default {
	namespaced: true,
	state: {
		themeList: {
			meta: {
				total: 0,
			},
		},
		themeStatus: 'pending',
	},
	getters: {
		getThemeList(state) {
			return state.themeList
		},
		getThemeStatus(state) {
			return state.themeStatus
		},
	},
	mutations: {
		SET_THEME_LIST(state, value) {
			state.themeList = value
		},
		SET_THEME_STATUS(state, value) {
			state.themeStatus = value
		},
	},
	actions: {
		async fetchThemes({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_THEME_STATUS', 'pending')
			commit('SET_THEME_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getThemes(params)
				commit('SET_THEME_STATUS', 'init')
				commit('SET_THEME_LIST', data)
				return data
			} catch (error) {
				commit('SET_THEME_STATUS', 'init')
				throw error
			}
		},
		async deleteThemeRecord({ commit }, params) {
			commit('SET_THEME_STATUS', 'pending')
			try {
				const { data } = await deleteTheme(params)
				commit('SET_THEME_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_THEME_STATUS', 'init')
				throw error
			}
		},
		async saveTheme({ commit }, params) {
			commit('SET_THEME_STATUS', 'pending')
			try {
				const { data } = await saveTheme(params)
				commit('SET_THEME_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_THEME_STATUS', 'init')
				throw error
			}
		},
		async updateTheme({ commit }, params) {
			commit('SET_THEME_STATUS', 'pending')
			try {
				const { data } = await updateTheme(params)
				commit('SET_THEME_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_THEME_STATUS', 'init')
				throw error
			}
		},
	},
}
