import {
	credentialByID,
	credentialCreate,
	credentialDestroy,
	credentialUpdate,
	credentialsList,
} from '@/services/apiCredentials.service'

export default {
	namespaced: true,
	state: {
		// APIS CREDENTIALS
		credentialsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		credential: {},
		// STATUS APIS CREDENTIALS
		credentialsStatus: 'pending',
		credentialsSaveStatus: 'init',
		credentialsDeleteStatus: 'init',
	},
	getters: {
		// APIS CREDENTIALS
		getCredentialsList(state) {
			return state.credentialsList
		},
		getCredential(state) {
			return state.credential
		},
		// APIS CREDENTIALS
		getCredentialsDeleteStatus(state) {
			return state.credentialsDeleteStatus
		},
		getCredentialsSaveStatus(state) {
			return state.credentialsSaveStatus
		},
		getCredentialsStatus(state) {
			return state.credentialsStatus
		},
	},
	mutations: {
		// APIS CREDENTIALS
		CREDENTIALS_LIST(state, value) {
			state.credentialsList = value
		},
		SET_CREDENTIAL(state, value) {
			state.credential = value
		},
		// STATUS APIS CREDENTIALS
		CREDENTIALS_STATUS(state, value) {
			state.credentialsStatus = value
		},
		CREDENTIAL_SAVE_STATUS(state, value) {
			state.credentialsSaveStatus = value
		},
		CREDENTIAL_DELETE_STATUS(state, value) {
			state.credentialsDeleteStatus = value
		},
	},
	actions: {
		// APIS Credentials
		async fetchCredentialList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('CREDENTIALS_STATUS', 'pending')
			commit('CREDENTIALS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await credentialsList(params)
				commit('CREDENTIALS_STATUS', 'init')
				commit('CREDENTIALS_LIST', data)
				return data
			} catch (error) {
				commit('CREDENTIALS_STATUS', 'init')
				throw error
			}
		},
		async fetchCredentialByID({ commit }, params) {
			commit('CREDENTIALS_STATUS', 'pending')
			try {
				const { data } = await credentialByID(params)
				commit('CREDENTIALS_STATUS', 'init')
				commit('SET_CREDENTIAL', data.data)
				return data
			} catch (error) {
				commit('CREDENTIALS_STATUS', 'init')

				return error
			}
		},
		async saveNewCredential({ commit }, params) {
			commit('CREDENTIAL_SAVE_STATUS', 'pending')
			try {
				const { data } = await credentialCreate(params)
				commit('CREDENTIAL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CREDENTIAL_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCredential({ commit }, params) {
			commit('CREDENTIAL_SAVE_STATUS', 'pending')
			try {
				const { data } = await credentialUpdate(params)
				commit('CREDENTIAL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CREDENTIAL_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCredentialByID({ commit }, params) {
			commit('CREDENTIAL_DELETE_STATUS', 'pending')
			try {
				const { data } = await credentialDestroy(params)
				commit('CREDENTIAL_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CREDENTIAL_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
