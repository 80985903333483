import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getEventsCategories = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/events-category/fetch${serialize(params)}`)

// DESTROY A EVENT CATEGORY
export const destroyEventGategory = (
	params = {
		eventCategoryId: null,
	},
) => Http.delete(`/events-category/${params.eventCategoryId}/destroy`)

// POST A NEW CATEGORY
export const saveEventCategory = (
	params = {
		name: null,
	},
) => Http.post(`/events-category/store`, params)

// UPDATE A CATEGORY
export const updateEventCategory = (
	params = {
		eventCategoryId: null,
		name: null,
	},
) => Http.put(`/events-category/${params.eventCategoryId}/update`, params)
