export default {
	namespaced: true,
	state: {
		shallContentShowOverlay: false,
		selectedPlatform: "network"
	},
	getters: {
		selectedPlatform(state){
			return state.selectedPlatform
		}
	},
	mutations: {
		TOGGLE_CONTENT_OVERLAY(state, value) {
			state.shallContentShowOverlay = value !== undefined ? value : !state
				.shallContentShowOverlay
		},
		CHANGE_PLAFTORM(state, value) {
			state.selectedPlatform = value
		}
	},
	actions: {},
}
