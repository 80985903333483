import { deleteContent, getContents } from '@/services/community/contentsComments'

export default {
	namespaced: true,
	state: {
		contentList: {
			meta: {
				total: 0,
			},
		},
		contentStatus: 'pending',
		contentDeleteStatus: 'init',
	},
	getters: {
		getContentList(state) {
			return state.contentList
		},
		getContentStatus(state) {
			return state.contentStatus
		},
		getContentDeleteStatus(state) {
			return state.contentDeleteStatus
		},
	},
	mutations: {
		SET_CONTENT_LIST(state, value) {
			state.contentList = value
		},
		SET_CONTENT_STATUS(state, value) {
			state.contentStatus = value
		},
		SET_CONTENT_DELETE_STATUS(state, value) {
			state.contentDeleteStatus = value
		},
	},
	actions: {
		async fetchContents({ commit }, params) {
			commit('SET_CONTENT_STATUS', 'pending')
			commit('SET_CONTENT_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getContents(params)
				commit('SET_CONTENT_STATUS', 'init')
				commit('SET_CONTENT_LIST', data)
				return data
			} catch (error) {
				commit('SET_CONTENT_STATUS', 'init')
				throw error
			}
		},

		async deleteContentRecord({ commit }, params) {
			commit('SET_CONTENT_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteContent(params)
				commit('SET_CONTENT_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_CONTENT_DELETE_STATUS', 'init')
				throw error
			}
		},
	},
}
