const educationLevels = [
	{
		path: '/education-levels/list',
		name: 'jobs-education-levels-list',
		component: () => import(/* webpackChunkName: "EducationLevelsList" */ '@/views/jobs/education-levels/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default educationLevels
