import { updateRating, deleteRating, getRatings } from '@/services/community/ratings'
import { mdiStarOutline } from '@mdi/js'

export default {
	namespaced: true,
	state: {
		ratingList: {
			meta: {
				total: 0,
			},
		},
		ratingStatus: 'pending',
		ratingSaveStatus: 'init',
		ratingDeleteStatus: 'init',
	},
	getters: {
		getRatingList(state) {
			return state.ratingList
		},
		getRatingStatus(state) {
			return state.ratingStatus
		},
		getRatingSaveStatus(state) {
			return state.ratingSaveStatus
		},
		getRatingDeleteStatus(state) {
			return state.ratingDeleteStatus
		},
	},
	mutations: {
		SET_RATING_LIST(state, value) {
			state.ratingList = value
		},
		SET_RATING_STATUS(state, value) {
			state.ratingStatus = value
		},
		SET_RATING_SAVE_STATUS(state, value) {
			state.ratingSaveStatus = value
		},
		SET_RATING_DELETE_STATUS(state, value) {
			state.ratingDeleteStatus = value
		},
	},
	actions: {
		async fetchRatings({ commit }, params) {
			commit('SET_RATING_STATUS', 'pending')
			commit('SET_RATING_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getRatings(params)
				commit('SET_RATING_STATUS', 'init')
				commit('SET_RATING_LIST', data)
				return data
			} catch (error) {
				commit('SET_RATING_STATUS', 'init')
				throw error
			}
		},

		async updateRatingRecord({ commit }, params) {
			commit('SET_RATING_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateRating(params)
				commit('SET_RATING_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_RATING_SAVE_STATUS', 'init')
				throw error
			}
		},

		async deleteRatingRecord({ commit }, params) {
			commit('SET_RATING_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteRating(params)
				commit('SET_RATING_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_RATING_DELETE_STATUS', 'init')
				throw error
			}
		},
	},
}
