import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getGenerations = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/generations/fetch${serialize(params)}`)

// SAVE GENERATION
export const saveGeneration = (
	params = {
		name: '',
		name_abrev: '',
	},
) => Http.post(`/generations/store`, params)

// UPDATE GENERATION
export const updateGeneration = (
	params = {
		generationId: null,
		name: '',
		name_abrev: '',
	},
) => Http.put(`/generations/${params.generationId}/update`, params)

// DELETE GENERATION
export const deleteGeneration = (
	params = {
		generationId: null,
	},
) => Http.delete(`/generations/${params.generationId}/destroy`)
