import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getAchievements = params => Http.get(`/achievements/fetch${serialize(params)}`)

// SAVE A NEW ACHIEVEMENT
export const saveAchievement = params => Http.post(`/achievements/store`, params)

//UPDATE ACHIEVEMENT
export const updateAchievement = (
	params = {
		achievementId: null,
	},
) => Http.put(`/achievements/${params.achievementId}/update`, params)

// DELETE ACHIEVEMENT
export const deleteAchievement = (
	params = {
		achievementId: null,
	},
) => Http.delete(`achievements/${params.achievementId}/destroy`)
