import {
	deleteContent,
	deleteRating,
	getContents,
	getRatings,
	saveContent,
	saveRating,
	updateContent,
	updateRating,
} from '@/services/community/wikigamer/gamesRating/esrb'

export default {
	namespaced: true,
	state: {
		contentList: {
			meta: {
				total: 0,
			},
		},
		ratingList: {
			meta: {
				total: 0,
			},
		},
		contentStatus: 'pending',
		ratingStatus: 'pending',
	},
	getters: {
		getContentList(state) {
			return state.contentList
		},
		getRatingList(state) {
			return state.ratingList
		},

		getContentStatus(state) {
			return state.contentStatus
		},
		getRatingStatus(state) {
			return state.ratingStatus
		},
	},
	mutations: {
		SET_CONTENT_LIST(state, value) {
			state.contentList = value
		},
		SET_RATING_LIST(state, value) {
			state.ratingList = value
		},
		SET_CONTENT_STATUS(state, value) {
			state.contentStatus = value
		},
		SET_RATING_STATUS(state, value) {
			state.ratingStatus = value
		},
	},
	actions: {
		async fetchContents({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_CONTENT_STATUS', 'pending')
			commit('SET_CONTENT_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getContents(params)
				commit('SET_CONTENT_STATUS', 'init')
				commit('SET_CONTENT_LIST', data)
				return data
			} catch (error) {
				commit('SET_CONTENT_STATUS', 'init')
				throw error
			}
		},

		async fetchRatings({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_RATING_STATUS', 'pending')
			commit('SET_RATING_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getRatings(params)
				commit('SET_RATING_STATUS', 'init')
				commit('SET_RATING_LIST', data)
				return data
			} catch (error) {
				commit('SET_RATING_STATUS', 'init')
				throw error
			}
		},

		async saveContent({ commit }, params) {
			commit('SET_CONTENT_STATUS', 'pending')
			try {
				const { data } = await saveContent(params)
				commit('SET_CONTENT_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_CONTENT_STATUS', 'init')
				throw error
			}
		},
		async saveRating({ commit }, params) {
			commit('SET_RATING_STATUS', 'pending')
			try {
				const { data } = await saveRating(params)
				commit('SET_RATING_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_RATING_STATUS', 'init')
				throw error
			}
		},
		async updateContent({ commit }, params) {
			commit('SET_CONTENT_STATUS', 'pending')
			try {
				const { data } = await updateContent(params)
				commit('SET_CONTENT_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_CONTENT_STATUS', 'init')
				throw error
			}
		},
		async updateRating({ commit }, params) {
			commit('SET_RATING_STATUS', 'pending')
			try {
				const { data } = await updateRating(params)
				commit('SET_RATING_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_RATING_STATUS', 'init')
				throw error
			}
		},
		async deleteContent({ commit }, params) {
			commit('SET_CONTENT_STATUS', 'pending')
			try {
				const { data } = await deleteContent(params)
				commit('SET_CONTENT_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_CONTENT_STATUS', 'init')
				throw error
			}
		},
		async deleteRating({ commit }, params) {
			commit('SET_RATING_STATUS', 'pending')
			try {
				const { data } = await deleteRating(params)
				commit('SET_RATING_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_RATING_STATUS', 'init')
				throw error
			}
		},
	},
}
