import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpJobs'

// // PERKS
export const perkCreate = (params = '') => Http.post('/perks', params)
// // // LIST ALL PERKS
export const perksList = (params = '') => Http.get(`/perks${serialize(params)}`)
// // // LIST ALL PERKS
export const perksListAll = (params = '') => Http.get(`/perks${serialize(params)}&per_page=all`)
// // // GET Perk BY ID
export const perkByID = (params = '') => Http.get(`/perks/${params}`)
// // // UPDATE PERK
export const perkUpdate = (params = '') => Http.put(`/perks/${params.id}`, params)
// // // DELETE PERK
export const perkDestroy = (params = '') => Http.delete(`/perks/${params.id}`)
// // // Get companie perks
export const getCompaniePerks = (params = '') => Http.get(`/companies/perks${serialize(params)}`)
