import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getSubcategories = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/reports/subcategories/fetch${serialize(params)}`)

//SAVE A NEW SUBCATEGORY
export const saveSubcategory = (
	params = {
		name: null,
	},
) => Http.post(`/reports/subcategories/store`, params)

//UPDATE SUBCATEGORY
export const updateSubcategory = (
	params = {
		name: null,
		subcategoryId: null,
	},
) => Http.put(`/reports/subcategories/${params.subcategoryId}/update`, params)

// DELETE SUBCATEGORY
export const deleteSubcategory = (
	params = {
		subcategoryId: null,
	},
) => Http.delete(`/reports/subcategories/${params.subcategoryId}/destroy`, params)
