import {
	batchUpsertGamesGame,
	destroyGame,
	destroyGameGame,
	getGames,
	saveGame,
	showGame,
	updateGame,
} from '@/services/community/wikigamer/games/games'
import { alertBasic } from '@/views/components/alert/demos/code'

export default {
	namespaced: true,
	state: {
		gameList: {
			meta: {
				total: 0,
			},
		},
		gameStatus: 'pending',
		gameData: null,
	},
	getters: {
		getGameList(state) {
			return state.gameList
		},
		getGameStatus(state) {
			return state.gameStatus
		},
		getGameData(state) {
			return state.gameData
		},
	},
	mutations: {
		SET_GAME_LIST(state, value) {
			state.gameList = value
		},
		SET_GAME_STATUS(state, value) {
			state.gameStatus = value
		},
		SET_GAME_DATA(state, value) {
			state.gameData = value
		},
	},
	actions: {
		async fetchGames({ commit }, params) {
			commit('SET_GAME_STATUS', 'pending')
			commit('SET_GAME_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getGames(params)
				commit('SET_GAME_STATUS', 'init')
				commit('SET_GAME_LIST', data)
				return data
			} catch (error) {
				commit('SET_GAME_STATUS', 'init')
				throw error
			}
		},

		async destroyGameRecord({ commit }, params) {
			commit('SET_GAME_STATUS', 'pending')
			try {
				const { data } = await destroyGame(params)
				commit('SET_GAME_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_GAME_STATUS', 'init')
				throw error
			}
		},
		async saveGame({ commit }, params) {
			commit('SET_GAME_STATUS', 'pending')
			try {
				const { data } = await saveGame(params)
				// if (params.games_id.length > 0) {
				// 	await batchUpsertGamesGame({ gameSlug: data.data.slug, games_id: params.games_id })
				// }
				commit('SET_GAME_STATUS', 'init')
				commit('SET_GAME_DATA', null)
				return data
			} catch (error) {
				commit('SET_GAME_STATUS', 'init')
				throw error
			}
		},
		async updateGame({ commit }, params) {
			commit('SET_GAME_STATUS', 'pending')
			try {
				const { data } = await updateGame(params)
				// if (params.games_id.length > 0) {
				// 	await batchUpsertGamesGame({ gameSlug: data.data.slug, games_id: params.games_id })
				// }
				commit('SET_GAME_STATUS', 'init')
				commit('SET_GAME_DATA', null)
				return data
			} catch (error) {
				commit('SET_GAME_STATUS', 'init')
				throw error
			}
		},
		async showGame({ commit }, params) {
			commit('SET_GAME_STATUS', 'pending')
			try {
				const { data } = await showGame(params)
				commit('SET_GAME_STATUS', 'init')
				commit('SET_GAME_DATA', data.data)
				return data
			} catch (error) {
				commit('SET_GAME_STATUS', 'init')
				throw error
			}
		},
		clear({ commit }, params) {
			commit('SET_GAME_STATUS', 'init')
			commit('SET_GAME_DATA', null)
		},
		async destroyGameByGame({ commit }, params) {
			commit('SET_GAME_STATUS', 'pending')
			try {
				await destroyGameGame(params)
				commit('SET_GAME_STATUS', 'init')
			} catch (error) {
				commit('SET_GAME_STATUS', 'init')
				throw error
			}
		},
	},
}
