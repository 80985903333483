import { faqByID, faqCreate, faqDestroy, faqsList, faqUpdate } from '@/services/faqs.service'

export default {
	namespaced: true,
	state: {
		// SERVICE FAQS
		faqsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		faq: {},

		// STATUS SERVICE FAQS
		faqsStatus: 'pending',
		faqsSaveStatus: 'init',
		faqsDeleteStatus: 'init',
	},
	getters: {
		// SERVICE FAQS
		getFaqsList(state) {
			return state.faqsList
		},
		getFaq(state) {
			return state.faq
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE FAQS
		getFaqsDeleteStatus(state) {
			return state.faqsDeleteStatus
		},
		getFaqsSaveStatus(state) {
			return state.faqsSaveStatus
		},
		getFaqsStatus(state) {
			return state.faqsStatus
		},
	},
	mutations: {
		// SERVICE FAQS
		FAQS_LIST(state, value) {
			state.faqsList = value
		},
		SET_FAQ(state, value) {
			state.faq = value
		},

		// STATUS SERVICE FAQS
		FAQS_STATUS(state, value) {
			state.faqsStatus = value
		},
		FAQ_SAVE_STATUS(state, value) {
			state.faqsSaveStatus = value
		},
		FAQ_DELETE_STATUS(state, value) {
			state.faqsDeleteStatus = value
		},
	},
	actions: {
		// Service Faqs
		async fetchFaqList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('FAQS_STATUS', 'pending')
			commit('FAQS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await faqsList(params)
				commit('FAQS_STATUS', 'init')
				commit('FAQS_LIST', data)
				return data
			} catch (error) {
				commit('FAQS_STATUS', 'init')
				throw error
			}
		},
		async fetchFaqByID({ commit }, params) {
			commit('FAQS_STATUS', 'pending')
			try {
				const { data } = await faqByID(params)
				commit('FAQS_STATUS', 'init')
				commit('SET_FAQ', data.data)
				return data
			} catch (error) {
				commit('FAQS_STATUS', 'init')

				return error
			}
		},
		async saveNewFaq({ commit }, params) {
			commit('FAQ_SAVE_STATUS', 'pending')
			try {
				const { data } = await faqCreate(params)
				commit('FAQ_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('FAQ_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateFaq({ commit }, params) {
			commit('FAQ_SAVE_STATUS', 'pending')
			try {
				const { data } = await faqUpdate(params)
				commit('FAQ_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('FAQ_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteFaqByID({ commit }, params) {
			commit('FAQ_DELETE_STATUS', 'pending')
			try {
				const { data } = await faqDestroy(params)
				commit('FAQ_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('FAQ_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
