const services = [
    {
        path: '/services',
        name: 'services-list',
        component: () => import('@/views/network/blog/posts/list.vue'),
        meta: {
            layout: 'content',
        },
    },
]

export default services