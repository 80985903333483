import {
	deleteUserByID,
	genderByID,
	genderCreate,
	genderDestroy,
	genderUpdate,
	gendersList,
	newUser,
	updateUser,
	updateUserRole,
	updateUserPermission,
	usersByID,
	usersList,
} from '@/services/users.service'

export default {
	namespaced: true,
	state: {
		// USERS
		usersList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		user: {},
		// STATUS
		usersStatus: 'init',
		usersSaveStatus: 'init',
		usersDeleteStatus: 'init',

		// USERS GENDERS
		gendersList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		gender: {},

		// STATUS USERS GENDERS
		gendersStatus: 'pending',
		gendersSaveStatus: 'init',
		gendersDeleteStatus: 'init',
	},
	getters: {
		// USERS
		getUsersList(state) {
			return state.usersList
		},
		getUser(state) {
			return state.user
		},
		// STATUS
		getUsersDeleteStatus(state) {
			return state.usersDeleteStatus
		},
		getUsersSaveStatus(state) {
			return state.usersSaveStatus
		},
		getUsersStatus(state) {
			return state.usersStatus
		},

		// USERS GENDERS
		getGendersList(state) {
			return state.gendersList
		},
		getGender(state) {
			return state.gender
		},
		// USERS GENDERS STATUS
		getGendersDeleteStatus(state) {
			return state.gendersDeleteStatus
		},
		getGendersSaveStatus(state) {
			return state.gendersSaveStatus
		},
		getGendersStatus(state) {
			return state.gendersStatus
		},
	},
	mutations: {
		// USERS
		USERS_LIST(state, value) {
			state.usersList = value
		},
		SET_USER(state, value) {
			state.user = value
		},
		// STATUS
		USERS_STATUS(state, value) {
			state.usersStatus = value
		},
		USERS_SAVE_STATUS(state, value) {
			state.usersSaveStatus = value
		},
		USERS_DELETE_STATUS(state, value) {
			state.usersDeleteStatus = value
		},

		// USERS GENDERS
		GENDERS_LIST(state, value) {
			state.gendersList = value
		},
		SET_GENDER(state, value) {
			state.gender = value
		},

		// STATUS USERS GENDERS
		GENDERS_STATUS(state, value) {
			state.gendersStatus = value
		},
		GENDER_SAVE_STATUS(state, value) {
			state.gendersSaveStatus = value
		},
		GENDER_DELETE_STATUS(state, value) {
			state.gendersDeleteStatus = value
		},
	},
	actions: {
		// Users
		async fetchUsersList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('USERS_STATUS', 'pending')
			commit('USERS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await usersList(params)
				commit('USERS_STATUS', 'init')
				commit('USERS_LIST', data)
				return data
			} catch (error) {
				commit('USERS_STATUS', 'init')
				throw error
			}
		},
		async fetchUserByID({ state, commit }, params) {
			commit('USERS_STATUS', 'pending')
			try {
				const { data } = await usersByID(params)
				commit('USERS_STATUS', 'init')
				commit('SET_USER', data.data)
				return data
			} catch (error) {
				commit('USERS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewUser({ commit }, params) {
			commit('USERS_SAVE_STATUS', 'pending')
			try {
				const { data } = await newUser(params)
				commit('USERS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('USERS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateUser({ commit }, params) {
			commit('USERS_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateUser(params)
				commit('USERS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('USERS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateUserRole({ commit }, params) {
			commit('USERS_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateUserRole(params)
				commit('USERS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('USERS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateUserPermission({ commit }, params) {
			commit('USERS_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateUserPermission(params)
				commit('USERS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('USERS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteUserByID({ state, commit }, params) {
			commit('USERS_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteUserByID(params)
				commit('USERS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('USERS_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		// USERS GENDERS
		async fetchGenderList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('GENDERS_STATUS', 'pending')
			commit('GENDERS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await gendersList(params)
				commit('GENDERS_STATUS', 'init')
				commit('GENDERS_LIST', data)
				return data
			} catch (error) {
				commit('GENDERS_STATUS', 'init')
				throw error
			}
		},
		async fetchGenderByID({ commit }, params) {
			commit('GENDERS_STATUS', 'pending')
			try {
				const { data } = await genderByID(params)
				commit('GENDERS_STATUS', 'init')
				commit('SET_GENDER', data.data)
				return data
			} catch (error) {
				commit('GENDERS_STATUS', 'init')

				return error
			}
		},
		async saveNewGender({ commit }, params) {
			commit('GENDER_SAVE_STATUS', 'pending')
			try {
				const { data } = await genderCreate(params)
				commit('GENDER_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('GENDER_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateGender({ commit }, params) {
			commit('GENDER_SAVE_STATUS', 'pending')
			try {
				const { data } = await genderUpdate(params)
				commit('GENDER_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('GENDER_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteGenderByID({ commit }, params) {
			commit('GENDER_DELETE_STATUS', 'pending')
			try {
				const { data } = await genderDestroy(params)
				commit('GENDER_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('GENDER_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
