import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpClub'

export const getFranchiseModels = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/franchise-models${serialize(params)}`)

//SAVE A NEW FRANCHISEMODEL
export const saveFranchiseModel = (params = {}) => Http.post('/franchise-models', params)

//UPDATE FRANCHISEMODEL
export const updateFranchiseModel = (
	params = {
		franchisemodelId: null,
	},
) => Http.put(`/franchise-models/${params.franchisemodelId}`, params)

//DELETE FRANCHISEMODEL
export const deleteFranchiseModel = (
	params = {
		franchisemodelId: null,
	},
) => Http.delete(`/franchise-models/${params.franchisemodelId}`)
