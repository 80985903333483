import { deleteRelease, getReleases } from '@/services/community/releases'

export default {
	namespaced: true,
	state: {
		releaseList:  {
			meta: {
				total: 0,
			},
		},
		releaseStatus: 'pending',
	},
	getters: {
		getReleaseList(state) {
			return state.releaseList
		},
		getReleaseStatus(state) {
			return state.releaseStatus
		},
	},
	mutations: {
		SET_RELEASE_LIST(state, value) {
			state.releaseList = value
		},
		SET_RELEASE_STATUS(state, value) {
			state.releaseStatus = value
		},
	},
	actions: {
		async fetchReleases({ commit }, params) {
			commit('SET_RELEASE_STATUS', 'pending')
			commit('SET_RELEASE_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getReleases(params)
				commit('SET_RELEASE_STATUS', 'init')
				commit('SET_RELEASE_LIST', data)
				return data
			} catch (error) {
				commit('SET_RELEASE_STATUS', 'init')
				throw error
			}
		},

		async deleteReleaseRecord({ commit }, params) {
			commit('SET_RELEASE_STATUS', 'pending')
			try {
				const { data } = await deleteRelease(params)
				commit('SET_RELEASE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_RELEASE_STATUS', 'init')
				throw error
			}
		},
	},
}
