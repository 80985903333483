import {
	benefitByID,
	benefitCreate,
	benefitDestroy,
	benefitsList,
	benefitUpdate
} from '@/services/accountBenefits.service'

export default {
	namespaced: true,
	state: {
		// BENEFITS
		benefitsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		benefit: {},

		// STATUS BENEFITS
		benefitsStatus: 'pending',
		benefitsSaveStatus: 'init',
		benefitsDeleteStatus: 'init',
	},
	getters: {
		// BENEFITS
		getBenefitsList(state) {
			return state.benefitsList
		},
		getBenefit(state) {
			return state.benefit
		},

		// STATUS BENEFITS
		getBenefitsDeleteStatus(state) {
			return state.benefitsDeleteStatus
		},
		getBenefitsSaveStatus(state) {
			return state.benefitsSaveStatus
		},
		getBenefitsStatus(state) {
			return state.benefitsStatus
		},
	},
	mutations: {
		// BENEFITS
		BENEFITS_LIST(state, value) {
			state.benefitsList = value
		},
		SET_BENEFIT(state, value) {
			state.benefit = value
		},

		// STATUS BENEFITS
		BENEFITS_STATUS(state, value) {
			state.benefitsStatus = value
		},
		BENEFIT_SAVE_STATUS(state, value) {
			state.benefitsSaveStatus = value
		},
		BENEFIT_DELETE_STATUS(state, value) {
			state.benefitsDeleteStatus = value
		},
	},
	actions: {
		// BENEFITS
		async fetchBenefitList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('BENEFITS_STATUS', 'pending')
			commit('BENEFITS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await benefitsList(params)
				commit('BENEFITS_STATUS', 'init')
				commit('BENEFITS_LIST', data)
				return data
			} catch (error) {
				commit('BENEFITS_STATUS', 'init')
				throw error
			}
		},
		async fetchBenefitByID({ commit }, params) {
			commit('BENEFITS_STATUS', 'pending')
			try {
				const { data } = await benefitByID(params)
				commit('BENEFITS_STATUS', 'init')
				commit('SET_BENEFIT', data.data)
				return data
			} catch (error) {
				commit('BENEFITS_STATUS', 'init')

				return error
			}
		},
		async saveNewBenefit({ commit }, params) {
			commit('BENEFIT_SAVE_STATUS', 'pending')
			try {
				const { data } = await benefitCreate(params)
				commit('BENEFIT_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BENEFIT_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateBenefit({ commit }, params) {
			commit('BENEFIT_SAVE_STATUS', 'pending')
			try {
				const { data } = await benefitUpdate(params)
				commit('BENEFIT_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BENEFIT_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteBenefitByID({ commit }, params) {
			commit('BENEFIT_DELETE_STATUS', 'pending')
			try {
				const { data } = await benefitDestroy(params)
				commit('BENEFIT_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BENEFIT_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
