import apis from './apis'
import blog from './blog'
import careers from './careers'
import companies from './companies'
import financial from './financial'
import industrySectorCategories from './industry-sector-categories'
import keysList from './keys-list'
import languagesLocations from './languages-locations'
import listProjects from './list-projects'
import mgCompanies from './mg-companies'
import networkSystem from './network-systems'
import partners from './partners'
import partnersCategories from './partners-categories'
import pressMedia from './press-media'
import settings from './settings'
import appThemes from './themes'
import users from './users'

const network = [
	{
		path: '/network/dashboard',
		name: 'network-dashboard',
		component: () => import('@/views/dashboards/crm/Crm.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/messages',
		name: 'network-messages-list',
		component: () => import('@/views/messages/Messages.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/newsletter',
		name: 'network-newsletter-list',
		component: () => import('@/views/newsletters/Newsletter.vue'),
		meta: {
			layout: 'content',
		},
	},
	...blog,
	...apis,
	...mgCompanies,
	...companies,
	...careers,
	...industrySectorCategories,
	...listProjects,
	...keysList,
	...partners,
	...partnersCategories,
	...networkSystem,
	...languagesLocations,
	...users,
	...financial,
	...pressMedia,
	...appThemes,
	...settings,
]

export default network
