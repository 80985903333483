import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

// // CAREERS
// // // LIST ALL CAREERS
export const careersList = (params = '') => Http.get(`/careers${serialize(params)}`)
// // // GET CAREER BY ID
export const careersByID = (params = '') => Http.get(`/careers/${params}`)
// // // POST NEW CAREER
export const newCareer = (params = '') => Http.post('/careers', params)
// // // UPDATE CAREER
export const updateCareer = (params = '') => Http.put(`/careers/${params.id}`, params)
// // // DELETE CAREER
export const deleteCareerByID = (params = '') => Http.delete(`/careers/${params.id}`)

// // APPLICATIONS
// // // LIST ALL APPLICATIONS
export const applicationsList = (params = { filter: '', per_page: 10, sort: 'id', order: 'desc' }) =>
	Http.get(`/careers-applications${serialize(params)}`)
// // // GET APPLICATION BY ID
export const applicationsByID = (params = '') => Http.get(`/careers-applications/${params}`)
// // // POST NEW APPLICATION
export const newApplication = (params = '') => Http.post('/careers-applications', params)
// // // UPDATE APPLICATION
export const updateApplications = (params = '') => Http.put(`/careers-applications/${params.id}`, params)
// // // DELETE APPLICATION
export const deleteApplicationByID = (params = '') => Http.delete(`/careers-applications/${params.id}`)

// // // GET APPLICATION TOGGLE
export const updateFavoriteToggle = (params = '') => Http.post(`/careers-applications/${params.id}/toggle-favorite`, params)
export const updateReadToggle = (params = '') => Http.post(`/careers-applications/${params.id}/toggle-read`, params)

// // SKILLS
// // // LIST ALL SKILLS
export const skillsList = (params = '') => Http.get(`/skills${serialize(params)}`)
// // // GET APPLICATION BY ID
export const skillsByID = (params = '') => Http.get(`/skills/${params}`)
// // // POST NEW APPLICATION
export const newSkill = (params = '') => Http.post('/skills', params)
// // // UPDATE APPLICATION
export const updateSkill = (params = '') => Http.put(`/skills/${params.id}`, params)
// // // DELETE APPLICATION
export const deleteSkillByID = (params = '') => Http.delete(`/skills/${params.id}`)
