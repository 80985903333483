const awards = [
	{
		path: '/community/awards/awards/list',
		name: 'community-awards-list',
		component: () => import('@/views/community/awards/awards/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/awards/categories/list',
		name: 'community-awards-categories-list',
		component: () => import('@/views/community/awards/categories/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/awards/competitors/list',
		name: 'community-awards-competitors-list',
		component: () => import('@/views/community/awards/competitors/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/awards/competitors/new/:awardId',
		name: 'community-awards-competitors-create',
		component: () => import('@/views/community/awards/competitors/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/awards/competitors/edit/:awardId/:categoryId',
		name: 'community-awards-competitors-edit',
		component: () => import('@/views/community/awards/competitors/new.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default awards
