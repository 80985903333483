import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

// // ROADMAPS
// // // LIST ALL ROADMAPS
export const roadmapsList = (params = '') => Http.get(`/roadmaps${serialize(params)}`)
// // // GET ROADMAP BY ID
export const roadmapsByID = (params = '') => Http.get(`/roadmaps/${params}`)
// // // POST NEW ROADMAP
export const newRoadmap = (params = '') => Http.post('/roadmaps', params)
// // // UPDATE ROADMAP
export const updateRoadmap = (params = '') => Http.put(`/roadmaps/${params.id}`, params)
// // // DELETE ROADMAP
export const deleteRoadmapByID = (params = '') => Http.delete(`/roadmaps/${params.id}`)
