import {
	networkSystems,

} from '@/services/utilities.service'

export default {
	namespaced: true,
	state: {
		// NETWORK SYSTEMS
		networkSystems: [],
		networkSystemsStatus: 'init',
	},
	getters: {
		// NETWORK SYSTEMS
		networkSystems(state) {
			return state.networkSystems
		},
		networkSystemsStatus(state) {
			return state.networkSystemsStatus
		},
	},
	mutations: {
		// NETWORK SYSTEMS
		SET_NETWORK_SYSTEMS(state, val) {
			state.networkSystems = val
		},
		SET_NETWORK_SYSTEMS_STATUS(state, val) {
			state.networkSystemsStatus = val
		},
	},
	actions: {
		// NETWORK SYSTEMS
		async fetchNetworkSystems({ commit }, params) {
			commit('SET_NETWORK_SYSTEMS_STATUS', 'pending')
			try {
				const { data } = await networkSystems(params)
				commit('SET_NETWORK_SYSTEMS', data.data)
				commit('SET_NETWORK_SYSTEMS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_NETWORK_SYSTEMS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
	},
}
