import { contacts, deleteContact, updateContacts } from '@/services/club/contact.service'

export default {
	namespaced: true,
	state: {
		// contactFranchise
		contacts: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		contact: {},
		contactsStatus: 'init',
		deleteContactStatus: 'init',
	},
	getters: {
		// contactFranchise
		contacts(state) {
			return state.contacts
		},
		contact(state) {
			return state.contact
		},
		contactsStatus(state) {
			return state.contactsStatus
		},
		deleteContactStatus(state) {
			return state.deleteContactStatus
		},
	},
	mutations: {
		// contactFranchise
		SET_CONTACTS(state, val) {
			state.contacts = val
		},
		SET_CONTACT(state, val) {
			state.contact = val
		},
		SET_CONTACTS_STATUS(state, val) {
			state.contactsStatus = val
		},
		SET_DELETE_CONTACT_STATUS(state, val) {
			state.deleteContactStatus = val
		},
	},
	actions: {
		// contactFranchise
		async fetchContacts({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_CONTACTS_STATUS', 'pending')
			commit('SET_CONTACTS', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await contacts(params)
				commit('SET_CONTACTS_STATUS', 'init')
				commit('SET_CONTACTS', data)
				return data
			} catch (error) {
				commit('SET_CONTACTS_STATUS', 'init')
				throw error
			}
		},

		async fetchContact({ commit }, params) {
			commit('SET_CONTACTS_STATUS', 'pending')
			try {
				const { data } = await contacts(params)
				commit('SET_CONTACT', data)
				commit('SET_CONTACTS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_CONTACTS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async deleteContactByID({ commit }, params) {
			commit('SET_DELETE_CONTACT_STATUS', 'pending')
			try {
				const { data } = await deleteContact(params)
				commit('SET_DELETE_CONTACT_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_DELETE_CONTACT_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		async updateContacts({ commit }, params) {
			commit('SET_CONTACTS_STATUS', 'pending')
			try {
				const { data } = await updateContacts(params)
				commit('SET_CONTACTS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_CONTACTS_STATUS', 'init')
				throw error
			}
		},
	},
}
