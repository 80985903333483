import contact from './contact'
import magazine from './magazine'
import rotatingBanners from './marketing'
import mundoIndie from './mundo-indie'
import newsletterAgency from './newsletter'
import services from './services'
import appThemes from './themes'

const agency = [
	...rotatingBanners,
	...appThemes,
	...services,
	...mundoIndie,
	...contact,
	...magazine,
	...newsletterAgency,
	{
		path: '/agency/dashboard',
		name: 'agency-dashboard',
		component: () => import('@/views/agency/dashboard/Index.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/services',
		name: 'agency-services',
		component: () => import('@/views/network/blog/posts/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/messages',
		name: 'agency-messages-list',
		component: () => import('@/views/messages/Messages.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default agency
