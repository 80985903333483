import {
	allEmploymentTypes,
	typeByID,
	typeCreate,
	typeDestroy,
	typeUpdate,
	typesList,
} from '@/services/jobs/employments.service'

export default {
	namespaced: true,
	state: {
		// COMPANIES TYPES
		typesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		type: {},
		// STATUS COMPANIES TYPES
		typesStatus: 'pending',
		typesSaveStatus: 'init',
		typesDeleteStatus: 'init',

		// ALL EMPLOYMENT TYPES
		allEmploymentTypes: [],
		allEmploymentTypesStatus: 'init',
	},
	getters: {
		// COMPANIES TYPES
		getTypesList(state) {
			return state.typesList
		},
		getType(state) {
			return state.type
		},
		// COMPANIES TYPES
		getTypesDeleteStatus(state) {
			return state.typesDeleteStatus
		},
		getTypesSaveStatus(state) {
			return state.typesSaveStatus
		},
		getTypesStatus(state) {
			return state.typesStatus
		},

		// ALL EMPLOYMENT TYPES
		allEmploymentTypes(state) {
			return state.allEmploymentTypes
		},
		allEmploymentTypesStatus(state) {
			return state.allEmploymentTypesStatus
		},
	},
	mutations: {
		// COMPANIES TYPES
		TYPES_LIST(state, value) {
			state.typesList = value
		},
		SET_TYPE(state, value) {
			state.type = value
		},
		// STATUS COMPANIES TYPES
		TYPES_STATUS(state, value) {
			state.typesStatus = value
		},
		TYPE_SAVE_STATUS(state, value) {
			state.typesSaveStatus = value
		},
		TYPE_DELETE_STATUS(state, value) {
			state.typesDeleteStatus = value
		},

		// ALL EMPLOYMENT TYPES
		SET_ALL_EMPLOYMENT_TYPES(state, val) {
			state.allEmploymentTypes = val
		},
		SET_ALL_EMPLOYMENT_TYPES_STATUS(state, val) {
			state.allEmploymentTypesStatus = val
		},
	},
	actions: {
		// Companies Types
		async fetchTypeList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('TYPES_STATUS', 'pending')
			commit('TYPES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await typesList(params)
				commit('TYPES_STATUS', 'init')
				commit('TYPES_LIST', data)
				return data
			} catch (error) {
				commit('TYPES_STATUS', 'init')
				throw error
			}
		},
		async fetchTypeByID({ commit }, params) {
			commit('TYPES_STATUS', 'pending')
			try {
				const { data } = await typeByID(params)
				commit('TYPES_STATUS', 'init')
				commit('SET_TYPE', data.data)
				return data
			} catch (error) {
				commit('TYPES_STATUS', 'init')

				return error
			}
		},
		async saveNewType({ commit }, params) {
			commit('TYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await typeCreate(params)
				commit('TYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateType({ commit }, params) {
			commit('TYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await typeUpdate(params)
				commit('TYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteTypeByID({ commit }, params) {
			commit('TYPE_DELETE_STATUS', 'pending')
			try {
				const { data } = await typeDestroy(params)
				commit('TYPE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_DELETE_STATUS', 'error')

				return error
			}
		},

		// ALL EMPLOYMENT TYPES
		async fetchEmploymentTypes({ commit }, params) {
			commit('SET_ALL_EMPLOYMENT_TYPES_STATUS', 'pending')
			try {
				const { data } = await allEmploymentTypes(params)
				commit('SET_ALL_EMPLOYMENT_TYPES', data.data)
				commit('SET_ALL_EMPLOYMENT_TYPES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_ALL_EMPLOYMENT_TYPES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
	},
}
