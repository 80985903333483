import { destroyQuestion, getQuestion, getQuestions, saveQuestion, updateQuestion } from '@/services/community/contents/questions'

export default {
	namespaced: true,
	state: {
		questionList: [],
		questionStatus: 'pending',
		questionData: null,
	},
	getters: {
		getQuestionList(state) {
			return state.questionList
		},
		getQuestionStatus(state) {
			return state.questionStatus
		},
		getQuestionData(state) {
			return state.questionData
		},
	},
	mutations: {
		SET_QUESTION_LIST(state, value) {
			state.questionList = value
		},
		SET_QUESTION_STATUS(state, value) {
			state.questionStatus = value
		},
		SET_QUESTION_DATA(state, value) {
			state.questionData = value
		},
	},
	actions: {
		async fetchQuestions({ commit }, params) {
			commit('SET_QUESTION_LIST', [])
			commit('SET_QUESTION_STATUS', 'pending')
			try {
				const { data } = await getQuestions(params)
				commit('SET_QUESTION_STATUS', 'init')
				commit('SET_QUESTION_LIST', data)
				return data
			} catch (error) {
				commit('SET_QUESTION_STATUS', 'init')
				throw error
			}
		},
		async destroyQuestionRecord({ commit }, params) {
			commit('SET_QUESTION_STATUS', 'pending')
			try {
				const { data } = await destroyQuestion(params)
				commit('SET_QUESTION_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_QUESTION_STATUS', 'init')
				throw error
			}
		},
		async saveQuestion({ commit }, params) {
			commit('SET_QUESTION_STATUS', 'pending')
			try {
				const { data } = await saveQuestion(params)
				// if (params.games_id.length > 0) {
				// 	await batchUpsertGamesQuestion({ questionSlug: data.data.slug, games_id: params.games_id })
				// }
				commit('SET_QUESTION_STATUS', 'init')
				commit('SET_QUESTION_DATA', null)
				return data
			} catch (error) {
				commit('SET_QUESTION_STATUS', 'init')
				throw error
			}
		},
		async updateQuestion({ commit }, params) {
			commit('SET_QUESTION_STATUS', 'pending')
			try {
				console.log('params')
				console.log(params)
				const { data } = await updateQuestion(params)
				// if (params.games_id.length > 0) {
				// 	await batchUpsertGamesQuestion({ questionSlug: data.data.slug, games_id: params.games_id })
				// }
				commit('SET_QUESTION_STATUS', 'init')
				commit('SET_QUESTION_DATA', null)
				return data
			} catch (error) {
				commit('SET_QUESTION_STATUS', 'init')
				throw error
			}
		},
		clear({ commit }, params) {
			commit('SET_QUESTION_STATUS', 'init')
			commit('SET_QUESTION_DATA', null)
		},
		async destroyGameByQuestion({ commit }, params) {
			commit('SET_QUESTION_STATUS', 'pending')
			try {
				await destroyGameQuestion(params)
				commit('SET_QUESTION_STATUS', 'init')
			} catch (error) {
				commit('SET_QUESTION_STATUS', 'init')
				throw error
			}
		},
		async fetchQuestion({ commit }, params) {
			commit('SET_QUESTION_STATUS', 'pending')
			try {
				const { data } = await getQuestion(params)
				commit('SET_QUESTION_STATUS', 'init')
				commit('SET_QUESTION_DATA', data)
				return data
			} catch(error) {
				commit('SET_QUESTION_STATUS', 'init')
				throw error
			}
		}
	},
}
