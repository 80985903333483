import { deleteEngine, getEngines, saveEngine, updateEngine } from '@/services/community/wikigamer/games/engines'
export default {
	namespaced: true,
	state: {
		engineList: {
			meta: {
				total: 0,
			},
		},
		engineStatus: 'pending',
	},
	getters: {
		getEngineList(state) {
			return state.engineList
		},
		getEngineStatus(state) {
			return state.engineStatus
		},
	},
	mutations: {
		SET_ENGINE_LIST(state, value) {
			state.engineList = value
		},
		SET_ENGINE_STATUS(state, value) {
			state.engineStatus = value
		},
	},
	actions: {
		async fetchEngines({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_ENGINE_STATUS', 'pending')
			commit('SET_ENGINE_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getEngines(params)
				commit('SET_ENGINE_STATUS', 'init')
				commit('SET_ENGINE_LIST', data)
				return data
			} catch (error) {
				commit('SET_ENGINE_STATUS', 'init')
				throw error
			}
		},

		async deleteEngineRecord({ commit }, params) {
			commit('SET_ENGINE_STATUS', 'pending')
			try {
				const { data } = await deleteEngine(params)
				commit('SET_ENGINE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_ENGINE_STATUS', 'init')
				throw error
			}
		},
		async saveEngine({ commit }, params) {
			commit('SET_ENGINE_STATUS', 'pending')
			try {
				const { data } = await saveEngine(params)
				commit('SET_ENGINE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_ENGINE_STATUS', 'init')
				throw error
			}
		},
		async updateEngine({ commit }, params) {
			commit('SET_ENGINE_STATUS', 'pending')
			try {
				const { data } = await updateEngine(params)
				commit('SET_ENGINE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_ENGINE_STATUS', 'init')
				throw error
			}
		},
	},
}
