const streamers = [
	{
		path: '/tv/streamers/list',
		name: 'tv-streamers-list',
		component: () => import('@/views/tv/streamers/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/tv/streamers/applications/list',
		name: 'tv-streamers-applications-list',
		component: () => import('@/views/tv/streamers/applications/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default streamers
