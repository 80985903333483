const services = [
	{
		path: '/agency/services/categories/list',
		name: 'services-categories-list',
		component: () => import('@/views/agency/services-categories/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/services/target-audience/list',
		name: 'agency-target-audiences-list',
		component: () => import('@/views/agency/target-audience/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/agency/services/services/list',
		name: 'agency-services-list',
		component: () => import('@/views/agency/services/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default services
