import {
	deleteInvoicesByID,
	invoicesByID,
	invoicesList,
	newInvoices,
	updateInvoices
} from '@/services/invoices.service'

export default {
	namespaced: true,
	state: {
		// INVOICES
		invoicesList: {},
		invoices: {},

		// STATUS
		invoicesStatus: 'pending',
		invoicesSaveStatus: 'init',
		invoicesDeleteStatus: 'init',
	},
	getters: {
		// INVOICES
		getInvoicesList(state) {
			return state.invoicesList
		},
		getInvoices(state) {
			return state.invoices
		},

		// STATUS
		getInvoicesDeleteStatus(state) {
			return state.invoicesDeleteStatus
		},
		getInvoicesSaveStatus(state) {
			return state.invoicesSaveStatus
		},
		getInvoicesStatus(state) {
			return state.invoicesStatus
		},
	},
	mutations: {
		// INVOICES
		INVOICES_LIST(state, value) {
			state.invoicesList = value
		},
		SET_INVOICES(state, value) {
			state.invoices = value
		},

		// STATUS
		INVOICES_STATUS(state, value) {
			state.invoicesStatus = value
		},
		INVOICES_SAVE_STATUS(state, value) {
			state.invoicesSaveStatus = value
		},
		INVOICES_DELETE_STATUS(state, value) {
			state.invoicesDeleteStatus = value
		},
	},
	actions: {
		// Invoices
		async fetchInvoicesList({ commit }, params) {
			console.log('params', params)
			commit('INVOICES_STATUS', 'pending')
			commit('INVOICES_LIST', {})
			try {
				const { data } = await invoicesList(params)
				console.log(data)
				commit('INVOICES_STATUS', 'init')
				commit('INVOICES_LIST', data)
				return data
			} catch (error) {
				commit('INVOICES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async fetchInvoicesByID({ state, commit }, params) {
			commit('INVOICES_STATUS', 'pending')
			try {
				const { data } = await invoicesByID(params)
				commit('INVOICES_STATUS', 'init')
				commit('SET_INVOICES', data.data)
				return data
			} catch (error) {
				commit('INVOICES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewInvoices({ commit }, params) {
			commit('INVOICES_SAVE_STATUS', 'pending')
			try {
				const { data } = await newInvoices(params)
				commit('INVOICES_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('INVOICES_SAVE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async updateInvoices({ commit }, params) {
			commit('INVOICES_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateInvoices(params)
				commit('INVOICES_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('INVOICES_SAVE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async deleteInvoicesByID({ state, commit }, params) {
			commit('INVOICES_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteInvoicesByID(params)
				commit('INVOICES_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('INVOICES_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
