import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getSources = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/pulse-sources/fetch${serialize(params)}`)

//SAVE A NEW SOURCE
export const saveSource = (
	params = {
		name: null,
		url: null,
		website: null,
		description: null,
		language_id: null,
		status: null,
	},
) => Http.post(`/pulse-sources/store`, params)

//UPDATE SOURCE
export const updateSource = (
	params = {
		name: null,
		url: null,
		website: null,
		description: null,
		language_id: null,
		status: null,
		sourceId: null,
	},
) => Http.put(`/pulse-sources/${params.sourceId}/update`, params)

//DELETE SOURCE
export const deleteSource = (
	params = {
		sourceId: null,
	},
) => Http.delete(`/pulse-sources/${params.sourceId}/destroy`)
