import {
	profileByID,
	profileCreate,
	profileDestroy,
	profilesList,
	profileUpdate
} from '@/services/ai/profiles.service'

export default {
	namespaced: true,
	state: {
		// PROFILES
		profilesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		profile: {},

		// STATUS PROFILES
		profilesStatus: 'pending',
		profilesSaveStatus: 'init',
		profilesDeleteStatus: 'init',
	},
	getters: {
		// PROFILES
		getProfilesList(state) {
			return state.profilesList
		},
		getProfile(state) {
			return state.profile
		},

		// STATUS PROFILES
		getProfilesDeleteStatus(state) {
			return state.profilesDeleteStatus
		},
		getProfilesSaveStatus(state) {
			return state.profilesSaveStatus
		},
		getProfilesStatus(state) {
			return state.profilesStatus
		},
	},
	mutations: {
		// PROFILES
		PROFILES_LIST(state, value) {
			state.profilesList = value
		},
		SET_PROFILE(state, value) {
			state.profile = value
		},

		// STATUS PROFILES
		PROFILES_STATUS(state, value) {
			state.profilesStatus = value
		},
		PROFILE_SAVE_STATUS(state, value) {
			state.profilesSaveStatus = value
		},
		PROFILE_DELETE_STATUS(state, value) {
			state.profilesDeleteStatus = value
		},
	},
	actions: {
		// PROFILES
		async fetchProfileList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('PROFILES_STATUS', 'pending')
			commit('PROFILES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await profilesList(params)
				commit('PROFILES_STATUS', 'init')
				commit('PROFILES_LIST', data)
				return data
			} catch (error) {
				commit('PROFILES_STATUS', 'init')
				throw error
			}
		},
		async fetchProfileByID({ commit }, params) {
			commit('PROFILES_STATUS', 'pending')
			try {
				const { data } = await profileByID(params)
				commit('PROFILES_STATUS', 'init')
				commit('SET_PROFILE', data.data)
				return data
			} catch (error) {
				commit('PROFILES_STATUS', 'init')

				return error
			}
		},
		async saveNewProfile({ commit }, params) {
			commit('PROFILE_SAVE_STATUS', 'pending')
			try {
				const { data } = await profileCreate(params)
				commit('PROFILE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PROFILE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateProfile({ commit }, params) {
			commit('PROFILE_SAVE_STATUS', 'pending')
			try {
				const { data } = await profileUpdate(params)
				commit('PROFILE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PROFILE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteProfileByID({ commit }, params) {
			commit('PROFILE_DELETE_STATUS', 'pending')
			try {
				const { data } = await profileDestroy(params)
				commit('PROFILE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PROFILE_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
