const quizzes = [
	{
		path: '/community/quizzes/list',
		name: 'community-contents-quizzes-list',
		component: () => import('@/views/community/quizzes/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/quizzes/new',
		name: 'community-contents-quizzes-create',
		component: () => import('@/views/community/quizzes/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/quizzes/questions/list/:id',
		name: 'community-contents-quizzes-questions-list',
		component: () => import('@/views/community/quizzes/questions/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/quizzes/edit/:id',
		name: 'community-contents-quizzes-edit',
		component: () => import(/* webpackChunkName: "NetworkCareersEdit" */ '@/views/community/quizzes/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/quizzes/questions/new/:quizId',
		name: 'community-contents-quizzes-questions-create',
		component: () => import('@/views/community/quizzes/questions/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/quizzes/questions/edit/:id',
		name: 'community-contents-quizzes-questions-edit',
		component: () =>
			import(/* webpackChunkName: "NetworkCareersEdit" */ '@/views/community/quizzes/questions/new.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default quizzes
