const reports = [
	{
		path: '/community/reports/list',
		name: 'community-reports-list',
		component: () => import('@/views/community/reports/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/reports/categories/list',
		name: 'community-reports-categories-list',
		component: () => import('@/views/community/reports/categories/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/reports/subcategories/list',
		name: 'community-reports-subcategories-list',
		component: () => import('@/views/community/reports/subcategories/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default reports
