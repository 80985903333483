import Http from '@/services/httpCommunity'

export const getNotifications = (
	params = {
		per_page: 10,
		page: 1,
		'filter[language_id]': '',
	},
) => Http.get(`/notifications/fetch${serialize(params)}`)

export const getStats = (
	params = {
		language_id: '',
	 
	},
) =>
	Http.get(
		`notifications/stats?filters[language_id]=${params.language_id}`,
	)

export const getStatsLanguageEnglish = (
	params = {
		language_id: 1,
	 
	},
) =>
	Http.get(
		`notifications/stats?filters[language_id]=${params.language_id}`,
	)

export const getStatsLanguageDeutsch = (
	params = {
		language_id: 2,
	 
	},
) =>
	Http.get(
		`notifications/stats?filters[language_id]=${params.language_id}`,
	)

export const getStatsLanguagePortuguese = (
	params = {
		language_id: 3,
	 
	},
) =>
	Http.get(
		`notifications/stats?filters[language_id]=${params.language_id}`,
	)

export const getStatsLanguageSpanish = (
	params = {
		language_id: 4,
	 
	},
) =>
	Http.get(
		`notifications/stats?filters[language_id]=${params.language_id}`,
	)

export const getStatsLanguageRomanian = (
	params = {
		language_id: 5,
	 
	},
) =>
	Http.get(
		`notifications/stats?filters[language_id]=${params.language_id}`,
	)

export const removeNotification = params => {
	return Http.delete(`/creator-mode/notifications/${params.slug}`, { baseURL: 'locale' })
}
