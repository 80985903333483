const settings = [
	{
		path: '/network/socialite-providers/list',
		name: 'network-socialite-providers-list',
		component: () => import('@/views/network/socialite-providers/list.vue'),
		meta: {
			layout: 'content',
		},
	},

]

export default settings
