import {
	companiesByID,
	companiesList,
	deleteCompanyByID,
	newCompany,
	sizeByID,
	sizeCreate,
	sizeDestroy,
	sizeUpdate,
	sizesList,
	stageByID,
	stageCreate,
	stageDestroy,
	stageUpdate,
	stagesList,
	typeByID,
	typeCreate,
	typeDestroy,
	typeUpdate,
	typesList,
	updateCompany,
} from '@/services/companies.service'

export default {
	namespaced: true,
	state: {
		// COMPANIES TYPES
		typesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		type: {},
		// STATUS COMPANIES TYPES
		typesStatus: 'pending',
		typesSaveStatus: 'init',
		typesDeleteStatus: 'init',

		// COMPANIES
		companiesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		company: {},
		// STATUS
		companiesStatus: 'init',
		companiesSaveStatus: 'init',
		companiesDeleteStatus: 'init',

		// COMPANIES SIZES
		sizesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		size: {},
		// STATUS COMPANIES SIZES
		sizesStatus: 'pending',
		sizesSaveStatus: 'init',
		sizesDeleteStatus: 'init',

		// COMPANIES STAGES
		stagesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		stage: {},
		// STATUS COMPANIES STAGES
		stagesStatus: 'pending',
		stagesSaveStatus: 'init',
		stagesDeleteStatus: 'init',
	},
	getters: {
		// COMPANIES TYPES
		getTypesList(state) {
			return state.typesList
		},
		getType(state) {
			return state.type
		},
		// COMPANIES TYPES
		getTypesDeleteStatus(state) {
			return state.typesDeleteStatus
		},
		getTypesSaveStatus(state) {
			return state.typesSaveStatus
		},
		getTypesStatus(state) {
			return state.typesStatus
		},

		// COMPANIES
		getCompaniesList(state) {
			return state.companiesList
		},
		getCompany(state) {
			return state.company
		},
		// STATUS
		getCompaniesDeleteStatus(state) {
			return state.companiesDeleteStatus
		},
		getCompaniesSaveStatus(state) {
			return state.companiesSaveStatus
		},
		getCompaniesStatus(state) {
			return state.companiesStatus
		},

		// COMPANIES SIZES
		getSizesList(state) {
			return state.sizesList
		},
		getSize(state) {
			return state.size
		},
		// COMPANIES SIZES
		getSizesDeleteStatus(state) {
			return state.sizesDeleteStatus
		},
		getSizesSaveStatus(state) {
			return state.sizesSaveStatus
		},
		getSizesStatus(state) {
			return state.sizesStatus
		},

		// COMPANIES STAGES
		getStagesList(state) {
			return state.stagesList
		},
		getStage(state) {
			return state.stage
		},
		// COMPANIES STAGES
		getStagesDeleteStatus(state) {
			return state.stagesDeleteStatus
		},
		getStagesSaveStatus(state) {
			return state.stagesSaveStatus
		},
		getStagesStatus(state) {
			return state.stagesStatus
		},
	},
	mutations: {
		// COMPANIES TYPES
		TYPES_LIST(state, value) {
			state.typesList = value
		},
		SET_TYPE(state, value) {
			state.type = value
		},
		// STATUS COMPANIES TYPES
		TYPES_STATUS(state, value) {
			state.typesStatus = value
		},
		TYPE_SAVE_STATUS(state, value) {
			state.typesSaveStatus = value
		},
		TYPE_DELETE_STATUS(state, value) {
			state.typesDeleteStatus = value
		},

		// COMPANIES
		COMPANIES_LIST(state, value) {
			state.companiesList = value
		},
		SET_COMPANY(state, value) {
			state.company = value
		},
		// STATUS
		COMPANIES_STATUS(state, value) {
			state.companiesStatus = value
		},
		COMPANIES_SAVE_STATUS(state, value) {
			state.companiesSaveStatus = value
		},
		COMPANIES_DELETE_STATUS(state, value) {
			state.companiesDeleteStatus = value
		},

		// COMPANIES SIZES
		SIZES_LIST(state, value) {
			state.sizesList = value
		},
		SET_SIZE(state, value) {
			state.size = value
		},
		// STATUS COMPANIES SIZES
		SIZES_STATUS(state, value) {
			state.sizesStatus = value
		},
		SIZE_SAVE_STATUS(state, value) {
			state.sizesSaveStatus = value
		},
		SIZE_DELETE_STATUS(state, value) {
			state.sizesDeleteStatus = value
		},

		// COMPANIES STAGES
		STAGES_LIST(state, value) {
			state.stagesList = value
		},
		SET_STAGE(state, value) {
			state.stage = value
		},
		// STATUS COMPANIES STAGES
		STAGES_STATUS(state, value) {
			state.stagesStatus = value
		},
		STAGE_SAVE_STATUS(state, value) {
			state.stagesSaveStatus = value
		},
		STAGE_DELETE_STATUS(state, value) {
			state.stagesDeleteStatus = value
		},
	},
	actions: {
		// Companies Types
		async fetchTypeList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('TYPES_STATUS', 'pending')
			commit('TYPES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await typesList(params)
				commit('TYPES_STATUS', 'init')
				commit('TYPES_LIST', data)
				return data
			} catch (error) {
				commit('TYPES_STATUS', 'init')
				throw error
			}
		},
		async fetchTypeByID({ commit }, params) {
			commit('TYPES_STATUS', 'pending')
			try {
				const { data } = await typeByID(params)
				commit('TYPES_STATUS', 'init')
				commit('SET_TYPE', data.data)
				return data
			} catch (error) {
				commit('TYPES_STATUS', 'init')

				return error
			}
		},
		async saveNewType({ commit }, params) {
			commit('TYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await typeCreate(params)
				commit('TYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateType({ commit }, params) {
			commit('TYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await typeUpdate(params)
				commit('TYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteTypeByID({ commit }, params) {
			commit('TYPE_DELETE_STATUS', 'pending')
			try {
				const { data } = await typeDestroy(params)
				commit('TYPE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_DELETE_STATUS', 'error')

				return error
			}
		},

		// Companies Sizes
		async fetchSizeList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SIZES_STATUS', 'pending')
			commit('SIZES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await sizesList(params)
				commit('SIZES_STATUS', 'init')
				commit('SIZES_LIST', data)
				return data
			} catch (error) {
				commit('SIZES_STATUS', 'init')
				throw error
			}
		},
		async fetchSizeByID({ commit }, params) {
			commit('SIZES_STATUS', 'pending')
			try {
				const { data } = await sizeByID(params)
				commit('SIZES_STATUS', 'init')
				commit('SET_SIZE', data.data)
				return data
			} catch (error) {
				commit('SIZES_STATUS', 'init')

				return error
			}
		},
		async saveNewSize({ commit }, params) {
			commit('SIZE_SAVE_STATUS', 'pending')
			try {
				const { data } = await sizeCreate(params)
				commit('SIZE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SIZE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateSize({ commit }, params) {
			commit('SIZE_SAVE_STATUS', 'pending')
			try {
				const { data } = await sizeUpdate(params)
				commit('SIZE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SIZE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteSizeByID({ commit }, params) {
			commit('SIZE_DELETE_STATUS', 'pending')
			try {
				const { data } = await sizeDestroy(params)
				commit('SIZE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SIZE_DELETE_STATUS', 'error')

				return error
			}
		},

		// Companies Stages
		async fetchStageList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('STAGES_STATUS', 'pending')
			commit('STAGES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await stagesList(params)
				commit('STAGES_STATUS', 'init')
				commit('STAGES_LIST', data)
				return data
			} catch (error) {
				commit('STAGES_STATUS', 'init')
				throw error
			}
		},
		async fetchStageByID({ commit }, params) {
			commit('STAGES_STATUS', 'pending')
			try {
				const { data } = await stageByID(params)
				commit('STAGES_STATUS', 'init')
				commit('SET_STAGE', data.data)
				return data
			} catch (error) {
				commit('STAGES_STATUS', 'init')

				return error
			}
		},
		async saveNewStage({ commit }, params) {
			commit('STAGE_SAVE_STATUS', 'pending')
			try {
				const { data } = await stageCreate(params)
				commit('STAGE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STAGE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateStage({ commit }, params) {
			commit('STAGE_SAVE_STATUS', 'pending')
			try {
				const { data } = await stageUpdate(params)
				commit('STAGE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STAGE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteStageByID({ commit }, params) {
			commit('STAGE_DELETE_STATUS', 'pending')
			try {
				const { data } = await stageDestroy(params)
				commit('STAGE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STAGE_DELETE_STATUS', 'error')

				return error
			}
		},

		// Companies
		async fetchCompaniesList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('COMPANIES_STATUS', 'pending')
			commit('COMPANIES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await companiesList(params)
				commit('COMPANIES_STATUS', 'init')
				commit('COMPANIES_LIST', data)
				return data
			} catch (error) {
				commit('COMPANIES_STATUS', 'init')
				throw error
			}
		},
		async fetchCompanyByID({ state, commit }, params) {
			commit('COMPANIES_STATUS', 'pending')
			try {
				const { data } = await companiesByID(params)
				commit('COMPANIES_STATUS', 'init')
				commit('SET_COMPANY', data.data)
				return data
			} catch (error) {
				commit('COMPANIES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewCompany({ commit }, params) {
			commit('COMPANIES_SAVE_STATUS', 'pending')
			try {
				const { data } = await newCompany(params)
				commit('COMPANIES_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('COMPANIES_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCompany({ commit }, params) {
			commit('COMPANIES_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateCompany(params)
				commit('COMPANIES_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('COMPANIES_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCompanyByID({ state, commit }, params) {
			commit('COMPANIES_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteCompanyByID(params)
				commit('COMPANIES_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('COMPANIES_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
