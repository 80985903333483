<template>
	<div class="vertical-nav-menu-container" @mouseenter="isMouseHovered = true" @mouseleave="isMouseHovered = false">
		<slot name="v-nav-menu-header">
			<vertical-nav-menu-header @close-nav-menu="$emit('close-nav-menu')"> </vertical-nav-menu-header>
		</slot>

		<v-select
			v-model="contextSelected"
			hide-details
			:items="itemsMenu"
			filled
			label="Change Platform"
			item-text="name"
			item-value="context"
			return-object
			@change="updatePlatorm"
		/>

		<!-- Shadow -->
		<div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

		<perfect-scrollbar
			v-if="navMenu != null"
			class="ps-nav-menu-items"
			:options="perfectScrollbarOptions"
			@ps-scroll-y="
				evt => {
					shallShadowBottom = evt.srcElement.scrollTop > 0
				}
			"
		>
			<v-list expand shaped class="vertical-nav-menu-items">
				<component
					:is="resolveNavItemComponent(item)"
					v-for="item in navMenu"
					:key="item.subheader || item.title"
					:item="item"
				/>
			</v-list>
		</perfect-scrollbar>
	</div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
// Composable
import useVerticalNavMenu from '@/@core/layouts/composable/vertical-nav/useVerticalNavMenu'
import navMenuNetwork from '@/navigation/vertical'
import navMenuAcademy from '@/navigation/vertical/academy'
import navMenuAi from '@/navigation/vertical/ai'
import navMenuAgency from '@/navigation/vertical/agency'
import navMenuClub from '@/navigation/vertical/club'
import navMenuCommunity from '@/navigation/vertical/community'
import navMenuJobs from '@/navigation/vertical/jobs'
import navMenuShop from '@/navigation/vertical/shop'
import navMenuToken from '@/navigation/vertical/token'
import navMenuTV from '@/navigation/vertical/tv'
import VerticalNavMenuGroup from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-group/VerticalNavMenuGroup.vue'
// SFCs
import VerticalNavMenuHeader from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-header/VerticalNavMenuHeader.vue'
import VerticalNavMenuLink from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuSectionTitle from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-section-title/VerticalNavMenuSectionTitle.vue'
import { provide, ref } from 'vue'
// 3rd Party
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { mapGetters, mapActions } from 'vuex'

export default {
	components: {
		VerticalNavMenuHeader,
		VerticalNavMenuGroup,
		VerticalNavMenuLink,
		VerticalNavMenuSectionTitle,

		// 3rd Party
		PerfectScrollbar,
	},
	props: {
		// navMenuItems: {
		// 	type: Array,
		// 	required: true,
		// },
	},
	setup() {
		const { resolveNavItemComponent, isMouseHovered } = useVerticalNavMenu()
		provide('isMouseHovered', isMouseHovered)

		const perfectScrollbarOptions = {
			maxScrollbarLength: 10,
			wheelPropagation: false,
			scrollYMarginOffset: 0,
		}

		// Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
		const shallShadowBottom = ref(false)

		provide('openGroups', ref([]))

		return {
			resolveNavItemComponent,
			perfectScrollbarOptions,
			isMouseHovered,
			shallShadowBottom,
			// itemsMenu,
		}
	},
	data() {
		return {
			contextSelected: '',
			navMenu: null,
			itemsMenu: [],
			userData: {},
		}
	},
	watch: {
		networkSystems: {
			async handler() {
        	    this.itemsMenu = []

        	    this.networkSystems.forEach(system => {
					const cleanedName = system.name.replace('Mundo Gamer', '').trim();
				
            		const newItem = {
            		    name: cleanedName,
            		    context: cleanedName.toLowerCase(),
            		    shortName: system.short_name,
            		};
            		this.itemsMenu.push(newItem);
        	    })
        	},
			deep: true,
		},
	},
	beforeRouteEnter(to, from) {},
	methods: {
		...mapActions({
			// NETWORK SYSTEMS
			fetchNetworkSystems: 'navMenu/fetchNetworkSystems',
		}),
		async changeClassContext(selected) {
			document.body.removeAttribute('class')
			document.body.classList.add(`selected-context-${selected}`)
		},
		async updatePlatorm(selected, reload = false) {
			this.navMenu = null
			/*
				TODO: IMPROVE THE METHOD TO CHANGE PLATFORM
			*/
			var r = document.querySelector(':root')
			let context
			// VERIFY THE TYPE OF SELECTED ITEM TO DEFINE THE DATA
			if (typeof selected === 'string') {
				this.contextSelected = selected
				this.changeClassContext(selected)
				context = selected
				localStorage.selectedPlatform = selected
			}

			if (typeof selected === 'object') {
				this.contextSelected = selected.context
				context = selected.context
				this.changeClassContext(selected.context)
				localStorage.selectedPlatform = selected.context
				localStorage.selectedPlatformShortName = selected.shortName
			}
			// SWITCH CASE TO CHANGE THE OBJECT FROM navMenu BASED ON IMPORTED ITEMS FROM @/navigation/vertical/ FOLDERS
			/*
				TODO: IMPROVE THE navMenu IMPORTS
			*/
			switch (context) {
				case 'academy':
					this.$nextTick(() => {
						this.navMenu = navMenuAcademy
					})
					if (reload === false) this.$router.push({ name: 'academy-dashboard' }).catch()
					r.style.setProperty('--v-anchor-base', '#f70')
					r.style.setProperty('--v-primary-base', '#f70')
					break
				case 'agency':
					this.$nextTick(() => {
						this.navMenu = navMenuAgency
					})
					if (reload === false) this.$router.push({ name: 'agency-dashboard' }).catch()
					r.style.setProperty('--v-anchor-base', '#febb1b')
					r.style.setProperty('--v-primary-base', '#febb1b')
					break
				case 'ai':
					this.$nextTick(() => {
						this.navMenu = navMenuAi
					})
					if (reload === false) this.$router.push({ name: 'ai-dashboard' }).catch()
					r.style.setProperty('--v-anchor-base', '#51a0ff')
					r.style.setProperty('--v-primary-base', '#51a0ff')
					break
				case 'club':
					this.$nextTick(() => {
						this.navMenu = navMenuClub
					})
					if (reload === false) this.$router.push({ name: 'club-dashboard' }).catch()
					r.style.setProperty('--v-anchor-base', '#573cef')
					r.style.setProperty('--v-primary-base', '#573cef')
					break
				case 'community':
					this.$nextTick(() => {
						this.navMenu = navMenuCommunity
					})
					if (reload === false) this.$router.push({ name: 'community-dashboard' }).catch()
					r.style.setProperty('--v-anchor-base', '#3970b3')
					r.style.setProperty('--v-primary-base', '#3970b3')
					break
				case 'exchange':
					this.$nextTick(() => {
						this.navMenu = {}
					})
					// this.$router.push({ name: 'academy-dashboard' })
					r.style.setProperty('--v-anchor-base', '#800080')
					r.style.setProperty('--v-primary-base', '#800080')
					break
				case 'jobs':
					this.$nextTick(() => {
						this.navMenu = navMenuJobs
					})
					if (reload === false) this.$router.push({ name: 'jobs-dashboard' }).catch()
					r.style.setProperty('--v-anchor-base', '#8a8a8a')
					r.style.setProperty('--v-primary-base', '#8a8a8a')
					break
				case 'network':
					this.$nextTick(() => {
						this.navMenu = navMenuNetwork
					})
					r.style.setProperty('--v-anchor-base', '#ee3831')
					r.style.setProperty('--v-primary-base', '#ee3831')
					if (reload === false) this.$router.push({ name: 'network-dashboard' }).catch()
					break
				case 'shop':
					this.$nextTick(() => {
						this.navMenu = navMenuShop
					})
					if (reload === false) this.$router.push({ name: 'shop-dashboard' }).catch()
					r.style.setProperty('--v-anchor-base', '#43b581')
					r.style.setProperty('--v-primary-base', '#43b581')
					break
				case 'token':
					this.$nextTick(() => {
						this.navMenu = navMenuToken
					})
					if (reload === false) this.$router.push({ name: 'token-dashboard' }).catch()
					r.style.setProperty('--v-anchor-base', '#ee3831')
					r.style.setProperty('--v-primary-base', '#ee3831')
					break
				case 'tv':
					this.$nextTick(() => {
						this.navMenu = navMenuTV
					})
					if (reload === false) this.$router.push({ name: 'tv-dashboard' }).catch()
					r.style.setProperty('--v-anchor-base', '#6b15ab')
					r.style.setProperty('--v-primary-base', '#6b15ab')
					break
				default:
					this.$nextTick(() => {
						this.navMenu = navMenuNetwork
					})
					if (reload === false) this.$router.push({ name: 'network-dashboard' }).catch()
					r.style.setProperty('--v-anchor-base', '#ee3831')
					r.style.setProperty('--v-primary-base', '#ee3831')
					this.navMenu = navMenuNetwork
					break
			}
		},
	},
	computed: {
		...mapGetters({
			// NETWORK SYSTEMS
			networkSystems: 'navMenu/networkSystems',
		}),
	},
	beforeMount() {
		if (!localStorage.selectedPlatform) {
			localStorage.selectedPlatform = 'network'
			localStorage.selectedPlatformShortName = 'MGN'
		}
	},
	async mounted() {
		this.updatePlatorm(localStorage.selectedPlatform, true)
		this.userData = await this.$authService.getUser()
		await this.fetchNetworkSystems({'filter[permission]': 1})
	},
}
</script>

<style lang="scss">
@import '~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
</style>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
@import '~@core/preset/preset/mixins.scss';

.app-navigation-menu {
	transition-duration: 0.4s !important;
	transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1), ease !important;
	will-change: box-shadow, transform !important;

	.v-list-item,
	.v-subheader {
		padding: 0 18px 0 22px;
	}

	.v-list-item {
		margin-top: 0.375rem;
	}

	.v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
		min-width: 24px;
	}

	.v-navigation-drawer__border {
		background-color: transparent !important;
	}

	// &.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover {
	//   @include elevation(9);
	//   @include elevationTransition();
	// }
}

@include app-elevation('app-navigation-menu.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover', 9);

// Set Perfect Scrollbar Container Height
.vertical-nav-menu-container {
	height: 100%;
	.ps-nav-menu-items {
		height: calc(100% - 57px) !important;
	}

	// menu scroll shadow
	.shadow-bottom {
		display: none;
		position: absolute;
		z-index: 2;
		height: 50px;
		width: 100%;
		left: -6px;
		pointer-events: none;
		margin-top: -0.7rem;
		filter: blur(5px);
	}
}

@include theme--child(vertical-nav-menu-container) using ($material) {
	.shadow-bottom {
		background: linear-gradient(
			map-deep-get($material, 'background') 40%,
			rgba(map-deep-get($material, 'background'), 0.1) 95%,
			rgba(map-deep-get($material, 'background'), 0.05)
		);
		margin-top: -0.5rem;
		filter: blur(4px);
	}
}

@include theme(vertical-nav-menu-items) using ($material) {
	@include ltr() {
		padding-right: 20px !important;
	}
	@include rtl() {
		padding-left: 20px !important;
	}
	@at-root .app-navigation-menu.v-navigation-drawer--mini-variant:not(.v-navigation-drawer--is-mouseover) {
		.vertical-nav-menu-items {
			@include ltr() {
				padding-right: 5px !important;
			}
			@include rtl() {
				padding-left: 5px !important;
			}
		}
	}

	.v-subheader:not(:first-child) {
		margin-top: 18px;
	}

	.v-list-item__icon {
		&:first-child {
			@include ltr() {
				margin: 0 12px 0 0;
			}
			@include rtl() {
				margin: 0 0 0 12px;
			}
			align-self: center;
		}
		&.v-list-group__header__append-icon {
			@include ltr() {
				margin-left: 4px;
			}
			@include rtl() {
				margin-right: 4px;
			}
		}
	}

	// Center align small icon
	.v-list-group__items .v-list-item__icon {
		margin-top: 0px;
		@include ltr() {
			margin-left: 5px;
		}
		@include rtl() {
			margin-right: 5px;
		}
	}

	.v-list-item {
		@include app-states($material);

		height: 44px;
		min-height: 44px;
		color: map-deep-get($material, 'text', 'primary');
		// ? Reason: In collapsed mode due to modified mini width & flex grow icon change position while collapsing the drawer
		justify-content: flex-start !important;

		&.v-list-item--active {
			&:not(.v-list-group__header) {
				color: white;
				// background-image: linear-gradient(98deg, #c48eff, var(--v-primary-base) 94%);
			}
			&.v-list-group__header {
				background-color: rgba(
					map-deep-get($material, 'text', 'primary'),
					map-deep-get($material, 'states', 'selected')
				);
			}
		}

		&.vertical-nav-menu-link {
			.v-list-item__icon {
				.v-icon {
					transition: none !important;
				}
			}
		}
	}
}

.vertical-nav-menu-container .ps-nav-menu-items {
	height: calc(100% - 16vh) !important;
}
</style>
