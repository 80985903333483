import { resumes } from '@/services/jobs/resumes.service'

export default {
	namespaced: true,
	state: {
		// resumeFranchise
		resumes: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		resume: {},
		resumesStatus: 'init',
	},
	getters: {
		// resumeFranchise
		resumes(state) {
			return state.resumes
		},
		resume(state) {
			return state.resume
		},
		resumesStatus(state) {
			return state.resumesStatus
		},
	},
	mutations: {
		// resumeFranchise
		SET_RESUMES(state, val) {
			state.resumes = val
		},
		SET_RESUME(state, val) {
			state.resume = val
		},
		SET_RESUMES_STATUS(state, val) {
			state.resumesStatus = val
		},
	},
	actions: {
		// resumeFranchise
		async fetchResumes({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_RESUMES_STATUS', 'pending')
			commit('SET_RESUMES', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await resumes(params)
				commit('SET_RESUMES_STATUS', 'init')
				commit('SET_RESUMES', data)
				return data
			} catch (error) {
				commit('SET_RESUMES_STATUS', 'init')
				throw error
			}
		},

		async fetchResume({ commit }, params) {
			commit('SET_RESUMES_STATUS', 'pending')
			try {
				const { data } = await resumes(params)
				commit('SET_RESUME', data)
				commit('SET_RESUMES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_RESUMES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
	},
}
