import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpAcademy'

// LIST ALL LEVELS
export const levelList = (params = '') => Http.get(`/course-levels${serialize(params)}`)

export const levelCreate = (params = '') => Http.post('/course-levels', params)
export const levelByID = (params = '') => Http.get(`/course-levels/${params}`)
export const levelUpdate = (params = '') => Http.put(`/course-levels/${params.id}`, params)
export const levelDestroy = (params = '') => Http.delete(`/course-levels/${params.id}`)
