import {
	applicationByID,
	applicationCreate,
	applicationDestroy,
	applicationList,
	applicationUpdate,
} from '@/services/tv/applications.service'

export default {
	namespaced: true,
	state: {
		// SERVICE APPLICATION
		applicationList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		application: {},

		// STATUS SERVICE APPLICATION
		applicationStatus: 'pending',
		applicationSaveStatus: 'init',
		applicationDeleteStatus: 'init',
	},
	getters: {
		// SERVICE APPLICATION
		getApplicationList(state) {
			return state.applicationList
		},
		getApplication(state) {
			return state.application
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE APPLICATION
		getApplicationDeleteStatus(state) {
			return state.applicationDeleteStatus
		},
		getApplicationSaveStatus(state) {
			return state.applicationSaveStatus
		},
		getApplicationStatus(state) {
			return state.applicationStatus
		},
	},
	mutations: {
		// SERVICE APPLICATION
		APPLICATION_LIST(state, value) {
			state.applicationList = value
		},
		SET_APPLICATION(state, value) {
			state.application = value
		},

		// STATUS SERVICE APPLICATION
		APPLICATION_STATUS(state, value) {
			state.applicationStatus = value
		},
		APPLICATION_SAVE_STATUS(state, value) {
			state.applicationSaveStatus = value
		},
		APPLICATION_DELETE_STATUS(state, value) {
			state.applicationDeleteStatus = value
		},
	},
	actions: {
		// Service Application
		async fetchApplicationList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('APPLICATION_STATUS', 'pending')
			commit('APPLICATION_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await applicationList(params)
				commit('APPLICATION_STATUS', 'init')
				commit('APPLICATION_LIST', data)
				return data
			} catch (error) {
				commit('APPLICATION_STATUS', 'init')
				throw error
			}
		},
		async fetchApplicationByID({ commit }, params) {
			commit('APPLICATION_STATUS', 'pending')
			try {
				const { data } = await applicationByID(params)
				commit('APPLICATION_STATUS', 'init')
				commit('SET_APPLICATION', data.data)
				return data
			} catch (error) {
				commit('APPLICATION_STATUS', 'init')

				return error
			}
		},
		async saveNewApplication({ commit }, params) {
			commit('APPLICATION_SAVE_STATUS', 'pending')
			try {
				const { data } = await applicationCreate(params)
				commit('APPLICATION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('APPLICATION_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateApplication({ commit }, params) {
			commit('APPLICATION_SAVE_STATUS', 'pending')
			try {
				const { data } = await applicationUpdate(params)
				commit('APPLICATION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('APPLICATION_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteApplicationByID({ commit }, params) {
			commit('APPLICATION_DELETE_STATUS', 'pending')
			try {
				const { data } = await applicationDestroy(params)
				commit('APPLICATION_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('APPLICATION_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
