import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

//Banks
export const bankCreate = (params = '') => Http.post('/financial-institution-banks', params)
export const banksList = (params = '') => Http.get(`/financial-institution-banks${serialize(params)}`)
export const allBanksList = () => Http.get(`/financial-institution-banks/?per_page=all`)
export const bankByID = (params = '') => Http.get(`/financial-institution-banks/${params}`)
export const bankUpdate = (params = '') => Http.put(`/financial-institution-banks/${params.id}`, params)
export const bankDestroy = (params = '') => Http.delete(`/financial-institution-banks/${params.id}`)

//Cupons
export const cupomCreate = (params = '') => Http.post('/discount-coupons', params)
export const cuponsList = (params = '') => Http.get(`/discount-coupons${serialize(params)}`)
export const cupomByID = (params = '') => Http.get(`/discount-coupons/${params}`)
export const cupomUpdate = (params = '') => Http.put(`/discount-coupons/${params.id}`, params)
export const cupomDestroy = (params = '') => Http.delete(`/discount-coupons/${params.id}`)

//Countries Currencies
export const currenciesList = (params = '') => Http.get(`/countries-currencies${serialize(params)}`)
export const currencyCreate = (params = '') => Http.post('/countries-currencies', params)
export const currencyByID = (params = '') => Http.get(`/countries-currencies/${params}`)
export const currencyUpdate = (params = '') => Http.put(`/countries-currencies/${params.id}`, params)
export const currencyDestroy = (params = '') => Http.delete(`/countries-currencies/${params.id}`)

export const categoryCreate = (params = '') => Http.post('/payment-expenses-categories', params)
export const categoriesList = (params = '') => Http.get(`/payment-expenses-categories${serialize(params)}`)
export const categoryByID = (params = '') => Http.get(`/payment-expenses-categories/${params}`)
export const categoryUpdate = (params = '') => Http.put(`/payment-expenses-categories/${params.id}`, params)
export const categoryDestroy = (params = '') => Http.delete(`/payment-expenses-categories/${params.id}`)

//Payment Methods
export const methodsList = (params = '') => Http.get(`/payment-methods${serialize(params)}`)
export const methodCreate = (params = '') => Http.post('/payment-methods', params)
export const methodByID = (params = '') => Http.get(`/payment-methods/${params}`)
export const methodUpdate = (params = '') => Http.put(`/payment-methods/${params.id}`, params)
export const methodDestroy = (params = '') => Http.delete(`/payment-methods/${params.id}`)

//Payment Periods
export const periodCreate = (params = '') => Http.post('/payment-periods', params)
export const periodsList = (params = '') => Http.get(`/payment-periods${serialize(params)}`)
export const periodByID = (params = '') => Http.get(`/payment-periods/${params}`)
export const periodUpdate = (params = '') => Http.put(`/payment-periods/${params.id}`, params)
export const periodDestroy = (params = '') => Http.delete(`/payment-periods/${params.id}`)

//Payment Fees
export const feeCreate = (params = '') => Http.post('/payment-billing-fees', params)
export const feesList = (params = '') => Http.get(`/payment-billing-fees${serialize(params)}`)
export const feeByID = (params = '') => Http.get(`/payment-billing-fees/${params}`)
export const feeUpdate = (params = '') => Http.put(`/payment-billing-fees/${params.id}`, params)
export const feeDestroy = (params = '') => Http.delete(`/payment-billing-fees/${params.id}`)

//Payment Refunds
export const refundCreate = (params = '') => Http.post('/payment-refund-periods', params)
export const refundsList = (params = '') => Http.get(`/payment-refund-periods${serialize(params)}`)
export const refundByID = (params = '') => Http.get(`/payment-refund-periods/${params}`)
export const refundUpdate = (params = '') => Http.put(`/payment-refund-periods/${params.id}`, params)
export const refundDestroy = (params = '') => Http.delete(`/payment-refund-periods/${params.id}`)

//Payment Taxes
export const taxCreate = (params = '') => Http.post('/taxes', params)
export const taxesList = (params = '') => Http.get(`/taxes${serialize(params)}`)
export const taxByID = (params = '') => Http.get(`/taxes/${params}`)
export const taxUpdate = (params = '') => Http.put(`/taxes/${params.id}`, params)

export const typeCreate = (params = '') => Http.post('/payment-types', params)
export const typesList = (params = '') => Http.get(`/payment-types${serialize(params)}`)
export const typeByID = (params = '') => Http.get(`/payment-types/${params}`)
export const typeUpdate = (params = '') => Http.put(`/payment-types/${params.id}`, params)
export const typeDestroy = (params = '') => Http.delete(`/payment-types/${params.id}`)

export const statusCreate = (params = '') => Http.post('/payment-status', params)
export const statusList = (params = '') => Http.get(`/payment-status${serialize(params)}`)
export const statusByID = (params = '') => Http.get(`/payment-status/${params}`)
export const statusUpdate = (params = '') => Http.put(`/payment-status/${params.id}`, params)
export const statusDestroy = (params = '') => Http.delete(`/payment-status/${params.id}`)
