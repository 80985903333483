import { 
    listMarketings, listMarketingProviders, marketingByID
} from '@/services/community/marketings/index'

export default {
    namespaced: true,
    state: {
        marketings: {
			meta: {
				pagination: {
					total: 0
				}
			}
		},
		marketing: {},
		marketingsStatus: 'init',
        marketingProviders: {
			meta: {
				pagination: {
					total: 0
				}
			}
		},
		marketingProvidersStatus: 'init',
    },
    getters: {
        getMarketings(state) {
			return state.marketings
		},
		getMarketing(state) {
			return state.marketing
		},
		getMarketingsStatus(state) {
			return state.marketingsStatus
		},
        getMarketingProviders(state) {
			return state.marketingProviders
		},
		getMarketingProvidersStatus(state) {
			return state.marketingProvidersStatus
		},
    },
    mutations: {
        SET_MARKETINGS(state, value) {
			state.marketings = value
		},
		SET_MARKETING(state, value) {
			state.marketing = value
		},
		SET_MARKETINGS_STATUS(state, value) {
			state.marketingsStatus = value
		},
        SET_MARKETING_PROVIDERS(state, value) {
			state.marketingProviders = value
		},
		SET_MARKETING_PROVIDERS_STATUS(state, value) {
			state.marketingProvidersStatus = value
		},
    },
    actions: {
        async fetchMarketings({ commit }, params) {
			commit('SET_MARKETINGS_STATUS', 'pending')
			commit('SET_MARKETINGS', {
				meta: {
					pagination: {
						total: 0
					}
				}
			})
			try {
				const { data } = await listMarketings(params)
				commit('SET_MARKETINGS_STATUS', 'init')
				commit('SET_MARKETINGS', data)
				return data
			} catch (error) {
				commit('SET_MARKETINGS_STATUS', 'init')
				throw error
			}
		},
        async fetchMarketingProviders({ commit }, params) {
			commit('SET_MARKETING_PROVIDERS_STATUS', 'pending')
			commit('SET_MARKETING_PROVIDERS', {
				meta: {
					pagination: {
						total: 0
					}
				}
			})
			try {
				const { data } = await listMarketingProviders(params)
				commit('SET_MARKETING_PROVIDERS_STATUS', 'init')
				commit('SET_MARKETING_PROVIDERS', data)
				return data
			} catch (error) {
				commit('SET_MARKETING_PROVIDERS_STATUS', 'init')
				throw error
			}
		},
		async fetchMarketingByID({ commit }, params) {
			commit('SET_MARKETINGS_STATUS', 'pending')
			try {
				const { data } = await marketingByID(params)
				commit('SET_MARKETINGS_STATUS', 'init')
				commit('SET_MARKETING', data.data)
				return data
			} catch (error) {
				commit('SET_MARKETINGS_STATUS', 'init')

				return error
			}
		},
    },
}
