import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getRatings = params => Http.get(`/ratings/fetch${serialize(params)}`)

//UPDATE RATING
export const updateRating = params => Http.put(`/ratings/${params.id}/update`, params)

//DELETE RATING
export const deleteRating = (
	params = {
		ratingId: null,
	},
) => Http.delete(`/ratings/${params.ratingId}/destroy`)
