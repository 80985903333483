const accountBenefits = [
	{
		path: '/account-benefits/list',
		name: 'account-benefits-list',
		component: () => import('@/views/account-benefits/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default accountBenefits
