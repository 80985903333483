import {
	getStats,
	streamerByID,
	streamerCreate,
	streamerDestroy,
	streamersList,
	streamerStatusList,
	streamerUpdate,
} from '@/services/tv/streamers.service'

export default {
	namespaced: true,
	state: {
		// SERVICE STREAMERS
		streamersList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		streamer: {},

		// STATUS SERVICE STREAMERS
		streamersStatus: 'pending',
		streamersSaveStatus: 'init',
		streamersDeleteStatus: 'init',

		// STATS
		streamerStats: [],
		streamerStatsStatus: 'pending',
	},
	getters: {
		// SERVICE STREAMERS
		getStreamersList(state) {
			return state.streamersList
		},
		getStreamer(state) {
			return state.streamer
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE STREAMERS
		getStreamersDeleteStatus(state) {
			return state.streamersDeleteStatus
		},
		getStreamersSaveStatus(state) {
			return state.streamersSaveStatus
		},
		getStreamersStatus(state) {
			return state.streamersStatus
		},

		// STATS
		getStreamerStats(state) {
			return state.streamerStats
		},
		getStreamerStatsStatus(state) {
			return state.streamerStatsStatus
		},
	},
	mutations: {
		// SERVICE STREAMERS
		STREAMERS_LIST(state, value) {
			state.streamersList = value
		},
		SET_STREAMER(state, value) {
			state.streamer = value
		},

		// STATUS SERVICE STREAMERS
		STREAMERS_STATUS(state, value) {
			state.streamersStatus = value
		},
		STREAMER_SAVE_STATUS(state, value) {
			state.streamersSaveStatus = value
		},
		STREAMER_DELETE_STATUS(state, value) {
			state.streamersDeleteStatus = value
		},

		// STATS
		SET_STREAMER_STATS(state, value) {
			state.streamerStats = value
		},
		SET_STREAMER_STATS_STATUS(state, value) {
			state.streamerStatsStatus = value
		},
	},
	actions: {
		// Service Streamers
		async fetchStreamerList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('STREAMERS_STATUS', 'pending')
			commit('STREAMERS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await streamersList(params)
				commit('STREAMERS_STATUS', 'init')
				commit('STREAMERS_LIST', data)
				return data
			} catch (error) {
				commit('STREAMERS_STATUS', 'init')
				throw error
			}
		},
		async fetchStreamerByID({ commit }, params) {
			commit('STREAMERS_STATUS', 'pending')
			try {
				const { data } = await streamerByID(params)
				commit('STREAMERS_STATUS', 'init')
				commit('SET_STREAMER', data.data)
				return data
			} catch (error) {
				commit('STREAMERS_STATUS', 'init')

				return error
			}
		},
		async saveNewStreamer({ commit }, params) {
			commit('STREAMER_SAVE_STATUS', 'pending')
			try {
				const { data } = await streamerCreate(params)
				commit('STREAMER_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STREAMER_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateStreamer({ commit }, params) {
			commit('STREAMER_SAVE_STATUS', ' pending')
			try {
				const { data } = await streamerUpdate(params)
				commit('STREAMER_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STREAMER_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteStreamerByID({ commit }, params) {
			commit('STREAMER_DELETE_STATUS', 'pending')
			try {
				const { data } = await streamerDestroy(params)
				commit('STREAMER_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STREAMER_DELETE_STATUS', 'error')

				return error
			}
		},
		async fetchStats({ commit }, params) {
			commit('SET_STREAMER_STATS', [])
			commit('SET_STREAMER_STATS_STATUS', 'pending')
			try {
				const { data } = await getStats(params)
				commit('SET_STREAMER_STATS_STATUS', 'init')
				commit('SET_STREAMER_STATS', data)
				return data
			} catch (error) {
				commit('SET_STREAMER_STATS_STATUS', 'init')
				throw error
			}
		},
		async getStreamerStatusList({ commit }, params) {
			commit('STREAMER_STATUS', 'pending')
			try {
				const { data } = await streamerStatusList(params)
				commit('STREAMER_STATUS', 'init')

				if (data.data) {
					data.data.sort((a, b) => a.id - b.id)
				}

				commit('STREAMER_STATUS_LIST', data.data)
				return data
			} catch (error) {
				commit('STREAMER_STATUS', 'init')
				throw error
			}
		},
	},
}
