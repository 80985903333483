import categories from './categories'
import streamers from './streamers'
import subcategories from './subcategories'
import banners from './banners'

const tv = [
	...categories,
	...subcategories,
	...streamers,
	...banners,
	{
		path: '/tv/dashboard',
		name: 'tv-dashboard',
		component: () => import('@/views/tv/dashboard/Index.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/tv/messages',
		name: 'tv-messages-list',
		component: () => import('@/views/messages/Messages.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/tv/newsletter',
		name: 'tv-newsletter-list',
		component: () => import('@/views/newsletters/Newsletter.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default tv
