import application from './application'
import educationLevels from './education-levels'
import employments from './employments'
import experiences from './experiences'
import languageLevels from './language-levels'
import perks from './perks'
import professionalSituations from './professional-situations'
import resumes from './resumes'
import vacancies from './vacancies'
import workAvailabilities from './work-availabilities'
import workHours from './work-hours'
import workMethodologies from './work-methodologies'

const jobs = [
	{
		path: '/jobs/dashboard',
		name: 'jobs-dashboard',
		component: () => import('@/views/jobs/dashboard/Index.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/jobs/applications',
		name: 'jobs-applications-list',
		component: () => import('@/views/jobs/applications/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/jobs/institutions',
		name: 'institutions',
		component: () => import('@/views/institutions/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/jobs/messages',
		name: 'jobs-messages-list',
		component: () => import('@/views/messages/Messages.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/jobs/newsletter',
		name: 'jobs-newsletter-list',
		component: () => import('@/views/newsletters/Newsletter.vue'),
		meta: {
			layout: 'content',
		},
	},
	...resumes,
	...vacancies,
	...educationLevels,
	...employments,
	...experiences,
	...workHours,
	...perks,
	...workMethodologies,
	...workAvailabilities,
	...languageLevels,
	...professionalSituations,
	...application,
]

export default jobs
