import { serialize } from '@/@core/libs/serialize/util'
import Http from '../http'

// // EDUCATION LEVELS
export const educationlevelCreate = (params = '') => Http.post('/education-levels', params)
// // // LIST ALL EDUCATION LEVELS
export const educationlevelsList = (params = '') => Http.get(`/education-levels${serialize(params)}`)

// // // GET EducationLevel BY ID
export const educationlevelByID = (params = '') => Http.get(`/education-levels/${params}`)
// // // UPDATE EDUCATION LEVEL
export const educationlevelUpdate = (params = '') => Http.put(`/education-levels/${params.id}`, params)
// // // DELETE EDUCATION LEVEL
export const educationlevelDestroy = (params = '') => Http.delete(`/education-levels/${params.id}`)
