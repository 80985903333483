import { serialize } from '@/@core/libs/serialize/util';
import Http from '@/services/http';

export const getMemberByID = (params = '') => Http.get(`/teams-members/${params}`)

export const saveMember = (params = '') => Http.post(`/teams-members/`, params)

export const updateMember = (params = '') => Http.put(`/teams-members/${params.id}`, params)

export const getProfessionalInfoByID = (params = '') => Http.get(`/teams-members/${params}/professional`)

export const saveProfessionalData = (params = '') => Http.post(`/teams-members/${params.id}/professional`, params)

export const updateProfessionalData = (params = '') => Http.put(`/teams-members/${params.id}/professional`, params)

export const getFinancialInfoByID = (id = '', params = '') => Http.get(`/teams-members/${id}/financials${serialize(params)}`)

export const getDocumentsInfoByID = (id = '', params = '') => Http.get(`/teams-members/${id}/documents${serialize(params)}`)

export const saveFinancialData = (id = '', params = '') => Http.post(`/teams-members/${id}/financials`, params)

export const saveDocumentsData = (id = '', params = '') => Http.post(`/teams-members/${id}/documents`, params)

export const deleteMember = (params = '') => Http.delete(`/teams-members/${params.id}`, params)

export const updateFinancialData = (id = '', params = '') => Http.put(`/teams-members/${id}/financials/${params.id}`, params)

export const deleteFinancialData = (id = '', params = '') => Http.delete(`/teams-members/${id}/financials/${params.id}`)

export const updateDocumentsData = (id = '', params = '') => Http.put(`/teams-members/${id}/documents/${params.id}`, params)

export const deleteDocumentsData = (id = '', params = '') => Http.delete(`/teams-members/${id}/documents/${params.id}`)
