import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getMediaTypes = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/media-types/fetch${serialize(params)}`)

export const destroyMediaType = (
	params = {
		mediaTypeId: null,
	},
) => Http.delete(`/media-types/${params.mediaTypeId}/destroy`)

export const saveMediaType = (
	params = {
		name: null,
		description: null,
	},
) => Http.post(`/media-types/store`, params)

export const updateMediaType = (
	params = {
		mediaTypeId: null,
		name: null,
		description: null,
	},
) => Http.put(`/media-types/${params.mediaTypeId}/update`, params)
