import {
	batchUpsertGamesCharacter,
	destroyCharacter,
	destroyGameCharacter,
	getCharacters,
	getStats,
	saveCharacter,
	showCharacter,
	updateCharacter,
} from '@/services/community/wikigamer/characters/characters'
import { alertBasic } from '@/views/components/alert/demos/code'

export default {
	namespaced: true,
	state: {
		characterList: {
			meta: {
				total: 0,
			},
		},
		characterStatus: 'pending',
		characterStats: [],
		characterStatStatus: 'pending',
		characterData: null,
	},
	getters: {
		getCharacterList(state) {
			return state.characterList
		},
		getCharacterStatus(state) {
			return state.characterStatus
		},
		getCharacterStats(state) {
			return state.characterStats
		},
		getCharacterStatStatus(state) {
			return state.characterStatStatus
		},
		getCharacterData(state) {
			return state.characterData
		},
	},
	mutations: {
		SET_CHARACTER_LIST(state, value) {
			state.characterList = value
		},
		SET_CHARACTER_STATUS(state, value) {
			state.characterStatus = value
		},
		SET_CHARACTER_STATS(state, value) {
			state.characterStats = value
		},
		SET_CHARACTER_STAT_STATUS(state, value) {
			state.characterStatStatus = value
		},
		SET_CHARACTER_DATA(state, value) {
			state.characterData = value
		},
	},
	actions: {
		async fetchCharacters({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_CHARACTER_STATUS', 'pending')
			commit('SET_CHARACTER_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getCharacters(params)
				commit('SET_CHARACTER_STATUS', 'init')
				commit('SET_CHARACTER_LIST', data)
				return data
			} catch (error) {
				commit('SET_CHARACTER_STATUS', 'init')
				throw error
			}
		},
		async fetchStats({ commit }, params) {
			commit('SET_CHARACTER_STATS', [])
			commit('SET_CHARACTER_STAT_STATUS', 'pending')
			try {
				const { data } = await getStats(params)
				commit('SET_CHARACTER_STAT_STATUS', 'init')
				commit('SET_CHARACTER_STATS', data)
				return data
			} catch (error) {
				commit('SET_CHARACTER_STAT_STATUS', 'init')
				throw error
			}
		},
		async destroyCharacterRecord({ commit }, params) {
			commit('SET_CHARACTER_STATUS', 'pending')
			try {
				const { data } = await destroyCharacter(params)
				commit('SET_CHARACTER_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_CHARACTER_STATUS', 'init')
				throw error
			}
		},
		async saveCharacter({ commit }, params) {
			commit('SET_CHARACTER_STATUS', 'pending')
			try {
				const { data } = await saveCharacter(params)
				// if (params.games_id.length > 0) {
				// 	await batchUpsertGamesCharacter({ characterSlug: data.data.slug, games_id: params.games_id })
				// }
				commit('SET_CHARACTER_STATUS', 'init')
				// commit('SET_CHARACTER_DATA', null)
				return data
			} catch (error) {
				commit('SET_CHARACTER_STATUS', 'init')
				throw error
			}
		},
		async updateCharacter({ commit }, params) {
			commit('SET_CHARACTER_STATUS', 'pending')
			try {
				const { data } = await updateCharacter(params.slug, params)
				// if (params.games_id.length > 0) {
				// 	await batchUpsertGamesCharacter({ characterSlug: data.data.slug, games_id: params.games_id })
				// }
				commit('SET_CHARACTER_STATUS', 'init')
				// commit('SET_CHARACTER_DATA', null)
				return data
			} catch (error) {
				commit('SET_CHARACTER_STATUS', 'init')
				throw error
			}
		},
		async showCharacter({ commit }, params) {
			commit('SET_CHARACTER_STATUS', 'pending')
			try {
				const { data } = await showCharacter(params)
				commit('SET_CHARACTER_STATUS', 'init')
				commit('SET_CHARACTER_DATA', data.data)
				return data
			} catch (error) {
				commit('SET_CHARACTER_STATUS', 'init')
				throw error
			}
		},
		clear({ commit }, params) {
			commit('SET_CHARACTER_STATUS', 'init')
			commit('SET_CHARACTER_DATA', null)
		},
		async destroyGameByCharacter({ commit }, params) {
			commit('SET_CHARACTER_STATUS', 'pending')
			try {
				await destroyGameCharacter(params)
				commit('SET_CHARACTER_STATUS', 'init')
			} catch (error) {
				commit('SET_CHARACTER_STATUS', 'init')
				throw error
			}
		},
	},
}
