import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

// // // LIST ALL POSTS FROM PRESS
export const pressPostsList = params => Http.get(`/press-posts${serialize(params)}`)
// // // LIST POSTS FROM ID
export const pressPostsByID = (params = '') => Http.get(`/press-posts/${params}`)
// // // GET POSTS STATUS
export const pressPostsStatusList = (params = '') => Http.get(`/press-posts/${params}`)
// // // POST NEW POST
export const newPost = (params = '') => Http.post('/press-posts', params)
// // // UPDATE POST
export const updatePost = (params = '') => Http.put(`/press-posts/${params.id}`, params)
// // // DELETE POST
export const postDestroy = (params = '') => Http.delete(`/press-posts/${params.id}`)

// // CATEGORIES
// // // LIST ALL CATEGORIES FROM PRESS
export const pressCategoriesList = (params = { page: 1, filter: '', language: '' }) =>
	Http.get(`/press-categories${serialize(params)}`)
// // // GET CATEGORY BY ID
export const pressCategoryByID = (params = '') => Http.get(`/press-categories/${params}`)
// // // POST NEW CATEGORY
export const newCategory = (params = '') => Http.post('/press-categories', params)
// // // UPDATE CATEGORY
export const updateCategory = (params = '') => Http.put(`/press-categories/${params.id}`, params)
// // // DELETE TAG
export const deleteCategoryByID = (params = '') => Http.delete(`/press-categories/${params.id}`)
// // // LIST ALL CATEGORIES FROM PRESS WITHOUT PAGINATION
export const categoriesListNoPagination = params => Http.get(`/press/list-categories${serialize(params)}`)
