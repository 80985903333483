const videos = [
	{
		path: '/community/contents/videos/list',
		name: 'community-contents-videos-list',
		component: () => import('@/views/community/videos/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/contents/videos/new',
		name: 'community-contents-videos-create',
		component: () => import('@/views/community/videos/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/contents/videos/edit/:link',
		name: 'community-contents-videos-edit',
		component: () => import('@/views/community/videos/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/contents/videos/category/list',
		name: 'community-contents-videos-category-list',
		component: () => import('@/views/community/videos/category/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default videos
