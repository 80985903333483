const gamesRating = [
	{
		path: '/community/wikigamer/games-rating/esrb/list',
		name: 'community-wikigamer-games-rating-esrb-list',
		component: () => import('@/views/community/wikigamer/games-rating/esrb/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/games-rating/pegi/list',
		name: 'community-wikigamer-games-rating-pegi-list',
		component: () => import('@/views/community/wikigamer/games-rating/pegi/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default gamesRating
