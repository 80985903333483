import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getTranslation = params => Http.get(`/translation-queue/fetch${serialize(params)}`)

export const saveTranslation = params => Http.post(`/translation-queue/store`, params)

export const updateTranslation = params => Http.put(`/translation-queue/${params.id}/update`, params)

export const resetTranslation = params => Http.put(`/translation-queue/${params.id}/reset`)

export const runTranslation = params => Http.put(`/translation-queue/${params.id}/translate`)

export const refreshTranslation = params => Http.post(`/translation-queue/refresh`, params)

export const deleteTranslation = params => Http.delete(`/translation-queue/${params.id}/destroy`)
