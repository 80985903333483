import { applicationByID, applicationList, applicationStatusList } from '@/services/jobs/applications.service'

export default {
	namespaced: true,
	state: {
		// APPLICATION
		applicationList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		applicationStatusList: [],
		application: {},

		// STATUS
		applicationStatus: 'init',
	},
	getters: {
		// APPLICATIONS
		getApplicationList(state) {
			return state.applicationList
		},
		getApplication(state) {
			return state.application
		},
		getApplicationStatusList(state) {
			return state.applicationStatusList
		},
		getApplicationStatus(state) {
			return state.applicationStatus
		},
	},
	mutations: {
		// APPLICATION
		APPLICATION_LIST(state, value) {
			state.applicationList = value
		},
		APPLICATION_STATUS_LIST(state, value) {
			state.applicationStatusList = value
		},
		SET_APPLICATION(state, value) {
			state.application = value
		},

		// STATUS
		APPLICATION_STATUS(state, value) {
			state.applicationStatus = value
		},
	},
	actions: {
		// APPLICATION
		async getApplicationList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('APPLICATION_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			commit('APPLICATION_STATUS', 'pending')
			try {
				const { data } = await applicationList(params)
				commit('APPLICATION_STATUS', 'init')
				commit('APPLICATION_LIST', data)
				return data
			} catch (error) {
				commit('APPLICATION_STATUS', 'init')
				throw error
			}
		},
		async fetchApplicationByID({ state, commit }, params) {
			commit('APPLICATION_STATUS', 'pending')
			try {
				const { data } = await applicationByID(params)
				commit('APPLICATION_STATUS', 'init')
				commit('SET_APPLICATION', data.data)
				return data
			} catch (error) {
				commit('APPLICATION_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async getApplicationStatusList({ commit }, params) {
			commit('APPLICATION_STATUS', 'pending')
			try {
				const { data } = await applicationStatusList(params)
				commit('APPLICATION_STATUS', 'init')

				if (data.data) {
					data.data.sort((a, b) => a.id - b.id)
				}

				commit('APPLICATION_STATUS_LIST', data.data)
				return data
			} catch (error) {
				commit('APPLICATION_STATUS', 'init')
				throw error
			}
		},
	},
}
