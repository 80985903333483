import {
	mdiAccount,
	mdiChartTimelineVariantShimmer,
	mdiChatProcessingOutline,
	mdiGoogleClassroom,
	mdiOfficeBuilding,
	mdiToolbox,
} from '@mdi/js'

// Array of sections
export default [
	{
		title: 'Empresas',
		icon: mdiOfficeBuilding,
		children: [
			{
				title: 'Empresas',
				to: 'companies-list',
			},
			{
				title: 'Direcionadores estratégicos',
				to: 'strategic-drivers',
			},
		],
	},
	{
		title: 'Usuários',
		icon: mdiAccount,
		children: [
			{
				title: 'Usuários',
				to: 'users-list',
			},
			{
				title: 'Perfis',
				to: 'strategic-drivers',
			},
		],
	},
	{
		icon: mdiToolbox,
		title: 'Serviços',
		to: 'services-list',
	},
	{
		icon: mdiGoogleClassroom,
		title: 'Turmas',
		to: 'groups',
	},
	{
		icon: mdiChatProcessingOutline,
		title: 'Reuniões',
		to: 'meetings',
	},
	{
		icon: mdiChartTimelineVariantShimmer,
		title: 'Progresso das Atividades',
		to: 'activities-progress-list',
	},
]
