import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

// // PARTNERS
// // // LIST ALL PARTNERS
export const partnersList = (params = '') => Http.get(`/partners${serialize(params)}`)
// // // GET PARTNER BY ID
export const partnersByID = (params = '') => Http.get(`/partners/${params}`)
// // // POST NEW PARTNER
export const newPartner = (params = '') => Http.post('/partners', params)
// // // UPDATE PARTNER
export const updatePartner = (params = '') => Http.put(`/partners/${params.id}`, params)
// // // DELETE PARTNER
export const deletePartnerByID = (params = '') => Http.delete(`/partners/${params.id}`)
