const languageLevels = [
	{
		path: '/language-levels/list',
		name: 'jobs-language-levels-list',
		component: () => import(/* webpackChunkName: "LanguageLevelsList" */ '@/views/jobs/language-levels/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default languageLevels
