import Vue from 'vue';

Vue.filter('firstName', function (name) {
	return name.split(' ')[0];
})

Vue.filter('camelCase', function (value) {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
});

Vue.filter('slug', function (value) {
	if (!value) return ''
	value = value.toString()
	var slug = "";
	// Change to lower case
	var valueLower = value.toLowerCase();
	// Letter "e"
	slug = valueLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
	// Letter "a"
	slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
	// Letter "o"
	slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
	// Letter "u"
	slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
	// Letter "d"
	slug = slug.replace(/đ/gi, 'd');
	// Trim the last whitespace
	slug = slug.replace(/\s*$/g, '');
	// Change whitespace to "-"
	slug = slug.replace(/\s+/g, '-');
	
	return slug;
});

export default {}
