import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getAchievements = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/game-achievements/fetch${serialize(params)}`)

// SAVE A NEW ACHIEVEMENT
export const saveAchievement = (
	params = {
		category: null,
	},
) => Http.post(`/game-achievements/store`, params)

//UPDATE ACHIEVEMENT
export const updateAchievement = (
	params = {
		category: null,
		achievementId: null,
	},
) => Http.put(`/game-achievements/${params.achievementId}/update`, params)

// DELETE ACHIEVEMENT
export const deleteAchievement = (
	params = {
		achievementId: null,
	},
) => Http.delete(`game-achievements/${params.achievementId}/destroy`)
