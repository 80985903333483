import {
	getStats,
	getYoutubeInfo,
	getVideoByLink,
	getVideos,
	newVideo,
	removeVideo,
	updateVideo,
} from '@/services/community/contents/videos'

export default {
	namespaced: true,
	state: {
		video: {
			meta: {
				total: 0,
			},
		},
		videoList: {
			meta: {
				total: 0,
			},
		},

		videoStatus: 'pending',
		videoStats: [],
		videoStatStatus: 'pending',
		youtubeInfo: [],
		youtubeInfoStatus: 'pending',
		deleteVideoStatus: 'pending',
	},
	getters: {
		getVideo(state) {
			return state.video
		},
		getVideoList(state) {
			return state.videoList
		},
		getVideoStatus(state) {
			return state.videoStatus
		},
		getVideoStats(state) {
			return state.videoStats
		},
		getYoutubeInfo(state) {
			return state.youtubeInfo
		},
		getYoutubeInfoStatus(state) {
			return state.youtubeInfoStatus
		},
		getVideoStatStatus(state) {
			return state.videoStatStatus
		},
		getDeleteVideoStatus(state) {
			return state.deleteVideoStatus
		},
	},
	mutations: {
		SET_VIDEO(state, value) {
			state.video = value
		},
		SET_VIDEO_LIST(state, value) {
			state.videoList = value
		},
		SET_VIDEO_STATUS(state, value) {
			state.videoStatus = value
		},
		SET_VIDEO_STATS(state, value) {
			state.videoStats = value
		},
		SET_YOUTUBE_INFO(state, value) {
			state.youtubeInfo = value
		},
		SET_YOUTUBE_INFO_STATUS(state, value) {
			state.youtubeInfoStatus = value
		},
		SET_VIDEO_STAT_STATUS(state, value) {
			state.videoStatStatus = value
		},
		DELETE_VIDEO_STATUS(state, value) {
			state.deleteVideoStatus = value
		},
	},
	actions: {
		async fetchVideos({ commit }, params) {
			commit('SET_VIDEO_STATUS', 'pending')
			commit('SET_VIDEO_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getVideos(params)
				commit('SET_VIDEO_STATUS', 'init')
				commit('SET_VIDEO_LIST', data)
				return data
			} catch (error) {
				commit('SET_VIDEO_STATUS', 'init')
				throw error
			}
		},
		async fetchStats({ commit }, params) {
			commit('SET_VIDEO_STATS', [])
			commit('SET_VIDEO_STAT_STATUS', 'pending')
			try {
				const { data } = await getStats(params)
				commit('SET_VIDEO_STAT_STATUS', 'init')
				commit('SET_VIDEO_STATS', data)
				return data
			} catch (error) {
				commit('SET_VIDEO_STAT_STATUS', 'init')
				throw error
			}
		},
		async fetchYoutubeInfo({ commit }, params) {
			commit('SET_YOUTUBE_INFO', [])
			commit('SET_YOUTUBE_INFO_STATUS', 'pending')
			try {
				const { data } = await getYoutubeInfo(params)
				commit('SET_YOUTUBE_INFO_STATUS', 'init')
				commit('SET_YOUTUBE_INFO', data)
				return data
			} catch (error) {
				commit('SET_YOUTUBE_INFO_STATUS', 'init')
				throw error
			}
		},

		async saveVideo({ commit }, params) {
			commit('SET_VIDEO_STATUS', 'pending')
			try {
				const { data } = await newVideo(params)
				commit('SET_VIDEO_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_VIDEO_STATUS', 'init')
				throw error
			}
		},
		async updateVideo({ commit }, params) {
			commit('SET_VIDEO_STATUS', 'pending')
			try {
				const { data } = await updateVideo(params.link, params)
				commit('SET_VIDEO_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_VIDEO_STATUS', 'init')
				throw error
			}
		},
		async fetchVideoByLink({ commit }, params) {
			commit('SET_VIDEO', {})
			commit('SET_VIDEO_STATUS', 'pending')
			try {
				const { data } = await getVideoByLink(params)
				commit('SET_VIDEO_STATUS', 'init')
				commit('SET_VIDEO', data)
				return data
			} catch (error) {
				commit('SET_VIDEO_STATUS', 'init')
				throw error
			}
		},
		async deleteVideo({ commit }, params) {
			commit('DELETE_VIDEO_STATUS', 'pending')
			try {
				const { data } = await removeVideo(params)
				commit('DELETE_VIDEO_STATUS', 'init')
				return data
			} catch (error) {
				commit('DELETE_VIDEO_STATUS', 'error')
				throw error
			}
		},
	},
}
