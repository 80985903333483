const platforms = [
	{
		path: '/community/wikigamer/platforms/platforms/list',
		name: 'community-wikigamer-platforms-list',
		component: () => import('@/views/community/wikigamer/platforms/platforms/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/platforms/platforms/new',
		name: 'community-wikigamer-platforms-create',
		component: () => import('@/views/community/wikigamer/platforms/platforms/new.vue'),
		meta: {
			layout: 'content',
		},
	},

	{
		path: '/community/wikigamer/platforms/types/list',
		name: 'community-wikigamer-platforms-types-list',
		component: () => import('@/views/community/wikigamer/platforms/types/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/platforms/family/list',
		name: 'community-wikigamer-platforms-family-list',
		component: () => import('@/views/community/wikigamer/platforms/family/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/platforms/generations/list',
		name: 'community-wikigamer-platforms-generations-list',
		component: () => import('@/views/community/wikigamer/platforms/generations/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default platforms
