import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

// LIST STATUS
export const getStatus = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/statuses/fetch${serialize(params)}`)
