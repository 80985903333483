import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getStatus = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/pulse-status/fetch${serialize(params)}`)

//SAVE A NEW STATUS
export const saveStatus = (
	params = {
		name: null,
		background_color: null,
	},
) => Http.post(`/pulse-status/store`, params)

//UPDATE STATUS
export const updateStatus = (
	params = {
		name: null,
		background_color: null,
		statusId: null,
	},
) => Http.put(`/pulse-status/${params.statusId}/update`, params)

//DELETE STATUS
export const deleteStatus = (
	params = {
		statusId: null,
	},
) => Http.delete(`/pulse-status/${params.statusId}/destroy`)
