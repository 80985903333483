import {
	workmethodologiesList,
	workmethodologyByID,
	workmethodologyCreate,
	workmethodologyDestroy,
	workmethodologyUpdate,
} from '@/services/jobs/workMethodologies.service'

export default {
	namespaced: true,
	state: {
		// WORKMETHODOLOGIES
		workmethodologiesList: {},
		workmethodology: {},
		// STATUS WORKMETHODOLOGY
		workmethodologiesStatus: 'pending',
		workmethodologiesSaveStatus: 'init',
		workmethodologiesDeleteStatus: 'init',
	},
	getters: {
		// WORKMETHODOLOGY
		getWorkmethodologiesList(state) {
			return state.workmethodologiesList
		},
		getWorkmethodology(state) {
			return state.workmethodology
		},
		// WORKMETHODOLOGY
		getWorkmethodologiesDeleteStatus(state) {
			return state.workmethodologiesDeleteStatus
		},
		getWorkmethodologiesSaveStatus(state) {
			return state.workmethodologiesSaveStatus
		},
		getWorkmethodologiesStatus(state) {
			return state.workmethodologiesStatus
		},
	},
	mutations: {
		// WORKMETHODOLOGIES
		WORKMETHODOLOGIES_LIST(state, value) {
			state.workmethodologiesList = value
		},
		SET_WORKMETHODOLOGY(state, value) {
			state.workmethodology = value
		},
		// STATUS WORKMETHODOLOGIES
		WORKMETHODOLOGIES_STATUS(state, value) {
			state.workmethodologiesStatus = value
		},
		WORKMETHODOLOGY_SAVE_STATUS(state, value) {
			state.workmethodologiesSaveStatus = value
		},
		WORKMETHODOLOGY_DELETE_STATUS(state, value) {
			state.workmethodologiesDeleteStatus = value
		},
	},
	actions: {
		// Workmethodologies
		async fetchWorkmethodologyList({ commit }, params) {
			commit('WORKMETHODOLOGIES_STATUS', 'pending')
			commit('WORKMETHODOLOGIES_LIST', {})
			try {
				const { data } = await workmethodologiesList(params)
				commit('WORKMETHODOLOGIES_STATUS', 'init')
				commit('WORKMETHODOLOGIES_LIST', data)
				return data
			} catch (error) {
				commit('WORKMETHODOLOGIES_STATUS', 'init')
				throw error
			}
		},
		async fetchWorkmethodologyByID({ commit }, params) {
			commit('WORKMETHODOLOGIES_STATUS', 'pending')
			try {
				const { data } = await workmethodologyByID(params)
				commit('WORKMETHODOLOGIES_STATUS', 'init')
				commit('SET_WORKMETHODOLOGY', data.data)
				return data
			} catch (error) {
				commit('WORKMETHODOLOGIES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewWorkmethodology({ commit }, params) {
			commit('WORKMETHODOLOGY_SAVE_STATUS', 'pending')
			try {
				const { data } = await workmethodologyCreate(params)
				commit('WORKMETHODOLOGY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WORKMETHODOLOGY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateWorkmethodology({ commit }, params) {
			commit('WORKMETHODOLOGY_SAVE_STATUS', 'pending')
			try {
				const { data } = await workmethodologyUpdate(params)
				commit('WORKMETHODOLOGY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WORKMETHODOLOGY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteWorkmethodologyByID({ commit }, params) {
			commit('WORKMETHODOLOGY_DELETE_STATUS', 'pending')
			try {
				const { data } = await workmethodologyDestroy(params)
				commit('WORKMETHODOLOGY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WORKMETHODOLOGY_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
