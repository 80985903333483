import {
	awardCreate,
	awardDestroy,
	awardUpdate,
	awardsList,
	categoriesList,
	categoryCreate,
	categoryDestroy,
	categoryUpdate,
	destroyCompetitor, 
	getCompetitor, 
	getCompetitors, 
	saveCompetitor, 
	updateCompetitor
} from '@/services/community/awards'

export default {
	namespaced: true,
	state: {
		// AWARDS
		awardsList: {
			meta: {
					total: 0,
			},
		},
		award: {},

		// STATUS AWARDS
		awardsStatus: 'pending',
		awardsSaveStatus: 'init',
		awardsDeleteStatus: 'init',

		// AWARDS Categories
		categoriesList: {
			meta: {
					total: 0,
			},
		},
		category: {},

		// STATUS AWARDS Categories
		categoriesStatus: 'pending',
		categoriesSaveStatus: 'init',
		categoriesDeleteStatus: 'init',

		competitorList: [],
		competitorStatus: 'pending',
		competitorData: null,
	},
	getters: {
		// AWARDS
		getAwardsList(state) {
			return state.awardsList
		},
		getAward(state) {
			return state.award
		},

		// STATUS AWARDS
		getAwardsDeleteStatus(state) {
			return state.awardsDeleteStatus
		},
		getAwardsSaveStatus(state) {
			return state.awardsSaveStatus
		},
		getAwardsStatus(state) {
			return state.awardsStatus
		},

		// AWARDS Categories
		getCategoriesList(state) {
			return state.categoriesList
		},
		getCategory(state) {
			return state.category
		},

		// STATUS AWARDS Categories
		getCategoriesDeleteStatus(state) {
			return state.categoriesDeleteStatus
		},
		getCategoriesSaveStatus(state) {
			return state.categoriesSaveStatus
		},
		getCategoriesStatus(state) {
			return state.categoriesStatus
		},

		getCompetitorList(state) {
			return state.competitorList
		},
		getCompetitorStatus(state) {
			return state.competitorStatus
		},
		getCompetitorData(state) {
			return state.competitorData
		},
	},
	mutations: {
		// AWARDS
		AWARDS_LIST(state, value) {
			state.awardsList = value
		},
		SET_AWARD(state, value) {
			state.award = value
		},

		// STATUS AWARDS
		AWARDS_STATUS(state, value) {
			state.awardsStatus = value
		},
		AWARD_SAVE_STATUS(state, value) {
			state.awardsSaveStatus = value
		},
		AWARD_DELETE_STATUS(state, value) {
			state.awardsDeleteStatus = value
		},

		// AWARDS Categories
		CATEGORIES_LIST(state, value) {
			state.categoriesList = value
		},
		SET_CATEGORY(state, value) {
			state.category = value
		},

		// STATUS AWARDS Categories
		CATEGORIES_STATUS(state, value) {
			state.categoriesStatus = value
		},
		CATEGORY_SAVE_STATUS(state, value) {
			state.categoriesSaveStatus = value
		},
		CATEGORY_DELETE_STATUS(state, value) {
			state.categoriesDeleteStatus = value
		},

		SET_COMPETITOR_LIST(state, value) {
			state.competitorList = value
		},
		SET_COMPETITOR_STATUS(state, value) {
			state.competitorStatus = value
		},
		SET_COMPETITOR_DATA(state, value) {
			state.competitorData = value
		},
	},
	actions: {
		// AWARDS
		async fetchAwardList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('AWARDS_STATUS', 'pending')
			commit('AWARDS_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await awardsList(params)
				commit('AWARDS_STATUS', 'init')
				commit('AWARDS_LIST', data)
				return data
			} catch (error) {
				commit('AWARDS_STATUS', 'init')
				throw error
			}
		},
		async saveNewAward({ commit }, params) {
			commit('AWARD_SAVE_STATUS', 'pending')
			try {
				const { data } = await awardCreate(params)
				commit('AWARD_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('AWARD_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateAward({ commit }, params) {
			commit('AWARD_SAVE_STATUS', 'pending')
			try {
				const { data } = await awardUpdate(params)
				commit('AWARD_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('AWARD_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteAwardByID({ commit }, params) {
			commit('AWARD_DELETE_STATUS', 'pending')
			try {
				const { data } = await awardDestroy(params)
				commit('AWARD_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('AWARD_DELETE_STATUS', 'error')

				return error
			}
		},

		// AWARDS Categories
		async fetchCategoryList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('CATEGORIES_STATUS', 'pending')
			commit('CATEGORIES_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await categoriesList(params)
				commit('CATEGORIES_STATUS', 'init')
				commit('CATEGORIES_LIST', data)
				return data
			} catch (error) {
				commit('CATEGORIES_STATUS', 'init')
				throw error
			}
		},
		async saveNewCategory({ commit }, params) {
			commit('CATEGORY_SAVE_STATUS', 'pending')
			try {
				const { data } = await categoryCreate(params)
				commit('CATEGORY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CATEGORY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCategory({ commit }, params) {
			commit('CATEGORY_SAVE_STATUS', 'pending')
			try {
				const { data } = await categoryUpdate(params)
				commit('CATEGORY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CATEGORY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCategoryByID({ commit }, params) {
			commit('CATEGORY_DELETE_STATUS', 'pending')
			try {
				const { data } = await categoryDestroy(params)
				commit('CATEGORY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CATEGORY_DELETE_STATUS', 'error')

				return error
			}
		},


		async fetchCompetitors({ commit }, params) {
			commit('SET_COMPETITOR_LIST', [])
			commit('SET_COMPETITOR_STATUS', 'pending')
			try {
				const { data } = await getCompetitors(params)
				commit('SET_COMPETITOR_STATUS', 'init')
				commit('SET_COMPETITOR_LIST', data)
				return data
			} catch (error) {
				commit('SET_COMPETITOR_STATUS', 'init')
				throw error
			}
		},
		async destroyCompetitorRecord({ commit }, params) {
			commit('SET_COMPETITOR_STATUS', 'pending')
			try {
				const { data } = await destroyCompetitor(params)
				commit('SET_COMPETITOR_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_COMPETITOR_STATUS', 'init')
				throw error
			}
		},
		async saveCompetitor({ commit }, params) {
			commit('SET_COMPETITOR_STATUS', 'pending')
			try {
				const { data } = await saveCompetitor(params)
				// if (params.games_id.length > 0) {
				// 	await batchUpsertGamesCompetitor({ competitorSlug: data.data.slug, games_id: params.games_id })
				// }
				commit('SET_COMPETITOR_STATUS', 'init')
				commit('SET_COMPETITOR_DATA', null)
				return data
			} catch (error) {
				commit('SET_COMPETITOR_STATUS', 'init')
				throw error
			}
		},
		async updateCompetitor({ commit }, params) {
			commit('SET_COMPETITOR_STATUS', 'pending')
			try {
				const { data } = await updateCompetitor(params)

				commit('SET_COMPETITOR_STATUS', 'init')
				commit('SET_COMPETITOR_DATA', null)
				return data
			} catch (error) {
				commit('SET_COMPETITOR_STATUS', 'init')
				throw error
			}
		},
		clear({ commit }, params) {
			commit('SET_COMPETITOR_STATUS', 'init')
			commit('SET_COMPETITOR_DATA', null)
		},
		async destroyGameByCompetitor({ commit }, params) {
			commit('SET_COMPETITOR_STATUS', 'pending')
			try {
				await destroyGameCompetitor(params)
				commit('SET_COMPETITOR_STATUS', 'init')
			} catch (error) {
				commit('SET_COMPETITOR_STATUS', 'init')
				throw error
			}
		},
		async fetchCompetitor({ commit }, params) {
			commit('SET_COMPETITOR_STATUS', 'pending')
			try {
				const { data } = await getCompetitor(params)
				commit('SET_COMPETITOR_STATUS', 'init')
				commit('SET_COMPETITOR_DATA', data)
				return data
			} catch(error) {
				commit('SET_COMPETITOR_STATUS', 'init')
				throw error
			}
		}

	},
}
