import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

// // BENEFITS
export const benefitCreate = (params = '') => Http.post('/network-plan-benefits', params)
// // // LIST ALL BENEFITS
export const benefitsList = (params = '') => Http.get(`/network-plan-benefits${serialize(params)}`)
// // // GET Benefit BY ID
export const benefitByID = (params = '') => Http.get(`/network-plan-benefits/${params}`)
// // // UPDATE BENEFIT
export const benefitUpdate = (params = '') => Http.put(`/network-plan-benefits/${params.id}`, params)
// // // DELETE BENEFIT
export const benefitDestroy = (params = '') => Http.delete(`/network-plan-benefits/${params.id}`)
// // // Get companie benefits
export const getPlanBenefits = (params = '') => Http.get(`/network-plans/plan_benefits${serialize(params)}`)
