import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

// LIST STATUS
export const statusList = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/character-status/fetch${serialize(params)}`)

// SHOW STATUS
export const statusByID = (
	params = {
		status: null,
		statusId: null,
	},
) => Http.put(`/character-status/${params.statusId}/show`)

// SAVE STATUS
export const statusCreate = (
	params = {
		status: null,
	},
) => Http.post('/character-status/store', params)

// UPDATE STATUS
export const statusUpdate = (
	params = {
		status: null,
		statusId: null,
	},
) => Http.put(`/character-status/${params.id}/update`, params)

// DELETE STATUS
export const statusDestroy = (
	params = {
		statusId: null,
	},
) => Http.delete(`/character-status/${params.statusId}/destroy`)
