import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

// // // LIST ALL Companies Credentials
export const credentialsList = (params = '') => Http.get(`/api-credentials${serialize(params)}`)

export const credentialCreate = (params = '') => Http.post('/api-credentials', params)
export const credentialByID = (params = '') => Http.get(`/api-credentials/${params}`)
export const credentialUpdate = (params = '') => Http.put(`/api-credentials/${params.id}`, params)
export const credentialDestroy = (params = '') => Http.delete(`/api-credentials/${params.id}`)
