import {
	destroyEventGategory,
	getEventsCategories,
	saveEventCategory,
	updateEventCategory,
} from '@/services/community/wikigamer/events/eventesCategories.js'

export default {
	namespaced: true,
	state: {
		eventsCategoriesList: {
			meta: {
				total: 0,
			},
		},
		eventsCategoriesStatus: 'pending',
	},
	getters: {
		getEventsCategoriesList(state) {
			return state.eventsCategoriesList
		},
		getEventsCategoriesStatus(state) {
			return state.eventsCategoriesStatus
		},
	},
	mutations: {
		SET_EVENTS_CATEGORIES_LIST(state, value) {
			state.eventsCategoriesList = value
		},
		SET_EVENTS_CATEGORIES_STATUS(state, value) {
			state.eventsCategoriesStatus = value
		},
	},
	actions: {
		async fetchEventsCategories({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_EVENTS_CATEGORIES_STATUS', 'pending')
			commit('SET_EVENTS_CATEGORIES_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getEventsCategories(params)
				commit('SET_EVENTS_CATEGORIES_STATUS', 'init')
				commit('SET_EVENTS_CATEGORIES_LIST', data)
				return data
			} catch (error) {
				commit('SET_EVENTS_CATEGORIES_STATUS', 'init')
				throw error
			}
		},

		async destroyEventCategoryRecord({ commit }, params) {
			commit('SET_EVENTS_CATEGORIES_STATUS', 'pending')
			try {
				const { data } = await destroyEventGategory(params)
				commit('SET_EVENTS_CATEGORIES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_EVENTS_CATEGORIES_STATUS', 'init')
				throw error
			}
		},
		async saveEventCategory({ commit }, params) {
			commit('SET_EVENTS_CATEGORIES_STATUS', 'pending')
			try {
				const { data } = await saveEventCategory(params)
				commit('SET_EVENTS_CATEGORIES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_EVENTS_CATEGORIES_STATUS', 'init')
				throw error
			}
		},
		async updateEventCategory({ commit }, params) {
			commit('SET_EVENTS_CATEGORIES_STATUS', 'pending')
			try {
				const { data } = await updateEventCategory(params)
				commit('SET_EVENTS_CATEGORIES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_EVENTS_CATEGORIES_STATUS', 'init')
				throw error
			}
		},
	},
}
