import { serialize } from '@/@core/libs/serialize/util'
import Http from '../http'

// // LANGUAGE LEVELS
export const languagelevelCreate = (params = '') => Http.post('/language-levels', params)
// // // LIST ALL LANGUAGE LEVELS
export const languagelevelsList = (params = '') => Http.get(`/language-levels${serialize(params)}`)

// // // GET LanguageLevel BY ID
export const languagelevelByID = (params = '') => Http.get(`/language-levels/${params}`)
// // // UPDATE LANGUAGE LEVEL
export const languagelevelUpdate = (params = '') => Http.put(`/language-levels/${params.id}`, params)
// // // DELETE LANGUAGE LEVEL
export const languagelevelDestroy = (params = '') => Http.delete(`/language-levels/${params.id}`)
