const industrySectorCategories = [
	{
		path: '/industry-sector-categories/list',
		name: 'industry-sector-categories-list',
		component: () =>
			import(/* webpackChunkName: "IndustrySectorCategoriesList" */ '@/views/industrySectorCategories/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default industrySectorCategories
