import {
	campaignByID,
	campaignCreate,
	campaignDestroy,
	campaignList,
	campaignUpdate,
} from '@/services/agency/campaigns.service'

export default {
	namespaced: true,
	state: {
		// SERVICE CAMPAIGN
		campaignList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		campaign: {},

		// STATUS SERVICE CAMPAIGN
		campaignStatus: 'pending',
		campaignSaveStatus: 'init',
		campaignDeleteStatus: 'init',
	},
	getters: {
		// SERVICE CAMPAIGN
		getCampaignList(state) {
			return state.campaignList
		},
		getCampaign(state) {
			return state.campaign
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE CAMPAIGN
		getCampaignDeleteStatus(state) {
			return state.campaignDeleteStatus
		},
		getCampaignSaveStatus(state) {
			return state.campaignSaveStatus
		},
		getCampaignStatus(state) {
			return state.campaignStatus
		},
	},
	mutations: {
		// SERVICE CAMPAIGN
		CAMPAIGN_LIST(state, value) {
			state.campaignList = value
		},
		SET_CAMPAIGN(state, value) {
			state.campaign = value
		},

		// STATUS SERVICE CAMPAIGN
		CAMPAIGN_STATUS(state, value) {
			state.campaignStatus = value
		},
		CAMPAIGN_SAVE_STATUS(state, value) {
			state.campaignSaveStatus = value
		},
		CAMPAIGN_DELETE_STATUS(state, value) {
			state.campaignDeleteStatus = value
		},
	},
	actions: {
		// Service Campaign
		async fetchCampaignList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('CAMPAIGN_STATUS', 'pending')
			commit('CAMPAIGN_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await campaignList(params)
				commit('CAMPAIGN_STATUS', 'init')
				commit('CAMPAIGN_LIST', data)
				return data
			} catch (error) {
				commit('CAMPAIGN_STATUS', 'init')
				throw error
			}
		},
		async fetchCampaignByID({ commit }, params) {
			commit('CAMPAIGN_STATUS', 'pending')
			try {
				const { data } = await campaignByID(params)
				commit('CAMPAIGN_STATUS', 'init')
				commit('SET_CAMPAIGN', data.data)
				return data
			} catch (error) {
				commit('CAMPAIGN_STATUS', 'init')

				return error
			}
		},
		async saveNewCampaign({ commit }, params) {
			commit('CAMPAIGN_SAVE_STATUS', 'pending')
			try {
				const { data } = await campaignCreate(params)
				commit('CAMPAIGN_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAMPAIGN_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCampaign({ commit }, params) {
			commit('CAMPAIGN_SAVE_STATUS', 'pending')
			try {
				const { data } = await campaignUpdate(params)
				commit('CAMPAIGN_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAMPAIGN_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCampaignByID({ commit }, params) {
			commit('CAMPAIGN_DELETE_STATUS', 'pending')
			try {
				const { data } = await campaignDestroy(params)
				commit('CAMPAIGN_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAMPAIGN_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
