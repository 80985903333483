import { deleteSource, getSources, saveSource, updateSource } from '@/services/community/contents/pulses-sources'
export default {
	namespaced: true,
	state: {
		sourceList: {
			meta: {
				total: 0,
			},
		},
		sourceStatus: 'pending',
	},
	getters: {
		getSourceList(state) {
			return state.sourceList
		},
		getSourceStatus(state) {
			return state.sourceStatus
		},
	},
	mutations: {
		SET_SOURCE_LIST(state, value) {
			state.sourceList = value
		},
		SET_SOURCE_STATUS(state, value) {
			state.sourceStatus = value
		},
	},
	actions: {
		async fetchSources({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_SOURCE_STATUS', 'pending')
			commit('SET_SOURCE_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getSources(params)
				commit('SET_SOURCE_STATUS', 'init')
				commit('SET_SOURCE_LIST', data)
				return data
			} catch (error) {
				commit('SET_SOURCE_STATUS', 'init')
				throw error
			}
		},

		async deleteSourceRecord({ commit }, params) {
			commit('SET_SOURCE_STATUS', 'pending')
			try {
				const { data } = await deleteSource(params)
				commit('SET_SOURCE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_SOURCE_STATUS', 'init')
				throw error
			}
		},
		async saveSource({ commit }, params) {
			commit('SET_SOURCE_STATUS', 'pending')
			try {
				const { data } = await saveSource(params)
				commit('SET_SOURCE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_SOURCE_STATUS', 'init')
				throw error
			}
		},
		async updateSource({ commit }, params) {
			commit('SET_SOURCE_STATUS', 'pending')
			try {
				const { data } = await updateSource(params)
				commit('SET_SOURCE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_SOURCE_STATUS', 'init')
				throw error
			}
		},
	},
}
