import { mdiAccountBoxMultiple, mdiCodeGreaterThanOrEqual, mdiHome, mdiMessage, mdiNewspaperVariantMultipleOutline, mdiTools, mdiTranslate } from '@mdi/js'

// Array of sections

export default [
	{
		icon: mdiHome,
		title: 'Dashboard',
		to: 'ai-dashboard',
	},
	{
		icon: mdiMessage,
		title: 'menu.messages',
		to: 'ai-messages-list',
	},
	{
		icon: mdiNewspaperVariantMultipleOutline,
		title: 'Newsletter',
		to: 'ai-newsletter-list',
	},
	{
		title: 'menu.profiles',
		icon: mdiAccountBoxMultiple,
		children: [
			{
				title: 'menu.profiles',
				to: 'ai-profiles-list',
			},
			{
				title: 'menu.profilesCategories',
				to: 'ai-profiles-categories-list',
			},
		],
	},
	{
		icon: mdiTranslate,
		title: 'menu.languageModel',
		to: 'ai-language-model-list',
	},
	{
		icon: mdiTools,
		title: 'menu.services',
		to: 'ai-services-list',
	},
	{
		icon: mdiCodeGreaterThanOrEqual,
		title: 'menu.tokenConfigs',
		to: 'ai-token-configs-list',
	},
]
