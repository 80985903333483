const companiesType = [
	{
		path: '/companies/companies-type/list',
		name: 'companies-type-list',
		component: () => import('@/views/companies/companies-type/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/companies/companies-size/list',
		name: 'companies-size-list',
		component: () => import('@/views/companies/companies-size/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/companies/companies-stage/list',
		name: 'companies-stage-list',
		component: () => import('@/views/companies/companies-stage/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/companies/list',
		name: 'companies-list',
		component: () => import('@/views/companies/companies/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/companies/new',
		name: 'companies-create',
		component: () => import('@/views/companies/companies/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/companies/edit/:id',
		name: 'companies-edit',
		component: () => import(/* webpackChunkName: "NetworkCompaniesEdit" */ '@/views/companies/companies/new.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default companiesType
