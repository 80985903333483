import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpAgency'

// LIST CONTACT FRANCHISE
export const contacts = (params = '') => Http.get(`/contacts${serialize(params)}`)

// DELETE FRANCHISE MESSAGE
export const deleteContact = (params = '') => Http.delete(`/contacts/${params.id}`)

// CONTACT FRANCHISE READ
export const updateContacts = (params = '') => Http.put(`/contacts/${params.id}`, params)
