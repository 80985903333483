import Http from '@/services/httpCommunity'
import { serialize } from '@/@core/libs/serialize/util'

export const getReleases = (
	params = {
		perPage: 10,
		page: 1,
		search: '',
		disable_filters: 1,
	},
	language = 'en',
) => Http.get(`/${language}/games/upcoming${serialize(params)}`)

export const deleteRelease = (
	params = {
		releaseId: null,
	},
) => Http.delete(`/games/${params.releaseId}/destroy`)
