import { serialize } from '@/@core/libs/serialize/util'
import Http from './httpCommunity'

// PULSES
// // PULSES
// // // LIST ALL PULSES
export const pulsesList = params => Http.get(`/pulses${serialize(params)}`)
// // // GET PULSE BY ID
export const pulseByID = (params = '') => Http.get(`/pulses/${params}`)
// // // POST NEW PULSE
export const newPulse = (params = '') => Http.post('/creator-mode/new-article', params, { baseURL: 'locale' })
// // // UPDATE PULSE
export const updatePulse = (params = '') => Http.put(`/creator-mode/article/${params.link}`, params, { baseURL: 'locale' })
// // // TRANSLATE PULSE
export const translatePulse = (params = '') => Http.put(`/creator-mode/translate-article/${params.link}`, params, { baseURL: 'locale' })
// // // DELETE PULSE
export const deletePulseByID = (params = '') => Http.delete(`/pulses/${params.id}`)

// // PULSES CATEGORIES
// // // LIST ALL PULSES CATEGORIES
export const pulsesCategoriesList = params => Http.get(`/pulses-category${serialize(params)}`)
// // // GET PULSE CATEGORY BY ID
export const pulseCategoryByID = (params = '') => Http.get(`/pulses-category/${params}`)
// // // POST NEW PULSE CATEGORY
export const newPulseCategory = (params = '') => Http.post('/pulses-category', params)
// // // UPDATE PULSE CATEGORY
export const updatePulseCategory = (params = '') => Http.put(`/pulses-category/${params.id}`, params)
// // // DELETE PULSE CATEGORY
export const deletePulseCategoryByID = (params = '') => Http.delete(`/pulses-category/${params.id}`)
