import Http from '@/services/http'
export default {
	state: {
		currentLocale: 'en',
		availableLanguagesStatus: null,
		availableLanguages: {},
		allLanguages: null,
		status: null,
		statusPublic: null,
	},
	mutations: {
		SET_CURRENT_LOCALE(state, locale) {
			localStorage.setItem('currentLocale', locale)
			state.currentLocale = locale
		},
		SET_ALL_LANGUAGES(state, allLanguages) {
			state.allLanguages = allLanguages
		},
		SET_STATUS(state, status) {
			state.status = status
		},
		SET_STATUS_PUBLIC(state, statusPublic) {
			state.statusPublic = statusPublic
		}
	},
	actions: {
		updateCurrentLocale({ commit }, locale) {
			switch (locale) {
				case 'br':
					locale = 'pt-BR'
					break // Brazilian Portuguese
			}
			commit('SET_CURRENT_LOCALE', locale)
		},
		setAllLanguages({ commit }, allLanguages) {
			commit('SET_ALL_LANGUAGES', allLanguages);
		},
		setStatus({ commit }, status) {
			commit('SET_STATUS', status);
		},
		setStatusPublic({ commit }, statusPublic) {
			commit('SET_STATUS_PUBLIC', statusPublic);
		}
	},
	getters: {
		currentLocale(state) {
			return state.currentLocale
		},
		getAvailableLanguagesStatus(state) {
			return state.availableLanguagesStatus
		},
		// languages
		getAvailableLanguages(state) {
			let locale = state.currentLocale
			return () => {
				state.availableLanguagesStatus = 'PENDING'

				return new Promise((resolve, reject) => {
					let url = 'languages?lang=' + locale
					if (state.allLanguages === true)  {
						url = url + '&per_page=all'
					}

					if (state.status !== null && state.status !== 'all')  {
						url = url + `&filter[status]=${state.status}`
					}

					if (state.statusPublic !== null)  {
						url = url + `&filter[status_public]=${state.statusPublic}`
					}
					Http.get(url)
						.then(response => {
							state.availableLanguages[locale] = response.data.data
							state.availableLanguagesStatus = 'LOADED'
							resolve(response.data.data)
						})
						.catch(error => {
							state.availableLanguagesStatus = 'LOADED'
							reject(error)
						})
						
				})
			}
		},
	},
}
