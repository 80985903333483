const keysList = [
	{
		path: '/network/keys-list/list',
		name: 'network-keys-list',
		component: () => import('@/views/network/keys-list/list.vue'),
		meta: {
			roles: ['MGN,translator'],
			layout: 'content',
		},
	},
]

export default keysList
