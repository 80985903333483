import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getPhrases = params => Http.get(`/character-phrases/fetch${serialize(params)}`)

export const destroyPhrase = (
	params = {
		phraseId: null,
	},
) => Http.delete(`/character-phrases/${params.phraseId}/destroy`)

export const savePhrase = (
	params = {
		phrase: null,
		character_id: null,
		game_id: null,
		status_id: null,
		language_id: null,
	},
) => Http.post(`/character-phrases/store`, params)

export const updatePhrase = (
	params = {
		phraseId: null,
		phrase: null,
		character_id: null,
		game_id: null,
		status_id: null,
		language_id: null,
	},
) => Http.put(`/character-phrases/${params.id}/update`, params)
