import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getReports = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/reports/fetch${serialize(params)}`)

//UPDATE REPORT
export const updateReport = (
	params = {
		name: null,
		reportId: null,
	},
) => Http.put(`/reports/${params.reportId}/update`, params)

// DELETE REPORT
export const deleteReport = (
	params = {
		reportId: null,
	},
) => Http.delete(`/reports/${params.reportId}/destroy`, params)

export const updateReadToggle = (
	params = ''
) => Http.post(`/reports/${params.reportId}/toggle-read`, params)
