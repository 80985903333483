import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

// // // LIST ALL POSTS FROM BLOG
export const blogPostsList = params => Http.get(`/blog-posts${serialize(params)}`)
// // // LIST POSTS FROM ID
export const blogPostsByID = (params = '') => Http.get(`/blog-posts/${params}`)
// // // GET POSTS STATUS
export const blogPostsStatusList = (params = '') => Http.get(`/blog-posts/${params}`)
// // // POST NEW POST
export const newPost = (params = '') => Http.post('/blog-posts', params)
// // // UPDATE POST
export const updatePost = (params = '') => Http.put(`/blog-posts/${params.id}`, params)
// // // DELETE POST
export const postDestroy = (params = '') => Http.delete(`/blog-posts/${params.id}`)

// // // LIST ALL BLOG CATEGORIES
export const blogCategoriesList = params => Http.get(`/blog-categories${serialize(params)}`)
// // // GET CATEGORY BY ID
export const blogCategoryByID = (params = '') => Http.get(`/blog-categories/${params}`)
// // // POST NEW CATEGORY
export const newCategory = (params = '') => Http.post('/blog-categories', params)
// // // UPDATE CATEGORY
export const updateCategory = (params = '') => Http.put(`/blog-categories/${params.id}`, params)
// // // DELETE TAG
export const deleteCategoryByID = (params = '') => Http.delete(`/blog-categories/${params.id}`)

// // // LIST ALL TAGS FROM BLOG WITH PAGINATION
export const blogTagsList = params => Http.get(`/blog-tags${serialize(params)}`)
// // // LIST ALL TAGS FROM BLOG IN TOTALITY
export const blogTagsListAll = (params = '') => Http.get('/blog/list-tags')
// // // GET BLOG TAG BY ID
export const blogTagByID = (params = '') => Http.get(`/blog-tags/${params}`)
// // // POST NEW TAG
export const newBlogTag = (params = '') => Http.post('/blog-tags', params)
// // // UPDATE TAG
export const updateTag = (params = '') => Http.put(`/blog-tags/${params.id}`, params)
// // // DELETE TAG
export const deleteTagByID = (params = '') => Http.delete(`/blog-tags/${params.id}`)
// REQUIRED TAGS
export const requiredTags = (params = '') => Http.get(`/blog-tags/${serialize(params)}`)
