import {
	deleteOperationalArea,
	listAllMembers,
	listOperationalArea,
	newOperationalArea,
	updateOperationalArea,
} from '@/services/operationalArea.service'

export default {
	namespaced: true,
	state: {
		allMembers: {},
		allMembersStatus: 'pending',
		operationalAreaList: {},
		operationalArea: {},
		operationalAreaStatus: 'pending',
		saveOperationalAreaStatus: 'init',
		deleteOperationalAreaStatus: 'init',
	},
	getters: {
		getAllMembers(state) {
			return state.allMembers
		},
		getAllMembersStatus(state) {
			return state.allMembersStatus
		},
		getOperationalAreaList(state) {
			return state.operationalAreaList
		},
		getOperationalArea(state) {
			return state.operationalArea
		},
		getOperationalAreaStatus(state) {
			return state.operationalAreaStatus
		},
		getSaveOperationalAreaStatus(state) {
			return state.saveOperationalAreaStatus
		},
		getDeleteOperationalAreaStatus(state) {
			return state.deleteOperationalAreaStatus
		},
	},
	mutations: {
		ALL_MEMBERS(state, value) {
			state.allMembers = value
		},
		ALL_MEMBERS_STATUS(state, value) {
			state.allMembersStatus = value
		},
		OPERATIONAL_AREA_LIST(state, value) {
			state.operationalAreaList = value
		},
		SET_OPERATIONAL_AREA(state, value) {
			state.operationalArea = value
		},
		OPERATIONAL_AREA_STATUS(state, value) {
			state.operationalAreaStatus = value
		},
		SAVE_OPERATIONAL_AREA_STATUS(state, value) {
			state.saveOperationalAreaStatus = value
		},
		DELETE_OPERATIONAL_AREA_STATUS(state, value) {
			state.deleteOperationalAreaStatus = value
		},
		REMOVE_OPERATIONAL_AREA_FROM_LIST(state, value) {
			state.operationalAreaList.data = state.operationalAreaList.data.filter(op => op.id !== value.id)
		},
	},
	actions: {
		async fetchAllMembers({ commit }, params) {
			commit('ALL_MEMBERS_STATUS', 'pending')
			try {
				const { data } = await listAllMembers(params)
				commit('ALL_MEMBERS_STATUS', 'init')
				commit('ALL_MEMBERS', data)
				return data
			} catch (error) {
				commit('ALL_MEMBERS_STATUS', 'error')
				throw error
			}
		},
		async fetchOperationalArea({ commit }, params) {
			commit('OPERATIONAL_AREA_STATUS', 'pending')
			try {
				const { data } = await listOperationalArea(params)
				commit('OPERATIONAL_AREA_STATUS', 'init')
				commit('OPERATIONAL_AREA_LIST', data)
				return data
			} catch (error) {
				commit('OPERATIONAL_AREA_STATUS', 'error')
				throw error
			}
		},
		async saveOperationalArea({ commit }, params) {
			commit('SAVE_OPERATIONAL_AREA_STATUS', 'pending')
			try {
				const { data } = await newOperationalArea(params)
				commit('SAVE_OPERATIONAL_AREA_STATUS', 'init')
				return data
			} catch (error) {
				commit('SAVE_OPERATIONAL_AREA_STATUS', 'error')
				throw error
			}
		},
		async updateOperationalArea({ commit }, params) {
			commit('SAVE_OPERATIONAL_AREA_STATUS', 'pending')
			try {
				const { data } = await updateOperationalArea(params)
				commit('SAVE_OPERATIONAL_AREA_STATUS', 'init')
				return data
			} catch (error) {
				commit('SAVE_OPERATIONAL_AREA_STATUS', 'error')
				throw error
			}
		},
		async deleteOperationalArea({ commit }, params) {
			commit('DELETE_OPERATIONAL_AREA_STATUS', 'pending')
			try {
				const { data } = await deleteOperationalArea(params)
				commit('REMOVE_OPERATIONAL_AREA_FROM_LIST', params)
				commit('DELETE_OPERATIONAL_AREA_STATUS', 'init')
				return data
			} catch (error) {
				commit('DELETE_OPERATIONAL_AREA_STATUS', 'error')
				throw error
			}
		},
	},
}
