const franchises = [
	{
		path: '/community/wikigamer/franchises/list',
		name: 'community-wikigamer-franchises-list',
		component: () => import('@/views/community/wikigamer/franchises/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/franchises/new',
		name: 'community-wikigamer-franchises-create',
		component: () => import('@/views/community/wikigamer/franchises/upsert.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/franchises/:franchiseSlug/edit',
		name: 'community-wikigamer-franchises-edit',
		component: () => import('@/views/community/wikigamer/franchises/upsert.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default franchises
