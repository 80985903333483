import {
	courseByID,
	courseCreate,
	courseDestroy,
	courseList,
	courseUpdate,
} from '@/services/academy/courses/courses.service'

export default {
	namespaced: true,
	state: {
		// SERVICE COURSE
		courseList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		course: {},

		// STATUS SERVICE COURSE
		courseStatus: 'pending',
		courseSaveStatus: 'init',
		courseDeleteStatus: 'init',
	},
	getters: {
		// SERVICE COURSE
		getCourseList(state) {
			return state.courseList
		},
		getCourse(state) {
			return state.course
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE COURSE
		getCourseDeleteStatus(state) {
			return state.courseDeleteStatus
		},
		getCourseSaveStatus(state) {
			return state.courseSaveStatus
		},
		getCourseStatus(state) {
			return state.courseStatus
		},
	},
	mutations: {
		// SERVICE COURSE
		COURSE_LIST(state, value) {
			state.courseList = value
		},
		SET_COURSE(state, value) {
			state.course = value
		},

		// STATUS SERVICE COURSE
		COURSE_STATUS(state, value) {
			state.courseStatus = value
		},
		COURSE_SAVE_STATUS(state, value) {
			state.courseSaveStatus = value
		},
		COURSE_DELETE_STATUS(state, value) {
			state.courseDeleteStatus = value
		},
	},
	actions: {
		// Service Course
		async fetchCourseList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('COURSE_STATUS', 'pending')
			commit('COURSE_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await courseList(params)
				commit('COURSE_STATUS', 'init')
				commit('COURSE_LIST', data)
				return data
			} catch (error) {
				commit('COURSE_STATUS', 'init')
				throw error
			}
		},
		async fetchCourseByID({ commit }, params) {
			commit('COURSE_STATUS', 'pending')
			try {
				const { data } = await courseByID(params)
				commit('COURSE_STATUS', 'init')
				commit('SET_COURSE', data.data)
				return data
			} catch (error) {
				commit('COURSE_STATUS', 'init')

				return error
			}
		},
		async saveNewCourse({ commit }, params) {
			commit('COURSE_SAVE_STATUS', 'pending')
			try {
				const { data } = await courseCreate(params)
				commit('COURSE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('COURSE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCourse({ commit }, params) {
			commit('COURSE_SAVE_STATUS', 'pending')
			try {
				const { data } = await courseUpdate(params)
				commit('COURSE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('COURSE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCourseByID({ commit }, params) {
			commit('COURSE_DELETE_STATUS', 'pending')
			try {
				const { data } = await courseDestroy(params)
				commit('COURSE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('COURSE_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
