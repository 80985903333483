const events = [
	{
		path: '/community/wikigamer/events/events/list',
		name: 'community-wikigamer-events-list',
		component: () => import('@/views/community/wikigamer/events/events/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/events/events/new',
		name: 'community-wikigamer-events-create',
		component: () => import('@/views/community/wikigamer/events/events/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/events/categories/list',
		name: 'community-wikigamer-events-categories-list',
		component: () => import('@/views/community/wikigamer/events/categories/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/events/events/edit/:id',
		name: 'comunity-wikigamer-events-edit',
		component: () => import('@/views/community/wikigamer/events/events/new.vue'),
		meta: {
			layout: 'content',
		},
	}
]

export default events
