import achievements from './achievements'
import characters from './characters'
import collections from './collections'
import companiesType from './companies'
import esports from './esports'
import events from './events'
import franchises from './franchises'
import games from './games'
import gamesRating from './games-rating'
import mediaTypes from './media-types'
import platforms from './platforms'
import regions from './regions'

const wikigamer = [
	...events,
	...regions,
	...mediaTypes,
	...characters,
	...platforms,
	...games,
	...achievements,
	...companiesType,
	...franchises,
	...collections,
	...gamesRating,
	...esports,
]

export default wikigamer
