const perks = [
	{
		path: '/jobs/perks/list',
		name: 'jobs-perks-list',
		component: () => import('@/views//jobs/perks/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default perks
