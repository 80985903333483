import { getPulseByLink, getPulses, getStats, removePulse } from '@/services/community/contents/pulses/pulses'

export default {
	namespaced: true,
	state: {
		pulse: {
			meta: {
				total: 0,
			},
		},
		pulseList: {
			meta: {
				total: 0,
			},
		},

		pulseStatus: 'pending',
		pulseStats: [],
		pulseStatStatus: 'pending',
		deletePulseStatus: 'pending',
	},
	getters: {
		getPulse(state) {
			return state.pulse
		},
		getPulseList(state) {
			return state.pulseList
		},
		getPulseStatus(state) {
			return state.pulseStatus
		},
		getPulseStats(state) {
			return state.pulseStats
		},
		getPulseStatStatus(state) {
			return state.pulseStatStatus
		},
		getDeletePulseStatus(state) {
			return state.deletePulseStatus
		},
	},
	mutations: {
		SET_PULSE(state, value) {
			state.pulse = value
		},
		SET_PULSE_LIST(state, value) {
			state.pulseList = value
		},
		SET_PULSE_STATUS(state, value) {
			state.pulseStatus = value
		},
		SET_PULSE_STATS(state, value) {
			state.pulseStats = value
		},
		SET_PULSE_STAT_STATUS(state, value) {
			state.pulseStatStatus = value
		},
		DELETE_PULSE_STATUS(state, value) {
			state.deletePulseStatus = value
		},
	},
	actions: {
		async fetchPulses({ commit }, params) {
			commit('SET_PULSE_STATUS', 'pending')
			commit('SET_PULSE_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getPulses(params)
				commit('SET_PULSE_STATUS', 'init')
				commit('SET_PULSE_LIST', data)
				return data
			} catch (error) {
				commit('SET_PULSE_STATUS', 'init')
				throw error
			}
		},

		async fetchStats({ commit }, params) {
			commit('SET_PULSE_STATS', [])
			commit('SET_PULSE_STAT_STATUS', 'pending')
			try {
				const { data } = await getStats(params)
				commit('SET_PULSE_STAT_STATUS', 'init')
				commit('SET_PULSE_STATS', data)
				return data
			} catch (error) {
				commit('SET_PULSE_STAT_STATUS', 'init')
				throw error
			}
		},

		async deletePulse({ commit }, params) {
			commit('DELETE_PULSE_STATUS', 'pending')
			try {
				const { data } = await removePulse(params)
				commit('DELETE_PULSE_STATUS', 'init')
				return data
			} catch (error) {
				commit('DELETE_PULSE_STATUS', 'error')
				throw error
			}
		},

		async fetchPulseByLink({ commit }, params) {
			commit('SET_PULSE', {})
			commit('SET_PULSE_STATUS', 'pending')
			try {
				const { data } = await getPulseByLink(params)
				commit('SET_PULSE_STATUS', 'init')
				commit('SET_PULSE', data)
				return data
			} catch (error) {
				commit('SET_PULSE_STATUS', 'init')
				throw error
			}
		},
	},
}
