import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getNotifications = params => Http.get(`/notifications/fetch${serialize(params)}`)

export const getStats = (
	params = {
		language_id: '',
	},
) => Http.get(`notifications/stats?filters[language_id]=${params.language_id}`)

export const newNotification = (params = '') => Http.post('/creator-mode/new-push-notifications', params, { baseURL: 'locale' })

export const updateNotification = (params = '') =>
	Http.put(`/creator-mode/push-notifications/${params.slug}`, params, { baseURL: 'locale' })

export const getNotificationBySlug = slug => {
	return Http.get(`creator-mode/push-notifications/${slug}`, { baseURL: 'locale' })
}
