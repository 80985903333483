const resumes = [
	{
		path: '/resumes/list',
		name: 'jobs-resumes-list',
		component: () => import(/* webpackChunkName: "ResumesList" */ '@/views/jobs/resumes/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default resumes
