import {
	getNotifications,
	getStats,
	getStatsLanguageDeutsch,
	getStatsLanguageEnglish,
	getStatsLanguagePortuguese,
	getStatsLanguageRomanian,
	getStatsLanguageSpanish,
	removeNotification,
} from '@/services/community/contents/notifications/notifications'

export default {
	namespaced: true,
	state: {
		notificationStats: [],
		notificationStatStatus: 'pending',

		notificationLanguageEnglish: [],
		notificationStatusLanguageEnglish: 'pending',

		notificationLanguageDeutsch: [],
		notificationStatusLanguageDeutsch: 'pending',

		notificationLanguagePortuguese: [],
		notificationStatusLanguagePortuguese: 'pending',

		notificationLanguageSpanish: [],
		notificationStatusLanguageSpanish: 'pending',

		notificationLanguageRomanian: [],
		notificationStatusLanguageRomanian: 'pending',

		deleteNotificationStatus: 'pending',
	},
	getters: {
		getNotificationList(state) {
			return state.notificationList
		},
		getNotificationStatus(state) {
			return state.notificationStatus
		},

		getNotificationStats(state) {
			return state.notificationStats
		},
		getNotificationStatStatus(state) {
			return state.notificationStatStatus
		},

		getNotificationLanguageEnglish(state) {
			return state.notificationLanguageEnglish
		},
		getNotificationStatusLanguageEnglish(state) {
			return state.notificationStatusLanguageEnglish
		},

		getNotificationLanguageDeutsch(state) {
			return state.notificationLanguageDeutsch
		},
		getNotificationStatusLanguageDeutsch(state) {
			return state.notificationStatusLanguageDeutsch
		},

		getNotificationLanguagePortuguese(state) {
			return state.notificationLanguagePortuguese
		},
		getNotificationStatusLanguagePortuguese(state) {
			return state.notificationStatusLanguagePortuguese
		},

		getNotificationLanguageSpanish(state) {
			return state.notificationLanguageSpanish
		},
		getNotificationStatusLanguageSpanish(state) {
			return state.notificationStatusLanguageSpanish
		},

		getNotificationLanguageRomanian(state) {
			return state.notificationLanguageRomanian
		},
		getNotificationStatusLanguageRomanian(state) {
			return state.notificationStatusLanguageRomanian
		},
		getDeleteNotificationStatus(state) {
			return state.deleteNotificationStatus
		},
	},
	mutations: {
		SET_NOTIFICATION_LIST(state, value) {
			state.notificationList = value
		},
		SET_NOTIFICATION_STATUS(state, value) {
			state.notificationStatus = value
		},

		SET_NOTIFICATION_STATS(state, value) {
			state.notificationStats = value
		},
		SET_NOTIFICATION_STAT_STATUS(state, value) {
			state.notificationStatStatus = value
		},

		SET_NOTIFICATION_STATS_LANGUAGE_ENGLISH(state, value) {
			state.notificationLanguageEnglish = value
		},
		SET_NOTIFICATION_STAT_STATUS_LANGUAGE_ENGLISH(state, value) {
			state.notificationStatusLanguageEnglish = value
		},

		SET_NOTIFICATION_STATS_LANGUAGE_DEUTSCH(state, value) {
			state.notificationLanguageDeutsch = value
		},
		SET_NOTIFICATION_STAT_STATUS_LANGUAGE_DEUTSCH(state, value) {
			state.notificationStatusLanguageDeutsch = value
		},

		SET_NOTIFICATION_STATS_LANGUAGE_PORTUGUESE(state, value) {
			state.notificationLanguagePortuguese = value
		},
		SET_NOTIFICATION_STAT_STATUS_LANGUAGE_PORTUGUESE(state, value) {
			state.notificationStatusLanguagePortuguese = value
		},

		SET_NOTIFICATION_STATS_LANGUAGE_SPANISH(state, value) {
			state.notificationLanguageSpanish = value
		},
		SET_NOTIFICATION_STAT_STATUS_LANGUAGE_SPANISH(state, value) {
			state.notificationStatusLanguageSpanish = value
		},

		SET_NOTIFICATION_STATS_LANGUAGE_ROMANIAN(state, value) {
			state.notificationLanguageRomanian = value
		},
		SET_NOTIFICATION_STAT_STATUS_LANGUAGE_ROMANIAN(state, value) {
			state.notificationStatusLanguageRomanian = value
		},
		DELETE_NOTIFICATION_STATUS(state, value) {
			state.deleteNotificationStatus = value
		},
	},
	actions: {
		async fetchNotifications({ commit }, params) {
			commit('SET_NOTIFICATION_LIST', [])
			commit('SET_NOTIFICATION_STATUS', 'pending')
			try {
				const { data } = await getNotifications(params)
				commit('SET_NOTIFICATION_STATUS', 'init')
				commit('SET_NOTIFICATION_LIST', data)
				return data
			} catch (error) {
				commit('SET_NOTIFICATION_STATUS', 'init')
				throw error
			}
		},

		async fetchStats({ commit }, params) {
			commit('SET_NOTIFICATION_STATS', [])
			commit('SET_NOTIFICATION_STAT_STATUS', 'pending')
			try {
				const { data } = await getStats(params)
				commit('SET_NOTIFICATION_STAT_STATUS', 'init')
				commit('SET_NOTIFICATION_STATS', data)
				return data
			} catch (error) {
				commit('SET_NOTIFICATION_STAT_STATUS', 'init')
				throw error
			}
		},

		async fetchLanguageEnglish({ commit }, params) {
			commit('SET_NOTIFICATION_STATS_LANGUAGE_ENGLISH', [])
			commit('SET_NOTIFICATION_STAT_STATUS_LANGUAGE_ENGLISH', 'pending')
			try {
				const { data } = await getStatsLanguageEnglish(params)
				commit('SET_NOTIFICATION_STAT_STATUS_LANGUAGE_ENGLISH', 'init')
				commit('SET_NOTIFICATION_STATS_LANGUAGE_ENGLISH', data)
				return data
			} catch (error) {
				commit('SET_NOTIFICATION_STATS_LANGUAGE_ENGLISH', 'init')
				throw error
			}
		},

		async fetchLanguageDeutsch({ commit }, params) {
			commit('SET_NOTIFICATION_STATS_LANGUAGE_DEUTSCH', [])
			commit('SET_NOTIFICATION_STAT_STATUS_LANGUAGE_DEUTSCH', 'pending')
			try {
				const { data } = await getStatsLanguageDeutsch(params)
				commit('SET_NOTIFICATION_STAT_STATUS_LANGUAGE_DEUTSCH', 'init')
				commit('SET_NOTIFICATION_STATS_LANGUAGE_DEUTSCH', data)
				return data
			} catch (error) {
				commit('SET_NOTIFICATION_STATS_LANGUAGE_DEUTSCH', 'init')
				throw error
			}
		},

		async fetchLanguagePortuguese({ commit }, params) {
			commit('SET_NOTIFICATION_STATS_LANGUAGE_PORTUGUESE', [])
			commit('SET_NOTIFICATION_STAT_STATUS_LANGUAGE_PORTUGUESE', 'pending')
			try {
				const { data } = await getStatsLanguagePortuguese(params)
				commit('SET_NOTIFICATION_STAT_STATUS_LANGUAGE_PORTUGUESE', 'init')
				commit('SET_NOTIFICATION_STATS_LANGUAGE_PORTUGUESE', data)
				return data
			} catch (error) {
				commit('SET_NOTIFICATION_STATS_LANGUAGE_PORTUGUESE', 'init')
				throw error
			}
		},

		async fetchLanguageSpanish({ commit }, params) {
			commit('SET_NOTIFICATION_STATS_LANGUAGE_SPANISH', [])
			commit('SET_NOTIFICATION_STAT_STATUS_LANGUAGE_SPANISH', 'pending')
			try {
				const { data } = await getStatsLanguageSpanish(params)
				commit('SET_NOTIFICATION_STAT_STATUS_LANGUAGE_SPANISH', 'init')
				commit('SET_NOTIFICATION_STATS_LANGUAGE_SPANISH', data)
				return data
			} catch (error) {
				commit('SET_NOTIFICATION_STATS_LANGUAGE_SPANISH', 'init')
				throw error
			}
		},

		async fetchLanguageRomanian({ commit }, params) {
			commit('SET_NOTIFICATION_STATS_LANGUAGE_ROMANIAN', [])
			commit('SET_NOTIFICATION_STAT_STATUS_LANGUAGE_ROMANIAN', 'pending')
			try {
				const { data } = await getStatsLanguageRomanian(params)
				commit('SET_NOTIFICATION_STAT_STATUS_LANGUAGE_ROMANIAN', 'init')
				commit('SET_NOTIFICATION_STATS_LANGUAGE_ROMANIAN', data)
				return data
			} catch (error) {
				commit('SET_NOTIFICATION_STATS_LANGUAGE_ROMANIAN', 'init')
				throw error
			}
		},

		async deleteNotification({ commit }, params) {
			console.log(commit)
			console.log(params)
			console.log('....')
			commit('DELETE_NOTIFICATION_STATUS', 'pending')
			try {
				const { data } = await removeNotification(params)
				commit('DELETE_NOTIFICATION_STATUS', 'init')
				return data
			} catch (error) {
				commit('DELETE_NOTIFICATION_STATUS', 'error')
				throw error
			}
		},
	},
}
