import { levelByID, levelCreate, levelDestroy, levelList, levelUpdate } from '@/services/academy/courses/levels.service'

export default {
	namespaced: true,
	state: {
		// SERVICE LEVEL
		levelList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		level: {},

		// STATUS SERVICE LEVEL
		levelStatus: 'pending',
		levelSaveStatus: 'init',
		levelDeleteStatus: 'init',
	},
	getters: {
		// SERVICE LEVEL
		getLevelList(state) {
			return state.levelList
		},
		getLevel(state) {
			return state.level
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE LEVEL
		getLevelDeleteStatus(state) {
			return state.levelDeleteStatus
		},
		getLevelSaveStatus(state) {
			return state.levelSaveStatus
		},
		getLevelStatus(state) {
			return state.levelStatus
		},
	},
	mutations: {
		// SERVICE LEVEL
		LEVEL_LIST(state, value) {
			state.levelList = value
		},
		SET_LEVEL(state, value) {
			state.level = value
		},

		// STATUS SERVICE LEVEL
		LEVEL_STATUS(state, value) {
			state.levelStatus = value
		},
		LEVEL_SAVE_STATUS(state, value) {
			state.levelSaveStatus = value
		},
		LEVEL_DELETE_STATUS(state, value) {
			state.levelDeleteStatus = value
		},
	},
	actions: {
		// Service Level
		async fetchLevelList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('LEVEL_STATUS', 'pending')
			commit('LEVEL_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await levelList(params)
				commit('LEVEL_STATUS', 'init')
				commit('LEVEL_LIST', data)
				return data
			} catch (error) {
				commit('LEVEL_STATUS', 'init')
				throw error
			}
		},
		async fetchLevelByID({ commit }, params) {
			commit('LEVEL_STATUS', 'pending')
			try {
				const { data } = await levelByID(params)
				commit('LEVEL_STATUS', 'init')
				commit('SET_LEVEL', data.data)
				return data
			} catch (error) {
				commit('LEVEL_STATUS', 'init')

				return error
			}
		},
		async saveNewLevel({ commit }, params) {
			commit('LEVEL_SAVE_STATUS', 'pending')
			try {
				const { data } = await levelCreate(params)
				commit('LEVEL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('LEVEL_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateLevel({ commit }, params) {
			commit('LEVEL_SAVE_STATUS', 'pending')
			try {
				const { data } = await levelUpdate(params)
				commit('LEVEL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('LEVEL_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteLevelByID({ commit }, params) {
			commit('LEVEL_DELETE_STATUS', 'pending')
			try {
				const { data } = await levelDestroy(params)
				commit('LEVEL_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('LEVEL_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
