import { deleteRegion, getRegions, saveRegion, updateRegion } from '@/services/community/wikigamer/regions'

export default {
	namespaced: true,
	state: {
		regionList: {
			meta: {
				total: 0,
			},
		},
		regionStatus: 'pending',
	},
	getters: {
		getRegionList(state) {
			return state.regionList
		},
		getRegionStatus(state) {
			return state.regionStatus
		},
	},
	mutations: {
		SET_REGION_LIST(state, value) {
			state.regionList = value
		},
		SET_REGION_STATUS(state, value) {
			state.regionStatus = value
		},
	},
	actions: {
		async fetchRegions({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_REGION_STATUS', 'pending')
			commit('SET_REGION_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getRegions(params)
				commit('SET_REGION_STATUS', 'init')
				commit('SET_REGION_LIST', data)
				return data
			} catch (error) {
				commit('SET_REGION_STATUS', 'init')
				throw error
			}
		},
		async deleteRegionRecord({ commit }, params) {
			commit('SET_REGION_STATUS', 'pending')
			try {
				const { data } = await deleteRegion(params)
				commit('SET_REGION_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_REGION_STATUS', 'init')
				throw error
			}
		},
		async saveRegion({ commit }, params) {
			commit('SET_REGION_STATUS', 'pending')
			try {
				const { data } = await saveRegion(params)
				commit('SET_REGION_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_REGION_STATUS', 'init')
				throw error
			}
		},
		async updateRegion({ commit }, params) {
			commit('SET_REGION_STATUS', 'pending')
			try {
				const { data } = await updateRegion(params)
				commit('SET_REGION_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_REGION_STATUS', 'init')
				throw error
			}
		},
	},
}
