import {
	destroyScheduler,
	getSchedulers,
} from '@/services/community/schedulers'

export default {
	namespaced: true,
	state: {
		schedulerList: {
			meta: {
				total: 0,
			},
		},
		schedulerStatus: 'pending',
	},
	getters: {
		getSchedulerList(state) {
			return state.schedulerList
		},
		getSchedulerStatus(state) {
			return state.schedulerStatus
		},
	},
	mutations: {
		SET_SCHEDULER_LIST(state, value) {
			state.schedulerList = value
		},
		SET_SCHEDULER_STATUS(state, value) {
			state.schedulerStatus = value
		},
	},
	actions: {
		async fetchSchedulers({ commit }, params) {
			commit('SET_SCHEDULER_STATUS', 'pending')
			commit('SET_SCHEDULER_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getSchedulers(params)
				commit('SET_SCHEDULER_STATUS', 'init')
				commit('SET_SCHEDULER_LIST', data)
				return data
			} catch (error) {
				commit('SET_SCHEDULER_STATUS', 'init')
				throw error
			}
		},

		async destroySchedulerRecord({ commit }, params) {
			commit('SET_SCHEDULER_STATUS', 'pending')
			try {
				const { data } = await destroyScheduler(params)
				commit('SET_SCHEDULER_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_SCHEDULER_STATUS', 'init')
				throw error
			}
		},
	},
}
