import Http from '@/services/httpCommunity'

export const getStats = (
	params = {
		languageId: '',
		status_id: 1,
	},
) => Http.get(`quizzes/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.languageId}`)

export const getStatsLanguageEnglish = (
	params = {
		languageId: 1,
		status_id: 1,
	},
) => Http.get(`quizzes/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.languageId}`)

export const getStatsLanguageDeutsch = (
	params = {
		languageId: 2,
		status_id: 1,
	},
) => Http.get(`quizzes/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.languageId}`)

export const getStatsLanguagePortuguese = (
	params = {
		languageId: 3,
		status_id: 1,
	},
) => Http.get(`quizzes/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.languageId}`)

export const getStatsLanguageSpanish = (
	params = {
		languageId: 4,
		status_id: 1,
	},
) => Http.get(`quizzes/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.languageId}`)

export const getStatsLanguageRomanian = (
	params = {
		languageId: 5,
		status_id: 1,
	},
) => Http.get(`quizzes/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.languageId}`)
