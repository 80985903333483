const courses = [
	{
		path: '/academy/courses/courses/list',
		name: 'academy-courses-courses-list',
		component: () => import('@/views/academy/courses/courses/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/academy/courses/courses/new',
		name: 'academy-courses-courses-new',
		component: () => import('@/views/academy/courses/courses/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/academy/courses/courses/edit/:id',
		name: 'academy-courses-courses-edit',
		component: () => import(/* webpackChunkName: "AcademyCoursesEdit" */ '@/views/academy/courses/courses/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/academy/courses/categories/list',
		name: 'academy-courses-categories-list',
		component: () => import('@/views/academy/courses/categories/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/academy/courses/subcategories/list',
		name: 'academy-courses-subcategories-list',
		component: () => import('@/views/academy/courses/subcategories/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/academy/courses/formats/list',
		name: 'academy-courses-formats-list',
		component: () => import('@/views/academy/courses/formats/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/academy/courses/levels/list',
		name: 'academy-courses-levels-list',
		component: () => import('@/views/academy/courses/levels/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default courses
