import {
	getReviews,
	getStats,
	getStatsLanguageDeutsch,
	getStatsLanguageEnglish,
	getStatsLanguagePortuguese,
	getStatsLanguageRomanian,
	getStatsLanguageSpanish,
	removeReview,
} from '@/services/community/contents/reviews/reviews'

export default {
	namespaced: true,
	state: {
		reviewStats: [],
		reviewStatStatus: 'pending',

		reviewLanguageEnglish: [],
		reviewStatusLanguageEnglish: 'pending',

		reviewLanguageDeutsch: [],
		reviewStatusLanguageDeutsch: 'pending',

		reviewLanguagePortuguese: [],
		reviewStatusLanguagePortuguese: 'pending',

		reviewLanguageSpanish: [],
		reviewStatusLanguageSpanish: 'pending',

		reviewLanguageRomanian: [],
		reviewStatusLanguageRomanian: 'pending',

		deleteReviewStatus: 'pending',
	},
	getters: {
		getReviewList(state) {
			return state.reviewList
		},
		getReviewStatus(state) {
			return state.reviewStatus
		},

		getReviewStats(state) {
			return state.reviewStats
		},
		getReviewStatStatus(state) {
			return state.reviewStatStatus
		},

		getReviewLanguageEnglish(state) {
			return state.reviewLanguageEnglish
		},
		getReviewStatusLanguageEnglish(state) {
			return state.reviewStatusLanguageEnglish
		},

		getReviewLanguageDeutsch(state) {
			return state.reviewLanguageDeutsch
		},
		getReviewStatusLanguageDeutsch(state) {
			return state.reviewStatusLanguageDeutsch
		},

		getReviewLanguagePortuguese(state) {
			return state.reviewLanguagePortuguese
		},
		getReviewStatusLanguagePortuguese(state) {
			return state.reviewStatusLanguagePortuguese
		},

		getReviewLanguageSpanish(state) {
			return state.reviewLanguageSpanish
		},
		getReviewStatusLanguageSpanish(state) {
			return state.reviewStatusLanguageSpanish
		},

		getReviewLanguageRomanian(state) {
			return state.reviewLanguageRomanian
		},
		getReviewStatusLanguageRomanian(state) {
			return state.reviewStatusLanguageRomanian
		},
		getDeleteReviewStatus(state) {
			return state.deleteReviewStatus
		},
	},
	mutations: {
		SET_REVIEW_LIST(state, value) {
			state.reviewList = value
		},
		SET_REVIEW_STATUS(state, value) {
			state.reviewStatus = value
		},

		SET_REVIEW_STATS(state, value) {
			state.reviewStats = value
		},
		SET_REVIEW_STAT_STATUS(state, value) {
			state.reviewStatStatus = value
		},

		SET_REVIEW_STATS_LANGUAGE_ENGLISH(state, value) {
			state.reviewLanguageEnglish = value
		},
		SET_REVIEW_STAT_STATUS_LANGUAGE_ENGLISH(state, value) {
			state.reviewStatusLanguageEnglish = value
		},

		SET_REVIEW_STATS_LANGUAGE_DEUTSCH(state, value) {
			state.reviewLanguageDeutsch = value
		},
		SET_REVIEW_STAT_STATUS_LANGUAGE_DEUTSCH(state, value) {
			state.reviewStatusLanguageDeutsch = value
		},

		SET_REVIEW_STATS_LANGUAGE_PORTUGUESE(state, value) {
			state.reviewLanguagePortuguese = value
		},
		SET_REVIEW_STAT_STATUS_LANGUAGE_PORTUGUESE(state, value) {
			state.reviewStatusLanguagePortuguese = value
		},

		SET_REVIEW_STATS_LANGUAGE_SPANISH(state, value) {
			state.reviewLanguageSpanish = value
		},
		SET_REVIEW_STAT_STATUS_LANGUAGE_SPANISH(state, value) {
			state.reviewStatusLanguageSpanish = value
		},

		SET_REVIEW_STATS_LANGUAGE_ROMANIAN(state, value) {
			state.reviewLanguageRomanian = value
		},
		SET_REVIEW_STAT_STATUS_LANGUAGE_ROMANIAN(state, value) {
			state.reviewStatusLanguageRomanian = value
		},
		DELETE_REVIEW_STATUS(state, value) {
			state.deleteReviewStatus = value
		},
	},
	actions: {
		async fetchReviews({ commit }, params) {
			commit('SET_REVIEW_LIST', [])
			commit('SET_REVIEW_STATUS', 'pending')
			try {
				const { data } = await getReviews(params)
				commit('SET_REVIEW_STATUS', 'init')
				commit('SET_REVIEW_LIST', data)
				return data
			} catch (error) {
				commit('SET_REVIEW_STATUS', 'init')
				throw error
			}
		},

		async fetchStats({ commit }, params) {
			commit('SET_REVIEW_STATS', [])
			commit('SET_REVIEW_STAT_STATUS', 'pending')
			try {
				const { data } = await getStats(params)
				commit('SET_REVIEW_STAT_STATUS', 'init')
				commit('SET_REVIEW_STATS', data)
				return data
			} catch (error) {
				commit('SET_REVIEW_STAT_STATUS', 'init')
				throw error
			}
		},

		async fetchLanguageEnglish({ commit }, params) {
			commit('SET_REVIEW_STATS_LANGUAGE_ENGLISH', [])
			commit('SET_REVIEW_STAT_STATUS_LANGUAGE_ENGLISH', 'pending')
			try {
				const { data } = await getStatsLanguageEnglish(params)
				commit('SET_REVIEW_STAT_STATUS_LANGUAGE_ENGLISH', 'init')
				commit('SET_REVIEW_STATS_LANGUAGE_ENGLISH', data)
				return data
			} catch (error) {
				commit('SET_REVIEW_STATS_LANGUAGE_ENGLISH', 'init')
				throw error
			}
		},

		async fetchLanguageDeutsch({ commit }, params) {
			commit('SET_REVIEW_STATS_LANGUAGE_DEUTSCH', [])
			commit('SET_REVIEW_STAT_STATUS_LANGUAGE_DEUTSCH', 'pending')
			try {
				const { data } = await getStatsLanguageDeutsch(params)
				commit('SET_REVIEW_STAT_STATUS_LANGUAGE_DEUTSCH', 'init')
				commit('SET_REVIEW_STATS_LANGUAGE_DEUTSCH', data)
				return data
			} catch (error) {
				commit('SET_REVIEW_STATS_LANGUAGE_DEUTSCH', 'init')
				throw error
			}
		},

		async fetchLanguagePortuguese({ commit }, params) {
			commit('SET_REVIEW_STATS_LANGUAGE_PORTUGUESE', [])
			commit('SET_REVIEW_STAT_STATUS_LANGUAGE_PORTUGUESE', 'pending')
			try {
				const { data } = await getStatsLanguagePortuguese(params)
				commit('SET_REVIEW_STAT_STATUS_LANGUAGE_PORTUGUESE', 'init')
				commit('SET_REVIEW_STATS_LANGUAGE_PORTUGUESE', data)
				return data
			} catch (error) {
				commit('SET_REVIEW_STATS_LANGUAGE_PORTUGUESE', 'init')
				throw error
			}
		},

		async fetchLanguageSpanish({ commit }, params) {
			commit('SET_REVIEW_STATS_LANGUAGE_SPANISH', [])
			commit('SET_REVIEW_STAT_STATUS_LANGUAGE_SPANISH', 'pending')
			try {
				const { data } = await getStatsLanguageSpanish(params)
				commit('SET_REVIEW_STAT_STATUS_LANGUAGE_SPANISH', 'init')
				commit('SET_REVIEW_STATS_LANGUAGE_SPANISH', data)
				return data
			} catch (error) {
				commit('SET_REVIEW_STATS_LANGUAGE_SPANISH', 'init')
				throw error
			}
		},

		async fetchLanguageRomanian({ commit }, params) {
			commit('SET_REVIEW_STATS_LANGUAGE_ROMANIAN', [])
			commit('SET_REVIEW_STAT_STATUS_LANGUAGE_ROMANIAN', 'pending')
			try {
				const { data } = await getStatsLanguageRomanian(params)
				commit('SET_REVIEW_STAT_STATUS_LANGUAGE_ROMANIAN', 'init')
				commit('SET_REVIEW_STATS_LANGUAGE_ROMANIAN', data)
				return data
			} catch (error) {
				commit('SET_REVIEW_STATS_LANGUAGE_ROMANIAN', 'init')
				throw error
			}
		},

		async deleteReview({ commit }, params) {
			console.log(commit)
			console.log(params)
			console.log('....')
			commit('DELETE_REVIEW_STATUS', 'pending')
			try {
				const { data } = await removeReview(params)
				commit('DELETE_REVIEW_STATUS', 'init')
				return data
			} catch (error) {
				commit('DELETE_REVIEW_STATUS', 'error')
				throw error
			}
		},
	},
}
