import {
    deleteDocumentsData, deleteFinancialData,
    getDocumentsInfoByID, getFinancialInfoByID, getMemberByID,
    getProfessionalInfoByID, saveDocumentsData, saveFinancialData,
    saveMember,
    saveProfessionalData,
    updateDocumentsData, updateFinancialData,
    updateMember,
    updateProfessionalData,
} from "@/services/networkSystem/members.service";

export default {
    namespaced: true,
    state: {
        member: {},
        memberStatus: 'pending',
        professionalData: {},
        professionalDataStatus: 'pending',
        professionalSaveStatus: 'pending',
        financialList: [],
        financialListStatus: 'pending',
        documentsList: [],
        documentsListStatus: 'pending',
        financialSaveStatus: 'pending',
        financialDeleteStatus: 'pending',
        documentSaveStatus: 'pending',
        documentDeleteStatus: 'pending',
    },
    getters: {
        getMember(state) {
            return state.member
        },
        getProfessionalData(state) {
            return state.professionalData;
        },
        getFinancialList(state) {
            return state.financialList;
        },
        getDocumentsList(state) {
            return state.documentsList;
        },
    },
    mutations: {
        SET_MEMBER(state,value) {
            state.member = value
        },
        MEMBER_STATUS(state,value) {
            state.memberStatus = value
        },
        SET_PROFISSIONAL_DATA(state, value) {
            state.professionalData = value;
        },
        PROFISSIONAL_DATA_STATUS(state, value) {
            state.professionalDataStatus = value;
        },
        PROFISSIONAL_SAVE_STATUS(state, value) {
            state.professionalSaveStatus = value;
        },
        SET_FINANCIAL_LIST(state, value) {
            state.financialList = value;
        },
        FINANCIAL_LIST_STATUS(state, value) {
            state.financialListStatus = value;
        },
        SET_DOCUMENTS_LIST(state, value) {
            state.documentsList = value;
        },
        DOCUMENTS_LIST_STATUS(state, value) {
            state.documentsListStatus = value;
        },
        FINANCIAL_SAVE_STATUS(state, value) {
            state.financialSaveStatus = value;
        },
        FINANCIAL_DELETE_STATUS(state, value) {
            state.financialDeleteStatus = value;
        },
        REMOVE_FINANCIAL_DATA(state, value) {
            state.financialList.data = state.financialList.data.filter(op => op.id !== value.id)
        },
        DOCUMENT_SAVE_STATUS(state, value) {
            state.documentSaveStatus = value;
        },
        DOCUMENT_DELETE_STATUS(state, value) {
            state.documentDeleteStatus = value;
        },
        REMOVE_DOCUMENT_DATA(state, value) {
            state.documentsList.data = state.documentsList.data.filter(op => op.id !== value.id)
        },
    },
    actions: {
        async fetchMemberById({ state, commit }, params) {
			commit('MEMBER_STATUS', 'pending')
			try {
				const { data } = await getMemberByID(params)
				commit('MEMBER_STATUS', 'init')
				commit('SET_MEMBER', data.data)
				return data
			} catch (error) {
				commit('MEMBER_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
        async fetchProfessionalData({ state, commit }, params) {
            commit('PROFISSIONAL_DATA_STATUS', 'pending');
            try {
                // Make an API call to fetch the professional data using the provided params
                const { data } = await getProfessionalInfoByID(params);
                commit('PROFISSIONAL_DATA_STATUS', 'init');
                commit('SET_PROFISSIONAL_DATA', data.data);
                return data.data;
            } catch (error) {
                commit('PROFISSIONAL_DATA_STATUS', 'init');
                // this._vm.$root.$notify({
                //     message: error.response.data.message,
                //     type: 'danger',
                // });
                return error;
            }
        },

        async fetchFinancialList({ state, commit }, params) {
            commit('FINANCIAL_LIST_STATUS', 'pending');
            try {
                // Make an API call to fetch the financial list using the provided params
                const { data } = await getFinancialInfoByID(params, { per_page: 'all', sort: 'id', order: 'asc' });
                commit('FINANCIAL_LIST_STATUS', 'init');
                commit('SET_FINANCIAL_LIST', data);
                return data;
            } catch (error) {
                commit('FINANCIAL_LIST_STATUS', 'init');
                this._vm.$root.$notify({
                    message: error.response.data.message,
                    type: 'danger',
                });
                return error;
            }
        },

        async fetchDocumentsList({ state, commit }, params) {
            commit('DOCUMENTS_LIST_STATUS', 'pending');
            try {
                // Make an API call to fetch the documents list using the provided params
                const { data } = await getDocumentsInfoByID(params, { per_page: 'all', sort: 'id', order: 'asc' });
                commit('DOCUMENTS_LIST_STATUS', 'init');
                commit('SET_DOCUMENTS_LIST', data);
                return data;
            } catch (error) {
                commit('DOCUMENTS_LIST_STATUS', 'init');
                this._vm.$root.$notify({
                    message: error.response.data.message,
                    type: 'danger',
                });
                return error;
            }
        },

        async saveMember({ state, commit }, params) {
            commit('MEMBER_STATUS', 'pending')
            try {
                const { data } = await saveMember(params)
                commit('MEMBER_STATUS', 'init')
                return data
            } catch (error) {
                commit('MEMBER_STATUS', 'init')
                throw error
            }
        },

        async updateMember({ state, commit }, params) {
            commit('MEMBER_STATUS', 'pending')
            try {
                const { data } = await updateMember(params)
                commit('MEMBER_STATUS', 'init')
                return data
            } catch (error) {
                commit('MEMBER_STATUS', 'init')
                throw error
            }
        },

        async saveProfessionalData({ state, commit }, params) {
            commit('PROFISSIONAL_SAVE_STATUS', 'pending')
            try {
                const { data } = await saveProfessionalData(params)
                commit('PROFISSIONAL_SAVE_STATUS', 'init')
                return data
            } catch (error) {
                commit('PROFISSIONAL_SAVE_STATUS', 'init')
                throw error
            }
        },

        async updateProfessionalData({ state, commit }, params) {
            commit ('PROFISSIONAL_SAVE_STATUS', 'pending')
            try {
                const { data } = await updateProfessionalData(params)
                commit('PROFISSIONAL_SAVE_STATUS', 'init')
            } catch (error) {
                commit('PROFISSIONAL_SAVE_STATUS', 'init')
            throw error
            }
        },

        async saveFinancialInformation({ state, commit }, params) {
            commit('FINANCIAL_SAVE_STATUS', 'pending')
			try {
				const { data } = await saveFinancialData(params.member_id, params)
				commit('FINANCIAL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('FINANCIAL_SAVE_STATUS', 'init')
				throw error
			}
        },

        async updateFinancialInformation({ state, commit }, params) {
            commit('FINANCIAL_SAVE_STATUS', 'pending')
            try {
                const { data } = await updateFinancialData(params.member_id, params)
                commit('FINANCIAL_SAVE_STATUS', 'init')
            } catch (error) {
                commit('FINANCIAL_SAVE_STATUS', 'init')
                throw error
            }
        },

        async deleteFinancialInformation({ commit }, params) {
			commit('FINANCIAL_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteFinancialData(params.member_id, params)
				commit('REMOVE_FINANCIAL_DATA', params)
				commit('FINANCIAL_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('FINANCIAL_DELETE_STATUS', 'error')
				throw error
			}
		},
        async saveDocumentsData({ state, commit }, params) {
            console.log('params => ', params)
            commit('DOCUMENT_SAVE_STATUS', 'pending')
            try {
                const { data } = await saveDocumentsData(params.member_id, params)
                commit('DOCUMENT_SAVE_STATUS', 'init')
                return data
            } catch (error) {
                commit('DOCUMENT_SAVE_STATUS', 'init')
                throw error
            }
        },

        async updateDocumentsData({ state, commit }, params) {
            commit('DOCUMENT_SAVE_STATUS', 'pending')
            try {
                const { data } = await updateDocumentsData(params.member_id, params)
                commit('DOCUMENT_SAVE_STATUS', 'init')
                return data
            } catch (error) {
                commit('DOCUMENT_SAVE_STATUS', 'init')
                throw error
            }
        },
        async deleteDocumentsData({ commit }, params) {
            commit('DOCUMENT_DELETE_STATUS', 'pending')
                try {
                    const { data } = await deleteDocumentsData(params.member_id, params)
                    commit('REMOVE_DOCUMENT_DATA', params)
                    commit('DOCUMENT_DELETE_STATUS', 'init')
                    return data
                } catch (error) {
                    commit('DOCUMENT_DELETE_STATUS', 'error')
                    throw error
                }
            },
        },
}
