import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

// LIST OF ALL TYPES
export const getTypes = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/game-types/fetch${serialize(params)}`)

// SAVE A NEW TYPE
export const saveType = (
	params = {
		category: null,
	},
) => Http.post('/game-types/store', params)

//UPDATE TYPE
export const updateType = (
	params = {
		category: null,
		typeId: null,
	},
) => Http.put(`/game-types/${params.typeId}/update`, params)

// DELETE TYPE
export const deleteType = (
	params = {
		typeId: null,
	},
) => Http.delete(`game-types/${params.typeId}/destroy`)
