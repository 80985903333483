import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getSchedulers = (
	params = {
		per_page: 10,
		page: 1,
		'filter[type]': '',
	},
) => Http.get(`/scheduler/fetch${serialize(params)}`)

export const destroyScheduler = (
	params = {
		schedulerId: null,
	},
) => Http.delete(`/scheduler/${params.schedulerId}/destroy`)
