import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

//Users Genders
export const gendersList = (params = '') => Http.get(`/genders${serialize(params)}`)
export const genderCreate = (params = '') => Http.post('/genders', params)
export const genderByID = (params = '') => Http.get(`/genders/${params}`)
export const genderUpdate = (params = '') => Http.put(`/genders/${params.id}`, params)
export const genderDestroy = (params = '') => Http.delete(`/genders/${params.id}`)

// // // LIST ALL USERS
export const usersList = (params = '') => Http.get(`/users${serialize(params)}`)
// // // GET USER BY ID
export const usersByID = (params = '') => Http.get(`/users/${params}`)
// // // POST NEW USER
export const newUser = (params = '') => Http.post('/users', params)
// // // UPDATE USER
export const updateUser = (params = '') => Http.put(`/users/${params.id}`, params)
// // // ASSIGN USER ROLE
export const updateUserRole = (params = '') => Http.post('/authorization/role-update-user', params)
// // // ASSIGN USER PERMISSION
export const updateUserPermission = (params = '') => Http.post('/authorization/permission-update-user', params)
// // // DELETE USER
export const deleteUserByID = (params = '') => Http.delete(`/users/${params.id}`)
