import {
	batchUpsertGamesCollection,
	destroyCollection,
	destroyGameCollection,
	getCollections,
	saveCollection,
	showCollection,
	updateCollection,
} from '@/services/community/wikigamer/collections'
import { alertBasic } from '@/views/components/alert/demos/code'

export default {
	namespaced: true,
	state: {
		collectionList: {
			meta: {
				total: 0,
			},
			data: [],
		},
		collectionData: null,
		collectionStatus: 'pending',
		collectionGameStatus: 'init',
	},
	getters: {
		getCollectionList(state) {
			return state.collectionList
		},
		getCollectionData(state) {
			return state.collectionData
		},
		getCollectionStatus(state) {
			return state.collectionStatus
		},
		getCollectionGameStatus(state) {
			return state.collectionGameStatus
		},
	},
	mutations: {
		SET_COLLECTION_LIST(state, value) {
			state.collectionList = value
		},
		SET_COLLECTION_DATA(state, value) {
			state.collectionData = value
		},
		SET_COLLECTION_STATUS(state, value) {
			state.collectionStatus = value
		},
		SET_COLLECTION_GAME_STATUS(state, value) {
			state.collectionGameStatus = value
		},
	},
	actions: {
		async fetchCollections({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_COLLECTION_STATUS', 'pending')
			commit('SET_COLLECTION_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getCollections(params)
				commit('SET_COLLECTION_STATUS', 'init')
				commit('SET_COLLECTION_LIST', data)
				return data
			} catch (error) {
				commit('SET_COLLECTION_STATUS', 'init')
				throw error
			}
		},

		async destroyCollectionRecord({ commit }, params) {
			commit('SET_COLLECTION_STATUS', 'pending')
			try {
				const { data } = await destroyCollection(params)
				commit('SET_COLLECTION_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_COLLECTION_STATUS', 'init')
				throw error
			}
		},
		async saveCollection({ commit }, params) {
			commit('SET_COLLECTION_STATUS', 'pending')
			try {
				const { data } = await saveCollection(params)
				if (params.games_id.length > 0) {
					await batchUpsertGamesCollection({ collectionSlug: data.data.slug, games_id: params.games_id })
				}
				commit('SET_COLLECTION_STATUS', 'init')
				commit('SET_COLLECTION_DATA', null)
				return data
			} catch (error) {
				commit('SET_COLLECTION_STATUS', 'init')
				throw error
			}
		},
		async updateCollection({ commit }, params) {
			commit('SET_COLLECTION_STATUS', 'pending')
			try {
				const { data } = await updateCollection(params)
				if (params.games_id.length > 0) {
					await batchUpsertGamesCollection({ collectionSlug: data.data.slug, games_id: params.games_id })
				}
				commit('SET_COLLECTION_STATUS', 'init')
				commit('SET_COLLECTION_DATA', null)
				return data
			} catch (error) {
				commit('SET_COLLECTION_STATUS', 'init')
				throw error
			}
		},
		async showCollection({ commit }, params) {
			commit('SET_COLLECTION_STATUS', 'pending')
			try {
				const { data } = await showCollection(params)
				commit('SET_COLLECTION_STATUS', 'init')
				commit('SET_COLLECTION_DATA', data.data)
				return data
			} catch (error) {
				commit('SET_COLLECTION_STATUS', 'init')
				throw error
			}
		},
		clear({ commit }, params) {
			commit('SET_COLLECTION_STATUS', 'init')
			commit('SET_COLLECTION_DATA', null)
		},
		async destroyGameByCollection({ commit }, params) {
			commit('SET_COLLECTION_GAME_STATUS', 'pending')
			try {
				await destroyGameCollection(params)
				commit('SET_COLLECTION_GAME_STATUS', 'init')
			} catch (error) {
				commit('SET_COLLECTION_GAME_STATUS', 'init')
				throw error
			}
		},
	},
}
