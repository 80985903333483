import {
	allBanksList,
	bankByID,
	bankCreate,
	bankDestroy,
	banksList,
	bankUpdate,
	// EXPENSES CATEGORIES
	categoriesList,
	categoryByID,
	categoryCreate,
	categoryDestroy,
	categoryUpdate,
	//Cupons
	cupomByID,
	cupomCreate,
	cupomDestroy,
	cupomUpdate,
	cuponsList,
	// CURRENCY
	currenciesList,
	currencyByID,
	currencyCreate,
	currencyDestroy,
	currencyUpdate,
	// PAYMENT FEES
	feeByID,
	feeCreate,
	feeDestroy,
	feesList,
	feeUpdate,
	// PAYMENT METHODS
	methodByID,
	methodCreate,
	methodDestroy,
	methodsList,
	methodUpdate,
	// PAYMENT PERIODS
	periodByID,
	periodCreate,
	periodDestroy,
	periodsList,
	periodUpdate,
	// PAYMENT REFUNDS
	refundByID,
	refundCreate,
	refundDestroy,
	refundsList,
	refundUpdate,
	// PAYMENT STATUS
	statusByID,
	statusCreate,
	statusDestroy,
	statusList,
	statusUpdate,
	// PAYMENT TAXES
	taxByID,
	taxCreate,
	taxesList,
	taxUpdate,
	// PAYMENT TYPES
	typeByID,
	typeCreate,
	typeDestroy,
	typesList,
	typeUpdate,
} from '@/services/financial.service'

export default {
	namespaced: true,
	state: {
		// BANKS
		banksList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		bank: {},
		allBanks: {},

		// CUPOMS
		cuponsList: {
			meta: {
				pagination: {},
			},
		},
		cupom: {},

		// CURRENCIES
		currenciesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		currency: {},

		// EXPENSES CATEGORIES
		categoriesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		category: {},

		// PAYMENT METHODS
		methodsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		method: {},

		// PAYMENT PERIODS
		periodsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		period: {},

		// PAYMENT FEES
		feesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		fee: {},

		// PAYMENT REFUNDS
		refundsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		refund: {},

		// TAXES
		taxesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		tax: {},

		// PAYMENT TYPES
		typesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		type: {},

		// PAYMENT STATUS
		statusList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		status: {},

		// STATUS BANK
		banksStatus: 'pending',
		banksSaveStatus: 'init',
		banksDeleteStatus: 'init',

		// STATUS CUPOM
		cuponsStatus: 'pending',
		cuponsSaveStatus: 'init',
		cuponsDeleteStatus: 'init',

		// STATUS CURRENCY
		currenciesStatus: 'pending',
		currenciesSaveStatus: 'init',
		currenciesDeleteStatus: 'init',

		// STATUS EXPENSES CATEGORIES
		categoriesStatus: 'pending',
		categoriesSaveStatus: 'init',
		categoriesDeleteStatus: 'init',

		// STATUS PAYMENT METHODS
		methodsStatus: 'pending',
		methodsSaveStatus: 'init',
		methodsDeleteStatus: 'init',

		// STATUS PAYMENT PERIODS
		periodsStatus: 'pending',
		periodsSaveStatus: 'init',
		periodsDeleteStatus: 'init',

		// STATUS PAYMENT FEES
		feesStatus: 'pending',
		feesSaveStatus: 'init',
		feesDeleteStatus: 'init',

		// STATUS PAYMENT REFUNDS
		refundsStatus: 'pending',
		refundsSaveStatus: 'init',
		refundsDeleteStatus: 'init',

		// STATUS PAYMENT TAXES
		taxesStatus: 'pending',
		taxesSaveStatus: 'init',

		// STATUS PAYMENT TYPES
		typesStatus: 'pending',
		typesSaveStatus: 'init',
		typesDeleteStatus: 'init',

		// STATUS PAYMENT STATUS
		statusStatus: 'pending',
		statusSaveStatus: 'init',
		statusDeleteStatus: 'init',
	},
	getters: {
		// BANKS
		getBanksList(state) {
			return state.banksList
		},
		getBanks(state) {
			return state.bank
		},
		getAllBanksList(state) {
			return state.allBanks
		},

		// CUPONS
		getCuponsList(state) {
			return state.cuponsList
		},
		getCupons(state) {
			return state.cupom
		},

		// CURRENCY
		getCurrenciesList(state) {
			return state.currenciesList
		},
		getCurrencys(state) {
			return state.currency
		},

		// EXPENSES CATEGORIES
		getCategoriesList(state) {
			return state.categoriesList
		},
		getCategory(state) {
			return state.category
		},

		// PAYMENT METHODS
		getMethodsList(state) {
			return state.methodsList
		},
		getMethod(state) {
			return state.method
		},

		// PAYMENT PERIODS
		getPeriodsList(state) {
			return state.periodsList
		},
		getPeriod(state) {
			return state.period
		},

		// PAYMENT FEES
		getFeesList(state) {
			return state.feesList
		},
		getFee(state) {
			return state.fee
		},

		// PAYMENT REFUNDS
		getRefundsList(state) {
			return state.refundsList
		},
		getRefund(state) {
			return state.refund
		},

		// PAYMENT TAXES
		getTaxesList(state) {
			return state.taxesList
		},
		getTax(state) {
			return state.tax
		},
		// PAYMENT TYPES
		getTypesList(state) {
			return state.typesList
		},
		getType(state) {
			return state.type
		},

		// PAYMENT STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// BANKS
		getBanksDeleteStatus(state) {
			return state.banksDeleteStatus
		},
		getBanksSaveStatus(state) {
			return state.banksSaveStatus
		},
		getBanksStatus(state) {
			return state.banksStatus
		},

		// CUPONS
		getCuponsDeleteStatus(state) {
			return state.cuponsDeleteStatus
		},
		getCuponsSaveStatus(state) {
			return state.cuponsSaveStatus
		},
		getCuponsStatus(state) {
			return state.cuponsStatus
		},

		// CURRENCY
		getCurrenciesDeleteStatus(state) {
			return state.currenciesDeleteStatus
		},
		getCurrenciesSaveStatus(state) {
			return state.currenciesSaveStatus
		},
		getCurrenciesStatus(state) {
			return state.currenciesStatus
		},

		// EXPENSES CATEGORIES
		getCategoriesDeleteStatus(state) {
			return state.categoriesDeleteStatus
		},
		getCategoriesSaveStatus(state) {
			return state.categoriesSaveStatus
		},
		getCategoriesStatus(state) {
			return state.categoriesStatus
		},

		// PAYMENT METHODS
		getMethodsDeleteStatus(state) {
			return state.methodsDeleteStatus
		},
		getMethodsSaveStatus(state) {
			return state.methodsSaveStatus
		},
		getMethodsStatus(state) {
			return state.methodsStatus
		},

		// PAYMENT PERIODS
		getPeriodsDeleteStatus(state) {
			return state.periodsDeleteStatus
		},
		getPeriodsSaveStatus(state) {
			return state.periodsSaveStatus
		},
		getPeriodsStatus(state) {
			return state.periodsStatus
		},

		// PAYMENT FEES
		getFeesDeleteStatus(state) {
			return state.feesDeleteStatus
		},
		getFeesSaveStatus(state) {
			return state.feesSaveStatus
		},
		getFeesStatus(state) {
			return state.feesStatus
		},

		// PAYMENT REFUNDS
		getRefundsDeleteStatus(state) {
			return state.refundsDeleteStatus
		},
		getRefundsSaveStatus(state) {
			return state.refundsSaveStatus
		},
		getRefundsStatus(state) {
			return state.refundsStatus
		},

		// PAYMENT TAXES
		getTaxesSaveStatus(state) {
			return state.taxesSaveStatus
		},
		getTaxesStatus(state) {
			return state.taxesStatus
		},

		// PAYMENT TYPES
		getTypesDeleteStatus(state) {
			return state.typesDeleteStatus
		},
		getTypesSaveStatus(state) {
			return state.typesSaveStatus
		},
		getTypesStatus(state) {
			return state.typesStatus
		},

		// PAYMENT STATUS
		getStatusDeleteStatus(state) {
			return state.statusDeleteStatus
		},
		getStatusSaveStatus(state) {
			return state.statusSaveStatus
		},
		getStatusStatus(state) {
			return state.statusStatus
		},
	},
	mutations: {
		// BANK
		BANKS_LIST(state, value) {
			state.banksList = value
		},
		SET_BANK(state, value) {
			state.bank = value
		},
		ALL_BANKS_LIST(state, value) {
			state.allBanks = value
		},

		// CUPOM
		CUPONS_LIST(state, value) {
			state.cuponsList = value
		},
		SET_CUPOM(state, value) {
			state.cupom = value
		},

		// CURRENCIES
		CURRENCIES_LIST(state, value) {
			state.currenciesList = value
		},
		SET_CURRENCY(state, value) {
			state.currency = value
		},

		// PAYMENT CATEGORIES
		CATEGORIES_LIST(state, value) {
			state.categoriesList = value
		},
		SET_CATEGORY(state, value) {
			state.category = value
		},

		// PAYMENT METHODS
		METHODS_LIST(state, value) {
			state.methodsList = value
		},
		SET_METHOD(state, value) {
			state.method = value
		},

		// PAYMENT PERIODS
		PERIODS_LIST(state, value) {
			state.periodsList = value
		},
		SET_PERIOD(state, value) {
			state.period = value
		},

		// PAYMENT FEES
		FEES_LIST(state, value) {
			state.feesList = value
		},
		SET_FEE(state, value) {
			state.fee = value
		},

		// PAYMENT REFUNDS
		REFUNDS_LIST(state, value) {
			state.refundsList = value
		},
		SET_REFUND(state, value) {
			state.refund = value
		},

		// PAYMENT TAXES
		TAXES_LIST(state, value) {
			state.taxesList = value
		},
		SET_TAX(state, value) {
			state.tax = value
		},

		// PAYMENT TYPES
		TYPES_LIST(state, value) {
			state.typesList = value
		},
		SET_TYPE(state, value) {
			state.type = value
		},

		// PAYMENT STATUS
		STATUS_LIST(state, value) {
			state.statusList = value
		},
		SET_STATUS(state, value) {
			state.status = value
		},

		// BANK STATUS
		BANKS_STATUS(state, value) {
			state.banksStatus = value
		},
		BANK_SAVE_STATUS(state, value) {
			state.banksSaveStatus = value
		},
		BANK_DELETE_STATUS(state, value) {
			state.banksDeleteStatus = value
		},

		// CUPOM STATUS
		CUPONS_STATUS(state, value) {
			state.cuponsStatus = value
		},
		CUPOM_SAVE_STATUS(state, value) {
			state.cuponsSaveStatus = value
		},
		CUPOM_DELETE_STATUS(state, value) {
			state.cuponsDeleteStatus = value
		},

		// STATUS CURRENCIES
		CURRENCIES_STATUS(state, value) {
			state.currenciesStatus = value
		},
		CURRENCY_SAVE_STATUS(state, value) {
			state.currenciesSaveStatus = value
		},
		CURRENCY_DELETE_STATUS(state, value) {
			state.currenciesDeleteStatus = value
		},

		// STATUS EXPENSES CATEGORIES
		CATEGORIES_STATUS(state, value) {
			state.categoriesStatus = value
		},
		CATEGORY_SAVE_STATUS(state, value) {
			state.categoriesSaveStatus = value
		},
		CATEGORY_DELETE_STATUS(state, value) {
			state.categoriesDeleteStatus = value
		},

		// STATUS PAYMENT METHODS
		METHODS_STATUS(state, value) {
			state.methodsStatus = value
		},
		METHOD_SAVE_STATUS(state, value) {
			state.methodsSaveStatus = value
		},
		METHOD_DELETE_STATUS(state, value) {
			state.methodsDeleteStatus = value
		},

		// STATUS PAYMENT PERIODS
		PERIODS_STATUS(state, value) {
			state.periodsStatus = value
		},
		PERIOD_SAVE_STATUS(state, value) {
			state.periodsSaveStatus = value
		},
		PERIOD_DELETE_STATUS(state, value) {
			state.periodsDeleteStatus = value
		},

		// STATUS PAYMENT FEES
		FEES_STATUS(state, value) {
			state.feesStatus = value
		},
		FEE_SAVE_STATUS(state, value) {
			state.feesSaveStatus = value
		},
		FEE_DELETE_STATUS(state, value) {
			state.feesDeleteStatus = value
		},

		// STATUS PAYMENT REFUNDS
		REFUNDS_STATUS(state, value) {
			state.refundsStatus = value
		},
		REFUND_SAVE_STATUS(state, value) {
			state.refundsSaveStatus = value
		},
		REFUND_DELETE_STATUS(state, value) {
			state.refundsDeleteStatus = value
		},

		// STATUS PAYMENT TAXES
		TAXES_STATUS(state, value) {
			state.taxesStatus = value
		},
		TAX_SAVE_STATUS(state, value) {
			state.taxesSaveStatus = value
		},

		// STATUS PAYMENT TYPES
		TYPES_STATUS(state, value) {
			state.typesStatus = value
		},
		TYPE_SAVE_STATUS(state, value) {
			state.typesSaveStatus = value
		},
		TYPE_DELETE_STATUS(state, value) {
			state.typesDeleteStatus = value
		},

		// STATUS PAYMENT STATUS
		STATUS_STATUS(state, value) {
			state.statusStatus = value
		},
		STATUS_SAVE_STATUS(state, value) {
			state.statusSaveStatus = value
		},
		STATUS_DELETE_STATUS(state, value) {
			state.statusDeleteStatus = value
		},
	},
	actions: {
		// Banks
		async fetchBanksList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('BANKS_STATUS', 'pending')
			commit('BANKS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await banksList(params)
				commit('BANKS_STATUS', 'init')
				commit('BANKS_LIST', data)
				return data
			} catch (error) {
				commit('BANKS_STATUS', 'init')
				throw error
			}
		},
		async fetchAllBanksList({ commit }) {
			commit('BANKS_STATUS', 'pending')
			try {
				const { data } = await allBanksList()
				commit('BANKS_STATUS', 'init')
				commit('ALL_BANKS_LIST', data)
			} catch (error) {
				commit('BANKS_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async fetchBanksByID({ commit }, params) {
			commit('BANKS_STATUS', 'pending')
			try {
				const { data } = await bankByID(params)
				commit('BANKS_STATUS', 'init')
				commit('SET_BANK', data.data)
				return data
			} catch (error) {
				commit('BANKS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewBanks({ commit }, params) {
			commit('BANK_SAVE_STATUS', 'pending')
			try {
				const { data } = await bankCreate(params)
				commit('BANK_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BANK_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateBanks({ commit }, params) {
			commit('BANK_SAVE_STATUS', 'pending')
			try {
				const { data } = await bankUpdate(params)
				commit('BANK_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BANK_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteBanksByID({ commit }, params) {
			commit('BANK_DELETE_STATUS', 'pending')
			try {
				const { data } = await bankDestroy(params)
				commit('BANK_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('BANK_DELETE_STATUS', 'error')

				return error
			}
		},

		// Cupons
		async fetchCuponsList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('CUPONS_STATUS', 'pending')
			commit('CUPONS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await cuponsList(params)
				commit('CUPONS_STATUS', 'init')
				commit('CUPONS_LIST', data)
				return data
			} catch (error) {
				commit('CUPONS_STATUS', 'init')
				throw error
			}
		},
		async fetchCuponsByID({ commit }, params) {
			commit('CUPONS_STATUS', 'pending')
			try {
				const { data } = await cupomByID(params)
				commit('CUPONS_STATUS', 'init')
				commit('SET_CUPOM', data.data)
				return data
			} catch (error) {
				commit('CUPONS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewCupons({ commit }, params) {
			commit('CUPOM_SAVE_STATUS', 'pending')
			try {
				const { data } = await cupomCreate(params)
				commit('CUPOM_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CUPOM_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCupons({ commit }, params) {
			commit('CUPOM_SAVE_STATUS', 'pending')
			try {
				const { data } = await cupomUpdate(params)
				commit('CUPOM_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CUPOM_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCuponsByID({ commit }, params) {
			commit('CUPOM_DELETE_STATUS', 'pending')
			try {
				const { data } = await cupomDestroy(params)
				commit('CUPOM_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CUPOM_DELETE_STATUS', 'error')

				return error
			}
		},

		// Currencies
		async fetchCurrencyList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('CURRENCIES_STATUS', 'pending')
			commit('CURRENCIES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await currenciesList(params)
				commit('CURRENCIES_STATUS', 'init')
				commit('CURRENCIES_LIST', data)
				return data
			} catch (error) {
				commit('CURRENCIES_STATUS', 'init')
				throw error
			}
		},
		async fetchCurrencyByID({ commit }, params) {
			commit('CURRENCIES_STATUS', 'pending')
			try {
				const { data } = await currencyByID(params)
				commit('CURRENCIES_STATUS', 'init')
				commit('SET_CURRENCY', data.data)
				return data
			} catch (error) {
				commit('CURRENCIES_STATUS', 'init')

				return error
			}
		},
		async saveNewCurrency({ commit }, params) {
			commit('CURRENCY_SAVE_STATUS', 'pending')
			try {
				const { data } = await currencyCreate(params)
				commit('CURRENCY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CURRENCY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCurrency({ commit }, params) {
			commit('CURRENCY_SAVE_STATUS', 'pending')
			try {
				const { data } = await currencyUpdate(params)
				commit('CURRENCY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CURRENCY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCurrencyByID({ commit }, params) {
			commit('CURRENCY_DELETE_STATUS', 'pending')
			try {
				const { data } = await currencyDestroy(params)
				commit('CURRENCY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CURRENCY_DELETE_STATUS', 'error')

				return error
			}
		},

		// Expenses Categories
		async fetchCategoryList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('CATEGORIES_STATUS', 'pending')
			commit('CATEGORIES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await categoriesList(params)
				commit('CATEGORIES_STATUS', 'init')
				commit('CATEGORIES_LIST', data)
				return data
			} catch (error) {
				commit('CATEGORIES_STATUS', 'init')
				throw error
			}
		},
		async fetchCategoryByID({ commit }, params) {
			commit('CATEGORIES_STATUS', 'pending')
			try {
				const { data } = await categoryByID(params)
				commit('CATEGORIES_STATUS', 'init')
				commit('SET_CATEGORY', data.data)
				return data
			} catch (error) {
				commit('CATEGORIES_STATUS', 'init')

				return error
			}
		},
		async saveNewCategory({ commit }, params) {
			commit('CATEGORY_SAVE_STATUS', 'pending')
			try {
				const { data } = await categoryCreate(params)
				commit('CATEGORY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CATEGORY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCategory({ commit }, params) {
			commit('CATEGORY_SAVE_STATUS', 'pending')
			try {
				const { data } = await categoryUpdate(params)
				commit('CATEGORY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CATEGORY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCategoryByID({ commit }, params) {
			commit('CATEGORY_DELETE_STATUS', 'pending')
			try {
				const { data } = await categoryDestroy(params)
				commit('CATEGORY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CATEGORY_DELETE_STATUS', 'error')

				return error
			}
		},

		// Payment Methods
		async fetchMethodList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('METHODS_STATUS', 'pending')
			commit('METHODS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await methodsList(params)
				commit('METHODS_STATUS', 'init')
				commit('METHODS_LIST', data)
				return data
			} catch (error) {
				commit('METHODS_STATUS', 'init')
				throw error
			}
		},
		async fetchMethodByID({ commit }, params) {
			commit('METHODS_STATUS', 'pending')
			try {
				const { data } = await methodByID(params)
				commit('METHODS_STATUS', 'init')
				commit('SET_METHOD', data.data)
				return data
			} catch (error) {
				commit('METHODS_STATUS', 'init')

				return error
			}
		},
		async saveNewMethod({ commit }, params) {
			commit('METHOD_SAVE_STATUS', 'pending')
			try {
				const { data } = await methodCreate(params)
				commit('METHOD_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('METHOD_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateMethod({ commit }, params) {
			commit('METHOD_SAVE_STATUS', 'pending')
			try {
				const { data } = await methodUpdate(params)
				commit('METHOD_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('METHOD_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteMethodByID({ commit }, params) {
			commit('METHOD_DELETE_STATUS', 'pending')
			try {
				const { data } = await methodDestroy(params)
				commit('METHOD_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('METHOD_DELETE_STATUS', 'error')

				return error
			}
		},

		// Payment Periods
		async fetchPeriodList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('PERIODS_STATUS', 'pending')
			commit('PERIODS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await periodsList(params)
				commit('PERIODS_STATUS', 'init')
				commit('PERIODS_LIST', data)
				return data
			} catch (error) {
				commit('PERIODS_STATUS', 'init')
				throw error
			}
		},
		async fetchPeriodByID({ commit }, params) {
			commit('PERIODS_STATUS', 'pending')
			try {
				const { data } = await periodByID(params)
				commit('PERIODS_STATUS', 'init')
				commit('SET_PERIOD', data.data)
				return data
			} catch (error) {
				commit('PERIODS_STATUS', 'init')

				return error
			}
		},
		async saveNewPeriod({ commit }, params) {
			commit('PERIOD_SAVE_STATUS', 'pending')
			try {
				const { data } = await periodCreate(params)
				commit('PERIOD_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PERIOD_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updatePeriod({ commit }, params) {
			commit('PERIOD_SAVE_STATUS', 'pending')
			try {
				const { data } = await periodUpdate(params)
				commit('PERIOD_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PERIOD_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deletePeriodByID({ commit }, params) {
			commit('PERIOD_DELETE_STATUS', 'pending')
			try {
				const { data } = await periodDestroy(params)
				commit('PERIOD_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PERIOD_DELETE_STATUS', 'error')

				return error
			}
		},

		// Payment Fees
		async fetchFeeList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('FEES_STATUS', 'pending')
			commit('FEES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await feesList(params)
				commit('FEES_STATUS', 'init')
				commit('FEES_LIST', data)
				return data
			} catch (error) {
				commit('FEES_STATUS', 'init')
				throw error
			}
		},
		async fetchFeeByID({ commit }, params) {
			commit('FEES_STATUS', 'pending')
			try {
				const { data } = await feeByID(params)
				commit('FEES_STATUS', 'init')
				commit('SET_FEE', data.data)
				return data
			} catch (error) {
				commit('FEES_STATUS', 'init')

				return error
			}
		},
		async saveNewFee({ commit }, params) {
			commit('FEE_SAVE_STATUS', 'pending')
			try {
				const { data } = await feeCreate(params)
				commit('FEE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('FEE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateFee({ commit }, params) {
			commit('FEE_SAVE_STATUS', 'pending')
			try {
				const { data } = await feeUpdate(params)
				commit('FEE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('FEE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteFeeByID({ commit }, params) {
			commit('FEE_DELETE_STATUS', 'pending')
			try {
				const { data } = await feeDestroy(params)
				commit('FEE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('FEE_DELETE_STATUS', 'error')

				return error
			}
		},

		// Payment Refunds
		async fetchRefundList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('REFUNDS_STATUS', 'pending')
			commit('REFUNDS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await refundsList(params)
				commit('REFUNDS_STATUS', 'init')
				commit('REFUNDS_LIST', data)
				return data
			} catch (error) {
				commit('REFUNDS_STATUS', 'init')
				throw error
			}
		},
		async fetchRefundByID({ commit }, params) {
			commit('REFUNDS_STATUS', 'pending')
			try {
				const { data } = await refundByID(params)
				commit('REFUNDS_STATUS', 'init')
				commit('SET_REFUND', data.data)
				return data
			} catch (error) {
				commit('REFUNDS_STATUS', 'init')

				return error
			}
		},
		async saveNewRefund({ commit }, params) {
			commit('REFUND_SAVE_STATUS', 'pending')
			try {
				const { data } = await refundCreate(params)
				commit('REFUND_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('REFUND_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateRefund({ commit }, params) {
			commit('REFUND_SAVE_STATUS', 'pending')
			try {
				const { data } = await refundUpdate(params)
				commit('REFUND_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('REFUND_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteRefundByID({ commit }, params) {
			commit('REFUND_DELETE_STATUS', 'pending')
			try {
				const { data } = await refundDestroy(params)
				commit('REFUND_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('REFUND_DELETE_STATUS', 'error')

				return error
			}
		},

		// Payment Taxes
		async fetchTaxList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('TAXES_STATUS', 'pending')
			commit('TAXES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await taxesList(params)
				commit('TAXES_STATUS', 'init')
				commit('TAXES_LIST', data)
				return data
			} catch (error) {
				commit('TAXES_STATUS', 'init')
				throw error
			}
		},
		async fetchTaxByID({ commit }, params) {
			commit('TAXES_STATUS', 'pending')
			try {
				const { data } = await taxByID(params)
				commit('TAXES_STATUS', 'init')
				commit('SET_TAX', data.data)
				return data
			} catch (error) {
				commit('TAXES_STATUS', 'init')

				return error
			}
		},
		async saveNewTax({ commit }, params) {
			commit('TAX_SAVE_STATUS', 'pending')
			try {
				const { data } = await taxCreate(params)
				commit('TAX_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TAX_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateTax({ commit }, params) {
			commit('TAX_SAVE_STATUS', 'pending')
			try {
				const { data } = await taxUpdate(params)
				commit('TAX_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TAX_SAVE_STATUS', 'init')
				throw error
			}
		},

		// Payment Types
		async fetchTypeList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('TYPES_STATUS', 'pending')
			commit('TYPES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await typesList(params)
				commit('TYPES_STATUS', 'init')
				commit('TYPES_LIST', data)
				return data
			} catch (error) {
				commit('TYPES_STATUS', 'init')
				throw error
			}
		},
		async fetchTypeByID({ commit }, params) {
			commit('TYPES_STATUS', 'pending')
			try {
				const { data } = await typeByID(params)
				commit('TYPES_STATUS', 'init')
				commit('SET_TYPE', data.data)
				return data
			} catch (error) {
				commit('TYPES_STATUS', 'init')

				return error
			}
		},
		async saveNewType({ commit }, params) {
			commit('TYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await typeCreate(params)
				commit('TYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateType({ commit }, params) {
			commit('TYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await typeUpdate(params)
				commit('TYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteTypeByID({ commit }, params) {
			commit('TYPE_DELETE_STATUS', 'pending')
			try {
				const { data } = await typeDestroy(params)
				commit('TYPE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_DELETE_STATUS', 'error')

				return error
			}
		},

		// Payment Status
		async fetchStatusList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('STATUS_STATUS', 'pending')
			commit('STATUS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await statusList(params)
				commit('STATUS_STATUS', 'init')
				commit('STATUS_LIST', data)
				return data
			} catch (error) {
				commit('STATUS_STATUS', 'init')
				throw error
			}
		},
		async fetchStatusByID({ commit }, params) {
			commit('STATUS_STATUS', 'pending')
			try {
				const { data } = await statusByID(params)
				commit('STATUS_STATUS', 'init')
				commit('SET_STATUS', data.data)
				return data
			} catch (error) {
				commit('STATUS_STATUS', 'init')

				return error
			}
		},
		async saveNewStatus({ commit }, params) {
			commit('STATUS_SAVE_STATUS', 'pending')
			try {
				const { data } = await statusCreate(params)
				commit('STATUS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STATUS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateStatus({ commit }, params) {
			commit('STATUS_SAVE_STATUS', 'pending')
			try {
				const { data } = await statusUpdate(params)
				commit('STATUS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STATUS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteStatusByID({ commit }, params) {
			commit('STATUS_DELETE_STATUS', 'pending')
			try {
				const { data } = await statusDestroy(params)
				commit('STATUS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('STATUS_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
