import { serialize } from '@/@core/libs/serialize/util';
import Http from '@/services/http';

export const listLanguages = () => {
	return Http.get('/languages')
}
export const listKeys = params => {
	return Http.get(`/translations-manager/keys${serialize(params)}`)
}
export const getKey = id => {
	return Http.get(`/translations-manager/keys/${id}`)
}
export const editKey = (id, data) => {
	return Http.put(`/translations-manager/keys/${id}`, data)
}
export const createKey = data => {
	return Http.post(`/translations-manager/keys`, data)
}

export const removeKey = params => {
	return Http.delete(`/translations-manager/keys/${params.id}`)
}

export const listProjects = params => {
	return Http.get(`/translations-manager/projects${serialize(params)}`)
}

export const listNetworkSystems = params => {
	return Http.get(`/network-systems${serialize(params)}`)
}

export const createProject = data => {
	return Http.post(`/translations-manager/projects`, data)
}

export const editProject = (id, data) => {
	return Http.post(`/translations-manager/projects/${id}`, data)
}
