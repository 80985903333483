const languageModel = [
	{
		path: '/ai/language-model/list',
		name: 'ai-language-model-list',
		component: () => import('@/views/ai/language-model/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default languageModel
