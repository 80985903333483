import {
	testimonialByID,
	testimonialCreate,
	testimonialDestroy,
	testimonialList,
	testimonialUpdate,
} from '@/services/agency/testimonials.service'

export default {
	namespaced: true,
	state: {
		// SERVICE TESTIMONIAL
		testimonialList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		testimonial: {},

		// STATUS SERVICE TESTIMONIAL
		testimonialStatus: 'pending',
		testimonialSaveStatus: 'init',
		testimonialDeleteStatus: 'init',
	},
	getters: {
		// SERVICE TESTIMONIAL
		getTestimonialList(state) {
			return state.testimonialList
		},
		getTestimonial(state) {
			return state.testimonial
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE TESTIMONIAL
		getTestimonialDeleteStatus(state) {
			return state.testimonialDeleteStatus
		},
		getTestimonialSaveStatus(state) {
			return state.testimonialSaveStatus
		},
		getTestimonialStatus(state) {
			return state.testimonialStatus
		},
	},
	mutations: {
		// SERVICE TESTIMONIAL
		TESTIMONIAL_LIST(state, value) {
			state.testimonialList = value
		},
		SET_TESTIMONIAL(state, value) {
			state.testimonial = value
		},

		// STATUS SERVICE TESTIMONIAL
		TESTIMONIAL_STATUS(state, value) {
			state.testimonialStatus = value
		},
		TESTIMONIAL_SAVE_STATUS(state, value) {
			state.testimonialSaveStatus = value
		},
		TESTIMONIAL_DELETE_STATUS(state, value) {
			state.testimonialDeleteStatus = value
		},
	},
	actions: {
		// Service Testimonial
		async fetchTestimonialList({ commit }, params) {
			commit('TESTIMONIAL_STATUS', 'pending')
			commit('TESTIMONIAL_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await testimonialList(params)
				commit('TESTIMONIAL_STATUS', 'init')
				commit('TESTIMONIAL_LIST', data)
				return data
			} catch (error) {
				commit('TESTIMONIAL_STATUS', 'init')
				throw error
			}
		},
		async fetchTestimonialByID({ commit }, params) {
			commit('TESTIMONIAL_STATUS', 'pending')
			try {
				const { data } = await testimonialByID(params)
				commit('TESTIMONIAL_STATUS', 'init')
				commit('SET_TESTIMONIAL', data.data)
				return data
			} catch (error) {
				commit('TESTIMONIAL_STATUS', 'init')

				return error
			}
		},
		async saveNewTestimonial({ commit }, params) {
			commit('TESTIMONIAL_SAVE_STATUS', 'pending')
			try {
				const { data } = await testimonialCreate(params)
				commit('TESTIMONIAL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TESTIMONIAL_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateTestimonial({ commit }, params) {
			commit('TESTIMONIAL_SAVE_STATUS', 'pending')
			try {
				const { data } = await testimonialUpdate(params)
				commit('TESTIMONIAL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TESTIMONIAL_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteTestimonialByID({ commit }, params) {
			commit('TESTIMONIAL_DELETE_STATUS', 'pending')
			try {
				const { data } = await testimonialDestroy(params)
				commit('TESTIMONIAL_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TESTIMONIAL_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
