import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getWebsites = (
	params = {
		per_page: 'all',
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/game-website-categories/fetch${serialize(params)}`)

// SAVE A NEW WEBSITE
export const saveWebsite = (
	params = {
		category: null,
	},
) => Http.post(`/game-website-categories/store`, params)

//UPDATE WEBSITE
export const updateWebsite = (
	params = {
		category: null,
		websiteId: null,
	},
) => Http.put(`/game-website-categories/${params.websiteId}/update`, params)

// DELETE WEBSITE
export const deleteWebsite = (
	params = {
		websiteId: null,
	},
) => Http.delete(`game-website-categories/${params.websiteId}/destroy`)
