const pushNotifications = [
	{
		path: '/community/contents/push-notifications/list',
		name: 'community-contents-push-notifications-list',
		component: () => import('@/views/community/push-notifications/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default pushNotifications
