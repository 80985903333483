import {
	deleteSocialiteProvider,
	getSocialiteProviders,
	saveSocialiteProvider,
	updateSocialiteProvider,
} from '@/services/socialiteProviders.service'
export default {
	namespaced: true,
	state: {
		socialiteproviderList: {
			meta: {
				total: 0,
			},
		},
		socialiteprovidersStatus: 'pending',
	},
	getters: {
		getSocialiteProviderList(state) {
			return state.socialiteproviderList
		},
		getSocialiteProvidersStatus(state) {
			return state.socialiteprovidersStatus
		},
	},
	mutations: {
		SET_SOCIALITE_PROVIDER_LIST(state, value) {
			state.socialiteproviderList = value
		},
		SET_SOCIALITE_PROVIDER_STATUS(state, value) {
			state.socialiteprovidersStatus = value
		},
	},
	actions: {
		async fetchSocialiteProviders({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_SOCIALITE_PROVIDER_STATUS', 'pending')
			commit('SET_SOCIALITE_PROVIDER_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getSocialiteProviders(params)
				commit('SET_SOCIALITE_PROVIDER_STATUS', 'init')
				commit('SET_SOCIALITE_PROVIDER_LIST', data)
				return data
			} catch (error) {
				commit('SET_SOCIALITE_PROVIDER_STATUS', 'init')
				throw error
			}
		},

		async deleteSocialiteProviderRecord({ commit }, params) {
			commit('SET_SOCIALITE_PROVIDER_STATUS', 'pending')
			try {
				const { data } = await deleteSocialiteProvider(params)
				commit('SET_SOCIALITE_PROVIDER_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_SOCIALITE_PROVIDER_STATUS', 'init')
				throw error
			}
		},
		async saveSocialiteProvider({ commit }, params) {
			commit('SET_SOCIALITE_PROVIDER_STATUS', 'pending')
			try {
				const { data } = await saveSocialiteProvider(params)
				commit('SET_SOCIALITE_PROVIDER_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_SOCIALITE_PROVIDER_STATUS', 'init')
				throw error
			}
		},
		async updateSocialiteProvider({ commit }, params) {
			commit('SET_SOCIALITE_PROVIDER_STATUS', 'pending')
			try {
				const { data } = await updateSocialiteProvider(params)
				commit('SET_SOCIALITE_PROVIDER_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_SOCIALITE_PROVIDER_STATUS', 'init')
				throw error
			}
		},
	},
}
