import { serialize } from '@/@core/libs/serialize/util'
import Http from '../http'

// // WORK HOURS
export const workhourCreate = (params = '') => Http.post('/work-hours', params)
// // // LIST ALL WORK HOURs
export const workhoursList = (params = '') => Http.get(`/work-hours${serialize(params)}`)
// // // GET WORK HOUR BY ID
export const workhourByID = (params = '') => Http.get(`/work-hours/${params}`)
// // // UPDATE WORK HOUR
export const workhourUpdate = (params = '') => Http.put(`/work-hours/${params.id}`, params)
// // // DELETE WORK HOUR
export const workhourDestroy = (params = '') => Http.delete(`/work-hours/${params.id}`)
