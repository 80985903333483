import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getHighlights = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/highlights/fetch${serialize(params)}`)

//SAVE A NEW HIGHLIGHT
export const saveHighlight = (params = {}) => Http.post(`/highlights/store`, params)

//UPDATE HIGHLIGHT
export const updateHighlight = (
	params = {
		highlightId: null,
	},
) => Http.put(`/highlights/${params.highlightId}/update`, params)

//DELETE HIGHLIGHT
export const deleteHighlight = (
	params = {
		highlightId: null,
	},
) => Http.delete(`/highlights/${params.highlightId}/destroy`)
