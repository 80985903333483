import {
	applicationmethodByID,
	applicationmethodCreate,
	applicationmethodDestroy,
	applicationmethodsList,
	applicationmethodUpdate,
} from '@/services/jobs/applicationMethods.service'

export default {
	namespaced: true,
	state: {
		// APPLICATIONMETHODS
		applicationmethodsList: {},
		applicationmethod: {},
		// STATUS APPLICATIONMETHOD
		applicationmethodsStatus: 'pending',
		applicationmethodsSaveStatus: 'init',
		applicationmethodsDeleteStatus: 'init',
	},
	getters: {
		// APPLICATIONMETHOD
		getApplicationMethodsList(state) {
			return state.applicationmethodsList
		},
		getApplicationMethod(state) {
			return state.applicationmethod
		},
		// APPLICATIONMETHOD
		getApplicationMethodsDeleteStatus(state) {
			return state.applicationmethodsDeleteStatus
		},
		getApplicationMethodsSaveStatus(state) {
			return state.applicationmethodsSaveStatus
		},
		getApplicationMethodsStatus(state) {
			return state.applicationmethodsStatus
		},
	},
	mutations: {
		// APPLICATIONMETHODS
		APPLICATIONMETHODS_LIST(state, value) {
			state.applicationmethodsList = value
		},
		SET_APPLICATIONMETHOD(state, value) {
			state.applicationmethod = value
		},
		// STATUS APPLICATIONMETHODS
		APPLICATIONMETHODS_STATUS(state, value) {
			state.applicationmethodsStatus = value
		},
		APPLICATIONMETHOD_SAVE_STATUS(state, value) {
			state.applicationmethodsSaveStatus = value
		},
		APPLICATIONMETHOD_DELETE_STATUS(state, value) {
			state.applicationmethodsDeleteStatus = value
		},
	},
	actions: {
		// ApplicationMethods
		async fetchApplicationMethodList({ commit }, params) {
			console.log('params', params)
			commit('APPLICATIONMETHODS_STATUS', 'pending')
			commit('APPLICATIONMETHODS_LIST', {})
			try {
				const { data } = await applicationmethodsList(params)
				console.log(data)
				commit('APPLICATIONMETHODS_STATUS', 'init')
				commit('APPLICATIONMETHODS_LIST', data)
				return data
			} catch (error) {
				commit('APPLICATIONMETHODS_STATUS', 'init')
				throw error
			}
		},
		async fetchApplicationMethodByID({ commit }, params) {
			commit('APPLICATIONMETHODS_STATUS', 'pending')
			try {
				const { data } = await applicationmethodByID(params)
				commit('APPLICATIONMETHODS_STATUS', 'init')
				commit('SET_APPLICATIONMETHOD', data.data)
				return data
			} catch (error) {
				commit('APPLICATIONMETHODS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewApplicationMethod({ commit }, params) {
			commit('APPLICATIONMETHOD_SAVE_STATUS', 'pending')
			try {
				const { data } = await applicationmethodCreate(params)
				commit('APPLICATIONMETHOD_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('APPLICATIONMETHOD_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateApplicationMethod({ commit }, params) {
			commit('APPLICATIONMETHOD_SAVE_STATUS', 'pending')
			try {
				const { data } = await applicationmethodUpdate(params)
				commit('APPLICATIONMETHOD_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('APPLICATIONMETHOD_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteApplicationMethodByID({ commit }, params) {
			commit('APPLICATIONMETHOD_DELETE_STATUS', 'pending')
			try {
				const { data } = await applicationmethodDestroy(params)
				commit('APPLICATIONMETHOD_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('APPLICATIONMETHOD_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
