import {
	mdiAppsBox,
	mdiArchive,
	mdiChatAlert,
	mdiGamepad,
	mdiGoogleCirclesCommunities,
	mdiHome,
	mdiLicense,
	mdiMessage,
	mdiNewspaperVariantMultipleOutline,
	mdiWikipedia
} from '@mdi/js'

// Array of sections

export default [
	{
		icon: mdiHome,
		title: 'Dashboard',
		to: 'community-dashboard',
	},
	{
		title: 'Community',
		icon: mdiGoogleCirclesCommunities,
		children: [
			{
				title: 'menu.community.communities.communities',
				to: 'community-communities-list',
			},
			{
				title: 'menu.community.communities.creators',
				to: 'community-creators-list',
			},
			{
				title: 'menu.community.communities.features',
				to: 'community-features-list',
			},
			{
				title: 'menu.community.communities.ratings',
				to: 'community-ratings-list',
			},
			{
				title: 'menu.community.communities.comments',
				to: 'community-comments-list',
			},
		],
	},
	{
		title: 'Contents',
		icon: mdiArchive,
		children: [
			{
				title: 'Pulses',
				children: [
					{
						title: 'menu.community.pulses.pulses',
						to: 'community-contents-pulses-list',
					},
					{
						title: 'menu.community.pulses.category',
						to: 'community-contents-pulses-category-list',
					},
					{
						title: 'menu.community.pulses.pending',
						to: 'community-contents-pulses-pending-list',
					},
					{
						title: 'menu.community.pulses.source',
						to: 'community-contents-pulses-source-list',
					},
					{
						title: 'menu.community.pulses.status',
						to: 'community-contents-pulses-status-list',
					},
				],
			},
			{
				title: 'menu.community.videos.videos',
				children: [
					{
						title: 'menu.community.videos.videos',
						to: 'community-contents-videos-list',
					},
					{
						title: 'menu.community.videos.category',
						to: 'community-contents-videos-category-list',
					},
				],
			},
			{
				title: 'menu.community.reviews',
				to: 'community-contents-reviews-list',
			},
			{
				title: 'menu.community.quizzes',
				to: 'community-contents-quizzes-list',
			},
			{
				title: 'menu.community.pushNotifications',
				to: 'community-contents-push-notifications-list',
			},
			{
				title: 'menu.community.Schedulers',
				to: 'community-contents-schedulers-list',
			},
			{
				title: 'menu.community.translationQueue',
				to: 'community-contents-translation-queue-list',
			},
		],
	},
	{
		title: 'App',
		icon: mdiAppsBox,
		children: [
			{
				title: 'menu.accountBenefits',
				to: 'account-benefits-list',
			},
			{
				title: 'Themes',
				children: [
					{
						title: 'menu.themes.appThemes',
						to: 'app-themes-list',
					},
					{
						title: 'menu.themes.projectsVariables',
						to: 'projects-variables-list',
					},
				],
			},
		],
	},

	{
		title: 'Awards',
		icon: mdiLicense,
		children: [
			{
				title: 'menu.awards',
				to: 'community-awards-list',
			},
			{
				title: 'menu.awardsCategories',
				to: 'community-awards-categories-list',
			},
		],
	},

	{
		title: 'Wikigamer',
		icon: mdiWikipedia,
		children: [
			{
				title: 'menu.community.wikigamer.achievements',
				to: 'community-wikigamer-achievements-list',
			},
			{
				title: 'menu.community.wikigamer.characters.characters',
				children: [
					{
						title: 'menu.community.wikigamer.characters.characters',
						to: 'community-wikigamer-characters-list',
					},
					{
						title: 'menu.community.wikigamer.characters.genders',
						to: 'community-wikigamer-characters-genders-list',
					},
					{
						title: 'menu.community.wikigamer.characters.phrases',
						to: 'community-wikigamer-characters-phrases-list',
					},
					{
						title: 'menu.community.wikigamer.characters.species',
						to: 'community-wikigamer-characters-species-list',
					},
					{
						title: 'menu.community.wikigamer.characters.status',
						to: 'community-wikigamer-characters-status-list',
					},
					{
						title: 'menu.community.wikigamer.characters.statusWebsite',
						to: 'community-wikigamer-characters-status-website-list',
					},
				],
			},
			{
				title: 'menu.community.wikigamer.collections',
				to: 'community-wikigamer-collections-list',
			},
			{
				title: 'menu.companies.companies',
				children: [
					{
						title: 'menu.companies.companiesList',
						to: 'companies-list',
					},
					{
						title: 'menu.companies.companiesType',
						to: 'companies-type-list',
					},
				],
			},
			{
				title: 'menu.community.wikigamer.events.events',
				children: [
					{
						title: 'menu.community.wikigamer.events.eventsList',
						to: 'community-wikigamer-events-list',
					},
					{
						title: 'menu.community.wikigamer.events.eventsCategories',
						to: 'community-wikigamer-events-categories-list',
					},
				],
			},
			{
				title: 'menu.community.wikigamer.franchises',
				to: 'community-wikigamer-franchises-list',
			},
			{
				title: 'menu.community.wikigamer.games.games',
				children: [
					{
						title: 'menu.community.wikigamer.games.games',
						to: 'community-wikigamer-games-list',
					},
					{
						title: 'menu.community.wikigamer.games.gamesAchievements',
						to: 'community-wikigamer-games-achievements-list',
					},
					{
						title: 'menu.community.wikigamer.games.gamesEngines',
						to: 'community-wikigamer-games-engines-list',
					},
					{
						title: 'menu.community.wikigamer.games.gamesTypes',
						to: 'community-wikigamer-games-types-list',
					},
					{
						title: 'menu.community.wikigamer.games.gamesGenres',
						to: 'community-wikigamer-games-genres-list',
					},
					{
						title: 'menu.community.wikigamer.games.gamesModes',
						to: 'community-wikigamer-games-modes-list',
					},
					{
						title: 'menu.community.wikigamer.games.gamesPerspectives',
						to: 'community-wikigamer-games-perspectives-list',
					},
					{
						title: 'menu.community.wikigamer.games.gamesStatus',
						to: 'community-wikigamer-games-status-list',
					},
					{
						title: 'menu.community.wikigamer.games.gamesThemes',
						to: 'community-wikigamer-games-themes-list',
					},
					{
						title: 'menu.community.wikigamer.games.gamesWebsites',
						to: 'community-wikigamer-games-websites-list',
					},
				],
			},
			{
				title: 'menu.community.wikigamer.gamesRating.gamesRating',
				children: [
					{
						title: 'ESRB',
						to: 'community-wikigamer-games-rating-esrb-list',
					},
					{
						title: 'Pegi',
						to: 'community-wikigamer-games-rating-pegi-list',
					},
				],
			},
			{
				title: 'menu.community.wikigamer.platforms.platforms',
				children: [
					{
						title: 'menu.community.wikigamer.platforms.platforms',
						to: 'community-wikigamer-platforms-list',
					},
					{
						title: 'menu.community.wikigamer.platforms.generations',
						to: 'community-wikigamer-platforms-generations-list',
					},
					{
						title: 'menu.community.wikigamer.platforms.platformsFamily',
						to: 'community-wikigamer-platforms-family-list',
					},
					{
						title: 'menu.community.wikigamer.platforms.platformsType',
						to: 'community-wikigamer-platforms-types-list',
					},
				],
			},
			{
				title: 'menu.community.wikigamer.mediaTypes',
				to: 'community-wikigamer-media-types-list',
			},
			{
				title: 'menu.community.wikigamer.regions',
				to: 'community-wikigamer-regions-list',
			},
		],
	},
	{
		title: 'e-Sports',
		icon: mdiGamepad,
		children: [
			{
				title: 'Teams',
				to: 'community-esports-teams-list',
			},
		],
	},
	{
		icon: mdiMessage,
		title: 'menu.messages',
		to: 'community-messages-list',
	},
	{
		icon: mdiNewspaperVariantMultipleOutline,
		title: 'Newsletter',
		to: 'community-newsletter-list',
	},

	{
		title: 'menu.reports.title',
		icon: mdiChatAlert,
		children: [
			{
				title: 'menu.reports.title',
				to: 'community-reports-list',
			},
			{
				title: 'categories',
				to: 'community-reports-categories-list',
			},
			{
				title: 'subCategories',
				to: 'community-reports-subcategories-list',
			},
		],
	},
]
