import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

// LIST OF ALL ENGINES
export const getEngines = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/game-engines/fetch${serialize(params)}`)

//SAVE A NEW ENGINE
export const saveEngine = (
	params = {
		name: null,
		image: null,
	},
) => Http.post(`/game-engines/store`, params)

//UPDATE ENGINE
export const updateEngine = (
	params = {
		name: null,
		image: null,
		engineId: null,
	},
) => Http.put(`/game-engines/${params.engineId}/update`, params)

// DELETE ENGINE
export const deleteEngine = (
	params = {
		engineId: null,
	},
) => Http.delete(`/game-engines/${params.engineId}/destroy`, params)
