import { getReviewBySlug, getReviews, getStats, newReview, updateReview } from '@/services/community/contents/reviews'

export default {
	namespaced: true,
	state: {
		reviewList: {
			meta: {
				total: 0,
			},
		},

		reviewStatus: 'init',
		reviewStats: [],
		reviewStatStatus: 'pending',
		review: {},
	},
	getters: {
		getReview(state) {
			return state.review
		},
		getReviewList(state) {
			return state.reviewList
		},
		getReviewStatus(state) {
			return state.reviewStatus
		},
		getReviewStats(state) {
			return state.reviewStats
		},
		getReviewStatStatus(state) {
			return state.reviewStatStatus
		},
	},
	mutations: {
		SET_REVIEW(state, value) {
			state.review = value
		},
		SET_REVIEW_LIST(state, value) {
			state.reviewList = value
		},
		SET_REVIEW_STATUS(state, value) {
			state.reviewStatus = value
		},
		SET_REVIEW_STATS(state, value) {
			state.reviewStats = value
		},
		SET_REVIEW_STAT_STATUS(state, value) {
			state.reviewStatStatus = value
		},
	},
	actions: {
		async fetchReviews({ commit }, params) {
			commit('SET_REVIEW_STATUS', 'pending')
			commit('SET_REVIEW_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getReviews(params)
				commit('SET_REVIEW_STATUS', 'init')
				commit('SET_REVIEW_LIST', data)
				return data
			} catch (error) {
				commit('SET_REVIEW_STATUS', 'init')
				throw error
			}
		},

		async fetchStats({ commit }, params) {
			commit('SET_REVIEW_STATS', [])
			commit('SET_REVIEW_STAT_STATUS', 'pending')
			try {
				const { data } = await getStats(params)
				commit('SET_REVIEW_STAT_STATUS', 'init')
				commit('SET_REVIEW_STATS', data)
				return data
			} catch (error) {
				commit('SET_REVIEW_STAT_STATUS', 'init')
				throw error
			}
		},
		async saveReview({ commit }, params) {
			commit('SET_REVIEW_STATUS', 'pending')
			try {
				const { data } = await newReview(params)
				commit('SET_REVIEW_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_REVIEW_STATUS', 'init')
				throw error
			}
		},
		async updateReview({ commit }, params) {
			commit('SET_REVIEW_STATUS', 'pending')
			try {
				const { data } = await updateReview(params)
				commit('SET_REVIEW_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_REVIEW_STATUS', 'init')
				throw error
			}
		},
		async fetchReviewBySlug({ commit }, params) {
			commit('SET_REVIEW_STATUS', 'pending')
			try {
				const { data } = await getReviewBySlug(params)
				commit('SET_REVIEW_STATUS', 'init')
				commit('SET_REVIEW', data)
				return data
			} catch (error) {
				commit('SET_REVIEW_STATUS', 'init')
				throw error
			}
		},
	},
}
