<template>
	<v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
		<template #activator="{ on, attrs }">
			<v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
				<v-avatar size="40px" v-bind="attrs" color="primary" class="v-avatar-light-bg primary--text" v-on="on">
					<v-img v-if="userData.avatar_url" :src="userData.avatar_url" />
					<v-icon v-else color="primary" size="28">
						{{ icons.mdiAccountOutline }}
					</v-icon>
				</v-avatar>
			</v-badge>
		</template>
		<v-list>
			<div class="pb-3 pt-2">
				<v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
					<v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text">
						<v-img v-if="userData.avatar_url" :src="userData.avatar_url" />
						<v-icon v-else color="primary" size="28">
							{{ icons.mdiAccountOutline }}
						</v-icon>
					</v-avatar>
				</v-badge>
				<div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
					<span class="text--primary font-weight-semibold mb-n1">
						{{ userData.nickname }}
					</span>
					<small class="text--disabled text-capitalize">{{ userData.role }}</small>
				</div>
			</div>

			<v-divider />

			<!-- Profile -->
			<!-- 			<v-list-item :to="{ name: 'apps-user-view', params: { id: 21 } }">
				<v-list-item-icon class="me-2">
					<v-icon size="22">
						{{ icons.mdiAccountOutline }}
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $t('profile') }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item> -->

			<!-- FAQ -->
			<!-- 			<v-list-item :to="{ name: 'page-faq' }">
				<v-list-item-icon class="me-2">
					<v-icon size="22">
						{{ icons.mdiHelpCircleOutline }}
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>FAQ</v-list-item-title>
				</v-list-item-content>
			</v-list-item> -->

			<v-divider class="my-2" />

			<!-- Logout -->
			<v-list-item @click="logoutUser">
				<v-list-item-icon class="me-2">
					<v-icon size="22">
						{{ icons.mdiLogoutVariant }}
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $t('logout') }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import {
	mdiAccountOutline,
	mdiEmailOutline,
	mdiCheckboxMarkedOutline,
	mdiChatOutline,
	mdiCogOutline,
	mdiCurrencyUsd,
	mdiHelpCircleOutline,
	mdiLogoutVariant,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import { initialAbility } from '@/plugins/acl/config'
import { getCurrentInstance } from 'vue'

export default {
	setup() {
		const vm = getCurrentInstance().proxy
		const { router } = useRouter()

		const logoutUser = () => {
			// Remove userData from localStorage
			// ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
			localStorage.removeItem('accessToken')

			// Remove userData & Ability from localStorage
			localStorage.removeItem('userData')
			localStorage.removeItem('userAbility')

			// Reset ability
			// vm.$ability.update(initialAbility)

			// Redirect to login page
			router.push({ name: 'auth-logout' })
		}

		return {
			logoutUser,

			icons: {
				mdiAccountOutline,
				mdiEmailOutline,
				mdiCheckboxMarkedOutline,
				mdiChatOutline,
				mdiCogOutline,
				mdiCurrencyUsd,
				mdiHelpCircleOutline,
				mdiLogoutVariant,
			},
		}
	},
	data() {
		return {
			userData: {},
		}
	},
	mounted() {
		this.userData = this.$authService.getUser()
	},
}
</script>

<style lang="scss">
.user-profile-menu-content {
	.v-list-item {
		min-height: 2.5rem !important;
	}
}
</style>
