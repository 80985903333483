const games = [
	{
		path: '/community/wikigamer/games/achievements/list',
		name: 'community-wikigamer-games-achievements-list',
		component: () => import('@/views/community/wikigamer/games/achievements/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/games/engines/list',
		name: 'community-wikigamer-games-engines-list',
		component: () => import('@/views/community/wikigamer/games/engines/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/games/games/list',
		name: 'community-wikigamer-games-list',
		component: () => import('@/views/community/wikigamer/games/games/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/games/games/new',
		name: 'community-wikigamer-games-create',
		component: () => import('@/views/community/wikigamer/games/games/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/games/games/:gameSlug/edit',
		name: 'community-wikigamer-games-edit',
		component: () => import('@/views/community/wikigamer/games/games/new.vue'),
		meta: {
			layout: 'content',
		},
	},

	{
		path: '/community/wikigamer/games/genres/list',
		name: 'community-wikigamer-games-genres-list',
		component: () => import('@/views/community/wikigamer/games/genres/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/games/modes/list',
		name: 'community-wikigamer-games-modes-list',
		component: () => import('@/views/community/wikigamer/games/modes/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/games/perspectives/list',
		name: 'community-wikigamer-games-perspectives-list',
		component: () => import('@/views/community/wikigamer/games/perspectives/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/games/status/list',
		name: 'community-wikigamer-games-status-list',
		component: () => import('@/views/community/wikigamer/games/status/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/games/themes/list',
		name: 'community-wikigamer-games-themes-list',
		component: () => import('@/views/community/wikigamer/games/themes/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/games/types/list',
		name: 'community-wikigamer-games-types-list',
		component: () => import('@/views/community/wikigamer/games/types/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/games/websites/list',
		name: 'community-wikigamer-games-websites-list',
		component: () => import('@/views/community/wikigamer/games/websites/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default games
