const achievements = [
	{
		path: '/community/wikigamer/achievements/list',
		name: 'community-wikigamer-achievements-list',
		component: () => import('@/views/community/wikigamer/achievements/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default achievements
