import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getPlatforms = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/platforms/fetch${serialize(params)}`)

export const savePlatform = (
	params = {
		name: null,
		url: null,
		status_id: null,
	},
) => Http.post(`/platforms/store`, params)

export const updatePlatform = (
	params = {
		platformSlug: null,
		name: null,
		url: null,
		status_id: null,
	},
) => Http.put(`/platforms/${params.platformSlug}/update`, params)

export const destroyPlatform = (
	params = {
		platformSlug: null,
	},
) => Http.delete(`/platforms/${params.platformSlug}/destroy`)
