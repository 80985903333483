import {
	languageByID,
	languageCreate,
	languageDestroy,
	languagesList,
	languageUpdate
} from '@/services/ai/languageModel.service'

export default {
	namespaced: true,
	state: {
		// LANGUAGES
		languagesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		language: {},

		// STATUS LANGUAGES
		languagesStatus: 'pending',
		languagesSaveStatus: 'init',
		languagesDeleteStatus: 'init',
	},
	getters: {
		// LANGUAGES
		getLanguagesList(state) {
			return state.languagesList
		},
		getLanguage(state) {
			return state.language
		},

		// STATUS LANGUAGES
		getLanguagesDeleteStatus(state) {
			return state.languagesDeleteStatus
		},
		getLanguagesSaveStatus(state) {
			return state.languagesSaveStatus
		},
		getLanguagesStatus(state) {
			return state.languagesStatus
		},
	},
	mutations: {
		// LANGUAGES
		LANGUAGES_LIST(state, value) {
			state.languagesList = value
		},
		SET_LANGUAGE(state, value) {
			state.language = value
		},

		// STATUS LANGUAGES
		LANGUAGES_STATUS(state, value) {
			state.languagesStatus = value
		},
		LANGUAGE_SAVE_STATUS(state, value) {
			state.languagesSaveStatus = value
		},
		LANGUAGE_DELETE_STATUS(state, value) {
			state.languagesDeleteStatus = value
		},
	},
	actions: {
		// LANGUAGES
		async fetchLanguageList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('LANGUAGES_STATUS', 'pending')
			commit('LANGUAGES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await languagesList(params)
				commit('LANGUAGES_STATUS', 'init')
				commit('LANGUAGES_LIST', data)
				return data
			} catch (error) {
				commit('LANGUAGES_STATUS', 'init')
				throw error
			}
		},
		async fetchLanguageByID({ commit }, params) {
			commit('LANGUAGES_STATUS', 'pending')
			try {
				const { data } = await languageByID(params)
				commit('LANGUAGES_STATUS', 'init')
				commit('SET_LANGUAGE', data.data)
				return data
			} catch (error) {
				commit('LANGUAGES_STATUS', 'init')

				return error
			}
		},
		async saveNewLanguage({ commit }, params) {
			commit('LANGUAGE_SAVE_STATUS', 'pending')
			try {
				const { data } = await languageCreate(params)
				commit('LANGUAGE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('LANGUAGE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateLanguage({ commit }, params) {
			commit('LANGUAGE_SAVE_STATUS', 'pending')
			try {
				const { data } = await languageUpdate(params)
				commit('LANGUAGE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('LANGUAGE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteLanguageByID({ commit }, params) {
			commit('LANGUAGE_DELETE_STATUS', 'pending')
			try {
				const { data } = await languageDestroy(params)
				commit('LANGUAGE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('LANGUAGE_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
