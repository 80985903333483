const reviews = [
	{
		path: '/community/contents/reviews/list',
		name: 'community-contents-reviews-list',
		component: () => import('@/views/community/reviews/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/contents/reviews/new',
		name: 'community-contents-reviews-create',
		component: () => import('@/views/community/reviews/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/contents/reviews/edit/:slug',
		name: 'community-contents-reviews-edit',
		component: () => import('@/views/community/reviews/new.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default reviews
