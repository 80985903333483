const experiences = [
	{
		path: '/experiences/list',
		name: 'jobs-experiences-list',
		component: () => import(/* webpackChunkName: "ExperiencesList" */ '@/views/jobs/experiences/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default experiences
