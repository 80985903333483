const profilesCategories = [
	{
		path: '/ai/profiles-categories/list',
		name: 'ai-profiles-categories-list',
		component: () => import('@/views/ai/profiles-categories/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default profilesCategories
