const blog = [
	{
		path: '/blog/posts/list',
		name: 'blog-posts-list',
		component: () => import( /* webpackChunkName: "BlogPostsList" */ '@/views/network/blog/posts/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/blog/posts/create',
		name: 'blog-posts-create',
		component: () => import( /* webpackChunkName: "BlogPostsCreate" */ '@/views/network/blog/posts/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/blog/posts/edit/:id',
		name: 'blog-posts-edit',
		component: () => import( /* webpackChunkName: "BlogPostsEdit" */ '@/views/network/blog/posts/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/blog/categories/list',
		name: 'blog-categories-list',
		component: () => import( /* webpackChunkName: "BlogCategoriesList" */ '@/views/network/blog/categories/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/blog/tags/list',
		name: 'blog-tags-list',
		component: () => import('@/views/network/blog/tags/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default blog
