import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpTV'

// LIST ALL CATEGORIES
export const categoryList = (params = '') => Http.get(`/categories${serialize(params)}`)

export const categoryCreate = (params = '') => Http.post('/categories', params)
export const categoryByID = (params = '') => Http.get(`/categories/${params}`)
export const categoryUpdate = (params = '') => Http.put(`/categories/${params.id}`, params)
export const categoryDestroy = (params = '') => Http.delete(`/categories/${params.id}`)
