import franchises from './franchises'

const club = [
	...franchises,
	{
		path: '/club/dashboard',
		name: 'club-dashboard',
		component: () => import('@/views/club/dashboard/Index.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/club/contact-franchise',
		name: 'club-contact-franchise',
		// component: () => import('@/views/network/blog/posts/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	// {
	// 	path: '/club/newsletter',
	// 	name: 'club-newsletter',
	// 	// component: () => import('@/views/network/blog/posts/list.vue'),
	// 	meta: {
	// 		layout: 'content',
	// 	},
	// },
	// {
	// 	path: '/club/messages',
	// 	name: 'club-messages',
	// 	component: () => import('@/views/network/blog/posts/list.vue'),
	// 	meta: {
	// 		layout: 'content',
	// 	},
	// },
	{
		path: '/club/messages',
		name: 'club-messages-list',
		component: () => import('@/views/messages/Messages.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/club/newsletter',
		name: 'club-newsletter-list',
		component: () => import('@/views/newsletters/Newsletter.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default club
