const esports = [
	{
		path: '/community/esports/teams/list',
		name: 'community-esports-teams-list',
		component: () => import('@/views/community/esports/teams/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/esports/teams/new',
		name: 'community-esports-teams-create',
		component: () => import('@/views/community/esports/teams/new.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default esports
