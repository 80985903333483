const newsletterAgency = [
	{
		path: '/agency/newsletter/categories/list',
		name: 'agency-newsletter-categories-list',
		component: () => import('@/views/agency/newsletter/categories/list.vue'),
		meta: {
			layout: 'content',
		},
	},

	{
		path: '/agency/newsletter/periodicities/list',
		name: 'agency-newsletter-periodicities-list',
		component: () => import('@/views/agency/newsletter/periodicities/list.vue'),
		meta: {
			layout: 'content',
		},
	},

	{
		path: '/agency/newsletter/campaigns/list',
		name: 'agency-newsletter-campaigns-list',
		component: () => import('@/views/agency/newsletter/campaigns/list.vue'),
		meta: {
			layout: 'content',
		},
	},

	{
		path: '/agency/newsletter/results/list',
		name: 'agency-newsletter-results-list',
		component: () => import('@/views/agency/newsletter/results/list.vue'),
		meta: {
			layout: 'content',
		},
	},

	{
		path: '/agency/newsletter',
		name: 'agency-newsletter-list',
		component: () => import('@/views/newsletters/Newsletter.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default newsletterAgency
