import { getUsers, getUserStatsDashboard } from '@/services/main/users.service'

export default {
	namespaced: true,
	state: {
		userList: [],
		userStatus: 'pending',
		userStatsStatus: 'pending',
		savedUserStatus: 'init',
		deletedUserStatus: 'init',
		userStats: [],
	},
	getters: {
		getUserList(state) {
			return state.userList
		},
		getUserStatus(state) {
			return state.userStatus
		},
		getSavedUserStatus(state) {
			return state.savedUserStatus
		},
		getDeletedUserStatus(state) {
			return state.deletedUserStatus
		},
		getUserStats(state) {
			return state.userStats
		},
		getUserStatsStatus(state) {
			return state.userStatsStatus
		},
	},
	mutations: {
		SET_USER_LIST(state, value) {
			state.userList = value
		},
		SET_USER_STATUS(state, value) {
			state.userStatus = value
		},
		SET_SAVED_USER_STATUS(state, value) {
			state.savedUserStatus = value
		},
		SET_DELETED_USER_STATUS(state, value) {
			state.deletedUserStatus = value
		},
		SET_USER_STATS(state, value){
			state.userStats = value
		},
		SET_USER_STATUS_STATS(state, value){
			state.userStatsStatus = value
		},
	},
	actions: {
		async fetchUsers({ commit }, params) {
			commit('SET_USER_LIST', [])
			commit('SET_USER_STATUS', 'pending')
			try {
				const { data } = await getUsers(params)
				commit('SET_USER_STATUS', 'init')
				commit('SET_USER_LIST', data)
				return data
			} catch (error) {
				commit('SET_USER_STATUS', 'init')
				throw error
			}
		},
		async fetchStats({ commit }, params) {
			commit('SET_USER_STATS', [])
			commit('SET_USER_STATUS_STATS', 'pending')
			try {
				const { data } = await getUserStatsDashboard(params)
				commit('SET_USER_STATUS_STATS', 'init')
				commit('SET_USER_STATS', data)
				return data
			} catch (error) {
				commit('SET_USER_STATUS_STATS', 'init')
				throw error
			}
		},
	},
}
