import accountBenefits from './account-benefits'
import awards from './awards'
import communityM from './community'
import contents from './contents'
import esports from './esports'
import reports from './reports'
import appThemes from './themes'
import wikigamer from './wikigamer'

const community = [
	...contents,
	...esports,
	...communityM,
	...wikigamer,
	...appThemes,
	...accountBenefits,
	...reports,
	...awards,
	{
		path: '/community/dashboard',
		name: 'community-dashboard',
		component: () => import('@/views/community/dashboard/Index.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/messages',
		name: 'community-messages-list',
		component: () => import('@/views/messages/Messages.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/newsletter',
		name: 'community-newsletter-list',
		component: () => import('@/views/newsletters/Newsletter.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/releases',
		name: 'community-releases-list',
		component: () => import('@/views/community/releases/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default community
