const vacancies = [
	{
		path: '/vacancies/list',
		name: 'jobs-vacancies-list',
		component: () => import(/* webpackChunkName: "VacanciesList" */ '@/views/jobs/vacancies/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/vacancies/create',
		name: 'jobs-vacancies-create',
		component: () => import(/* webpackChunkName: "VacanciesCreate" */ '@/views/jobs/vacancies/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/vacancies/edit/:id',
		name: 'jobs-vacancies-edit',
		component: () => import(/* webpackChunkName: "VacanciesCreate" */ '@/views/jobs/vacancies/new.vue'),
		meta: {
			layout: 'content',
		},
	},

	{
		path: '/vacancies-status/list',
		name: 'jobs-vacancies-status-list',
		component: () => import(/* webpackChunkName: "VacanciesStatusList" */ '@/views/jobs/vacancies-status/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default vacancies
