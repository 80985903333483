import Http from '@/services/httpCommunity'

export const getReviews = (
	params = {
		per_page: 10,
		page: 1,
		'filter[language_id]': '',
		'filter[term]': '',
	},
) => Http.get(`/reviews/fetch${serialize(params)}`)

export const getStats = (
	params = {
		language_id: '',
		status_id: 1,
	},
) => Http.get(`reviews/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.language_id}`)

export const getStatsLanguageEnglish = (
	params = {
		language_id: 1,
		status_id: 1,
	},
) => Http.get(`reviews/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.language_id}`)

export const getStatsLanguageDeutsch = (
	params = {
		language_id: 2,
		status_id: 1,
	},
) => Http.get(`reviews/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.language_id}`)

export const getStatsLanguagePortuguese = (
	params = {
		language_id: 3,
		status_id: 1,
	},
) => Http.get(`reviews/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.language_id}`)

export const getStatsLanguageSpanish = (
	params = {
		language_id: 4,
		status_id: 1,
	},
) => Http.get(`reviews/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.language_id}`)

export const getStatsLanguageRomanian = (
	params = {
		language_id: 5,
		status_id: 1,
	},
) => Http.get(`reviews/stats?filters[status_id]=${params.status_id}&filters[language_id]=${params.language_id}`)

export const translateReview = (params = '') => Http.put(`/creator-mode/translate-review/${params.slug}`, params, { baseURL: 'locale' })

export const removeReview = params => {
	return Http.delete(`/creator-mode/review/${params.slug}`, { baseURL: 'locale' })
}
