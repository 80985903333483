import {
	getStats,
	getStatsLanguageDeutsch,
	getStatsLanguageEnglish,
	getStatsLanguagePortuguese,
	getStatsLanguageRomanian,
	getStatsLanguageSpanish,
	getVideos,
} from '@/services/community/contents/videos/videos'

export default {
	namespaced: true,
	state: {
		videoStats: [],
		videoStatStatus: 'pending',

		videoLanguageEnglish: [],
		videoStatusLanguageEnglish: 'pending',

		videoLanguageDeutsch: [],
		videoStatusLanguageDeutsch: 'pending',

		videoLanguagePortuguese: [],
		videoStatusLanguagePortuguese: 'pending',

		videoLanguageSpanish: [],
		videoStatusLanguageSpanish: 'pending',

		videoLanguageRomanian: [],
		videoStatusLanguageRomanian: 'pending',
	},
	getters: {
		getVideoList(state) {
			return state.videoList
		},
		getVideoStatus(state) {
			return state.videoStatus
		},

		getVideoStats(state) {
			return state.videoStats
		},
		getVideoStatStatus(state) {
			return state.videoStatStatus
		},

		getVideoLanguageEnglish(state) {
			return state.videoLanguageEnglish
		},
		getVideoStatusLanguageEnglish(state) {
			return state.videoStatusLanguageEnglish
		},

		getVideoLanguageDeutsch(state) {
			return state.videoLanguageDeutsch
		},
		getVideoStatusLanguageDeutsch(state) {
			return state.videoStatusLanguageDeutsch
		},

		getVideoLanguagePortuguese(state) {
			return state.videoLanguagePortuguese
		},
		getVideoStatusLanguagePortuguese(state) {
			return state.videoStatusLanguagePortuguese
		},

		getVideoLanguageSpanish(state) {
			return state.videoLanguageSpanish
		},
		getVideoStatusLanguageSpanish(state) {
			return state.videoStatusLanguageSpanish
		},

		getVideoLanguageRomanian(state) {
			return state.videoLanguageRomanian
		},
		getVideoStatusLanguageRomanian(state) {
			return state.videoStatusLanguageRomanian
		},
	},
	mutations: {
		SET_VIDEO_LIST(state, value) {
			state.videoList = value
		},
		SET_VIDEO_STATUS(state, value) {
			state.videoStatus = value
		},

		SET_VIDEO_STATS(state, value) {
			state.videoStats = value
		},
		SET_VIDEO_STAT_STATUS(state, value) {
			state.videoStatStatus = value
		},

		SET_VIDEO_STATS_LANGUAGE_ENGLISH(state, value) {
			state.videoLanguageEnglish = value
		},
		SET_VIDEO_STAT_STATUS_LANGUAGE_ENGLISH(state, value) {
			state.videoStatusLanguageEnglish = value
		},

		SET_VIDEO_STATS_LANGUAGE_DEUTSCH(state, value) {
			state.videoLanguageDeutsch = value
		},
		SET_VIDEO_STAT_STATUS_LANGUAGE_DEUTSCH(state, value) {
			state.videoStatusLanguageDeutsch = value
		},

		SET_VIDEO_STATS_LANGUAGE_PORTUGUESE(state, value) {
			state.videoLanguagePortuguese = value
		},
		SET_VIDEO_STAT_STATUS_LANGUAGE_PORTUGUESE(state, value) {
			state.videoStatusLanguagePortuguese = value
		},

		SET_VIDEO_STATS_LANGUAGE_SPANISH(state, value) {
			state.videoLanguageSpanish = value
		},
		SET_VIDEO_STAT_STATUS_LANGUAGE_SPANISH(state, value) {
			state.videoStatusLanguageSpanish = value
		},

		SET_VIDEO_STATS_LANGUAGE_ROMANIAN(state, value) {
			state.videoLanguageRomanian = value
		},
		SET_VIDEO_STAT_STATUS_LANGUAGE_ROMANIAN(state, value) {
			state.videoStatusLanguageRomanian = value
		},
	},
	actions: {
		async fetchVideos({ commit }, params) {
			commit('SET_VIDEO_LIST', [])
			commit('SET_VIDEO_STATUS', 'pending')
			try {
				const { data } = await getVideos(params)
				commit('SET_VIDEO_STATUS', 'init')
				commit('SET_VIDEO_LIST', data)
				return data
			} catch (error) {
				commit('SET_VIDEO_STATUS', 'init')
				throw error
			}
		},

		async fetchStats({ commit }, params) {
			commit('SET_VIDEO_STATS', [])
			commit('SET_VIDEO_STAT_STATUS', 'pending')
			try {
				const { data } = await getStats(params)
				commit('SET_VIDEO_STAT_STATUS', 'init')
				commit('SET_VIDEO_STATS', data)
				return data
			} catch (error) {
				commit('SET_VIDEO_STAT_STATUS', 'init')
				throw error
			}
		},

		async fetchLanguageEnglish({ commit }, params) {
			commit('SET_VIDEO_STATS_LANGUAGE_ENGLISH', [])
			commit('SET_VIDEO_STAT_STATUS_LANGUAGE_ENGLISH', 'pending')
			try {
				const { data } = await getStatsLanguageEnglish(params)
				commit('SET_VIDEO_STAT_STATUS_LANGUAGE_ENGLISH', 'init')
				commit('SET_VIDEO_STATS_LANGUAGE_ENGLISH', data)
				return data
			} catch (error) {
				commit('SET_VIDEO_STATS_LANGUAGE_ENGLISH', 'init')
				throw error
			}
		},

		async fetchLanguageDeutsch({ commit }, params) {
			commit('SET_VIDEO_STATS_LANGUAGE_DEUTSCH', [])
			commit('SET_VIDEO_STAT_STATUS_LANGUAGE_DEUTSCH', 'pending')
			try {
				const { data } = await getStatsLanguageDeutsch(params)
				commit('SET_VIDEO_STAT_STATUS_LANGUAGE_DEUTSCH', 'init')
				commit('SET_VIDEO_STATS_LANGUAGE_DEUTSCH', data)
				return data
			} catch (error) {
				commit('SET_VIDEO_STATS_LANGUAGE_DEUTSCH', 'init')
				throw error
			}
		},

		async fetchLanguagePortuguese({ commit }, params) {
			commit('SET_VIDEO_STATS_LANGUAGE_PORTUGUESE', [])
			commit('SET_VIDEO_STAT_STATUS_LANGUAGE_PORTUGUESE', 'pending')
			try {
				const { data } = await getStatsLanguagePortuguese(params)
				commit('SET_VIDEO_STAT_STATUS_LANGUAGE_PORTUGUESE', 'init')
				commit('SET_VIDEO_STATS_LANGUAGE_PORTUGUESE', data)
				return data
			} catch (error) {
				commit('SET_VIDEO_STATS_LANGUAGE_PORTUGUESE', 'init')
				throw error
			}
		},

		async fetchLanguageSpanish({ commit }, params) {
			commit('SET_VIDEO_STATS_LANGUAGE_SPANISH', [])
			commit('SET_VIDEO_STAT_STATUS_LANGUAGE_SPANISH', 'pending')
			try {
				const { data } = await getStatsLanguageSpanish(params)
				commit('SET_VIDEO_STAT_STATUS_LANGUAGE_SPANISH', 'init')
				commit('SET_VIDEO_STATS_LANGUAGE_SPANISH', data)
				return data
			} catch (error) {
				commit('SET_VIDEO_STATS_LANGUAGE_SPANISH', 'init')
				throw error
			}
		},

		async fetchLanguageRomanian({ commit }, params) {
			commit('SET_VIDEO_STATS_LANGUAGE_ROMANIAN', [])
			commit('SET_VIDEO_STAT_STATUS_LANGUAGE_ROMANIAN', 'pending')
			try {
				const { data } = await getStatsLanguageRomanian(params)
				commit('SET_VIDEO_STAT_STATUS_LANGUAGE_ROMANIAN', 'init')
				commit('SET_VIDEO_STATS_LANGUAGE_ROMANIAN', data)
				return data
			} catch (error) {
				commit('SET_VIDEO_STATS_LANGUAGE_ROMANIAN', 'init')
				throw error
			}
		},
	},
}
