import {
	audienceByID,
	audienceCreate,
	audienceDestroy,
	audienceList,
	audienceUpdate,
} from '@/services/agency/audiences.service'

export default {
	namespaced: true,
	state: {
		// SERVICE AUDIENCE
		audienceList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		audience: {},

		// STATUS SERVICE AUDIENCE
		audienceStatus: 'pending',
		audienceSaveStatus: 'init',
		audienceDeleteStatus: 'init',
	},
	getters: {
		// SERVICE AUDIENCE
		getAudienceList(state) {
			return state.audienceList
		},
		getAudience(state) {
			return state.audience
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE AUDIENCE
		getAudienceDeleteStatus(state) {
			return state.audienceDeleteStatus
		},
		getAudienceSaveStatus(state) {
			return state.audienceSaveStatus
		},
		getAudienceStatus(state) {
			return state.audienceStatus
		},
	},
	mutations: {
		// SERVICE AUDIENCE
		AUDIENCE_LIST(state, value) {
			state.audienceList = value
		},
		SET_AUDIENCE(state, value) {
			state.audience = value
		},

		// STATUS SERVICE AUDIENCE
		AUDIENCE_STATUS(state, value) {
			state.audienceStatus = value
		},
		AUDIENCE_SAVE_STATUS(state, value) {
			state.audienceSaveStatus = value
		},
		AUDIENCE_DELETE_STATUS(state, value) {
			state.audienceDeleteStatus = value
		},
	},
	actions: {
		// Service Audience
		async fetchAudienceList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('AUDIENCE_STATUS', 'pending')
			commit('AUDIENCE_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await audienceList(params)
				commit('AUDIENCE_STATUS', 'init')
				commit('AUDIENCE_LIST', data)
				return data
			} catch (error) {
				commit('AUDIENCE_STATUS', 'init')
				throw error
			}
		},
		async fetchAudienceByID({ commit }, params) {
			commit('AUDIENCE_STATUS', 'pending')
			try {
				const { data } = await audienceByID(params)
				commit('AUDIENCE_STATUS', 'init')
				commit('SET_AUDIENCE', data.data)
				return data
			} catch (error) {
				commit('AUDIENCE_STATUS', 'init')

				return error
			}
		},
		async saveNewAudience({ commit }, params) {
			commit('AUDIENCE_SAVE_STATUS', 'pending')
			try {
				const { data } = await audienceCreate(params)
				commit('AUDIENCE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('AUDIENCE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateAudience({ commit }, params) {
			commit('AUDIENCE_SAVE_STATUS', 'pending')
			try {
				const { data } = await audienceUpdate(params)
				commit('AUDIENCE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('AUDIENCE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteAudienceByID({ commit }, params) {
			commit('AUDIENCE_DELETE_STATUS', 'pending')
			try {
				const { data } = await audienceDestroy(params)
				commit('AUDIENCE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('AUDIENCE_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
