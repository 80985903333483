import Http from '@/services/httpCommunity'

export const getHighlights = (
	params = {
		perPage: 10,
		page: 1,
		search: '',
		languageId: 1,
		status: 1,
	},
) =>
	Http.get(
		`publicity-highlights-banners/fetch?perPage=${params.perPage}&page=${params.page}&search=${params.search}&filters[language_id]=${params.languageId}&filters[status]=${params.status}`,
	)

export const getStats = (
	params = {
		languageId: '',
		status: 1,
	},
) =>
	Http.get(
		`publicity-highlights-banners/stats?filters[status]=${params.status}&filters[language_id]=${params.languageId}`,
	)

export const getStatsLanguageEnglish = (
	params = {
		languageId: 1,
		status: 1,
	},
) =>
	Http.get(
		`publicity-highlights-banners/stats?filters[status]=${params.status}&filters[language_id]=${params.languageId}`,
	)

export const getStatsLanguageDeutsch = (
	params = {
		languageId: 2,
		status: 1,
	},
) =>
	Http.get(
		`publicity-highlights-banners/stats?filters[status]=${params.status}&filters[language_id]=${params.languageId}`,
	)

export const getStatsLanguagePortuguese = (
	params = {
		languageId: 3,
		status: 1,
	},
) =>
	Http.get(
		`publicity-highlights-banners/stats?filters[status]=${params.status}&filters[language_id]=${params.languageId}`,
	)

export const getStatsLanguageSpanish = (
	params = {
		languageId: 4,
		status: 1,
	},
) =>
	Http.get(
		`publicity-highlights-banners/stats?filters[status]=${params.status}&filters[language_id]=${params.languageId}`,
	)

export const getStatsLanguageRomanian = (
	params = {
		languageId: 5,
		status: 1,
	},
) =>
	Http.get(
		`publicity-highlights-banners/stats?filters[status]=${params.status}&filters[language_id]=${params.languageId}`,
	)
