import languageModel from './language-model'
import profiles from './profiles'
import profilesCategories from './profiles-categories'
import services from './services'
import tokens from './tokens'

const ai = [
	{
		path: '/ai/dashboard',
		name: 'ai-dashboard',
		component: () => import('@/views/ai/dashboard/Index.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/ai/messages',
		name: 'ai-messages-list',
		component: () => import('@/views/messages/Messages.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/ai/newsletter',
		name: 'ai-newsletter-list',
		component: () => import('@/views/newsletters/Newsletter.vue'),
		meta: {
			layout: 'content',
		},
	},
	...profilesCategories,
	...profiles,
	...services,
	...tokens,
	...languageModel,
]

export default ai
