const application = [
	{
		path: '/application-method/list',
		name: 'jobs-application-method-list',
		component: () =>
			import(/* webpackChunkName: "applicationMethodList" */ '@/views/jobs/application-method/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/application-status/list',
		name: 'jobs-application-status-list',
		component: () =>
			import(/* webpackChunkName: "applicationStatusList" */ '@/views/jobs/application-status/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default application
