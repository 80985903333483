import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getThemes = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/themes/fetch${serialize(params)}`)

// SAVE THEME
export const saveTheme = (
	params = {
		name: null,
	},
) => Http.post(`/themes/store`, params)

// UPDATE THEME
export const updateTheme = (
	params = {
		name: null,
		themeId: null,
	},
) => Http.put(`/themes/${params.themeId}/update`, params)

// DELETE THEME
export const deleteTheme = (
	params = {
		themeId: null,
	},
) => Http.delete(`/themes/${params.themeId}/destroy`)
