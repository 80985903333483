import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getFranchises = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/franchises/fetch${serialize(params)}`)

export const destroyFranchise = (
	params = {
		franchiseSlug: null,
	},
) => Http.delete(`/franchises/${params.franchiseSlug}/destroy`)

export const saveFranchise = (
	params = {
		name: null,
		url: null,
		status_id: null,
	},
) => Http.post(`/franchises/store`, params)

export const updateFranchise = (
	params = {
		franchiseSlug: null,
		name: null,
		url: null,
		status_id: null,
		games: null,
	},
) => Http.put(`/franchises/${params.franchiseSlug}/update`, params)

export const showFranchise = (
	params = {
		franchiseSlug: null,
	},
) => Http.get(`/franchises/${params.franchiseSlug}/show`)

export const batchUpsertGamesFranchise = (
	params = {
		franchiseSlug: null,
		games_id: null,
	},
) => Http.post(`/franchises/batch/games/${params.franchiseSlug}/upsert`, params)
export const destroyGameFranchise = (
	params = {
		gameId: null,
	},
) => Http.delete(`/franchises/games/${params.franchiseSlug}/destroy`, { data: { game_id: params.gameId } })
