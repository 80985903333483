import { deleteType, getTypes, saveType, updateType } from '@/services/community/wikigamer/games/types'
export default {
	namespaced: true,
	state: {
		typeList: {
			meta: {
				total: 0,
			},
		},
		typeStatus: 'pending',
	},
	getters: {
		getTypeList(state) {
			return state.typeList
		},
		getTypeStatus(state) {
			return state.typeStatus
		},
	},
	mutations: {
		SET_TYPE_LIST(state, val) {
			state.typeList = val
		},
		SET_TYPE_STATUS(state, val) {
			state.typeStatus = val
		},
	},
	actions: {
		async fetchTypes({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_TYPE_STATUS', 'pending')
			commit('SET_TYPE_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getTypes(params)
				commit('SET_TYPE_STATUS', 'init')
				commit('SET_TYPE_LIST', data)
				return data
			} catch (error) {
				commit('SET_TYPE_STATUS', 'init')
				throw error
			}
		},

		async deleteTypeRecord({ commit }, params) {
			commit('SET_TYPE_STATUS', 'pending')
			try {
				const { data } = await deleteType(params)
				commit('SET_TYPE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_TYPE_STATUS', 'init')
				throw error
			}
		},
		async saveType({ commit }, params) {
			commit('SET_TYPE_STATUS', 'pending')
			try {
				const { data } = await saveType(params)
				commit('SET_TYPE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_TYPE_STATUS', 'init')
				throw error
			}
		},
		async updateType({ commit }, params) {
			commit('SET_TYPE_STATUS', 'pending')
			try {
				const data = await updateType(params)
				commit('SET_TYPE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_TYPE_STATUS', 'init')
				throw error
			}
		},
	},
}
