import {
	getHighlights,
	getStats,
	getStatsLanguageDeutsch,
	getStatsLanguageEnglish,
	getStatsLanguagePortuguese,
	getStatsLanguageRomanian,
	getStatsLanguageSpanish,
} from '@/services/tv/highlights/highlights'

export default {
	namespaced: true,
	state: {
		highlightStats: [],
		highlightStatStatus: 'pending',

		highlightLanguageEnglish: [],
		highlightStatusLanguageEnglish: 'pending',

		highlightLanguageDeutsch: [],
		highlightStatusLanguageDeutsch: 'pending',

		highlightLanguagePortuguese: [],
		highlightStatusLanguagePortuguese: 'pending',

		highlightLanguageSpanish: [],
		highlightStatusLanguageSpanish: 'pending',

		highlightLanguageRomanian: [],
		highlightStatusLanguageRomanian: 'pending',
	},
	getters: {
		getHighlightList(state) {
			return state.highlightList
		},
		getHighlightStatus(state) {
			return state.highlightStatus
		},

		getHighlightStats(state) {
			return state.highlightStats
		},
		getHighlightStatStatus(state) {
			return state.highlightStatStatus
		},

		getHighlightLanguageEnglish(state) {
			return state.highlightLanguageEnglish
		},
		getHighlightStatusLanguageEnglish(state) {
			return state.highlightStatusLanguageEnglish
		},

		getHighlightLanguageDeutsch(state) {
			return state.highlightLanguageDeutsch
		},
		getHighlightStatusLanguageDeutsch(state) {
			return state.highlightStatusLanguageDeutsch
		},

		getHighlightLanguagePortuguese(state) {
			return state.highlightLanguagePortuguese
		},
		getHighlightStatusLanguagePortuguese(state) {
			return state.highlightStatusLanguagePortuguese
		},

		getHighlightLanguageSpanish(state) {
			return state.highlightLanguageSpanish
		},
		getHighlightStatusLanguageSpanish(state) {
			return state.highlightStatusLanguageSpanish
		},

		getHighlightLanguageRomanian(state) {
			return state.highlightLanguageRomanian
		},
		getHighlightStatusLanguageRomanian(state) {
			return state.highlightStatusLanguageRomanian
		},
	},
	mutations: {
		SET_HIGHLIGHT_LIST(state, value) {
			state.highlightList = value
		},
		SET_HIGHLIGHT_STATUS(state, value) {
			state.highlightStatus = value
		},

		SET_HIGHLIGHT_STATS(state, value) {
			state.highlightStats = value
		},
		SET_HIGHLIGHT_STAT_STATUS(state, value) {
			state.highlightStatStatus = value
		},

		SET_HIGHLIGHT_STATS_LANGUAGE_ENGLISH(state, value) {
			state.highlightLanguageEnglish = value
		},
		SET_HIGHLIGHT_STAT_STATUS_LANGUAGE_ENGLISH(state, value) {
			state.highlightStatusLanguageEnglish = value
		},

		SET_HIGHLIGHT_STATS_LANGUAGE_DEUTSCH(state, value) {
			state.highlightLanguageDeutsch = value
		},
		SET_HIGHLIGHT_STAT_STATUS_LANGUAGE_DEUTSCH(state, value) {
			state.highlightStatusLanguageDeutsch = value
		},

		SET_HIGHLIGHT_STATS_LANGUAGE_PORTUGUESE(state, value) {
			state.highlightLanguagePortuguese = value
		},
		SET_HIGHLIGHT_STAT_STATUS_LANGUAGE_PORTUGUESE(state, value) {
			state.highlightStatusLanguagePortuguese = value
		},

		SET_HIGHLIGHT_STATS_LANGUAGE_SPANISH(state, value) {
			state.highlightLanguageSpanish = value
		},
		SET_HIGHLIGHT_STAT_STATUS_LANGUAGE_SPANISH(state, value) {
			state.highlightStatusLanguageSpanish = value
		},

		SET_HIGHLIGHT_STATS_LANGUAGE_ROMANIAN(state, value) {
			state.highlightLanguageRomanian = value
		},
		SET_HIGHLIGHT_STAT_STATUS_LANGUAGE_ROMANIAN(state, value) {
			state.highlightStatusLanguageRomanian = value
		},
	},
	actions: {
		async fetchHighlights({ commit }, params) {
			commit('SET_HIGHLIGHT_LIST', [])
			commit('SET_HIGHLIGHT_STATUS', 'pending')
			try {
				const { data } = await getHighlights(params)
				commit('SET_HIGHLIGHT_STATUS', 'init')
				commit('SET_HIGHLIGHT_LIST', data)
				return data
			} catch (error) {
				commit('SET_HIGHLIGHT_STATUS', 'init')
				throw error
			}
		},

		async fetchStats({ commit }, params) {
			commit('SET_HIGHLIGHT_STATS', [])
			commit('SET_HIGHLIGHT_STAT_STATUS', 'pending')
			try {
				const { data } = await getStats(params)
				commit('SET_HIGHLIGHT_STAT_STATUS', 'init')
				commit('SET_HIGHLIGHT_STATS', data)
				return data
			} catch (error) {
				commit('SET_HIGHLIGHT_STAT_STATUS', 'init')
				throw error
			}
		},

		async fetchLanguageEnglish({ commit }, params) {
			commit('SET_HIGHLIGHT_STATS_LANGUAGE_ENGLISH', [])
			commit('SET_HIGHLIGHT_STAT_STATUS_LANGUAGE_ENGLISH', 'pending')
			try {
				const { data } = await getStatsLanguageEnglish(params)
				commit('SET_HIGHLIGHT_STAT_STATUS_LANGUAGE_ENGLISH', 'init')
				commit('SET_HIGHLIGHT_STATS_LANGUAGE_ENGLISH', data)
				return data
			} catch (error) {
				commit('SET_HIGHLIGHT_STATS_LANGUAGE_ENGLISH', 'init')
				throw error
			}
		},

		async fetchLanguageDeutsch({ commit }, params) {
			commit('SET_HIGHLIGHT_STATS_LANGUAGE_DEUTSCH', [])
			commit('SET_HIGHLIGHT_STAT_STATUS_LANGUAGE_DEUTSCH', 'pending')
			try {
				const { data } = await getStatsLanguageDeutsch(params)
				commit('SET_HIGHLIGHT_STAT_STATUS_LANGUAGE_DEUTSCH', 'init')
				commit('SET_HIGHLIGHT_STATS_LANGUAGE_DEUTSCH', data)
				return data
			} catch (error) {
				commit('SET_HIGHLIGHT_STATS_LANGUAGE_DEUTSCH', 'init')
				throw error
			}
		},

		async fetchLanguagePortuguese({ commit }, params) {
			commit('SET_HIGHLIGHT_STATS_LANGUAGE_PORTUGUESE', [])
			commit('SET_HIGHLIGHT_STAT_STATUS_LANGUAGE_PORTUGUESE', 'pending')
			try {
				const { data } = await getStatsLanguagePortuguese(params)
				commit('SET_HIGHLIGHT_STAT_STATUS_LANGUAGE_PORTUGUESE', 'init')
				commit('SET_HIGHLIGHT_STATS_LANGUAGE_PORTUGUESE', data)
				return data
			} catch (error) {
				commit('SET_HIGHLIGHT_STATS_LANGUAGE_PORTUGUESE', 'init')
				throw error
			}
		},

		async fetchLanguageSpanish({ commit }, params) {
			commit('SET_HIGHLIGHT_STATS_LANGUAGE_SPANISH', [])
			commit('SET_HIGHLIGHT_STAT_STATUS_LANGUAGE_SPANISH', 'pending')
			try {
				const { data } = await getStatsLanguageSpanish(params)
				commit('SET_HIGHLIGHT_STAT_STATUS_LANGUAGE_SPANISH', 'init')
				commit('SET_HIGHLIGHT_STATS_LANGUAGE_SPANISH', data)
				return data
			} catch (error) {
				commit('SET_HIGHLIGHT_STATS_LANGUAGE_SPANISH', 'init')
				throw error
			}
		},

		async fetchLanguageRomanian({ commit }, params) {
			commit('SET_HIGHLIGHT_STATS_LANGUAGE_ROMANIAN', [])
			commit('SET_HIGHLIGHT_STAT_STATUS_LANGUAGE_ROMANIAN', 'pending')
			try {
				const { data } = await getStatsLanguageRomanian(params)
				commit('SET_HIGHLIGHT_STAT_STATUS_LANGUAGE_ROMANIAN', 'init')
				commit('SET_HIGHLIGHT_STATS_LANGUAGE_ROMANIAN', data)
				return data
			} catch (error) {
				commit('SET_HIGHLIGHT_STATS_LANGUAGE_ROMANIAN', 'init')
				throw error
			}
		},
	},
}
