const careers = [
	{
		path: '/network/careers/list',
		name: 'network-careers-list',
		component: () => import(/* webpackChunkName: "NetworkCareersList" */ '@/views/network/careers/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/careers/create',
		name: 'network-careers-create',
		component: () => import(/* webpackChunkName: "NetworkCareersCreate" */ '@/views/network/careers/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/careers/edit/:id',
		name: 'network-careers-edit',
		component: () => import(/* webpackChunkName: "NetworkCareersEdit" */ '@/views/network/careers/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	// APPLICATIONS
	{
		path: '/network/careers/applications/list',
		name: 'network-careers-applications-list',
		component: () =>
			import(/* webpackChunkName: "NetworkCareersList" */ '@/views/network/careers/applications/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	// SKILLS
	{
		path: '/network/careers/skills/list',
		name: 'network-careers-skills-list',
		component: () => import(/* webpackChunkName: "NetworkCareersList" */ '@/views/network/careers/skills/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default careers
