import { serialize } from '@/@core/libs/serialize/util'
import Http from '../http'

// // WORK METHODOLOGIES
export const workmethodologyCreate = (params = '') => Http.post('/work-methodology', params)
// // // LIST ALL WORK METHODOLOGIES
export const workmethodologiesList = (params = '') => Http.get(`/work-methodology${serialize(params)}`)
// // // GET WORK METHODOLOGY BY ID
export const workmethodologyByID = (params = '') => Http.get(`/work-methodology/${params}`)
// // // UPDATE WORK METHODOLOGY
export const workmethodologyUpdate = (params = '') => Http.put(`/work-methodology/${params.id}`, params)
// // // DELETE WORK METHODOLOGY
export const workmethodologyDestroy = (params = '') => Http.delete(`/work-methodology/${params.id}`)
