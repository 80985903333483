import {
	serviceByID,
	serviceCreate,
	serviceDestroy,
	servicesList,
	serviceUpdate
} from '@/services/ai/services.service'

export default {
	namespaced: true,
	state: {
		// SERVICES
		servicesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		service: {},

		// STATUS SERVICES
		servicesStatus: 'pending',
		servicesSaveStatus: 'init',
		servicesDeleteStatus: 'init',
	},
	getters: {
		// SERVICES
		getServicesList(state) {
			return state.servicesList
		},
		getService(state) {
			return state.service
		},

		// STATUS SERVICES
		getServicesDeleteStatus(state) {
			return state.servicesDeleteStatus
		},
		getServicesSaveStatus(state) {
			return state.servicesSaveStatus
		},
		getServicesStatus(state) {
			return state.servicesStatus
		},
	},
	mutations: {
		// SERVICES
		SERVICES_LIST(state, value) {
			state.servicesList = value
		},
		SET_SERVICE(state, value) {
			state.service = value
		},

		// STATUS SERVICES
		SERVICES_STATUS(state, value) {
			state.servicesStatus = value
		},
		SERVICE_SAVE_STATUS(state, value) {
			state.servicesSaveStatus = value
		},
		SERVICE_DELETE_STATUS(state, value) {
			state.servicesDeleteStatus = value
		},
	},
	actions: {
		// SERVICES
		async fetchServiceList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SERVICES_STATUS', 'pending')
			commit('SERVICES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await servicesList(params)
				commit('SERVICES_STATUS', 'init')
				commit('SERVICES_LIST', data)
				return data
			} catch (error) {
				commit('SERVICES_STATUS', 'init')
				throw error
			}
		},
		async fetchServiceByID({ commit }, params) {
			commit('SERVICES_STATUS', 'pending')
			try {
				const { data } = await serviceByID(params)
				commit('SERVICES_STATUS', 'init')
				commit('SET_SERVICE', data.data)
				return data
			} catch (error) {
				commit('SERVICES_STATUS', 'init')

				return error
			}
		},
		async saveNewService({ commit }, params) {
			commit('SERVICE_SAVE_STATUS', 'pending')
			try {
				const { data } = await serviceCreate(params)
				commit('SERVICE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SERVICE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateService({ commit }, params) {
			commit('SERVICE_SAVE_STATUS', 'pending')
			try {
				const { data } = await serviceUpdate(params)
				commit('SERVICE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SERVICE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteServiceByID({ commit }, params) {
			commit('SERVICE_DELETE_STATUS', 'pending')
			try {
				const { data } = await serviceDestroy(params)
				commit('SERVICE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SERVICE_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
