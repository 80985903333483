import {
	experienceByID,
	experienceCreate,
	experienceDestroy,
	experiencesList,
	experienceUpdate,
} from '@/services/jobs/experiences.service'

export default {
	namespaced: true,
	state: {
		// EXPERIENCES
		experiencesList: {},
		experience: {},
		// STATUS EXPERIENCE
		experiencesStatus: 'pending',
		experiencesSaveStatus: 'init',
		experiencesDeleteStatus: 'init',
	},
	getters: {
		// EXPERIENCE
		getExperiencesList(state) {
			return state.experiencesList
		},
		getExperience(state) {
			return state.experience
		},
		// EXPERIENCE
		getExperiencesDeleteStatus(state) {
			return state.experiencesDeleteStatus
		},
		getExperiencesSaveStatus(state) {
			return state.experiencesSaveStatus
		},
		getExperiencesStatus(state) {
			return state.experiencesStatus
		},
	},
	mutations: {
		// EXPERIENCES
		EXPERIENCES_LIST(state, value) {
			state.experiencesList = value
		},
		SET_EXPERIENCE(state, value) {
			state.experience = value
		},
		// STATUS EXPERIENCES
		EXPERIENCES_STATUS(state, value) {
			state.experiencesStatus = value
		},
		EXPERIENCE_SAVE_STATUS(state, value) {
			state.experiencesSaveStatus = value
		},
		EXPERIENCE_DELETE_STATUS(state, value) {
			state.experiencesDeleteStatus = value
		},
	},
	actions: {
		// Experiences
		async fetchExperienceList({ commit }, params) {
			console.log('params', params)
			commit('EXPERIENCES_STATUS', 'pending')
			commit('EXPERIENCES_LIST', {})
			try {
				const { data } = await experiencesList(params)
				console.log(data)
				commit('EXPERIENCES_STATUS', 'init')
				commit('EXPERIENCES_LIST', data)
				return data
			} catch (error) {
				commit('EXPERIENCES_STATUS', 'init')
				throw error
			}
		},
		async fetchExperienceByID({ commit }, params) {
			commit('EXPERIENCES_STATUS', 'pending')
			try {
				const { data } = await experienceByID(params)
				commit('EXPERIENCES_STATUS', 'init')
				commit('SET_EXPERIENCE', data.data)
				return data
			} catch (error) {
				commit('EXPERIENCES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewExperience({ commit }, params) {
			commit('EXPERIENCE_SAVE_STATUS', 'pending')
			try {
				const { data } = await experienceCreate(params)
				commit('EXPERIENCE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('EXPERIENCE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateExperience({ commit }, params) {
			commit('EXPERIENCE_SAVE_STATUS', 'pending')
			try {
				const { data } = await experienceUpdate(params)
				commit('EXPERIENCE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('EXPERIENCE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteExperienceByID({ commit }, params) {
			commit('EXPERIENCE_DELETE_STATUS', 'pending')
			try {
				const { data } = await experienceDestroy(params)
				commit('EXPERIENCE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('EXPERIENCE_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
