import {
	deleteLanguage,
	getAllLanguage,
	getLanguages,
	newLanguage,
	updateLanguage,
} from '@/services/main/languages.service'

export default {
	namespaced: true,
	state: {
		languageList: [],
		allLanguagesList: {},
		languageStatus: 'pending',
		saveLanguageStatus: 'init',
		deleteLanguageStatus: 'init',
	},
	getters: {
		//this is used in pulses
		getLanguageList(state) {
			return state.languageList
		},
		getAllLanguageList(state) {
			return state.allLanguagesList
		},
		getLanguageStatus(state) {
			return state.languageStatus
		},
		getSaveLanguageStatus(state) {
			return state.saveLanguageStatus
		},
		getDeleteLanguageStatus(state) {
			return state.deleteLanguageStatus
		},
	},
	mutations: {
		//this is used in pulses
		SET_LANGUAGE_LIST(state, value) {
			state.languageList = value
		},
		SET_LANGUAGE_STATUS(state, value) {
			state.languageStatus = value
		},
		ALL_LANGUAGES_LIST(state, value) {
			state.allLanguagesList = value
		},
		SAVE_LANGUAGE_STATUS(state, value) {
			state.saveLanguageStatus = value
		},
		DELETE_LANGUAGE_STATUS(state, value) {
			state.deleteLanguageStatus = value
		},
		REMOVE_LANGUAGE_FROM_LIST(state, value) {
			state.allLanguagesList.data = state.allLanguagesList.data.filter(language => language.id !== value.id)
		},
	},
	actions: {
		//this is used in pulses
		async fetchLanguages({ commit }, params) {
			commit('SET_LANGUAGE_LIST', [])
			commit('SET_LANGUAGE_STATUS', 'pending')
			try {
				const { data } = await getLanguages(params)
				commit('SET_LANGUAGE_STATUS', 'init')
				data.data.unshift({ id: '', native_name: 'all' })
				commit('SET_LANGUAGE_LIST', data)
				return data
			} catch (error) {
				commit('SET_LANGUAGE_STATUS', 'init')
				throw error
			}
		},
		async fetchLanguagesList({ commit }, params) {
			commit('SET_LANGUAGE_STATUS', 'pending')
			try {
				const { data } = await getAllLanguage(params)
				commit('ALL_LANGUAGES_LIST', data)
				commit('SET_LANGUAGE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_LANGUAGE_STATUS', 'error')
				throw error
			}
		},
		async saveLanguage({ commit }, params) {
			commit('SAVE_LANGUAGE_STATUS', 'pending')
			try {
				const { data } = await newLanguage(params)
				commit('SAVE_LANGUAGE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SAVE_LANGUAGE_STATUS', 'error')
				throw error
			}
		},
		async updateLanguage({ commit }, params) {
			commit('SAVE_LANGUAGE_STATUS', 'pending')
			try {
				const { data } = await updateLanguage(params)
				commit('SAVE_LANGUAGE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SAVE_LANGUAGE_STATUS', 'error')
				throw error
			}
		},
		async deleteLanguage({ commit }, params) {
			commit('DELETE_LANGUAGE_STATUS', 'pending')
			try {
				const { data } = await deleteLanguage(params)
				commit('SAVE_LANGUAGE_STATUS', 'init')
				commit('REMOVE_LANGUAGE_FROM_LIST', params)
				return data
			} catch (error) {
				commit('SAVE_LANGUAGE_STATUS', 'error')
				throw error
			}
		},
	},
}
