import {
	getStats,
	getStatsLanguageDeutsch,
	getStatsLanguageEnglish,
	getStatsLanguagePortuguese,
	getStatsLanguageRomanian,
	getStatsLanguageSpanish,
} from '@/services/community/contents/quizzes/quizzes'

export default {
	namespaced: true,
	state: {
		quizStats: [],
		quizStatstatus: 'pending',

		quizLanguageEnglish: [],
		quizStatusLanguageEnglish: 'pending',

		quizLanguageDeutsch: [],
		quizStatusLanguageDeutsch: 'pending',

		quizLanguagePortuguese: [],
		quizStatusLanguagePortuguese: 'pending',

		quizLanguageSpanish: [],
		quizStatusLanguageSpanish: 'pending',

		quizLanguageRomanian: [],
		quizStatusLanguageRomanian: 'pending',
	},
	getters: {
		getQuizList(state) {
			return state.quizList
		},
		getQuizStatus(state) {
			return state.quizStatus
		},

		getQuizStats(state) {
			return state.quizStats
		},
		getQuizStatstatus(state) {
			return state.quizStatstatus
		},

		getQuizLanguageEnglish(state) {
			return state.quizLanguageEnglish
		},
		getQuizStatusLanguageEnglish(state) {
			return state.quizStatusLanguageEnglish
		},

		getQuizLanguageDeutsch(state) {
			return state.quizLanguageDeutsch
		},
		getQuizStatusLanguageDeutsch(state) {
			return state.quizStatusLanguageDeutsch
		},

		getQuizLanguagePortuguese(state) {
			return state.quizLanguagePortuguese
		},
		getQuizStatusLanguagePortuguese(state) {
			return state.quizStatusLanguagePortuguese
		},

		getQuizLanguageSpanish(state) {
			return state.quizLanguageSpanish
		},
		getQuizStatusLanguageSpanish(state) {
			return state.quizStatusLanguageSpanish
		},

		getQuizLanguageRomanian(state) {
			return state.quizLanguageRomanian
		},
		getQuizStatusLanguageRomanian(state) {
			return state.quizStatusLanguageRomanian
		},
	},
	mutations: {
		SET_QUIZ_LIST(state, value) {
			state.quizList = value
		},
		SET_QUIZ_STATUS(state, value) {
			state.quizStatus = value
		},

		SET_QUIZ_STATS(state, value) {
			state.quizStats = value
		},
		SET_QUIZ_STAT_STATUS(state, value) {
			state.quizStatstatus = value
		},

		SET_QUIZ_STATS_LANGUAGE_ENGLISH(state, value) {
			state.quizLanguageEnglish = value
		},
		SET_QUIZ_STAT_STATUS_LANGUAGE_ENGLISH(state, value) {
			state.quizStatusLanguageEnglish = value
		},

		SET_QUIZ_STATS_LANGUAGE_DEUTSCH(state, value) {
			state.quizLanguageDeutsch = value
		},
		SET_QUIZ_STAT_STATUS_LANGUAGE_DEUTSCH(state, value) {
			state.quizStatusLanguageDeutsch = value
		},

		SET_QUIZ_STATS_LANGUAGE_PORTUGUESE(state, value) {
			state.quizLanguagePortuguese = value
		},
		SET_QUIZ_STAT_STATUS_LANGUAGE_PORTUGUESE(state, value) {
			state.quizStatusLanguagePortuguese = value
		},

		SET_QUIZ_STATS_LANGUAGE_SPANISH(state, value) {
			state.quizLanguageSpanish = value
		},
		SET_QUIZ_STAT_STATUS_LANGUAGE_SPANISH(state, value) {
			state.quizStatusLanguageSpanish = value
		},

		SET_QUIZ_STATS_LANGUAGE_ROMANIAN(state, value) {
			state.quizLanguageRomanian = value
		},
		SET_QUIZ_STAT_STATUS_LANGUAGE_ROMANIAN(state, value) {
			state.quizStatusLanguageRomanian = value
		},
	},
	actions: {
		async fetchStats({ commit }, params) {
			commit('SET_QUIZ_STATS', [])
			commit('SET_QUIZ_STAT_STATUS', 'pending')
			try {
				const { data } = await getStats(params)
				commit('SET_QUIZ_STAT_STATUS', 'init')
				commit('SET_QUIZ_STATS', data)
				return data
			} catch (error) {
				commit('SET_QUIZ_STAT_STATUS', 'init')
				throw error
			}
		},

		async fetchLanguageEnglish({ commit }, params) {
			commit('SET_QUIZ_STATS_LANGUAGE_ENGLISH', [])
			commit('SET_QUIZ_STAT_STATUS_LANGUAGE_ENGLISH', 'pending')
			try {
				const { data } = await getStatsLanguageEnglish(params)
				commit('SET_QUIZ_STAT_STATUS_LANGUAGE_ENGLISH', 'init')
				commit('SET_QUIZ_STATS_LANGUAGE_ENGLISH', data)
				return data
			} catch (error) {
				commit('SET_QUIZ_STATS_LANGUAGE_ENGLISH', 'init')
				throw error
			}
		},

		async fetchLanguageDeutsch({ commit }, params) {
			commit('SET_QUIZ_STATS_LANGUAGE_DEUTSCH', [])
			commit('SET_QUIZ_STAT_STATUS_LANGUAGE_DEUTSCH', 'pending')
			try {
				const { data } = await getStatsLanguageDeutsch(params)
				commit('SET_QUIZ_STAT_STATUS_LANGUAGE_DEUTSCH', 'init')
				commit('SET_QUIZ_STATS_LANGUAGE_DEUTSCH', data)
				return data
			} catch (error) {
				commit('SET_QUIZ_STATS_LANGUAGE_DEUTSCH', 'init')
				throw error
			}
		},

		async fetchLanguagePortuguese({ commit }, params) {
			commit('SET_QUIZ_STATS_LANGUAGE_PORTUGUESE', [])
			commit('SET_QUIZ_STAT_STATUS_LANGUAGE_PORTUGUESE', 'pending')
			try {
				const { data } = await getStatsLanguagePortuguese(params)
				commit('SET_QUIZ_STAT_STATUS_LANGUAGE_PORTUGUESE', 'init')
				commit('SET_QUIZ_STATS_LANGUAGE_PORTUGUESE', data)
				return data
			} catch (error) {
				commit('SET_QUIZ_STATS_LANGUAGE_PORTUGUESE', 'init')
				throw error
			}
		},

		async fetchLanguageSpanish({ commit }, params) {
			commit('SET_QUIZ_STATS_LANGUAGE_SPANISH', [])
			commit('SET_QUIZ_STAT_STATUS_LANGUAGE_SPANISH', 'pending')
			try {
				const { data } = await getStatsLanguageSpanish(params)
				commit('SET_QUIZ_STAT_STATUS_LANGUAGE_SPANISH', 'init')
				commit('SET_QUIZ_STATS_LANGUAGE_SPANISH', data)
				return data
			} catch (error) {
				commit('SET_QUIZ_STATS_LANGUAGE_SPANISH', 'init')
				throw error
			}
		},

		async fetchLanguageRomanian({ commit }, params) {
			commit('SET_QUIZ_STATS_LANGUAGE_ROMANIAN', [])
			commit('SET_QUIZ_STAT_STATUS_LANGUAGE_ROMANIAN', 'pending')
			try {
				const { data } = await getStatsLanguageRomanian(params)
				commit('SET_QUIZ_STAT_STATUS_LANGUAGE_ROMANIAN', 'init')
				commit('SET_QUIZ_STATS_LANGUAGE_ROMANIAN', data)
				return data
			} catch (error) {
				commit('SET_QUIZ_STATS_LANGUAGE_ROMANIAN', 'init')
				throw error
			}
		},
	},
}
