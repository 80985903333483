import { mdiArchive, mdiDomain, mdiHome, mdiMessage, mdiNewspaperVariantMultipleOutline } from '@mdi/js'

// Array of sections

export default [
	{
		icon: mdiHome,
		title: 'Dashboard',
		to: 'academy-dashboard',
	},
	{
		title: 'menu.academy.courses',
		icon: mdiArchive,
		children: [
			{
				title: 'menu.academy.courses',
				to: 'academy-courses-courses-list',
			},
			{
				title: 'menu.academy.categories',
				to: 'academy-courses-categories-list',
			},
			{
				title: 'menu.academy.subcategories',
				to: 'academy-courses-subcategories-list',
			},
			{
				title: 'menu.academy.formats',
				to: 'academy-courses-formats-list',
			},
			{
				title: 'menu.academy.levels',
				to: 'academy-courses-levels-list',
			},
		],
	},
	{
		icon: mdiDomain,
		title: 'menu.institutions',
		to: 'institutions',
	},
	{
		icon: mdiMessage,
		title: 'menu.messages',
		to: 'academy-messages-list',
	},
	{
		icon: mdiNewspaperVariantMultipleOutline,
		title: 'Newsletter',
		to: 'academy-newsletter-list',
	},
]
