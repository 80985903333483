import {
	workflowByID,
	workflowCreate,
	workflowDestroy,
	workflowList,
	workflowUpdate,
} from '@/services/agency/workflows.service'

export default {
	namespaced: true,
	state: {
		// SERVICE WORKFLOW
		workflowList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		workflow: {},

		// STATUS SERVICE WORKFLOW
		workflowStatus: 'pending',
		workflowSaveStatus: 'init',
		workflowDeleteStatus: 'init',
	},
	getters: {
		// SERVICE WORKFLOW
		getWorkflowList(state) {
			return state.workflowList
		},
		getWorkflow(state) {
			return state.workflow
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE WORKFLOW
		getWorkflowDeleteStatus(state) {
			return state.workflowDeleteStatus
		},
		getWorkflowSaveStatus(state) {
			return state.workflowSaveStatus
		},
		getWorkflowStatus(state) {
			return state.workflowStatus
		},
	},
	mutations: {
		// SERVICE WORKFLOW
		WORKFLOW_LIST(state, value) {
			state.workflowList = value
		},
		SET_WORKFLOW(state, value) {
			state.workflow = value
		},

		// STATUS SERVICE WORKFLOW
		WORKFLOW_STATUS(state, value) {
			state.workflowStatus = value
		},
		WORKFLOW_SAVE_STATUS(state, value) {
			state.workflowSaveStatus = value
		},
		WORKFLOW_DELETE_STATUS(state, value) {
			state.workflowDeleteStatus = value
		},
	},
	actions: {
		// Service Workflow
		async fetchWorkflowList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('WORKFLOW_STATUS', 'pending')
			commit('WORKFLOW_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await workflowList(params)
				commit('WORKFLOW_STATUS', 'init')
				commit('WORKFLOW_LIST', data)
				return data
			} catch (error) {
				commit('WORKFLOW_STATUS', 'init')
				throw error
			}
		},
		async fetchWorkflowByID({ commit }, params) {
			commit('WORKFLOW_STATUS', 'pending')
			try {
				const { data } = await workflowByID(params)
				commit('WORKFLOW_STATUS', 'init')
				commit('SET_WORKFLOW', data.data)
				return data
			} catch (error) {
				commit('WORKFLOW_STATUS', 'init')

				return error
			}
		},
		async saveNewWorkflow({ commit }, params) {
			commit('WORKFLOW_SAVE_STATUS', 'pending')
			try {
				const { data } = await workflowCreate(params)
				commit('WORKFLOW_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WORKFLOW_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateWorkflow({ commit }, params) {
			commit('WORKFLOW_SAVE_STATUS', 'pending')
			try {
				const { data } = await workflowUpdate(params)
				commit('WORKFLOW_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WORKFLOW_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteWorkflowByID({ commit }, params) {
			commit('WORKFLOW_DELETE_STATUS', 'pending')
			try {
				const { data } = await workflowDestroy(params)
				commit('WORKFLOW_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WORKFLOW_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
