import { serialize } from '@/@core/libs/serialize/util'
import Http from '../http'

// // // LIST ALL Companies Types
export const typesList = params => Http.get(`/employment-contract-types${serialize(params)}`)
export const typeCreate = (params = '') => Http.post('/employment-contract-types', params)
export const typeByID = (params = '') => Http.get(`/employment-contract-types/${params}`)
export const typeUpdate = (params = '') => Http.put(`/employment-contract-types/${params.id}`, params)
export const typeDestroy = (params = '') => Http.delete(`/employment-contract-types/${params.id}`)

// ALL EMPLOYMENT TYPES
export const allEmploymentTypes = params => Http.get(`/employment-contract-types${serialize(params)}`)
