import { deleteWebsite, getWebsites, saveWebsite, updateWebsite } from '@/services/community/wikigamer/games/websites'
export default {
	namespaced: true,
	state: {
		websiteList: {
			meta: {
				total: 0,
			},
		},
		websiteStatus: 'pending',
	},
	getters: {
		getWebsiteList(state) {
			return state.websiteList
		},
		getWebsiteStatus(state) {
			return state.websiteStatus
		},
	},
	mutations: {
		SET_WEBSITE_LIST(state, val) {
			state.websiteList = val
		},
		SET_WEBSITE_STATUS(state, val) {
			state.websiteStatus = val
		},
	},
	actions: {
		async fetchWebsites({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_WEBSITE_STATUS', 'pending')
			commit('SET_WEBSITE_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getWebsites(params)
				commit('SET_WEBSITE_STATUS', 'init')
				commit('SET_WEBSITE_LIST', data)
				return data
			} catch (error) {
				commit('SET_WEBSITE_STATUS', 'init')
				throw error
			}
		},

		async deleteWebsiteRecord({ commit }, params) {
			commit('SET_WEBSITE_STATUS', 'pending')
			try {
				const { data } = await deleteWebsite(params)
				commit('SET_WEBSITE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_WEBSITE_STATUS', 'init')
				throw error
			}
		},
		async saveWebsite({ commit }, params) {
			commit('SET_WEBSITE_STATUS', 'pending')
			try {
				const { data } = await saveWebsite(params)
				commit('SET_WEBSITE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_WEBSITE_STATUS', 'init')
				throw error
			}
		},
		async updateWebsite({ commit }, params) {
			commit('SET_WEBSITE_STATUS', 'pending')
			try {
				const data = await updateWebsite(params)
				commit('SET_WEBSITE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_WEBSITE_STATUS', 'init')
				throw error
			}
		},
	},
}
