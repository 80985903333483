import pulses from './pulses'
import pushNotifications from './push-notifications'
import quizzes from './quizzes'
import reviews from './reviews'
import schedulers from './schedulers'
import translationQueue from './translation-queue'
import videos from './videos'

const contents = [...pulses, ...reviews, ...videos, ...pushNotifications, ...quizzes, ...schedulers, ...translationQueue ]

export default contents
