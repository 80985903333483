import { serialize } from '@/@core/libs/serialize/util'
import Http from '../http'

// // WORK AVAILABILITIES
export const workavailabilityCreate = (params = '') => Http.post('/work-availabilities', params)
// // // LIST ALL WORK AVAILABILITIES
export const workavailabilitiesList = (params = '') => Http.get(`/work-availabilities${serialize(params)}`)
// // // GET WORK AVAILABILITY BY ID
export const workavailabilityByID = (params = '') => Http.get(`/work-availabilities/${params}`)
// // // UPDATE WORK AVAILABILITY
export const workavailabilityUpdate = (params = '') => Http.put(`/work-availabilities/${params.id}`, params)
// // // DELETE WORK AVAILABILITY
export const workavailabilityDestroy = (params = '') => Http.delete(`/work-availabilities/${params.id}`)
