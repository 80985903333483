const rotatingBanners = [
	{
		path: '/tv/banners/rotating-banners/list',
		name: 'tv-banners-rotating-banners-list',
		component: () => import('@/views/tv/banners/rotating-banners/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default rotatingBanners
