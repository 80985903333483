import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getCharacters = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/characters/fetch${serialize(params)}`)

export const getStats = () => Http.get(`characters/stats`)

export const destroyCharacter = (
	params = {
		characterSlug: null,
	},
) => Http.delete(`/characters/${params.characterSlug}/destroy`)

export const saveCharacter = (
	params = {
		name: null,
	},
) => Http.post(`/characters/store`, params)

export const updateCharacter = (
	slug,
	params
) => Http.put(`/characters/${slug}/update`, params)

export const showCharacter = (
	params = {
		characterSlug: null,
	},
) => Http.get(`/characters/${params.characterSlug}/show`)

export const batchUpsertGamesCharacter = (
	params = {
		characterSlug: null,
		games_id: null,
	},
) => Http.post(`/characters/batch/games/${params.characterSlug}/upsert`, params)
export const destroyGameCharacter = (
	params = {
		gameId: null,
	},
) => Http.delete(`/characters/games/${params.characterSlug}/destroy`, { data: { game_id: params.gameId } })
