import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpAcademy'

// LIST ALL SUBCATEGORIES
export const subcategoryList = (params = '') => Http.get(`/subcategories${serialize(params)}`)

export const subcategoryCreate = (params = '') => Http.post('/subcategories', params)
export const subcategoryByID = (params = '') => Http.get(`/subcategories/${params}`)
export const subcategoryUpdate = (params = '') => Http.put(`/subcategories/${params.id}`, params)
export const subcategoryDestroy = (params = '') => Http.delete(`/subcategories/${params.id}`)
