const characters = [
	{
		path: '/community/wikigamer/characters/characters/list',
		name: 'community-wikigamer-characters-list',
		component: () => import('@/views/community/wikigamer/characters/characters/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/characters/characters/new',
		name: 'community-wikigamer-characters-create',
		component: () => import('@/views/community/wikigamer/characters/characters/upsert.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/characters/:characterSlug/edit',
		name: 'community-wikigamer-characters-edit',
		component: () => import('@/views/community/wikigamer/characters/characters/upsert.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/characters/phrases/list',
		name: 'community-wikigamer-characters-phrases-list',
		component: () => import('@/views/community/wikigamer/characters/phrases/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/characters/genders',
		name: 'community-wikigamer-characters-genders-list',
		component: () => import('@/views/community/wikigamer/characters/genders/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/characters/phrases',
		name: 'community-wikigamer-characters-phrases',
		component: () => import('@/views/network/blog/posts/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/characters/species/list',
		name: 'community-wikigamer-characters-species-list',
		component: () => import('@/views/community/wikigamer/characters/species/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/characters/status/list',
		name: 'community-wikigamer-characters-status-list',
		component: () => import('@/views/community/wikigamer/characters/status/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/characters/status-website/list',
		name: 'community-wikigamer-characters-status-website-list',
		component: () => import('@/views/community/wikigamer/characters/status-website/list.vue'),
		meta: {
			layout: 'content',
		},
	},

	{
		path: '/community/wikigamer/characters/status-website/characters-status/:context',
		name: 'community-wikigamer-characters-status',
		component: () => import('@/views/community/wikigamer/characters/status-website/CharactersStatus.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default characters
