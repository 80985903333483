import { serialize } from '@/@core/libs/serialize/util'
import Http from '../http'

// // PROFESSIONAL SITUATIONS
export const professionalsituationCreate = (params = '') => Http.post('/professional-situation', params)
// // // LIST ALL PROFESSIONAL SITUATIONS
export const professionalsituationsList = (params = '') => Http.get(`/professional-situation${serialize(params)}`)
// // // GET PROFESSIONAL SITUATION BY ID
export const professionalsituationByID = (params = '') => Http.get(`/professional-situation/${params}`)
// // // UPDATE PROFESSIONAL SITUATION
export const professionalsituationUpdate = (params = '') => Http.put(`/professional-situation/${params.id}`, params)
// // // DELETE PROFESSIONAL SITUATION
export const professionalsituationDestroy = (params = '') => Http.delete(`/professional-situation/${params.id}`)
