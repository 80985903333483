const workAvailabilities = [
	{
		path: '/work-availabilities/list',
		name: 'jobs-work-availabilities-list',
		component: () =>
			import(/* webpackChunkName: "WorkavailabilitiesList" */ '@/views/jobs/work-availabilities/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default workAvailabilities
