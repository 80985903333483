import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getPerspectives = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/player-perspectives/fetch${serialize(params)}`)

//SAVE A NEW PERSPECTIVE
export const savePerspective = (
	params = {
		name: null,
	},
) => Http.post(`/player-perspectives/store`, params)

//UPDATE PERSPECTIVE
export const updatePerspective = (
	params = {
		name: null,
		perspectiveId: null,
	},
) => Http.put(`/player-perspectives/${params.perspectiveId}/update`, params)

//DELETE PERSPECTIVE
export const deletePerspective = (
	params = {
		perspectiveId: null,
	},
) => Http.delete(`/player-perspectives/${params.perspectiveId}/destroy`)
