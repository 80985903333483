const categories = [
	{
		path: '/shop/categories/list',
		name: 'shop-categories-list',
		component: () => import('@/views/shop/categories/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default categories
