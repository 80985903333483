import { getUsers } from '@/services/community/users'

export default {
	namespaced: true,
	state: {
		userList: [],
		userStatus: 'pending',
		savedUserStatus: 'init',
		deletedUserStatus: 'init',
	},
	getters: {
		getUserList(state) {
			return state.userList
		},
		getUserStatus(state) {
			return state.userStatus
		},
		getSavedUserStatus(state) {
			return state.savedUserStatus
		},
		getDeletedUserStatus(status) {
			return state.deletedUserStatus
		},
	},
	mutations: {
		SET_USER_LIST(state, value) {
			state.userList = value
		},
		SET_USER_STATUS(state, value) {
			state.userStatus = value
		},
		SET_SAVED_USER_STATUS(state, value) {
			state.savedUserStatus = value
		},
		SET_DELETED_USER_STATUS(state, value) {
			state.deletedUserStatus = value
		},
	},
	actions: {
		async fetchUsers({ commit }, params) {
			commit('SET_USER_LIST', [])
			commit('SET_USER_STATUS', 'pending')
			try {
				const { data } = await getUsers(params)
				commit('SET_USER_STATUS', 'init')
				commit('SET_USER_LIST', data)
				return data
			} catch (error) {
				commit('SET_USER_STATUS', 'init')
				throw error
			}
		},
	},
}
