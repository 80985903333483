import {
	tokenByID,
	tokensList,
	tokenUpdate
} from '@/services/ai/tokens.service'

export default {
	namespaced: true,
	state: {
		// TOKENS
		tokensList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		token: {},

		// STATUS TOKENS
		tokensStatus: 'pending',
		tokensSaveStatus: 'init',
	},
	getters: {
		// TOKENS
		getTokensList(state) {
			return state.tokensList
		},
		getToken(state) {
			return state.token
		},

		// STATUS TOKENS
		getTokensSaveStatus(state) {
			return state.tokensSaveStatus
		},
		getTokensStatus(state) {
			return state.tokensStatus
		},
	},
	mutations: {
		// TOKENS
		TOKENS_LIST(state, value) {
			state.tokensList = value
		},
		SET_TOKEN(state, value) {
			state.token = value
		},

		// STATUS TOKENS
		TOKENS_STATUS(state, value) {
			state.tokensStatus = value
		},
		TOKEN_SAVE_STATUS(state, value) {
			state.tokensSaveStatus = value
		},
	},
	actions: {
		// TOKENS
		async fetchTokenList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('TOKENS_STATUS', 'pending')
			commit('TOKENS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await tokensList(params)
				commit('TOKENS_STATUS', 'init')
				commit('TOKENS_LIST', data)
				return data
			} catch (error) {
				commit('TOKENS_STATUS', 'init')
				throw error
			}
		},
		async fetchTokenByID({ commit }, params) {
			commit('TOKENS_STATUS', 'pending')
			try {
				const { data } = await tokenByID(params)
				commit('TOKENS_STATUS', 'init')
				commit('SET_TOKEN', data.data)
				return data
			} catch (error) {
				commit('TOKENS_STATUS', 'init')

				return error
			}
		},
		async updateToken({ commit }, params) {
			commit('TOKEN_SAVE_STATUS', 'pending')
			try {
				const { data } = await tokenUpdate(params)
				commit('TOKEN_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TOKEN_SAVE_STATUS', 'init')
				throw error
			}
		},
	
	},
}
