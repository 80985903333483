import {
	destroyMediaType,
	getMediaTypes,
	saveMediaType,
	updateMediaType,
} from '@/services/community/wikigamer/mediaTypes'

export default {
	namespaced: true,
	state: {
		mediaTypeList: {
			meta: {
				total: 0,
			},
		},
		mediaTypeStatus: 'pending',
	},
	getters: {
		getMediaTypeList(state) {
			return state.mediaTypeList
		},
		getMediaTypeStatus(state) {
			return state.mediaTypeStatus
		},
	},
	mutations: {
		SET_MEDIA_TYPE_LIST(state, value) {
			state.mediaTypeList = value
		},
		SET_MEDIA_TYPE_STATUS(state, value) {
			state.mediaTypeStatus = value
		},
	},
	actions: {
		async fetchMediaTypes({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_MEDIA_TYPE_STATUS', 'pending')
			commit('SET_MEDIA_TYPE_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getMediaTypes(params)
				commit('SET_MEDIA_TYPE_STATUS', 'init')
				commit('SET_MEDIA_TYPE_LIST', data)
				return data
			} catch (error) {
				commit('SET_MEDIA_TYPE_STATUS', 'init')
				throw error
			}
		},

		async destroyMediaTypeRecord({ commit }, params) {
			commit('SET_MEDIA_TYPE_STATUS', 'pending')
			try {
				const { data } = await destroyMediaType(params)
				commit('SET_MEDIA_TYPE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_MEDIA_TYPE_STATUS', 'init')
				throw error
			}
		},
		async saveMediaType({ commit }, params) {
			commit('SET_MEDIA_TYPE_STATUS', 'pending')
			try {
				const { data } = await saveMediaType(params)
				commit('SET_MEDIA_TYPE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_MEDIA_TYPE_STATUS', 'init')
				throw error
			}
		},
		async updateMediaType({ commit }, params) {
			commit('SET_MEDIA_TYPE_STATUS', 'pending')
			try {
				const { data } = await updateMediaType(params)
				commit('SET_MEDIA_TYPE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_MEDIA_TYPE_STATUS', 'init')
				throw error
			}
		},
	},
}
