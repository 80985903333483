import {
	applicationstatusByID,
	applicationstatusCreate,
	applicationstatusDestroy,
	applicationstatusList,
	applicationstatusUpdate,
} from '@/services/jobs/applicationStatus.service'

export default {
	namespaced: true,
	state: {
		// APPLICATIONSTATUS
		applicationstatusList: {},
		applicationstatus: {},
		// STATUS APPLICATIONSTATUS
		applicationstatusStatus: 'pending',
		applicationstatusSaveStatus: 'init',
		applicationstatusDeleteStatus: 'init',
	},
	getters: {
		// APPLICATIONSTATUS
		getApplicationStatusList(state) {
			return state.applicationstatusList
		},
		getApplicationStatus(state) {
			return state.applicationstatus
		},
		// APPLICATIONSTATUS
		getApplicationStatusDeleteStatus(state) {
			return state.applicationstatusDeleteStatus
		},
		getApplicationStatusSaveStatus(state) {
			return state.applicationstatusSaveStatus
		},
		getApplicationStatusStatus(state) {
			return state.applicationstatusStatus
		},
	},
	mutations: {
		// APPLICATIONSTATUS
		APPLICATIONSTATUS_LIST(state, value) {
			state.applicationstatusList = value
		},
		SET_APPLICATIONSTATUS(state, value) {
			state.applicationstatus = value
		},
		// STATUS APPLICATIONSTATUS
		APPLICATIONSTATUS_STATUS(state, value) {
			state.applicationstatusStatus = value
		},
		APPLICATIONSTATUS_SAVE_STATUS(state, value) {
			state.applicationstatusSaveStatus = value
		},
		APPLICATIONSTATUS_DELETE_STATUS(state, value) {
			state.applicationstatusDeleteStatus = value
		},
	},
	actions: {
		// ApplicationStatus
		async fetchApplicationStatusList({ commit }, params) {
			console.log('params', params)
			commit('APPLICATIONSTATUS_STATUS', 'pending')
			commit('APPLICATIONSTATUS_LIST', {})
			try {
				const { data } = await applicationstatusList(params)
				console.log(data)
				commit('APPLICATIONSTATUS_STATUS', 'init')
				commit('APPLICATIONSTATUS_LIST', data)
				return data
			} catch (error) {
				commit('APPLICATIONSTATUS_STATUS', 'init')
				throw error
			}
		},
		async fetchApplicationStatusByID({ commit }, params) {
			commit('APPLICATIONSTATUS_STATUS', 'pending')
			try {
				const { data } = await applicationstatusByID(params)
				commit('APPLICATIONSTATUS_STATUS', 'init')
				commit('SET_APPLICATIONSTATUS', data.data)
				return data
			} catch (error) {
				commit('APPLICATIONSTATUS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewApplicationStatus({ commit }, params) {
			commit('APPLICATIONSTATUS_SAVE_STATUS', 'pending')
			try {
				const { data } = await applicationstatusCreate(params)
				commit('APPLICATIONSTATUS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('APPLICATIONSTATUS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateApplicationStatus({ commit }, params) {
			commit('APPLICATIONSTATUS_SAVE_STATUS', 'pending')
			try {
				const { data } = await applicationstatusUpdate(params)
				commit('APPLICATIONSTATUS_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('APPLICATIONSTATUS_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteApplicationStatusByID({ commit }, params) {
			commit('APPLICATIONSTATUS_DELETE_STATUS', 'pending')
			try {
				const { data } = await applicationstatusDestroy(params)
				commit('APPLICATIONSTATUS_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('APPLICATIONSTATUS_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
