import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getContents = params => Http.get(`/moderate-comments/fetch-all${serialize(params)}`)

//DELETE CONTENT
export const deleteContent = (
	params = {
		contentId: null,
	},
) => Http.delete(`/moderate-comments/${params.contentId}/destroy`)
