import {
	accountCreate,
	accountDestroy,
	accountUpdate,
	accountsList,
	apiList,
	apisByID,
	companiesByTerm,
	deleteApisByID,
	deleteMgCompanyByID,
	mgCompaniesByID,
	mgCompaniesList,
	newApis,
	newMGCompany,
	typeByID,
	typeCreate,
	typeDestroy,
	typeUpdate,
	typesList,
	updateApis,
	updateMGCompany,
} from '@/services/institutional.service'

export default {
	namespaced: true,
	state: {
		// APIs
		apisList: {},
		api: {},

		// MG COMPANIES
		mgCompaniesList: {},
		mgCompany: {},

		// STATUS
		institutionalStatus: 'pending',
		institutionalSaveStatus: 'init',
		institutionalDeleteStatus: 'init',

		// ACCOUNTS
		accountsList: {},
		account: {},
		// STATUS ACCOUNT
		accountsStatus: 'pending',
		accountsSaveStatus: 'init',
		accountsDeleteStatus: 'init',

		// COMPANIES TYPES
		typesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		type: {},
		// STATUS COMPANIES TYPES
		typesStatus: 'pending',
		typesSaveStatus: 'init',
		typesDeleteStatus: 'init',
	},
	getters: {
		// APIS
		getApisList(state) {
			return state.apisList
		},
		// MG COMPANIES
		getMGCompaniesList(state) {
			return state.mgCompaniesList
		},
		getMGCompany(state) {
			return state.mgCompany
		},
		// STATUS
		getInstitutionalDeleteStatus(state) {
			return state.institutionalDeleteStatus
		},
		getInstitutionalSaveStatus(state) {
			return state.institutionalSaveStatus
		},
		getInstitutionalStatus(state) {
			return state.institutionalStatus
		},

		// ACCOUNT
		getAccountsList(state) {
			return state.accountsList
		},
		getAccounts(state) {
			return state.account
		},
		getAccountsDeleteStatus(state) {
			return state.accountsDeleteStatus
		},
		getAccountsSaveStatus(state) {
			return state.accountsSaveStatus
		},
		getAccountsStatus(state) {
			return state.accountsStatus
		},

		// COMPANIES TYPES
		getTypesList(state) {
			return state.typesList
		},
		getType(state) {
			return state.type
		},
		getTypesDeleteStatus(state) {
			return state.typesDeleteStatus
		},
		getTypesSaveStatus(state) {
			return state.typesSaveStatus
		},
		getTypesStatus(state) {
			return state.typesStatus
		},
	},
	mutations: {
		// TAGS
		SET_APIS_LIST(state, value) {
			state.apisList = value
		},
		SET_API(state, value) {
			state.api = value
		},
		// MG COMPANIES
		SET_MG_COMPANIES_LIST(state, value) {
			state.mgCompaniesList = value
		},
		SET_MG_COMPANY(state, value) {
			state.mgCompany = value
		},
		// STATUS
		INSTITUTIONAL_STATUS(state, value) {
			state.institutionalStatus = value
		},
		INSTITUTIONAL_SAVE_STATUS(state, value) {
			state.institutionalSaveStatus = value
		},
		INSTITUTIONAL_DELETE_STATUS(state, value) {
			state.institutionalDeleteStatus = value
		},
		// ACCOUNTS
		ACCOUNTS_LIST(state, value) {
			state.accountsList = value
		},
		SET_ACCOUNT(state, value) {
			state.account = value
		},
		// STATUS ACCOUNTS
		ACCOUNTS_STATUS(state, value) {
			state.accountsStatus = value
		},
		ACCOUNT_SAVE_STATUS(state, value) {
			state.accountsSaveStatus = value
		},
		ACCOUNT_DELETE_STATUS(state, value) {
			state.accountsDeleteStatus = value
		},

		// COMPANIES TYPES
		TYPES_LIST(state, value) {
			state.typesList = value
		},
		SET_TYPE(state, value) {
			state.type = value
		},
		// STATUS COMPANIES TYPES
		TYPES_STATUS(state, value) {
			state.typesStatus = value
		},
		TYPE_SAVE_STATUS(state, value) {
			state.typesSaveStatus = value
		},
		TYPE_DELETE_STATUS(state, value) {
			state.typesDeleteStatus = value
		},
	},
	actions: {
		// MG COMPANIES
		async fetchMGCompaniesList({ commit }, params) {
			commit('SET_MG_COMPANIES_LIST', {})
			commit('INSTITUTIONAL_STATUS', 'pending')
			try {
				const { data } = await mgCompaniesList(params)
				commit('INSTITUTIONAL_STATUS', 'init')
				commit('SET_MG_COMPANIES_LIST', data)
				return data
			} catch (error) {
				commit('INSTITUTIONAL_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		async fetchCompaniesByTerm({ commit }, params) {
			commit('SET_MG_COMPANIES_LIST', [])
			commit('INSTITUTIONAL_STATUS', 'pending')

			try {
				const { data } = await companiesByTerm({
					'filter[term]': params.term,
					per_page: 20,
					include: 'perks',
				})

				commit('SET_MG_COMPANIES_LIST', data)
				return data
			} catch (error) {
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		async fetchCompanyByID({ state, commit }, params) {
			try {
				const { data } = await mgCompaniesByID(params)
				commit('SET_MG_COMPANY', data.data)
				return data
			} catch (error) {
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewMGCompany({ commit }, params) {
			commit('INSTITUTIONAL_SAVE_STATUS', 'pending')
			try {
				const { data } = await newMGCompany(params)
				commit('INSTITUTIONAL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('INSTITUTIONAL_SAVE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async updateMGCompany({ commit }, params) {
			commit('INSTITUTIONAL_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateMGCompany(params)
				commit('INSTITUTIONAL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('INSTITUTIONAL_SAVE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async deleteMGCompanyByID({ state, commit }, params) {
			commit('INSTITUTIONAL_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteMgCompanyByID(params)
				commit('INSTITUTIONAL_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('INSTITUTIONAL_DELETE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		// APIS
		async fetchApisList({ commit }, params) {
			commit('INSTITUTIONAL_STATUS', 'pending')
			try {
				const { data } = await apiList(params)
				commit('INSTITUTIONAL_STATUS', 'init')
				commit('SET_APIS_LIST', data)
				return data
			} catch (error) {
				commit('INSTITUTIONAL_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async fetchApisByID({ state, commit }, params) {
			try {
				const { data } = await apisByID(params)
				commit('SET_API', data.data)
				return data
			} catch (error) {
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewApis({ commit }, params) {
			commit('INSTITUTIONAL_SAVE_STATUS', 'pending')
			try {
				const { data } = await newApis(params)
				commit('INSTITUTIONAL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('INSTITUTIONAL_SAVE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async updateApis({ commit }, params) {
			params = {
				id: params.id,
				name: params.name,
				status: params.status,
				doc_url: params.doc_url,
			}

			commit('INSTITUTIONAL_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateApis(params)
				commit('INSTITUTIONAL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('INSTITUTIONAL_SAVE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async deleteApisByID({ state, commit }, params) {
			commit('INSTITUTIONAL_DELETE_STATUS', 'pending')
			try {
				const { data } = await deleteApisByID(params)
				commit('INSTITUTIONAL_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('INSTITUTIONAL_DELETE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		// Accounts
		async fetchAccounts({ commit }, params) {
			commit('ACCOUNTS_STATUS', 'pending')
			try {
				const { data } = await accountsList(params)
				commit('ACCOUNTS_STATUS', 'init')
				commit('ACCOUNTS_LIST', data)
				return data
			} catch (error) {
				commit('ACCOUNTS_STATUS', 'error')
				throw error
			}
		},
		async fetchAccountByID({ commit }, params) {
			commit('ACCOUNTS_STATUS', 'pending')
			try {
				const { data } = await accountByID(params)
				commit('ACCOUNTS_STATUS', 'init')
				commit('SET_ACCOUNT', data.data)
				return data
			} catch (error) {
				commit('ACCOUNTS_STATUS', 'init')

				return error
			}
		},
		async saveNewAccount({ commit }, params) {
			commit('ACCOUNT_SAVE_STATUS', 'pending')
			try {
				const { data } = await accountCreate(params)
				commit('ACCOUNT_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('ACCOUNT_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateAccount({ commit }, params) {
			commit('ACCOUNT_SAVE_STATUS', 'pending')
			try {
				const { data } = await accountUpdate(params)
				commit('ACCOUNT_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('ACCOUNT_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteAccountByID({ commit }, params) {
			commit('ACCOUNT_DELETE_STATUS', 'pending')
			try {
				const { data } = await accountDestroy(params)
				commit('ACCOUNT_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('ACCOUNT_DELETE_STATUS', 'error')

				return error
			}
		},

		// Companies Types
		async fetchTypeList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('TYPES_STATUS', 'pending')
			commit('TYPES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await typesList(params)
				commit('TYPES_STATUS', 'init')
				commit('TYPES_LIST', data)
				return data
			} catch (error) {
				commit('TYPES_STATUS', 'init')
				throw error
			}
		},
		async fetchTypeByID({ commit }, params) {
			commit('TYPES_STATUS', 'pending')
			try {
				const { data } = await typeByID(params)
				commit('TYPES_STATUS', 'init')
				commit('SET_TYPE', data.data)
				return data
			} catch (error) {
				commit('TYPES_STATUS', 'init')

				return error
			}
		},
		async saveNewType({ commit }, params) {
			commit('TYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await typeCreate(params)
				commit('TYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateType({ commit }, params) {
			commit('TYPE_SAVE_STATUS', 'pending')
			try {
				const { data } = await typeUpdate(params)
				commit('TYPE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteTypeByID({ commit }, params) {
			commit('TYPE_DELETE_STATUS', 'pending')
			try {
				const { data } = await typeDestroy(params)
				commit('TYPE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('TYPE_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
