import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getSpecies = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/species/fetch${serialize(params)}`)

export const destroySpecie = (
	params = {
		specieId: null,
	},
) => Http.delete(`/species/${params.specieId}/destroy`)

export const saveSpecie = (
	params = {
		name: null,
	},
) => Http.post(`/species/store`, params)

export const updateSpecie = (
	params = {
		specieId: null,
		name: null,
	},
) => Http.put(`/species/${params.specieId}/update`, params)
