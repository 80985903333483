// axios
import axios from 'axios'
import Vue from 'vue'

const baseDomain = process.env.VUE_APP_API_BASE_URL

const axiosIns = axios.create({
	baseURL: baseDomain,
	headers: {
		"Content-Type": "application/json",
		"Accept": "application/json",
		"Content-Language": `pt-BR`
	}
})

axiosIns.interceptors.request.use(
	config => {
		// Do something before request is sent

		const accessToken = localStorage.getItem('accessToken')

		// eslint-disable-next-line no-param-reassign
		if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

		return config
	},
	error => Promise.reject(error),
)

axios.interceptors.request.use(config => {
	// Do something before request is sent
	const token = typeof window !== 'undefined' ? sessionStorage.getItem('token') : null
	if (token) {
		config.headers.common.Authorization = token
	}
	return config
}, function (error) {
	// Do something with request error
	return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
