import {
	educationlevelByID,
	educationlevelCreate,
	educationlevelDestroy,
	educationlevelsList,
	educationlevelUpdate,
} from '@/services/jobs/educationLevels.service'

export default {
	namespaced: true,
	state: {
		// EDUCATIONLEVELS
		educationlevelsList: {},
		educationlevel: {},
		// STATUS EDUCATIONLEVEL
		educationlevelsStatus: 'pending',
		educationlevelsSaveStatus: 'init',
		educationlevelsDeleteStatus: 'init',
	},
	getters: {
		// EDUCATIONLEVEL
		getEducationLevelsList(state) {
			return state.educationlevelsList
		},
		getEducationLevel(state) {
			return state.educationlevel
		},
		// EDUCATIONLEVEL
		getEducationLevelsDeleteStatus(state) {
			return state.educationlevelsDeleteStatus
		},
		getEducationLevelsSaveStatus(state) {
			return state.educationlevelsSaveStatus
		},
		getEducationLevelsStatus(state) {
			return state.educationlevelsStatus
		},
	},
	mutations: {
		// EDUCATIONLEVELS
		EDUCATIONLEVELS_LIST(state, value) {
			state.educationlevelsList = value
		},
		SET_EDUCATIONLEVEL(state, value) {
			state.educationlevel = value
		},
		// STATUS EDUCATIONLEVELS
		EDUCATIONLEVELS_STATUS(state, value) {
			state.educationlevelsStatus = value
		},
		EDUCATIONLEVEL_SAVE_STATUS(state, value) {
			state.educationlevelsSaveStatus = value
		},
		EDUCATIONLEVEL_DELETE_STATUS(state, value) {
			state.educationlevelsDeleteStatus = value
		},
	},
	actions: {
		// EducationLevels
		async fetchEducationLevelList({ commit }, params) {
			commit('EDUCATIONLEVELS_STATUS', 'pending')
			commit('EDUCATIONLEVELS_LIST', {})
			try {
				const { data } = await educationlevelsList(params)
				commit('EDUCATIONLEVELS_STATUS', 'init')
				commit('EDUCATIONLEVELS_LIST', data)
				return data
			} catch (error) {
				commit('EDUCATIONLEVELS_STATUS', 'init')
				throw error
			}
		},
		async fetchEducationLevelByID({ commit }, params) {
			commit('EDUCATIONLEVELS_STATUS', 'pending')
			try {
				const { data } = await educationlevelByID(params)
				commit('EDUCATIONLEVELS_STATUS', 'init')
				commit('SET_EDUCATIONLEVEL', data.data)
				return data
			} catch (error) {
				commit('EDUCATIONLEVELS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewEducationLevel({ commit }, params) {
			commit('EDUCATIONLEVEL_SAVE_STATUS', 'pending')
			try {
				const { data } = await educationlevelCreate(params)
				commit('EDUCATIONLEVEL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('EDUCATIONLEVEL_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateEducationLevel({ commit }, params) {
			commit('EDUCATIONLEVEL_SAVE_STATUS', 'pending')
			try {
				const { data } = await educationlevelUpdate(params)
				commit('EDUCATIONLEVEL_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('EDUCATIONLEVEL_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteEducationLevelByID({ commit }, params) {
			commit('EDUCATIONLEVEL_DELETE_STATUS', 'pending')
			try {
				const { data } = await educationlevelDestroy(params)
				commit('EDUCATIONLEVEL_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('EDUCATIONLEVEL_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
