import {
	deleteCategory,
	getCategories,
	saveCategory,
	updateCategory,
} from '@/services/community/contents/pulses-categories'
export default {
	namespaced: true,
	state: {
		categoryList: [],
		categoriestatus: 'pending',
	},
	getters: {
		getCategoryList(state) {
			return state.categoryList
		},
		getCategoriesStatus(state) {
			return state.categoriestatus
		},
	},
	mutations: {
		SET_CATEGORY_LIST(state, value) {
			state.categoryList = value
		},
		SET_CATEGORY_STATUS(state, value) {
			state.categoriestatus = value
		},
	},
	actions: {
		async fetchCategories({ commit }, params) {
			commit('SET_CATEGORY_LIST', [])
			commit('SET_CATEGORY_STATUS', 'pending')
			try {
				const { data } = await getCategories(params)
				commit('SET_CATEGORY_LIST', data)
				commit('SET_CATEGORY_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_CATEGORY_STATUS', 'init')
				throw error
			}
		},
		async deleteCategoryRecord({ commit }, params) {
			commit('SET_CATEGORY_STATUS', 'pending')
			try {
				const { data } = await deleteCategory(params)
				commit('SET_CATEGORY_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_CATEGORY_STATUS', 'init')
				throw error
			}
		},
		async saveCategory({ commit }, params) {
			commit('SET_CATEGORY_STATUS', 'pending')
			try {
				const { data } = await saveCategory(params)
				commit('SET_CATEGORY_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_CATEGORY_STATUS', 'init')
				throw error
			}
		},
		async updateCategory({ commit }, params) {
			commit('SET_CATEGORY_STATUS', 'pending')
			try {
				const { data } = await updateCategory(params)
				commit('SET_CATEGORY_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_CATEGORY_STATUS', 'init')
				throw error
			}
		},
	},
}
