const users = [
	{
		path: '/network/users/list',
		name: 'users-list',
		component: () => import('@/views/network/users/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/users/edit/:id',
		name: 'users-edit',
		component: () => import(/* webpackChunkName: "UsersEdit" */ '@/views/network/users/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: 'network/users/create',
		name: 'users-create',
		component: () => import(/* webpackChunkName: "UsersCreate" */ '@/views/network/users/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/users/genders/list',
		name: 'network-users-genders-list',
		component: () => import('@/views/network/users/genders/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default users
