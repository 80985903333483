import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getModes = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/game-modes/fetch${serialize(params)}`)

//SAVE A NEW MODE
export const saveMode = (
	params = {
		name: null,
	},
) => Http.post(`/game-modes/store`, params)

//UPDATE MODE
export const updateMode = (
	params = {
		name: null,
		modeId: null,
	},
) => Http.put(`/game-modes/${params.modeId}/update`, params)

//DELETE MODE
export const deleteMode = (
	params = {
		modeId: null,
	},
) => Http.delete(`/game-modes/${params.modeId}/destroy`)
