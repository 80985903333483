import Http from '@/services/httpCommunity'

//LIST OF ALL CATEGORIES
export const getCategories = (
	params = {
		perPage: null,
		page: null,
		search: '',
	},
) =>
	Http.get(
		`/pulse-item-categories/fetch?perPage=${params.perPage}&page=${params.page}&language_id=${params.page}&search=${params.search}`,
	)

//SAVE A NEW CATEGORY
export const saveCategory = (
	params = {
		name: null,
	},
) => Http.post(`/pulse-item-categories/store`, params)

//UPDATE CATEGORY
export const updateCategory = (
	params = {
		name: null,
		categoryId: null,
	},
) => Http.put(`/pulse-item-categories/${params.categoryId}/update`, params)

//DELETE CATEGORY
export const deleteCategory = (
	params = {
		categoryId: null,
	},
) => Http.delete(`/pulse-item-categories/${params.categoryId}/destroy`)
