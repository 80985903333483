import {
	categoryByID,
	categoryCreate,
	categoryDestroy,
	categoryList,
	categoryUpdate,
} from '@/services/academy/courses/categories.service'

export default {
	namespaced: true,
	state: {
		// SERVICE CATEGORY
		categoryList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		category: {},

		// STATUS SERVICE CATEGORY
		categoryStatus: 'pending',
		categorySaveStatus: 'init',
		categoryDeleteStatus: 'init',
	},
	getters: {
		// SERVICE CATEGORY
		getCategoryList(state) {
			return state.categoryList
		},
		getCategory(state) {
			return state.category
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE CATEGORY
		getCategoryDeleteStatus(state) {
			return state.categoryDeleteStatus
		},
		getCategorySaveStatus(state) {
			return state.categorySaveStatus
		},
		getCategoryStatus(state) {
			return state.categoryStatus
		},
	},
	mutations: {
		// SERVICE CATEGORY
		CATEGORY_LIST(state, value) {
			state.categoryList = value
		},
		SET_CATEGORY(state, value) {
			state.category = value
		},

		// STATUS SERVICE CATEGORY
		CATEGORY_STATUS(state, value) {
			state.categoryStatus = value
		},
		CATEGORY_SAVE_STATUS(state, value) {
			state.categorySaveStatus = value
		},
		CATEGORY_DELETE_STATUS(state, value) {
			state.categoryDeleteStatus = value
		},
	},
	actions: {
		// Service Category
		async fetchCategoryList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('CATEGORY_STATUS', 'pending')
			commit('CATEGORY_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await categoryList(params)
				commit('CATEGORY_STATUS', 'init')
				commit('CATEGORY_LIST', data)
				return data
			} catch (error) {
				commit('CATEGORY_STATUS', 'init')
				throw error
			}
		},
		async fetchCategoryByID({ commit }, params) {
			commit('CATEGORY_STATUS', 'pending')
			try {
				const { data } = await categoryByID(params)
				commit('CATEGORY_STATUS', 'init')
				commit('SET_CATEGORY', data.data)
				return data
			} catch (error) {
				commit('CATEGORY_STATUS', 'init')

				return error
			}
		},
		async saveNewCategory({ commit }, params) {
			commit('CATEGORY_SAVE_STATUS', 'pending')
			try {
				const { data } = await categoryCreate(params)
				commit('CATEGORY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CATEGORY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCategory({ commit }, params) {
			commit('CATEGORY_SAVE_STATUS', 'pending')
			try {
				const { data } = await categoryUpdate(params)
				commit('CATEGORY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CATEGORY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCategoryByID({ commit }, params) {
			commit('CATEGORY_DELETE_STATUS', 'pending')
			try {
				const { data } = await categoryDestroy(params)
				commit('CATEGORY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CATEGORY_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
