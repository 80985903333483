import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getGenres = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/genres/fetch${serialize(params)}`)

//SAVE A NEW GENRE
export const saveGenre = (
	params = {
		name: null,
	},
) => Http.post(`/genres/store`, params)

//UPDATE GENRE
export const updateGenre = (
	params = {
		name: null,
		genreId: null,
	},
) => Http.put(`/genres/${params.genreId}/update`, params)

//DELETE GENRE
export const deleteGenre = (
	params = {
		genreId: null,
	},
) => Http.delete(`/genres/${params.genreId}/destroy`)
