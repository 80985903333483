import {
	workavailabilitiesList,
	workavailabilityByID,
	workavailabilityCreate,
	workavailabilityDestroy,
	workavailabilityUpdate,
} from '@/services/jobs/workAvailabilities.service'

export default {
	namespaced: true,
	state: {
		// WORKAVAILABILITIES
		workavailabilitiesList: {},
		workavailability: {},
		// STATUS WORKAVAILABILITY
		workavailabilitiesStatus: 'pending',
		workavailabilitiesSaveStatus: 'init',
		workavailabilitiesDeleteStatus: 'init',
	},
	getters: {
		// WORKAVAILABILITY
		getWorkavailabilitiesList(state) {
			return state.workavailabilitiesList
		},
		getWorkavailability(state) {
			return state.workavailability
		},
		// WORKAVAILABILITY
		getWorkavailabilitiesDeleteStatus(state) {
			return state.workavailabilitiesDeleteStatus
		},
		getWorkavailabilitiesSaveStatus(state) {
			return state.workavailabilitiesSaveStatus
		},
		getWorkavailabilitiesStatus(state) {
			return state.workavailabilitiesStatus
		},
	},
	mutations: {
		// WORKAVAILABILITIES
		WORKAVAILABILITIES_LIST(state, value) {
			state.workavailabilitiesList = value
		},
		SET_WORKAVAILABILITY(state, value) {
			state.workavailability = value
		},
		// STATUS WORKAVAILABILITIES
		WORKAVAILABILITIES_STATUS(state, value) {
			state.workavailabilitiesStatus = value
		},
		WORKAVAILABILITY_SAVE_STATUS(state, value) {
			state.workavailabilitiesSaveStatus = value
		},
		WORKAVAILABILITY_DELETE_STATUS(state, value) {
			state.workavailabilitiesDeleteStatus = value
		},
	},
	actions: {
		// Workavailabilities
		async fetchWorkavailabilityList({ commit }, params) {
			commit('WORKAVAILABILITIES_STATUS', 'pending')
			commit('WORKAVAILABILITIES_LIST', {})
			try {
				const { data } = await workavailabilitiesList(params)
				commit('WORKAVAILABILITIES_STATUS', 'init')
				commit('WORKAVAILABILITIES_LIST', data)
				return data
			} catch (error) {
				commit('WORKAVAILABILITIES_STATUS', 'init')
				throw error
			}
		},
		async fetchWorkavailabilityByID({ commit }, params) {
			commit('WORKAVAILABILITIES_STATUS', 'pending')
			try {
				const { data } = await workavailabilityByID(params)
				commit('WORKAVAILABILITIES_STATUS', 'init')
				commit('SET_WORKAVAILABILITY', data.data)
				return data
			} catch (error) {
				commit('WORKAVAILABILITIES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewWorkavailability({ commit }, params) {
			commit('WORKAVAILABILITY_SAVE_STATUS', 'pending')
			try {
				const { data } = await workavailabilityCreate(params)
				commit('WORKAVAILABILITY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WORKAVAILABILITY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateWorkavailability({ commit }, params) {
			commit('WORKAVAILABILITY_SAVE_STATUS', 'pending')
			try {
				const { data } = await workavailabilityUpdate(params)
				commit('WORKAVAILABILITY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WORKAVAILABILITY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteWorkavailabilityByID({ commit }, params) {
			commit('WORKAVAILABILITY_DELETE_STATUS', 'pending')
			try {
				const { data } = await workavailabilityDestroy(params)
				commit('WORKAVAILABILITY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('WORKAVAILABILITY_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
