import { deleteStatus, getStatus, saveStatus, updateStatus } from '@/services/community/wikigamer/games/status'

export default {
	namespaced: true,
	state: {
		statusList: {
			meta: {
				total: 0,
			},
		},

		statusStatus: 'pending',
	},
	getters: {
		getStatusList(state) {
			return state.statusList
		},
		getStatusStatus(state) {
			return state.statusStatus
		},
	},
	mutations: {
		SET_STATUS_LIST(state, value) {
			state.statusList = value
		},
		SET_STATUS_STATUS(state, value) {
			state.statusStatus = value
		},
	},
	actions: {
		async fetchStatus({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_STATUS_STATUS', 'pending')
			commit('SET_STATUS_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getStatus(params)
				commit('SET_STATUS_STATUS', 'init')
				commit('SET_STATUS_LIST', data)
				return data
			} catch (error) {
				commit('SET_STATUS_STATUS', 'init')
				throw error
			}
		},

		async saveStatus({ commit }, params) {
			commit('SET_STATUS_STATUS', 'pending')
			try {
				const { data } = await saveStatus(params)
				commit('SET_STATUS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_STATUS_STATUS', 'init')
				throw error
			}
		},
		async updateStatus({ commit }, params) {
			commit('SET_STATUS_STATUS', 'pending')
			try {
				const { data } = await updateStatus(params)
				commit('SET_STATUS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_STATUS_STATUS', 'init')
				throw error
			}
		},
		async deleteStatusRecord({ commit }, params) {
			commit('SET_STATUS_STATUS', 'pending')
			try {
				const { data } = await deleteStatus(params)
				commit('SET_STATUS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_STATUS_STATUS', 'init')
				throw error
			}
		},
	},
}
