import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const awardsList = (params = '') => Http.get(`/awards/fetch${serialize(params)}`)
export const awardCreate = (params = '') => Http.post(`/awards/store`, params)
export const awardUpdate = (params = '') => Http.put(`/awards/${params.id}/update`, params)
export const awardDestroy = (params = '') => Http.delete(`/awards/${params.id}/destroy`)


export const categoriesList = (params = '') => Http.get(`/award/categories/fetch${serialize(params)}`)
export const categoryCreate = (params = '') => Http.post(`/award/categories/store`, params)
export const categoryUpdate = (params = '') => Http.put(`/award/categories/${params.id}/update`, params)
export const categoryDestroy = (params = '') => Http.delete(`/award/categories/${params.id}/destroy`)


export const getCompetitors = (
	params = {
		perPage: 10,
		page: 1,
		search: '',
		award_id: '',
	},
) => {
	const { award_id, ...restParams } = params;
	return Http.get(`/award/${award_id}/category/options/fetch${serialize(restParams)}`);
}

export const destroyCompetitor = (
	params = {
	  award_id: null,
	  id: null, 
	},
  ) => {
	return Http.delete(`/award/${params.award_id}/category/options/${params.id}/destroy`);
  };

export const saveCompetitor = (
    params = {
        awardId: '',
    },
) => Http.post(`/award/${params.award_id}/category/options/store`, params)

export const updateCompetitor = (params = '') => Http.put(`/award/${params.award_id}/category/options/${params.award_category_id}/update`, params)

export const getCompetitor = (params = '') => Http.get(`/award/${params.award_id}/category/options/${params.category_id}/show`)
