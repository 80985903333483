import Http from '@/services/http'

export default {
	state: {
		statusUser: [],
		statusUserLoading: false,
	},
	mutations: {
		SET_STATUS_USER(state, statusUser) {
			state.statusUser = statusUser
		},
		SET_STATUS_USER_LOADING(state, loading) {
			state.statusUserLoading = loading
		},
	},
	actions: {
		fetchStatusUser({ commit }) {
			commit('SET_STATUS_USER_LOADING', true)
			Http.get('status-user')
				.then(response => {
					commit('SET_STATUS_USER', response.data.data)
				})
				.catch(error => {
					console.error('Error fetching status_user:', error)
				})
				.finally(() => {
					commit('SET_STATUS_USER_LOADING', false)
				})
		},
	},
	getters: {
		getStatusUser(state) {
			return state.statusUser
		},
		getStatusUserLoading(state) {
			return state.statusUserLoading
		}
	}
}
