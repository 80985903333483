import {
	getPulses,
	getStats,
	getStatsLanguageDeutsch,
	getStatsLanguageEnglish,
	getStatsLanguagePortuguese,
	getStatsLanguageRomanian,
	getStatsLanguageSpanish,
} from '@/services/community/contents/pulses/pulses'

export default {
	namespaced: true,
	state: {
		pulseStats: [],
		pulseStatStatus: 'pending',

		pulseLanguageEnglish: [],
		pulseStatusLanguageEnglish: 'pending',

		pulseLanguageDeutsch: [],
		pulseStatusLanguageDeutsch: 'pending',

		pulseLanguagePortuguese: [],
		pulseStatusLanguagePortuguese: 'pending',

		pulseLanguageSpanish: [],
		pulseStatusLanguageSpanish: 'pending',

		pulseLanguageRomanian: [],
		pulseStatusLanguageRomanian: 'pending',
	},
	getters: {
		getPulseList(state) {
			return state.pulseList
		},
		getPulseStatus(state) {
			return state.pulseStatus
		},

		getPulseStats(state) {
			return state.pulseStats
		},
		getPulseStatStatus(state) {
			return state.pulseStatStatus
		},

		getPulseLanguageEnglish(state) {
			return state.pulseLanguageEnglish
		},
		getPulseStatusLanguageEnglish(state) {
			return state.pulseStatusLanguageEnglish
		},

		getPulseLanguageDeutsch(state) {
			return state.pulseLanguageDeutsch
		},
		getPulseStatusLanguageDeutsch(state) {
			return state.pulseStatusLanguageDeutsch
		},

		getPulseLanguagePortuguese(state) {
			return state.pulseLanguagePortuguese
		},
		getPulseStatusLanguagePortuguese(state) {
			return state.pulseStatusLanguagePortuguese
		},

		getPulseLanguageSpanish(state) {
			return state.pulseLanguageSpanish
		},
		getPulseStatusLanguageSpanish(state) {
			return state.pulseStatusLanguageSpanish
		},

		getPulseLanguageRomanian(state) {
			return state.pulseLanguageRomanian
		},
		getPulseStatusLanguageRomanian(state) {
			return state.pulseStatusLanguageRomanian
		},
	},
	mutations: {
		SET_PULSE_LIST(state, value) {
			state.pulseList = value
		},
		SET_PULSE_STATUS(state, value) {
			state.pulseStatus = value
		},

		SET_PULSE_STATS(state, value) {
			state.pulseStats = value
		},
		SET_PULSE_STAT_STATUS(state, value) {
			state.pulseStatStatus = value
		},

		SET_PULSE_STATS_LANGUAGE_ENGLISH(state, value) {
			state.pulseLanguageEnglish = value
		},
		SET_PULSE_STAT_STATUS_LANGUAGE_ENGLISH(state, value) {
			state.pulseStatusLanguageEnglish = value
		},

		SET_PULSE_STATS_LANGUAGE_DEUTSCH(state, value) {
			state.pulseLanguageDeutsch = value
		},
		SET_PULSE_STAT_STATUS_LANGUAGE_DEUTSCH(state, value) {
			state.pulseStatusLanguageDeutsch = value
		},

		SET_PULSE_STATS_LANGUAGE_PORTUGUESE(state, value) {
			state.pulseLanguagePortuguese = value
		},
		SET_PULSE_STAT_STATUS_LANGUAGE_PORTUGUESE(state, value) {
			state.pulseStatusLanguagePortuguese = value
		},

		SET_PULSE_STATS_LANGUAGE_SPANISH(state, value) {
			state.pulseLanguageSpanish = value
		},
		SET_PULSE_STAT_STATUS_LANGUAGE_SPANISH(state, value) {
			state.pulseStatusLanguageSpanish = value
		},

		SET_PULSE_STATS_LANGUAGE_ROMANIAN(state, value) {
			state.pulseLanguageRomanian = value
		},
		SET_PULSE_STAT_STATUS_LANGUAGE_ROMANIAN(state, value) {
			state.pulseStatusLanguageRomanian = value
		},
	},
	actions: {
		async fetchPulses({ commit }, params) {
			commit('SET_PULSE_LIST', [])
			commit('SET_PULSE_STATUS', 'pending')
			try {
				const { data } = await getPulses(params)
				commit('SET_PULSE_STATUS', 'init')
				commit('SET_PULSE_LIST', data)
				return data
			} catch (error) {
				commit('SET_PULSE_STATUS', 'init')
				throw error
			}
		},

		async fetchStats({ commit }, params) {
			commit('SET_PULSE_STATS', [])
			commit('SET_PULSE_STAT_STATUS', 'pending')
			try {
				const { data } = await getStats(params)
				commit('SET_PULSE_STAT_STATUS', 'init')
				commit('SET_PULSE_STATS', data)
				return data
			} catch (error) {
				commit('SET_PULSE_STAT_STATUS', 'init')
				throw error
			}
		},

		async fetchLanguageEnglish({ commit }, params) {
			commit('SET_PULSE_STATS_LANGUAGE_ENGLISH', [])
			commit('SET_PULSE_STAT_STATUS_LANGUAGE_ENGLISH', 'pending')
			try {
				const { data } = await getStatsLanguageEnglish(params)
				commit('SET_PULSE_STAT_STATUS_LANGUAGE_ENGLISH', 'init')
				commit('SET_PULSE_STATS_LANGUAGE_ENGLISH', data)
				return data
			} catch (error) {
				commit('SET_PULSE_STATS_LANGUAGE_ENGLISH', 'init')
				throw error
			}
		},

		async fetchLanguageDeutsch({ commit }, params) {
			commit('SET_PULSE_STATS_LANGUAGE_DEUTSCH', [])
			commit('SET_PULSE_STAT_STATUS_LANGUAGE_DEUTSCH', 'pending')
			try {
				const { data } = await getStatsLanguageDeutsch(params)
				commit('SET_PULSE_STAT_STATUS_LANGUAGE_DEUTSCH', 'init')
				commit('SET_PULSE_STATS_LANGUAGE_DEUTSCH', data)
				return data
			} catch (error) {
				commit('SET_PULSE_STATS_LANGUAGE_DEUTSCH', 'init')
				throw error
			}
		},

		async fetchLanguagePortuguese({ commit }, params) {
			commit('SET_PULSE_STATS_LANGUAGE_PORTUGUESE', [])
			commit('SET_PULSE_STAT_STATUS_LANGUAGE_PORTUGUESE', 'pending')
			try {
				const { data } = await getStatsLanguagePortuguese(params)
				commit('SET_PULSE_STAT_STATUS_LANGUAGE_PORTUGUESE', 'init')
				commit('SET_PULSE_STATS_LANGUAGE_PORTUGUESE', data)
				return data
			} catch (error) {
				commit('SET_PULSE_STATS_LANGUAGE_PORTUGUESE', 'init')
				throw error
			}
		},

		async fetchLanguageSpanish({ commit }, params) {
			commit('SET_PULSE_STATS_LANGUAGE_SPANISH', [])
			commit('SET_PULSE_STAT_STATUS_LANGUAGE_SPANISH', 'pending')
			try {
				const { data } = await getStatsLanguageSpanish(params)
				commit('SET_PULSE_STAT_STATUS_LANGUAGE_SPANISH', 'init')
				commit('SET_PULSE_STATS_LANGUAGE_SPANISH', data)
				return data
			} catch (error) {
				commit('SET_PULSE_STATS_LANGUAGE_SPANISH', 'init')
				throw error
			}
		},

		async fetchLanguageRomanian({ commit }, params) {
			commit('SET_PULSE_STATS_LANGUAGE_ROMANIAN', [])
			commit('SET_PULSE_STAT_STATUS_LANGUAGE_ROMANIAN', 'pending')
			try {
				const { data } = await getStatsLanguageRomanian(params)
				commit('SET_PULSE_STAT_STATUS_LANGUAGE_ROMANIAN', 'init')
				commit('SET_PULSE_STATS_LANGUAGE_ROMANIAN', data)
				return data
			} catch (error) {
				commit('SET_PULSE_STATS_LANGUAGE_ROMANIAN', 'init')
				throw error
			}
		},
	},
}
