import {
	mdiAccountTie,
	mdiArchive,
	mdiBriefcaseAccountOutline,
	mdiClockOutline,
	mdiDomain,
	mdiFactory,
	mdiFileOutline,
	mdiFileTableBoxMultiple,
	mdiFirework,
	mdiFolderAccountOutline,
	mdiHome,
	mdiHours24,
	mdiLaptop,
	mdiMessage,
	mdiNewspaperVariantMultipleOutline,
	mdiSchool,
	mdiTranslate,
} from '@mdi/js'

// Array of sections

export default [
	{
		icon: mdiHome,
		title: 'Dashboard',
		to: 'jobs-dashboard',
	},
	{
		title: 'application',
		icon: mdiFileTableBoxMultiple,
		children: [
			{
				title: 'method',
				to: 'jobs-application-method-list',
			},
			{
				title: 'Status',
				to: 'jobs-application-status-list',
			},
		],
	},
	{
		icon: mdiFirework,
		title: 'companyPerks',
		to: 'jobs-perks-list',
	},
	{
		icon: mdiSchool,
		title: 'educationLevels',
		to: 'jobs-education-levels-list',
	},
	{
		title: 'menu.jobs.employment.employment',
		icon: mdiLaptop,
		children: [
			{
				title: 'menu.jobs.employment.employmentType',
				to: 'jobs-employments-types-list',
			},
		],
	},
	{
		icon: mdiFolderAccountOutline,
		title: 'menu.jobs.experiences',
		to: 'jobs-experiences-list',
	},
	{
		icon: mdiFactory,
		title: 'menu.jobs.industrySectors',
		to: 'industry-sector-categories-list',
	},

	{
		icon: mdiTranslate,
		title: 'languageLevels',
		to: 'jobs-language-levels-list',
	},
	{
		icon: mdiMessage,
		title: 'menu.messages',
		to: 'jobs-messages-list',
	},
	{
		icon: mdiNewspaperVariantMultipleOutline,
		title: 'Newsletter',
		to: 'jobs-newsletter-list',
	},
	{
		icon: mdiAccountTie,
		title: 'menu.jobs.professionalSituations',
		to: 'jobs-professional-situations-list',
	},
	{
		icon: mdiFileOutline,
		title: 'menu.jobs.resumes',
		to: 'jobs-resumes-list',
	},
	{
		title: 'menu.jobs.vacancies',
		icon: mdiBriefcaseAccountOutline,
		children: [
			{
				title: 'menu.jobs.vacanciesDrop.list',
				to: 'jobs-vacancies-list',
			},
			{
				title: 'Status',
				to: 'jobs-vacancies-status-list',
			},
			{
				title: 'menu.jobs.vacanciesDrop.applications',
				to: 'jobs-applications-list',
			},
		],
	},
	{
		icon: mdiDomain,
		title: 'menu.institutions',
		to: 'institutions',
	},
	{
		icon: mdiHours24,
		title: 'menu.jobs.workAvailabilities',
		to: 'jobs-work-availabilities-list',
	},
	{
		icon: mdiClockOutline,
		title: 'menu.jobs.workHours',
		to: 'jobs-work-hours-list',
	},
	{
		icon: mdiArchive,
		title: 'menu.jobs.workMethodologies',
		to: 'jobs-work-methodologies-list',
	},
]
