import {
	batchUpsertGamesFranchise,
	destroyFranchise,
	destroyGameFranchise,
	getFranchises,
	saveFranchise,
	showFranchise,
	updateFranchise,
} from '@/services/community/wikigamer/franchises'
import { alertBasic } from '@/views/components/alert/demos/code'

export default {
	namespaced: true,
	state: {
		franchiseList: {
			meta: {
				total: 0,
			},
			data: [],
		},
		franchiseData: null,
		franchiseStatus: 'pending',
		franchiseGameStatus: 'init',
	},
	getters: {
		getFranchiseList(state) {
			return state.franchiseList
		},
		getFranchiseData(state) {
			return state.franchiseData
		},
		getFranchiseStatus(state) {
			return state.franchiseStatus
		},
		getFranchiseGameStatus(state) {
			return state.franchiseGameStatus
		},
	},
	mutations: {
		SET_FRANCHISE_LIST(state, value) {
			state.franchiseList = value
		},
		SET_FRANCHISE_DATA(state, value) {
			state.franchiseData = value
		},
		SET_FRANCHISE_STATUS(state, value) {
			state.franchiseStatus = value
		},
		SET_FRANCHISE_GAME_STATUS(state, value) {
			state.franchiseGameStatus = value
		},
	},
	actions: {
		async fetchFranchises({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_FRANCHISE_STATUS', 'pending')
			commit('SET_FRANCHISE_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getFranchises(params)
				commit('SET_FRANCHISE_STATUS', 'init')
				commit('SET_FRANCHISE_LIST', data)
				return data
			} catch (error) {
				commit('SET_FRANCHISE_STATUS', 'init')
				throw error
			}
		},

		async destroyFranchiseRecord({ commit }, params) {
			commit('SET_FRANCHISE_STATUS', 'pending')
			try {
				const { data } = await destroyFranchise(params)
				commit('SET_FRANCHISE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_FRANCHISE_STATUS', 'init')
				throw error
			}
		},
		async saveFranchise({ commit }, params) {
			commit('SET_FRANCHISE_STATUS', 'pending')
			try {
				const { data } = await saveFranchise(params)
				if (params.games_id.length > 0) {
					await batchUpsertGamesFranchise({ franchiseSlug: data.data.slug, games_id: params.games_id })
				}
				commit('SET_FRANCHISE_STATUS', 'init')
				commit('SET_FRANCHISE_DATA', null)
				return data
			} catch (error) {
				commit('SET_FRANCHISE_STATUS', 'init')
				throw error
			}
		},
		async updateFranchise({ commit }, params) {
			commit('SET_FRANCHISE_STATUS', 'pending')
			try {
				const { data } = await updateFranchise(params)
				if (params.games_id.length > 0) {
					await batchUpsertGamesFranchise({ franchiseSlug: data.data.slug, games_id: params.games_id })
				}
				commit('SET_FRANCHISE_STATUS', 'init')
				commit('SET_FRANCHISE_DATA', null)
				return data
			} catch (error) {
				commit('SET_FRANCHISE_STATUS', 'init')
				throw error
			}
		},
		async showFranchise({ commit }, params) {
			commit('SET_FRANCHISE_STATUS', 'pending')
			try {
				const { data } = await showFranchise(params)
				commit('SET_FRANCHISE_STATUS', 'init')
				commit('SET_FRANCHISE_DATA', data.data)
				return data
			} catch (error) {
				commit('SET_FRANCHISE_STATUS', 'init')
				throw error
			}
		},
		clear({ commit }, params) {
			commit('SET_FRANCHISE_STATUS', 'init')
			commit('SET_FRANCHISE_DATA', null)
		},
		async destroyGameByFranchise({ commit }, params) {
			commit('SET_FRANCHISE_GAME_STATUS', 'pending')
			try {
				await destroyGameFranchise(params)
				commit('SET_FRANCHISE_GAME_STATUS', 'init')
			} catch (error) {
				commit('SET_FRANCHISE_GAME_STATUS', 'init')
				throw error
			}
		},
	},
}
