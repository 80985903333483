import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getContents = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/esrb-rating-contents/fetch${serialize(params)}`)

// SAVE CONTENT
export const saveContent = (
	params = {
		name: null,
		description: null,
	},
) => Http.post(`/esrb-rating-contents/store`, params)

// UPDATE CONTENT
export const updateContent = (
	params = {
		contentId: null,
		name: null,
		description: null,
	},
) => Http.put(`/esrb-rating-contents/${params.contentId}/update`, params)

//DELETE CONTENT
export const deleteContent = (
	params = {
		contentId: null,
	},
) => Http.delete(`/esrb-rating-contents/${params.contentId}/destroy`)

//LIST OS RATINGS
export const getRatings = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/esrb-ratings/fetch${serialize(params)}`)

// SAVE RATING
export const saveRating = (
	params = {
		name: '',
		minimum_age: null,
		description: '',
		image: null,
	},
) => Http.post(`/esrb-ratings/store`, params)

// UPDATE RATING
export const updateRating = (
	params = {
		ratingId: null,
		name: '',
		minimum_age: null,
		description: '',
		image: null,
	},
) => Http.put(`/esrb-ratings/${params.ratingId}/update`, params)

// DELETE RATING
export const deleteRating = (
	params = {
		ratingId: null,
	},
) => Http.delete(`/esrb-ratings/${params.ratingId}/destroy`)
