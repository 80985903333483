import {
	institutionByID,
	institutionCreate,
	institutionDestroy,
	institutionList,
	institutionUpdate,
} from '@/services/institutions.service'

export default {
	namespaced: true,
	state: {
		// SERVICE INSTITUTION
		institutionList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		institution: {},

		// STATUS SERVICE INSTITUTION
		institutionStatus: 'pending',
		institutionSaveStatus: 'init',
		institutionDeleteStatus: 'init',
	},
	getters: {
		// SERVICE INSTITUTION
		getInstitutionList(state) {
			return state.institutionList
		},
		getInstitution(state) {
			return state.institution
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE INSTITUTION
		getInstitutionDeleteStatus(state) {
			return state.institutionDeleteStatus
		},
		getInstitutionSaveStatus(state) {
			return state.institutionSaveStatus
		},
		getInstitutionStatus(state) {
			return state.institutionStatus
		},
	},
	mutations: {
		// SERVICE INSTITUTION
		INSTITUTION_LIST(state, value) {
			state.institutionList = value
		},
		SET_INSTITUTION(state, value) {
			state.institution = value
		},

		// STATUS SERVICE INSTITUTION
		INSTITUTION_STATUS(state, value) {
			state.institutionStatus = value
		},
		INSTITUTION_SAVE_STATUS(state, value) {
			state.institutionSaveStatus = value
		},
		INSTITUTION_DELETE_STATUS(state, value) {
			state.institutionDeleteStatus = value
		},
	},
	actions: {
		// Service Institution
		async fetchInstitutionList({ commit }, params) {
			commit('INSTITUTION_STATUS', 'pending')
			commit('INSTITUTION_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await institutionList(params)
				commit('INSTITUTION_STATUS', 'init')
				commit('INSTITUTION_LIST', data)
				return data
			} catch (error) {
				commit('INSTITUTION_STATUS', 'init')
				throw error
			}
		},
		async fetchInstitutionByID({ commit }, params) {
			commit('INSTITUTION_STATUS', 'pending')
			try {
				const { data } = await institutionByID(params)
				commit('INSTITUTION_STATUS', 'init')
				commit('SET_INSTITUTION', data.data)
				return data
			} catch (error) {
				commit('INSTITUTION_STATUS', 'init')

				return error
			}
		},
		async saveNewInstitution({ commit }, params) {
			commit('INSTITUTION_SAVE_STATUS', 'pending')
			try {
				const { data } = await institutionCreate(params)
				commit('INSTITUTION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('INSTITUTION_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateInstitution({ commit }, params) {
			commit('INSTITUTION_SAVE_STATUS', 'pending')
			try {
				const { data } = await institutionUpdate(params)
				commit('INSTITUTION_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('INSTITUTION_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteInstitutionByID({ commit }, params) {
			commit('INSTITUTION_DELETE_STATUS', 'pending')
			try {
				const { data } = await institutionDestroy(params)
				commit('INSTITUTION_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('INSTITUTION_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
