const partners = [
	{
		path: '/network/partners/list',
		name: 'network-partners-list',
		component: () => import(/* webpackChunkName: "NetworkPartnersList" */ '@/views/network/partners/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/partners/create',
		name: 'network-partners-create',
		component: () => import(/* webpackChunkName: "NetworkPartnersCreate" */ '@/views/network/partners/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/partners/edit/:id',
		name: 'network-partners-edit',
		component: () => import(/* webpackChunkName: "NetworkPartnersEdit" */ '@/views/network/partners/new.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default partners
