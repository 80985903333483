import { deleteHighlight, getHighlights, saveHighlight, updateHighlight } from '@/services/agency/highlights.service'
export default {
	namespaced: true,
	state: {
		highlightList: {
			meta: {
				total: 0,
			},
		},
		highlightstatus: 'pending',
	},
	getters: {
		getHighlightList(state) {
			return state.highlightList
		},
		getHighlightsStatus(state) {
			return state.highlightstatus
		},
	},
	mutations: {
		SET_HIGHLIGHT_LIST(state, value) {
			state.highlightList = value
		},
		SET_HIGHLIGHT_STATUS(state, value) {
			state.highlightstatus = value
		},
	},
	actions: {
		async fetchHighlights({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_HIGHLIGHT_STATUS', 'pending')
			commit('SET_HIGHLIGHT_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getHighlights(params)
				commit('SET_HIGHLIGHT_STATUS', 'init')
				commit('SET_HIGHLIGHT_LIST', data)
				return data
			} catch (error) {
				commit('SET_HIGHLIGHT_STATUS', 'init')
				throw error
			}
		},

		async deleteHighlightRecord({ commit }, params) {
			commit('SET_HIGHLIGHT_STATUS', 'pending')
			try {
				const { data } = await deleteHighlight(params)
				commit('SET_HIGHLIGHT_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_HIGHLIGHT_STATUS', 'init')
				throw error
			}
		},
		async saveHighlight({ commit }, params) {
			commit('SET_HIGHLIGHT_STATUS', 'pending')
			try {
				const { data } = await saveHighlight(params)
				commit('SET_HIGHLIGHT_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_HIGHLIGHT_STATUS', 'init')
				throw error
			}
		},
		async updateHighlight({ commit }, params) {
			commit('SET_HIGHLIGHT_STATUS', 'pending')
			try {
				const { data } = await updateHighlight(params)
				commit('SET_HIGHLIGHT_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_HIGHLIGHT_STATUS', 'init')
				throw error
			}
		},
	},
}
