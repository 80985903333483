import { gameByID, gameCreate, gameDestroy, gameList, gameUpdate } from '@/services/agency/games.service'

export default {
	namespaced: true,
	state: {
		// SERVICE GAME
		gameList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		game: {},

		// STATUS SERVICE GAME
		gameStatus: 'pending',
		gameSaveStatus: 'init',
		gameDeleteStatus: 'init',
	},
	getters: {
		// SERVICE GAME
		getGameList(state) {
			return state.gameList
		},
		getGame(state) {
			return state.game
		},

		// SERVICE STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},

		// SERVICE GAME
		getGameDeleteStatus(state) {
			return state.gameDeleteStatus
		},
		getGameSaveStatus(state) {
			return state.gameSaveStatus
		},
		getGameStatus(state) {
			return state.gameStatus
		},
	},
	mutations: {
		// SERVICE GAME
		GAME_LIST(state, value) {
			state.gameList = value
		},
		SET_GAME(state, value) {
			state.game = value
		},

		// STATUS SERVICE GAME
		GAME_STATUS(state, value) {
			state.gameStatus = value
		},
		GAME_SAVE_STATUS(state, value) {
			state.gameSaveStatus = value
		},
		GAME_DELETE_STATUS(state, value) {
			state.gameDeleteStatus = value
		},
	},
	actions: {
		// Service Game
		async fetchGameList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('GAME_STATUS', 'pending')
			commit('GAME_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await gameList(params)
				commit('GAME_STATUS', 'init')
				commit('GAME_LIST', data)
				return data
			} catch (error) {
				commit('GAME_STATUS', 'init')
				throw error
			}
		},
		async fetchGameByID({ commit }, params) {
			commit('GAME_STATUS', 'pending')
			try {
				const { data } = await gameByID(params)
				commit('GAME_STATUS', 'init')
				commit('SET_GAME', data.data)
				return data
			} catch (error) {
				commit('GAME_STATUS', 'init')

				return error
			}
		},
		async saveNewGame({ commit }, params) {
			commit('GAME_SAVE_STATUS', 'pending')
			try {
				const { data } = await gameCreate(params)
				commit('GAME_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('GAME_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateGame({ commit }, params) {
			commit('GAME_SAVE_STATUS', 'pending')
			try {
				const { data } = await gameUpdate(params)
				commit('GAME_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('GAME_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteGameByID({ commit }, params) {
			commit('GAME_DELETE_STATUS', 'pending')
			try {
				const { data } = await gameDestroy(params)
				commit('GAME_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('GAME_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
