import {
	campaignByID,
	campaignCreate,
	campaignDestroy,
	campaignUpdate,
	campaignsList,
	categoriesList,
	categoryByID,
	categoryCreate,
	categoryDestroy,
	categoryUpdate,
	periodicitiesList,
	periodicityByID,
	periodicityCreate,
	periodicityDestroy,
	periodicityUpdate,
	resultByID,
	resultsList,
} from '@/services/agency/newsletter.service'

export default {
	namespaced: true,
	state: {
		// Newsletter CATEGORIES
		categoriesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		category: {},

		// STATUS Newsletter CATEGORIES
		categoriesStatus: 'pending',
		categoriesSaveStatus: 'init',
		categoriesDeleteStatus: 'init',

		// Newsletter PERIODICITIES
		periodicitiesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		periodicity: {},

		// STATUS Newsletter PERIODICITIES
		periodicitiesStatus: 'pending',
		periodicitiesSaveStatus: 'init',
		periodicitiesDeleteStatus: 'init',

		// Newsletter CAMPAIGNS
		campaignsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		campaign: {},

		// STATUS Newsletter CAMPAIGNS
		campaignsStatus: 'pending',
		campaignsSaveStatus: 'init',
		campaignsDeleteStatus: 'init',

		// Newsletter RESULTS
		resultsList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		result: {},

		// STATUS Newsletter RESULTS
		resultsStatus: 'pending',
	},
	getters: {
		// CATEGORIES Newsletter
		getCategoriesList(state) {
			return state.categoriesList
		},
		getCategory(state) {
			return state.category
		},

		// Newsletter CATEGORIES
		getCategoriesDeleteStatus(state) {
			return state.categoriesDeleteStatus
		},
		getCategoriesSaveStatus(state) {
			return state.categoriesSaveStatus
		},
		getCategoriesStatus(state) {
			return state.categoriesStatus
		},


		// Periodicities Newsletter
		getPeriodicitiesList(state) {
			return state.periodicitiesList
		},
		getPeriodicity(state) {
			return state.periodicity
		},

		// Newsletter PERIODICITIES
		getPeriodicitiesDeleteStatus(state) {
			return state.periodicitiesDeleteStatus
		},
		getPeriodicitiesSaveStatus(state) {
			return state.periodicitiesSaveStatus
		},
		getPeriodicitiesStatus(state) {
			return state.periodicitiesStatus
		},

		// Campaigns Newsletter
		getCampaignsList(state) {
			return state.campaignsList
		},
		getCampaign(state) {
			return state.campaign
		},

		// Newsletter CAMPAIGNS
		getCampaignsDeleteStatus(state) {
			return state.campaignsDeleteStatus
		},
		getCampaignsSaveStatus(state) {
			return state.campaignsSaveStatus
		},
		getCampaignsStatus(state) {
			return state.campaignsStatus
		},


		// RESULTS Newsletter
		getResultsList(state) {
			return state.resultsList
		},
		getResult(state) {
			return state.result
		},

		getResultsStatus(state) {
			return state.resultsStatus
		},
	},
	mutations: {
		// Newsletter CATEGORIES
		CATEGORIES_LIST(state, value) {
			state.categoriesList = value
		},
		SET_CATEGORY(state, value) {
			state.category = value
		},

		// STATUS Newsletter CATEGORIES
		CATEGORIES_STATUS(state, value) {
			state.categoriesStatus = value
		},
		CATEGORY_SAVE_STATUS(state, value) {
			state.categoriesSaveStatus = value
		},
		CATEGORY_DELETE_STATUS(state, value) {
			state.categoriesDeleteStatus = value
		},

		// Newsletter PERIODICITIES
		PERIODICITIES_LIST(state, value) {
			state.periodicitiesList = value
		},
		SET_PERIODICITY(state, value) {
			state.periodicity = value
		},

		// STATUS Newsletter PERIODICITIES
		PERIODICITIES_STATUS(state, value) {
			state.periodicitiesStatus = value
		},
		PERIODICITY_SAVE_STATUS(state, value) {
			state.periodicitiesSaveStatus = value
		},
		PERIODICITY_DELETE_STATUS(state, value) {
			state.periodicitiesDeleteStatus = value
		},

		// Newsletter CAMPAIGNS
		CAMPAIGNS_LIST(state, value) {
			state.campaignsList = value
		},
		SET_CAMPAIGN(state, value) {
			state.campaign = value
		},

		// STATUS Newsletter CAMPAIGNS
		CAMPAIGNS_STATUS(state, value) {
			state.campaignsStatus = value
		},
		CAMPAIGN_SAVE_STATUS(state, value) {
			state.campaignsSaveStatus = value
		},
		CAMPAIGN_DELETE_STATUS(state, value) {
			state.campaignsDeleteStatus = value
		},

		// Newsletter RESULTS
		RESULTS_LIST(state, value) {
			state.resultsList = value
		},
		SET_RESULT(state, value) {
			state.result = value
		},
		// STATUS Newsletter RESULTS
		RESULTS_STATUS(state, value) {
			state.resultsStatus = value
		},
	},
	actions: {
		// Newsletter Categories
		async fetchCategoryList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('CATEGORIES_STATUS', 'pending')
			commit('CATEGORIES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await categoriesList(params)
				commit('CATEGORIES_STATUS', 'init')
				commit('CATEGORIES_LIST', data)
				return data
			} catch (error) {
				commit('CATEGORIES_STATUS', 'init')
				throw error
			}
		},
		async fetchCategoryByID({ commit }, params) {
			commit('CATEGORIES_STATUS', 'pending')
			try {
				const { data } = await categoryByID(params)
				commit('CATEGORIES_STATUS', 'init')
				commit('SET_CATEGORY', data.data)
				return data
			} catch (error) {
				commit('CATEGORIES_STATUS', 'init')

				return error
			}
		},
		async saveNewCategory({ commit }, params) {
			commit('CATEGORY_SAVE_STATUS', 'pending')
			try {
				const { data } = await categoryCreate(params)
				commit('CATEGORY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CATEGORY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCategory({ commit }, params) {
			commit('CATEGORY_SAVE_STATUS', 'pending')
			try {
				const { data } = await categoryUpdate(params)
				commit('CATEGORY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CATEGORY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCategoryByID({ commit }, params) {
			commit('CATEGORY_DELETE_STATUS', 'pending')
			try {
				const { data } = await categoryDestroy(params)
				commit('CATEGORY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CATEGORY_DELETE_STATUS', 'error')

				return error
			}
		},




		// Newsletter Periodicities
		async fetchPeriodicityList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('PERIODICITIES_STATUS', 'pending')
			commit('PERIODICITIES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await periodicitiesList(params)
				commit('PERIODICITIES_STATUS', 'init')
				commit('PERIODICITIES_LIST', data)
				return data
			} catch (error) {
				commit('PERIODICITIES_STATUS', 'init')
				throw error
			}
		},
		async fetchPeriodicityByID({ commit }, params) {
			commit('PERIODICITIES_STATUS', 'pending')
			try {
				const { data } = await periodicityByID(params)
				commit('PERIODICITIES_STATUS', 'init')
				commit('SET_PERIODICITY', data.data)
				return data
			} catch (error) {
				commit('PERIODICITIES_STATUS', 'init')

				return error
			}
		},
		async saveNewPeriodicity({ commit }, params) {
			commit('PERIODICITY_SAVE_STATUS', 'pending')
			try {
				const { data } = await periodicityCreate(params)
				commit('PERIODICITY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PERIODICITY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updatePeriodicity({ commit }, params) {
			commit('PERIODICITY_SAVE_STATUS', 'pending')
			try {
				const { data } = await periodicityUpdate(params)
				commit('PERIODICITY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PERIODICITY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deletePeriodicityByID({ commit }, params) {
			commit('PERIODICITY_DELETE_STATUS', 'pending')
			try {
				const { data } = await periodicityDestroy(params)
				commit('PERIODICITY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PERIODICITY_DELETE_STATUS', 'error')

				return error
			}
		},


		// Newsletter Campaigns
		async fetchCampaignList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('CAMPAIGNS_STATUS', 'pending')
			commit('CAMPAIGNS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await campaignsList(params)
				commit('CAMPAIGNS_STATUS', 'init')
				commit('CAMPAIGNS_LIST', data)
				return data
			} catch (error) {
				commit('CAMPAIGNS_STATUS', 'init')
				throw error
			}
		},
		async fetchCampaignByID({ commit }, params) {
			commit('CAMPAIGNS_STATUS', 'pending')
			try {
				const { data } = await campaignByID(params)
				commit('CAMPAIGNS_STATUS', 'init')
				commit('SET_CAMPAIGN', data.data)
				return data
			} catch (error) {
				commit('CAMPAIGNS_STATUS', 'init')

				return error
			}
		},
		async saveNewCampaign({ commit }, params) {
			commit('CAMPAIGN_SAVE_STATUS', 'pending')
			try {
				const { data } = await campaignCreate(params)
				commit('CAMPAIGN_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAMPAIGN_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateCampaign({ commit }, params) {
			commit('CAMPAIGN_SAVE_STATUS', 'pending')
			try {
				const { data } = await campaignUpdate(params)
				commit('CAMPAIGN_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAMPAIGN_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteCampaignByID({ commit }, params) {
			commit('CAMPAIGN_DELETE_STATUS', 'pending')
			try {
				const { data } = await campaignDestroy(params)
				commit('CAMPAIGN_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('CAMPAIGN_DELETE_STATUS', 'error')

				return error
			}
		},


		// Newsletter Results
		async fetchResultList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('RESULTS_STATUS', 'pending')
			commit('RESULTS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await resultsList(params)
				commit('RESULTS_STATUS', 'init')
				commit('RESULTS_LIST', data)
				return data
			} catch (error) {
				commit('RESULTS_STATUS', 'init')
				throw error
			}
		},
		async fetchResultByID({ commit }, params) {
			commit('RESULTS_STATUS', 'pending')
			try {
				const { data } = await resultByID(params)
				commit('RESULTS_STATUS', 'init')
				commit('SET_RESULT', data.data)
				return data
			} catch (error) {
				commit('RESULTS_STATUS', 'init')

				return error
			}
		},
	},
}
