import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpJobs'

// // VACANCY STATUS
// // // LIST ALL VACANCY STATUS
export const vacanciesstatusList = (params = '') => Http.get(`/vacancy-status${serialize(params)}`)

// // // CREATE VacancyStatus
export const vacancystatusCreate = (params = '') => Http.post('/vacancy-status', params)
// // // GET VacancyStatus BY ID
export const vacancystatusByID = (params = '') => Http.get(`/vacancy-status/${params}`)
// // // UPDATE VACANCY STATUS
export const vacancystatusUpdate = (params = '') => Http.put(`/vacancy-status/${params.id}`, params)
// // // DELETE VACANCY STATUS
export const vacancystatusDestroy = (params = '') => Http.delete(`/vacancy-status/${params.id}`)
