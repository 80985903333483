import { destroyGender, getGenders, saveGender, updateGender } from '@/services/community/wikigamer/characters/genders'

export default {
	namespaced: true,
	state: {
		genderList: [],
		genderStatus: 'pending',
	},
	getters: {
		getGenderList(state) {
			return state.genderList
		},
		getGenderStatus(state) {
			return state.genderStatus
		},
	},
	mutations: {
		SET_GENDER_LIST(state, value) {
			state.genderList = value
		},
		SET_GENDER_STATUS(state, value) {
			state.genderStatus = value
		},
	},
	actions: {
		async fetchGenders({ commit }, params) {
			commit('SET_GENDER_LIST', [])
			commit('SET_GENDER_STATUS', 'pending')
			try {
				const { data } = await getGenders(params)
				commit('SET_GENDER_STATUS', 'init')
				commit('SET_GENDER_LIST', data)
				return data
			} catch (error) {
				commit('SET_GENDER_STATUS', 'init')
				throw error
			}
		},
		async destroyGenderRecord({ commit }, params) {
			commit('SET_GENDER_STATUS', 'pending')
			try {
				const { data } = await destroyGender(params)
				commit('SET_GENDER_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_GENDER_STATUS', 'init')
				throw error
			}
		},
		async saveGender({ commit }, params) {
			commit('SET_GENDER_STATUS', 'pending')
			try {
				const { data } = await saveGender(params)
				commit('SET_GENDER_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_GENDER_STATUS', 'init')
				throw error
			}
		},
		async updateGender({ commit }, params) {
			commit('SET_GENDER_STATUS', 'pending')
			try {
				const { data } = await updateGender(params)
				commit('SET_GENDER_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_GENDER_STATUS', 'init')
				throw error
			}
		},
	},
}
