import Http from './http'
export class User {
	acl_permissions = {
		roles: [],
		permissions: [],
	}
	constructor(data) {
		Object.assign(this, data)
		return this
	}
}
export class AuthService {
	#user = null
	/**
	 * @param {{refresh: boolean}} options
	 * @returns {Promise<boolean>}
	 */
	isAuthenticated(options = { refresh: false }) {
		if (this.#user && !options.refresh) {
			return Promise.resolve(true)
		}
		return this.authenticate()
	}

	authenticate() {
		return new Promise(resolve => {
			var xhr = Http.get('/auth/user')
			xhr.then(response => {
				if (response.status !== 200) {
					resolve(false)
				}
				let user = new User(response.data)
				if (user.acl_permissions.roles.length === 0) {
					document.write('<h1>You are not authorized to access this page</h1>')
					return
				}
				Object.freeze(user)
				this.#user = user

				resolve(true)
				// eslint-disable-next-line no-unused-vars
			}).catch(_error => {
				resolve(false)
			})
		})
	}
	/**
	 * @returns {User}
	 */
	getUser() {
		if (!this.#user) {
			throw new Error('User is not authenticated')
		}
		return this.#user
	}
}

/**
 *
 * @param {string[]} roles
 * @returns
 */
export function canNavigate(roles = null, app = null) {
	/**
	 * @type {User}
	 */
	const user = (app || this).$authService.getUser()
	if (!roles || user.acl_permissions.roles.indexOf('MGN,admin_master') > -1) {
		return true
	}

	if (!Array.isArray(roles)) {
		roles = [roles]
	}

	for (const role of roles) {
		const system = role.split(',')[0]
		if (!roles.includes(`${system},admin`)) {
			roles.push(`${system},admin`)
		}
		if (user.acl_permissions.roles.includes(role)) {
			return true
		}
	}
	return false
}
