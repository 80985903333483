import { perkByID, perkCreate, perkDestroy, perksList, perkUpdate } from '@/services/jobs/perks.service'

export default {
	namespaced: true,
	state: {
		// PERKS
		perksList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		perk: {},
		// STATUS PERK
		perksStatus: 'pending',
		perksSaveStatus: 'init',
		perksDeleteStatus: 'init',
	},
	getters: {
		// PERK
		getPerksList(state) {
			return state.perksList
		},
		getPerk(state) {
			return state.perk
		},
		// STATUS
		getStatusList(state) {
			return state.statusList
		},
		getStatus(state) {
			return state.status
		},
		// PERK STATUS
		getPerksDeleteStatus(state) {
			return state.perksDeleteStatus
		},
		getPerksSaveStatus(state) {
			return state.perksSaveStatus
		},
		getPerksStatus(state) {
			return state.perksStatus
		},
	},
	mutations: {
		// PERKS
		PERKS_LIST(state, value) {
			state.perksList = value
		},
		SET_PERK(state, value) {
			state.perk = value
		},
		// STATUS PERKS
		PERKS_STATUS(state, value) {
			state.perksStatus = value
		},
		PERK_SAVE_STATUS(state, value) {
			state.perksSaveStatus = value
		},
		PERK_DELETE_STATUS(state, value) {
			state.perksDeleteStatus = value
		},
	},
	actions: {
		// Perks
		async fetchPerkList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('PERKS_STATUS', 'pending')
			commit('PERKS_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await perksList(params)
				console.log(data)
				commit('PERKS_STATUS', 'init')
				commit('PERKS_LIST', data)
				return data
			} catch (error) {
				commit('PERKS_STATUS', 'init')
				throw error
			}
		},
		async fetchPerkByID({ commit }, params) {
			commit('PERKS_STATUS', 'pending')
			try {
				const { data } = await perkByID(params)
				commit('PERKS_STATUS', 'init')
				commit('SET_PERK', data.data)
				return data
			} catch (error) {
				commit('PERKS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async saveNewPerk({ commit }, params) {
			commit('PERK_SAVE_STATUS', 'pending')
			try {
				const { data } = await perkCreate(params)
				commit('PERK_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PERK_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updatePerk({ commit }, params) {
			commit('PERK_SAVE_STATUS', 'pending')
			try {
				const { data } = await perkUpdate(params)
				commit('PERK_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PERK_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deletePerkByID({ commit }, params) {
			commit('PERK_DELETE_STATUS', 'pending')
			try {
				const { data } = await perkDestroy(params)
				commit('PERK_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('PERK_DELETE_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
	},
}
