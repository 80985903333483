import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const getQuizzes = (
	params = {
		per_page: 10,
		page: 1,
		'filter[language_id]': '',
		'filter[term]': '',
	},
) => Http.get(`/quizzes/fetch${serialize(params)}`)

export const destroyQuiz = (
	params = {
		quizId: null,
	},
) => Http.delete(`/quizzes/${params.quizId}/destroy`)

export const saveQuiz = (
	params = {
		title: null,
		status_id: null,
	},
) => Http.post(`/quizzes/store`, params)

export const updateQuiz = (
	params = {
		quizSlug: null,
		title: null,
		status_id: null,
	},
) => Http.put(`/quizzes/${params.id}/update`, params)

export const translateQuiz = (params = '') => Http.put(`/quizzes/${params.id}/translate`, params)

export const showQuiz = (
	params = {
		quizId: null,
	},
) => Http.get(`/quizzes/${params.quizId}/show`)
