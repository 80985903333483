import {
	deletePulseByID, deletePulseCategoryByID, newPulse, newPulseCategory, pulseByID, pulseCategoryByID, pulsesCategoriesList, pulsesList, updatePulse, updatePulseCategory
} from '@/services/pulses.service';

export default {
	namespaced: true,
	state: {
		// PULSES
		pulsesList: {},
		pulse: {},
		// CATEGORIES
		pulsesCategoriesList: {},
		pulseCategory: {},
		// STATUS
		pulsesStatus: 'pending',
		pulsesSaveStatus: 'init',
		pulsesDeleteStatus: 'init',
	},
	getters: {
		// GET PULSES LIST
		getPulsesList(state){
			return state.pulsesList
		},
		getPulse(state){
			return state.pulse
		},
		// GET PULSES CATEGORIES LIST
		getPulsesCategoriesList(state){
			return state.pulsesCategoriesList
		},
		getPulseCategory(state){
			return state.pulseCategory
		},
		// STATUS
		getPulsesDeleteStatus(state){
			return state.pulsesDeleteStatus
		},
		getPulsesSaveStatus(state){
			return state.pulsesSaveStatus
		},
		getPulsesStatus(state){
			return state.pulsesStatus
		},
	},
	mutations: {
		// PULSES
		SET_PULSES_LIST(state, value) {
			state.pulsesList = value;
		},
		SET_PULSE(state, value) {
			state.pulse = value;
		},
		// PULSES
		SET_PULSES_CATEGORIES_LIST(state, value) {
			state.pulsesList = value;
		},
		SET_PULSE_CATEGORY(state, value) {
			state.pulse = value;
		},
        // STATUS
		PULSES_STATUS(state, value) {
			state.pulsesStatus = value;
		},
		PULSES_SAVE_STATUS(state, value) {
			state.pulsesSaveStatus = value;
		},
		PULSES_DELETE_STATUS(state, value) {
			state.pulsesDeleteStatus = value;
		},
	},
	actions: {
		// PULSES
		async fetchPulses({ commit }, params) {
			commit('SET_PULSES_CATEGORIES_LIST', {});
			commit('PULSES_STATUS', 'pending');
			try {
				const { data } = await pulsesList(params)
				commit('PULSES_STATUS', 'init');
				commit('SET_PULSES_CATEGORIES_LIST', data);
				return data;
			} catch (error) {
				commit('PULSES_STATUS', 'init');
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async fetchPulseByID({ state, commit }, params){
			try {
				const { data } = await pulseByID(params);
				commit('SET_PULSE_CATEGORY', data.data)
				return data;
			} catch (error) {
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error;
			}
		},
		async saveNewPulse({ commit }, params) {
			commit('PULSES_SAVE_STATUS', 'pending');
			try {
				const { data } = await newPulse(params)
				commit('PULSES_SAVE_STATUS', 'init');
				return data;
			} catch (error) {
				commit('PULSES_SAVE_STATUS', 'init');
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async updatePulse({ commit }, params) {
			params = {
				id: params.id,
				name: params.name,
				status: params.status,
				language_id: params.language_id,
			}
			commit('PULSES_SAVE_STATUS', 'pending');
			try {
				const { data } = await updatePulse(params)
				commit('PULSES_SAVE_STATUS', 'init');
				return data;
			} catch (error) {
				commit('PULSES_SAVE_STATUS', 'init');
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async deletePulseByID({ state, commit }, params){
			commit('PULSES_DELETE_STATUS', 'pending');
			try {
				const { data } = await deletePulseByID(params);
				commit('PULSES_DELETE_STATUS', 'init');
				return data;
			} catch (error) {
				commit('PULSES_DELETE_STATUS', 'error');
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error;
			}
		},
		// PULSES CATEGORIES
		async fetchPulsesCategories({ commit }, params) {
			commit('SET_PULSES_CATEGORIES_LIST', {});
			commit('PULSES_STATUS', 'pending');
			try {
				const { data } = await pulsesCategoriesList(params)
				commit('PULSES_STATUS', 'init');
				commit('SET_PULSES_CATEGORIES_LIST', data);
				return data;
			} catch (error) {
				commit('PULSES_STATUS', 'init');
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async fetchPulseCategoryByID({ state, commit }, params){
			try {
				const { data } = await pulseCategoryByID(params);
				commit('SET_PULSE_CATEGORY', data.data)
				return data;
			} catch (error) {
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error;
			}
		},
		async saveNewPulseCategory({ commit }, params) {
			commit('PULSES_SAVE_STATUS', 'pending');
			try {
				const { data } = await newPulseCategory(params)
				commit('PULSES_SAVE_STATUS', 'init');
				return data;
			} catch (error) {
				commit('PULSES_SAVE_STATUS', 'init');
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async updatePulseCategory({ commit }, params) {
			params = {
				id: params.id,
				name: params.name,
				status: params.status,
				language_id: params.language_id,
			}
			commit('PULSES_SAVE_STATUS', 'pending');
			try {
				const { data } = await updatePulseCategory(params)
				commit('PULSES_SAVE_STATUS', 'init');
				return data;
			} catch (error) {
				commit('PULSES_SAVE_STATUS', 'init');
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
		async deletePulseCategoryByID({ state, commit }, params){
			commit('PULSES_DELETE_STATUS', 'pending');
			try {
				const { data } = await deletePulseCategoryByID(params);
				commit('PULSES_DELETE_STATUS', 'init');
				return data;
			} catch (error) {
				commit('PULSES_DELETE_STATUS', 'error');
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error;
			}
		},
	},
}
