import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/http'

// // // LIST ALL Magazines
export const magazineCreate = (params = '') => Http.post('/magazines', params)
export const magazinesList = (params = '') => Http.get(`/magazines${serialize(params)}`)
export const magazineByID = (params = '') => Http.get(`/magazines/${params}`)
export const magazineUpdate = (params = '') => Http.put(`/magazines/${params.id}`, params)
export const magazineDestroy = (params = '') => Http.delete(`/magazines/${params.id}`)

// // // LIST ALL Magazines
export const magazineSectionCreate = (params = '') => Http.post('/magazine-sections', params)
export const magazineSectionsList = (params = '') => Http.get(`/magazine-sections${serialize(params)}`)
export const magazineSectionByID = (params = '') => Http.get(`/magazine-sections/${params}`)
export const magazineSectionUpdate = (params = '') => Http.put(`/magazine-sections/${params.id}`, params)
export const magazineSectionDestroy = (params = '') => Http.delete(`/magazine-sections/${params.id}`)