const categories = [
	{
		path: '/tv/categories/list',
		name: 'tv-categories-list',
		component: () => import('@/views/tv/categories/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default categories
