const collections = [
	{
		path: '/community/wikigamer/collections/list',
		name: 'community-wikigamer-collections-list',
		component: () => import('@/views/community/wikigamer/collections/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/collections/new',
		name: 'community-wikigamer-collections-create',
		component: () => import('@/views/community/wikigamer/collections/upsert.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/community/wikigamer/collections/:collectionSlug/edit',
		name: 'community-wikigamer-collections-edit',
		component: () => import('@/views/community/wikigamer/collections/upsert.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default collections
