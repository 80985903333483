import { destroyPhrase, getPhrases, savePhrase, updatePhrase } from '@/services/community/wikigamer/characters/phrases'

export default {
	namespaced: true,
	state: {
		phraseList: {
			meta: {
				total: 0,
			},
		},
		phraseStatus: 'pending',
	},
	getters: {
		getPhraseList(state) {
			return state.phraseList
		},
		getPhraseStatus(state) {
			return state.phraseStatus
		},
	},
	mutations: {
		SET_PHRASE_LIST(state, value) {
			state.phraseList = value
		},
		SET_PHRASE_STATUS(state, value) {
			state.phraseStatus = value
		},
	},
	actions: {
		async fetchPhrases({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_PHRASE_STATUS', 'pending')
			commit('SET_PHRASE_LIST', {
				meta: {
					total: 0,
				},
			})
			try {
				const { data } = await getPhrases(params)
				commit('SET_PHRASE_STATUS', 'init')
				commit('SET_PHRASE_LIST', data)
				return data
			} catch (error) {
				commit('SET_PHRASE_STATUS', 'init')
				throw error
			}
		},

		async destroyPhraseRecord({ commit }, params) {
			commit('SET_PHRASE_STATUS', 'pending')
			try {
				const { data } = await destroyPhrase(params)
				commit('SET_PHRASE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_PHRASE_STATUS', 'init')
				throw error
			}
		},
		async savePhrase({ commit }, params) {
			commit('SET_PHRASE_STATUS', 'pending')
			try {
				const { data } = await savePhrase(params)
				commit('SET_PHRASE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_PHRASE_STATUS', 'init')
				throw error
			}
		},
		async updatePhrase({ commit }, params) {
			commit('SET_PHRASE_STATUS', 'pending')
			try {
				const { data } = await updatePhrase(params)
				commit('SET_PHRASE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_PHRASE_STATUS', 'init')
				throw error
			}
		},
	},
}
