import {
	getStats,
	newVacancy,
	updateVacancy,
	vacancyByID,
	vacancyDestroy,
	vacancyList,
	vacancyStatusList,
} from '@/services/jobs/vacancies.service'

export default {
	namespaced: true,
	state: {
		// VACANCY
		vacancyList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		vacancyStatusList: [],
		vacancy: {},

		// STATUS
		vacancyStatus: 'init',
		vacancySaveStatus: 'init',
		vacancyDeleteStatus: 'init',

		// STATS
		vacancyStats: [],
		vacancyStatStatus: 'pending',
	},
	getters: {
		// VACANCYS
		getVacancyList(state) {
			return state.vacancyList
		},
		getVacancy(state) {
			return state.vacancy
		},
		getVacancyStatusList(state) {
			return state.vacancyStatusList
		},

		// STATUS
		getVacancyDeleteStatus(state) {
			return state.vacancyDeleteStatus
		},
		getVacancySaveStatus(state) {
			return state.vacancySaveStatus
		},
		getVacancyStatus(state) {
			return state.vacancyStatus
		},

		// STATS
		getVacancyStats(state) {
			return state.vacancyStats
		},
		getVacancyStatStatus(state) {
			return state.vacancyStatStatus
		},
	},
	mutations: {
		// VACANCY
		VACANCY_LIST(state, value) {
			state.vacancyList = value
		},
		VACANCY_STATUS_LIST(state, value) {
			state.vacancyStatusList = value
		},
		SET_VACANCY(state, value) {
			state.vacancy = value
		},

		// STATUS
		VACANCY_STATUS(state, value) {
			state.vacancyStatus = value
		},
		VACANCY_SAVE_STATUS(state, value) {
			state.vacancySaveStatus = value
		},
		VACANCY_DELETE_STATUS(state, value) {
			state.vacancyDeleteStatus = value
		},

		// STATS
		SET_VACANCY_STATS(state, value) {
			state.vacancyStats = value
		},
		SET_VACANCY_STAT_STATUS(state, value) {
			state.vacancyStatStatus = value
		},
	},
	actions: {
		// VACANCY
		async getVacancyList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('VACANCY_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			commit('VACANCY_STATUS', 'pending')
			try {
				const { data } = await vacancyList(params)
				commit('VACANCY_STATUS', 'init')
				commit('VACANCY_LIST', data)
				return data
			} catch (error) {
				commit('VACANCY_STATUS', 'init')
				throw error
			}
		},
		async deleVacancyByID({ commit }, params) {
			commit('VACANCY_DELETE_STATUS', 'pending')
			try {
				const { data } = await vacancyDestroy(params)
				commit('VACANCY_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('VACANCY_DELETE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async updateVacancy({ commit }, params) {
			commit('VACANCY_SAVE_STATUS', 'pending')
			try {
				const { data } = await updateVacancy(params)
				commit('VACANCY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('VACANCY_SAVE_STATUS', 'init')
				throw error
			}
		},
		async fetchVacancyByID({ state, commit }, params) {
			commit('VACANCY_STATUS', 'pending')
			try {
				const { data } = await vacancyByID(params)
				commit('VACANCY_STATUS', 'init')
				commit('SET_VACANCY', data.data)
				return data
			} catch (error) {
				commit('VACANCY_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},
		async fetchStats({ commit }, params) {
			commit('SET_VACANCY_STATS', [])
			commit('SET_VACANCY_STAT_STATUS', 'pending')
			try {
				const { data } = await getStats(params)
				commit('SET_VACANCY_STAT_STATUS', 'init')
				commit('SET_VACANCY_STATS', data)
				return data
			} catch (error) {
				commit('SET_VACANCY_STAT_STATUS', 'init')
				throw error
			}
		},
		async getVacancyStatusList({ commit }, params) {
			commit('VACANCY_STATUS', 'pending')
			try {
				const { data } = await vacancyStatusList(params)
				commit('VACANCY_STATUS', 'init')

				if (data.data) {
					data.data.sort((a, b) => a.id - b.id)
				}

				commit('VACANCY_STATUS_LIST', data.data)
				return data
			} catch (error) {
				commit('VACANCY_STATUS', 'init')
				throw error
			}
		},
		async saveNewVacancy({ commit }, params) {
			commit('VACANCY_SAVE_STATUS', 'pending')
			try {
				const { data } = await newVacancy(params)
				commit('VACANCY_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('VACANCY_SAVE_STATUS', 'init')
				throw error
			}
		},
	},
}
