import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpAcademy'

// LIST ALL COURSES
export const courseList = (params = '') => Http.get(`/courses${serialize(params)}`)

export const courseCreate = (params = '') => Http.post('/courses', params)
export const courseByID = (params = '') => Http.get(`/courses/${params}`)
export const courseUpdate = (params = '') => Http.put(`/courses/${params.id}`, params)
export const courseDestroy = (params = '') => Http.delete(`/courses/${params.id}`)
